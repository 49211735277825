import {
    getSpriteCharacterOfGender,
    getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";
import { setVisibleTeacher } from "../../../../../Components/dialogueSystem/scripts/VisibleTeacher";

// background
const imikn_ped = require("../../../assets/imikn/backgrounds/imikn_ped.jpg");

// character
const guide_default = require("../../../assets/imikn/characters/guide.png");
const guide_smile = require("../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let direction_ped = {
    Start: [
        {
            body: [
                {
                    background: imikn_ped,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Так-с, педагогическое образование с двумя профилями, с чего же начать?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Во-первых, это уникальный бакалавриат, то есть обычно на таких программах студенты учатся 4 года",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Но так как здесь преподают целых два профиля: математику и информатику, обучение будет длиться 5 лет, почти специалитет!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А если я хочу быть учителем только математики, можно выбрать один профиль?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "В нашем университете нельзя, возможно в других можно...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Но насколько я знаю математика, чаще всего, идёт в паре с чем-то другим, например, с физикой или информатикой",
                },
            ],
        },
        {
            choices: [
                "И нет других вариантов, чтобы выбрать один профиль?|direction_ped_other_options",
                "Понятно|direction_ped_continue",
            ],
        },
    ],

    direction_ped_other_options: [
        {
            body: [
                {
                    background: imikn_ped,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну почему же, есть!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Расскажи, пожалуйста, это очень интересно!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Смотри, можно получить диплом бакалавра математики или другой математической специальности в ВУЗе",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Затем попытаться устроиться в школу, и уже от предприятия пройти профессиональную переподготовку на учителя средних и старших классов",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Довольно рискованный вариант, если нет связей в школе, не думаешь?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Да, но самый быстрый! Получение диплома займёт 4 года, а во время переподготовки уже можно работать стажёром и получать опыт!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Есть и другой способ",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "При наличии диплома бакалавра любой специальности можно поступить в магистратуру и получить необходимые знания в педагогике и психологии",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Но это целых 6 лет!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Да, обучение получается довольно длительное",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Но не стоит забывать, что педагогам необходимо непрерывно учиться на протяжении всей профессиональной деятельности!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Почему? Разве в ВУЗе дают недостаточно знаний?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "На этот вопрос нет точного ответа...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Сейчас все сферы жизни человечества быстро развиваются, и учителям тоже нужно не отставать от мира...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Проходить курсы повышения квалификации и даже сдавать экзамены!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Ого! Получается педагоги не только учат, но и постоянно учатся сами, как интересно!",
                },
            ],
        },
        {
            choices: [
                "Но ты рассказал про то, как стать учителем средних и старших классов, а что насчёт начальной школы и преподавания в ВУЗе?|direction_ped_option",
                "*Промолчать*|direction_ped_continue",
            ],
        },
    ],

    direction_ped_option: [
        {
            body: [
                {
                    background: imikn_ped,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Для начальной школы достаточно окончить колледж или ВУЗ по направлению “педагогическое образование” с профилем “начальное образование”",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А чтобы претендовать на должность преподавателя ВУЗа, необходимо иметь высшее образование не ниже специалиста или магистра",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Теперь пазл сложился, понятно",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_ped_continue",
        },
    ],

    direction_ped_continue: [
        {
            body: [
                {
                    background: imikn_ped,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "В целом, выпускники данного направления могут пойти работать не только учителями в школы",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А кем ещё и куда?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Если продолжать преподавательскую карьеру, но не в государственных школах",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "То можно пойти работать частным репетитором или преподавателем в специальные организации по дополнительному образованию",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Те, у кого уже есть опыт преподавания могут пойти выше по карьерной лестнице",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Завучем, директором школы, а дальше в структуры, которые контролируют и развивают образование в целом",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Что это за структуры?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Например, в Тюменской области - это Тюменский областной государственный институт развития регионального образования или ТОГИРРО",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Он занимается контролем качества образования",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "У тебя в школе наверняка были дни",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Когда учителя особо настойчиво просили вести себя очень культурно, не шуметь и здороваться со всеми взрослыми?",
                },
            ],
        },
        {
            choices: [
                "Да|direction_ped_yes",
                "Нет|direction_ped_short_no",
            ],
        },
    ],

    direction_ped_yes: [
        {
            body: [
                {
                    background: imikn_ped,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Так вот это перед приходом проверяющей комиссии из подобной организации!",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_ped_continue_2",
        },
    ],

    direction_ped_short_no: [
        {
            body: [
                {
                    background: imikn_ped,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Значит учителя были уверены в хорошем поведении своих учеников и не боялись проверок из подобных организаций!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Похвально, редко такое встретишь!",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_ped_continue_2",
        },
    ],

    direction_ped_continue_2: [
        {
            body: [
                {
                    background: imikn_ped,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Также ТОГИРРО следит за содержанием образовательных программ",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Вносит корректировки и создаёт методические пособия, скажем так “инструкции преподавания” для учителей",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А зачем менять содержание образовательных программ?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Из-за учёных!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Понимаешь, появляются новые данные, новые знания, некоторые прошлые знания становятся неактуальными и их нужно периодически обновлять",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Именно эта структура в нашем регионе следит за подобным",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Также они создают курсы повышения квалификации для учителей, поддерживают талантливых учеников и разрабатывают олимпиады",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Ого! Это действительно ступенька повыше, чем работа школьным учителем. Как интересно!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ещё можно из образования перейти в культуру",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Если больше нравятся точные науки, а не творческая составляющая",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "То после прохождения определённых курсов, можно пойти работать программистом или аналитиком",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Что же тут изучается, если список потенциальных профессий такой большой?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Я думал ты и не спросишь!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Здесь изучают педагогику, психологию, конфликтологию, высшую математику, информатику и основы программирования",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Будущие педагоги более чем подготовлены с точки зрения знаний, а вот понравится ли им работа в действительности...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Выдержат ли они её трудности, не знает никто",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Почему? По-моему, учителя еще с детства чувствуют свое призвание...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Понимаешь, работа учителя очень специфическая...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Во-первых, способность к обучению других людей есть не у каждого с рождения",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "На ее развитие, как и на любой другой навык, потребуется много усилий и практики",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А во-вторых, люди, которых предстоит учить, могут быть очень разными...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Нужно понимать их психологию, причину поступков, избегать и разрешать конфликтные ситуации",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "В некоторых моментах суметь достучаться, уметь находить подход к каждому индивидуально!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Не у каждого, даже подготовленного человека, найдётся столько терпения, сил и выдержки для этого дела",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Хм, действительно, у меня даже мыслей таких не было...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Поэтому, чтобы подготовить будущих учителей, предусмотрено большое количество практики",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Как теоретической: в виде проектов и курсовых",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Так и в реальных организациях: в учебных лагерях для детей и в общеобразовательных школах. Но не всегда этого достаточно...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Хочешь, дам ценный совет по обучению на этом направлении? Как можно самостоятельно практиковаться и понять, твоё это или нет?",
                },
            ],
        },
        {
            choices: [
                "Да, давай!|direction_ped_yes_2",
                "Нет, спасибо, хочу скорее узнать про другие направления|direction_ped_no_2",
            ],
        },
    ],

    direction_ped_yes_2: [
        {
            body: [
                {
                    background: imikn_ped,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Всё гениальное - просто! Начиная со второго курса, можно подрабатывать репетитором для детей",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это будет индивидуальной практикой для тебя, проверкой на стойкость и интерес",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А также порадует приятный бонус в виде зарплаты, так необходимой каждому студенту!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "В процессе работы попадаются самые разнообразные люди, как ученики, так и их родители",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Возникают удивительные ситуации, оттачиваются навыки и развивается смекалка",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну и немаловажным для новичка плюсом является простой способ отследить результат своей работы",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это оценки ребёнка в школе, баллы за экзамены и в целом, вид того как на твоих глазах человек растёт",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "И начинает понимать новые вещи благодаря тебе!",
                    achiev_child_id: 4, // 1.3
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_ped_exit",
        },
    ],
    
    direction_ped_no_2: [
        {
            body: [
                {
                    background: imikn_ped,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Хорошо, не буду задерживать",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_ped_exit",
        },
    ],

    direction_ped_exit: [
        {
            body: [
                {
                    background: imikn_ped,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Можешь ещё потом побеседовать с Талалаем Ярославовичем - преподавателем ответственным за данное направление",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    rightSprite: guide_smile,
                    speakColor: guide_color,
                    text: "Он несомненно даст несколько ценных советов, и с ним довольно приятно общаться!",
                    visibleTeacher: "tal_yar",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Хорошо, загляну к нему",
                },
            ],
        },
        {
            exits: true,
        },
    ],
};

export default direction_ped;
