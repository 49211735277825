import { Box, Card, Typography } from "@mui/material";
function CardAbout(props) {
  const beforeAfterStyle = {
    content: "",
    position: "absolute",
    inset: "1.5rem",
    border: `${props.color} 0.5rem solid`,
  };

  const cardStyle = {
    position: "relative",
    height: "100%",
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#ffffff",
    "& .hover-text": {
      visibility: "hidden",
    },
  };
  const cardSelectStyle = {
    position: "absolute",
    height: "100%",
    width: "100%",
    color: "#ffffff",
    backgroundColor: "#ffffff",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      background: `linear-gradient(${props.colorGradient})`,
      height: 0,
      transition: "height 0.5s",
      zIndex: 1,
    },
    "&:hover::after": {
      height: "100%",
    },
    "& .hover-text": {
      zIndex: 8,
      visibility: "hidden",
    },
    "&:hover .hover-text": {
      visibility: "visible",
    },
    "& .hover-label": {
      zIndex: 8,
      visibility: "visible",
    },
    "&:hover .hover-label": {
      visibility: "hidden",
    },
    "@media (max-width: 700px)": {
      position: "relative", // Change position to relative on smaller screens
    },
  };
  return (
    <>
      <Card sx={props.selectedId === props.id ? cardSelectStyle : cardStyle}>
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            backgroundImage: `url(${props.image})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            backgroundPositionY: `${props.positionY}`,
            opacity: 0.8,
            filter: "blur(2rem) saturate(0.9)",
            boxShadow: "0 -1.5rem 2rem -0.5rem rgba(0, 0, 0, 0.7)",
          }}
        ></Box>
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent 40%), url(${props.image})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            backgroundPositionY: `${props.positionY}`,
            maskImage: `url(${props.image})`,
            maskSize: "cover",
            maskPosition: "top",
            WebkitMaskPositionY: `${props.positionY}`,
          }}
        ></Box>
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent 40%), url(${props.image})`,
            backgroundSize: "cover",
            backgroundPosition: "top",
            backgroundPositionY: `${props.positionY}`,
            maskImage: `url(${props.image})`,
            maskSize: "cover",
            maskPosition: "top",
            WebkitMaskPositionY: `${props.positionY}`,
            zIndex: 3,
          }}
        ></Box>
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            inset: 0,
            padding: "15%",
            zIndex: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center", 
              alignItems: "flex-end", 
              position: "absolute",
              inset: 0,
              padding: "5% 5% 20% 5%",
              mb: -5,
              zIndex: 7,
              '@media (max-width: 300px)': {
                padding: "5% 5% 15% 5%",
              },
            }}
          >
            <Typography
              className="hover-text"
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                width: "fit-content",
                padding: "1%",
                '@media (max-width: 1300px) and (min-width: 1050px)': {
                  fontSize: "85%",
                  },
                '@media (max-width: 1050px) and (min-width: 900px)': {
                  fontSize: "80%",
                  },
                '@media (max-width: 900px) and (min-width: 600px)': {
                fontSize: "120%",
                },
                '@media (max-width: 600px) and (min-width: 300px)': {
                  fontSize: '3.3vw',
                  width:"200%"
                },
                '@media (max-width: 300px)': {
                  fontSize: '4vw',
                  width:"200%"
                },
              }}
            >
              {props.desc}
            </Typography>
          </Box>

          <Box className="hover-label">
            <Typography
              variant="body2"
              component="h5"
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                width: "fit-content",
                padding: "2%",
                mb: 1,
                '@media (max-width: 900px) and (min-width: 500px)': {
                  fontSize: "120%",
                },
                '@media (max-width: 250px)': {
                  fontSize: '5vw',
                },
              }}
            >
              {props.name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                width: "fit-content",
                padding: "5px",
                '@media (max-width: 900px) and (min-width: 500px)': {
                  fontSize: "120%",
                },
                '@media (max-width: 250px)': {
                  fontSize: '5vw',
                },
              }}
            >
              {props.label}
            </Typography>
          </Box>
        </Box>
        <Box style={beforeAfterStyle}></Box>
      </Card>
    </>
  );
}

export default CardAbout;
