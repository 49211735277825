import React from "react";
import { Route, Routes } from "react-router-dom";
import * as Router from "./RouteNames";

import Login from "../Pages/auth/LoginPage";
import Register from "../Pages/auth/RegisterPage";
import Recover from "../Pages/auth/RecoverPage";
import PasswordChange from "../Pages/auth/PasswordChangePage";

import Home from "../Pages/home/HomePage";
import News from "../Pages/news/NewsPage";
import NewsDetail from "../Pages/news/NewsDetail";

import Feedback from "../Pages/FeedbackPage";
import Character from "../Pages/CharacterPage";
import AboutUs from "../Pages/about/AboutUsPage";

import GameSection from "../Pages/gameSection/GameSectionPage";
import GameSectionInst from "../Pages/gameSection/GameSectionInstPage";
import IMIKNDirection from "../Games/imikn/sections/DirectionPage";
import IMIKNTeacher from "../Games/imikn/sections/TeacherPage";
import IMIKNLesson from "../Games/imikn/sections/LessonLocationPage";

import StudBook from "../Pages/studentBook/StudentBookPage";
import Intro from "../Pages/studentBook/IntroPage";
import Conclusion from "../Pages/studentBook/ConclusionPage";

import Profile from "../Pages/ProfilePage";
import Error403 from "../Pages/Error/403";
import Error404 from "../Pages/Error/404";
import CheckRole from "../Services/CheckRole";
import Achievement from "../Pages/achievement/AchievementPage";
import Achievements from "../Pages/achievement/AllAchievementPage";
import AchievementDetails from "../Pages/achievement/AchievementDetails";

import DashboardAdmin from "../Pages/adminPanel/DashboardAdmin";
import VocabularyIndex from "../Pages/adminPanel/vocabulary/Index";
import StoryIndex from "../Pages/adminPanel/story/Index";
import UserIndex from "../Pages/adminPanel/user/Index";
import InstituteIndex from "../Pages/adminPanel/institute/Index";
import DirectionIndex from "../Pages/adminPanel/direction/Index";
import RoleIndex from "../Pages/adminPanel/role/Index";
import FeedbackIndex from "../Pages/adminPanel/feedback/Index";
import AchievementIndex from "../Pages/adminPanel/achievement/Index";

function AppRouter() {
  return (
    <Routes>
      <Route path="*" element={<Error404 />} />
      <Route path={Router.LANDING} element={<Home />} />
      <Route path={Router.LOGIN} element={<Login />} />
      <Route path={Router.REGISTER} element={<Register />} />
      <Route path={Router.RECOVER} element={<Recover />} />
      <Route
        path={Router.RECOVER + Router.PASSWORD_CHANGE}
        element={<PasswordChange />}
      />
     
      <Route path={Router.NEWS + Router.ID} element={<NewsDetail />} />
      <Route path={Router.FEEDBACK} element={<Feedback />} />
      <Route path={Router.CHARACTER} element={<Character />} />
      <Route path={Router.ABOUTUS} element={<AboutUs />} />
      <Route path={Router.PROFILE_USER} element={<Profile />} />
      <Route path={Router.ACHIEVEMENT} element={<Achievements />} />
      <Route path={Router.ACHIEVEMENT + Router.INSTNAME} element={<Achievement />} />
      <Route
        path={Router.ACHIEVEMENT + Router.INSTNAME + Router.ID}
        element={<AchievementDetails />}
      />
      <Route path={Router.GAMESECTION} element={<GameSection />} />
      <Route
        path={Router.GAMESECTION + Router.INSTNAME}
        element={<GameSectionInst />}
      />
      <Route
        path={Router.GAMESECTION + Router.IMIKN + Router.DIRECTIONSECTION}
        element={<IMIKNDirection />}
      />
      <Route
        path={Router.GAMESECTION + Router.IMIKN + Router.TEACHERSECTION}
        element={<IMIKNTeacher />}
      />
      <Route
        path={Router.GAMESECTION + Router.IMIKN + Router.LESSONSECTION}
        element={<IMIKNLesson />}
      />
      <Route path={Router.STUDENTBOOK} element={<StudBook />} />
      <Route
        path="/studentbook/:instName/:directionName"
        element={<StudBook />}
      />
      <Route path={Router.STUDENTBOOK + Router.INTRO} element={<Intro />} />
      <Route
        path={Router.STUDENTBOOK + Router.CONCLUSION}
        element={<Conclusion />}
      />
      {/* ADMIN PANEL */}
      <Route
        path={Router.ADMIN + Router.ADMIN_DASHBOARD}
        element={
          <CheckRole
            roles={["SuperAdmin", "Admin", "Moderator"]}
            children={<DashboardAdmin />}
            error={<Error403 />}
          />
        }
      />
      <Route
        path={Router.ADMIN + Router.ADMIN_VOCABULARY}
        element={
          <CheckRole
            roles={["SuperAdmin", "Admin", "Moderator"]}
            children={<VocabularyIndex />}
            error={<Error403 />}
          />
        }
      />
      <Route
        path={Router.ADMIN + Router.ADMIN_STORY}
        element={
          <CheckRole
            roles={["SuperAdmin", "Admin", "Moderator"]}
            children={<StoryIndex />}
            error={<Error403 />}
          />
        }
      />
      <Route
        path={Router.ADMIN + Router.ADMIN_INSTITUTE}
        element={
          <CheckRole
            roles={["SuperAdmin"]}
            children={<InstituteIndex />}
            error={<Error403 />}
          />
        }
      />
      <Route
        path={Router.ADMIN + Router.ADMIN_DIRECTION}
        element={
          <CheckRole
            roles={["SuperAdmin"]}
            children={<DirectionIndex />}
            error={<Error403 />}
          />
        }
      />
      <Route
        path={Router.ADMIN + Router.ADMIN_ROLE}
        element={
          <CheckRole
            roles={["SuperAdmin"]}
            children={<RoleIndex />}
            error={<Error403 />}
          />
        }
      />
      <Route
        path={Router.ADMIN + Router.ADMIN_USER}
        element={
          <CheckRole
            roles={["SuperAdmin", "Admin"]}
            children={<UserIndex />}
            error={<Error403 />}
          />
        }
      />
      <Route
        path={Router.ADMIN + Router.FEEDBACK}
        element={
          <CheckRole
            roles={["SuperAdmin", "Admin"]}
            children={<FeedbackIndex />}
            error={<Error403 />}
          />
        }
      />
      <Route
        path={Router.ADMIN + Router.ACHIEVEMENT}
        element={
          <CheckRole
            roles={["SuperAdmin", "Admin"]}
            children={<AchievementIndex />}
            error={<Error403 />}
          />
        }
      />
    </Routes>
  );
}

export default AppRouter;
