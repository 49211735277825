import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function IntroConclusion(props) {
  return (
    <>
      <Grid
        item
        sx={{
          maxWidth: 800,
          minWidth: 200,
          backgroundColor: "#F4F4F4",
          mr: 10,
          position: "relative",
          boxShadow:
            "0px 0px 37px 16px rgba(0, 0, 0, 0.26), 40px 25px 0px 0px rgba(0, 194, 255, 1), 40px -15px 0px 0px rgba(0, 194, 255, 1), 40px 0px 0px 0px rgba(0, 194, 255, 1)",
          px: "40px",
        }}
      >
        <Grid sx={{ borderLeft: "2px solid rgb(200, 200, 200)", my: 6 }}>
          <Grid
            container
            wrap="nowrap"
            spacing={2}
            sx={{
              mx: 4,
            }}
          >
            <Grid item xs>
              <Typography
                sx={{
                  color: "black",
                  maxWidth: "calc(100% - 60px)",
                  fontSize: "calc(16px + 0.5vw)",
                }}
                variant="h5"
                align="center"
              >
                {props.title}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            wrap="nowrap"
            spacing={2}
            sx={{
              mx: 4,
              p: 2,
            }}
          >
            <Grid item xs>
              <Typography
                sx={{
                  color: "black",
                  maxWidth: "calc(100% - 60px)",
                  textAlign: "justify",
                  fontSize: "calc(11px + 0.5vw)",
                }}
                variant="body1"
              >
                {props.text}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
