import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import CardStoriesAuth from "./CardStoriesAuth";
import CardStoriesGuest from "./CardStoriesGuest";
import AuthUser from "../../Services/AuthUser";
import CardStoriesNull from "./CardStoriesNull";

export default function ListStoriesBook(props) {
  const { getToken } = AuthUser();
  const {
    instName,
    stories,
    setStories,
    storyToShow,
    setStoryToShow,
    directions,
  } = props;

  const [page, setPage] = React.useState(1);

  const handleChange = (event, value) => {
    setPage(value);
    setStoryToShow(stories[value - 1]);
  };

  const handleRatingChange = (storyId, rating) => {
    const updatedStories = stories.map((story) => {
      if (story.id === storyId) {
        return {
          ...story,
          rating,
        };
      }
      return story;
    });
    setStories(updatedStories);
    // console.log(storyId, rating);
  };

  const handleChipClick = (value) => {
    const filteredStories = stories.filter((story) => {
      return story.keywords.some((word) => word.keyword.includes(value));
    });

    setStories(filteredStories);
    setStoryToShow(filteredStories[0]);
    setPage(1);
  };

  function CardStories() {
    if (!getToken()) {
      if (stories.length === 0) {
        return <CardStoriesNull />;
      } else {
        return (
          <CardStoriesGuest
            currentStory={storyToShow}
            // keyword={storyToShow.keywords}
            count={stories.length}
            page={page}
            onChange={handleChange}
            instName={instName}
            directionButton={directions}
            onChipClick={handleChipClick}
          />
        );
      }
    } else {
      if (stories.length === 0) {
        return <CardStoriesNull />;
      } else {
        return (
          <CardStoriesAuth
            currentStory={storyToShow}
            // keyword={storyToShow.keywords}
            count={stories.length}
            page={page}
            onChange={handleChange}
            instName={instName}
            directionButton={directions}
            handleRatingChange={handleRatingChange}
            onChipClick={handleChipClick}
          />
        );
      }
    }
  }
  return (
    <>
      <Grid
        item
        sx={{
          maxWidth: 800,
          minWidth: 200,
          backgroundColor: "#F4F4F4",
          mr: 10,
          position: "relative",
          boxShadow:
            "0px 0px 37px 16px rgba(0, 0, 0, 0.26), 40px 25px 0px 0px rgba(0, 194, 255, 1), 40px -15px 0px 0px rgba(0, 194, 255, 1), 40px 0px 0px 0px rgba(0, 194, 255, 1)",
          px: "40px",
        }}
      >
        {CardStories()}
      </Grid>
    </>
  );
}
