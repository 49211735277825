import Box from "@mui/material/Box";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Tooltip from "@mui/material/Tooltip";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

export default function BoxGame(props) {
  const handle = useFullScreenHandle();
  return (
    <>
      <FullScreen handle={handle}>
        <Box
          sx={{
            width: handle.active ? "100%" : "100%",
            height: handle.active ? "100%" : "100%",
            fontSize: handle.active ? "150%" : "80%",
            borderRadius: "8px",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            mt: 6,
            mb: 3,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
        >
          {props.main}
        </Box>
      </FullScreen>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "flex-end",
          mb: 3,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <Tooltip title="Полноэкранный режим" placement="right-end">
          <FullscreenIcon
            onClick={handle.enter}
            sx={{
              fontSize: 60,
              color: "rgba(255, 255, 255, 0.7)",
              cursor: "pointer",
              "&:hover": {
                color: "#000",
              },
            }}
          />
        </Tooltip>
      </Box>
    </>
  );
}
