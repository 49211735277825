import CardAchievement from "./Card";
import { Grid, Box, Container, Typography } from "@mui/material";
import AuthUser from "../../Services/AuthUser";
import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";

export default function Achievement(props) {
  const theme = useTheme();
  const [showProgress, setShowProgress] = useState(true);
  const { http } = AuthUser();
  const [achievements, setAchievements] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));

  const getAchievements = async () => {
    try {
      const response = await http.get(
        `/achievement/${props.instPath}/${user.id}`
      );
      setAchievements(response.data);
      sessionStorage.setItem("achievements", JSON.stringify(response.data));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAchievements();
    const timer = setTimeout(() => {
      setShowProgress(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Container>
        <Box
          sx={{
            mt: 5,
            mb: 5,
            color: "white"
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${require("../../Assets/imikn/cover_imikn.jpg")})`,
              backgroundPosition: "2430px center",
              p: 6,
              boxShadow: `0px 0px 15px 2px ${theme?.palette?.box.shadow}`,
              borderRadius: 3,
            }}
          >
            <Typography
              align="center"
              variant="body"
              component="h2"
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                width: "fit-content",
                margin: "0 auto",
                padding: "10px",
                borderRadius: 5,
              }}
            >
              {props.nameInst}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 2, sm: 4, md: 6, lg: 8 }}
            >
              {showProgress ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                achievements.map((achievement, index) => (
                  <Grid item xs key={index}>
                    <CardAchievement
                      id={achievement.id}
                      instName={props.instPath}
                      img={require(`../../Assets/${props.instPath}/achievement/${achievement.achiev_img_name}`)}
                      title={achievement.achiev_name}
                      desc={achievement.achiev_description}
                      is_issued={achievement.is_issued}
                      achievChild={achievement.achiev_child}
                    />
                  </Grid>

                  // <Grid item key={achievement.id} xs={12} sm={6} md={4}>
                  //   <CardAchievement
                  //     id={achievement.id}
                  //     instName={props.instPath}
                  //     img={require(`../../Assets/${props.instPath}/achievement/${achievement.achiev_img_name}`)}
                  //     title={achievement.achiev_name}
                  //     desc={achievement.achiev_description}
                  //     isAchieved={achievement.is_issued}
                  //     achievChild={achievement.achiev_child}
                  //   />
                  // </Grid>
                ))
              )}
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}
