import React, { createContext, useMemo } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import useLocalStorage from "../Hooks/useLocalStorage";
import { darkTheme, lightTheme } from "./Theme"

export const ThemeContext = createContext();

export const ThemeModeProvider = ({ children }) => {
	const [darkMode, setDarkMode] = useLocalStorage("darkTheme", useMediaQuery("(prefers-color-scheme: dark)"));

	const mode = useMemo(
		() => ({
			toggleColorMode: () => {
				setDarkMode(!darkMode);
			},
		}),
		[darkMode, setDarkMode]
	);

	return (
		<ThemeContext.Provider value={mode}>
			<ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</ThemeContext.Provider>
	);
};
