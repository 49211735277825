import {
    getSpriteCharacterOfGender,
    getNamePlayer,
} from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_first_coridor = require("../../../../assets/imikn/backgrounds/imikn_first_coridor.jpg");

// character
const guide_default = require("../../../../assets/imikn/characters/guide.png");
const guide_smile = require("../../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let directions_intro_4 = {
    Start: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ой, так устал рассказывать, сбегаешь мне за водичкой в буфет?",
                },
            ],
        },
        {
            choices: [
                "Без проблем!|directions_intro_4_yes",
                "А призраки пьют воду?|directions_intro_4_no",
            ],
        },
    ],

    directions_intro_4_yes: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: "",
                    centerSprite: "",
                    rightSprite: "",
                    speakSprite: "",
                    nameSpeak: "",
                    speakColor: guide_color,
                    text: "*пять минут спустя*",
                },
                {
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    nameSpeak: getNamePlayer(),
                    speakSprite: "left",
                    speakColor: player_color,
                    text: "Вот, держи, уф-ф-ф",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Я тут вспомнил, что призраки не пьют, ха-ха-ха, но спасибо!",
                },
                {
                    nameSpeak: getNamePlayer(),
                    speakSprite: "left",
                    speakColor: player_color,
                    text: "Да ты издеваешься...",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Не сердись, давай я тебе лучше про какое-нибудь направление расскажу...",
                    achiev_child_id: 3, // 1.2
                },
            ],
        },
        {
            exits: true,
        },
    ],

    directions_intro_4_no: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ой, точно!",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Чуть не заставил тебя зря тратить энергию, ха-ха, пошли дальше!",
                },
            ],
        },
        {
            exits: true,
        },
    ],
};

export default directions_intro_4;
