import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import AuthUser from "../../../../Services/AuthUser";
import AchievementChild from "./child/AchievementChild";
// import UploadImage from "./UploadImagetermIDinstitute ";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";

const EditAchievementModal = (props) => {
  const { token, http } = AuthUser();
  const { open, close, achievement, setAchievements } = props;
  const [achievementIDinstitute, setAchievementIDinstitute] = useState("");
  const [achievementImg, setAchievementImg] = useState("");
  const [institutes, setInstitutes] = useState([]);

  useEffect(() => {
    const getInstitutes = async () => {
      const response = await http.get("/institutes", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setInstitutes(response.data);
    };
    getInstitutes();

    setAchievementImg(achievement?.achiev_img_name || "");
    setAchievementIDinstitute(achievement?.achiev_id_inst || "");
  }, [achievement]);

  const handleSave = () => {
    const data = {
      achiev_img_name: achievementImg,
      achiev_id_inst: achievementIDinstitute,
    };
    // console.log(data);
    http
      .put(`/admin/achievements/${achievement.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setAchievements((prevAchievements) =>
          prevAchievements.map((achiev) =>
            achiev.id === achievement.id ? res.data : achiev
          )
        );
        close();
      });
  };

  const handleSaveChild = (updatedChild, panelId) => {
    const data = {
      achiev_name: updatedChild.achiev_name,
      achiev_description: updatedChild.achiev_description,
      achiev_child_img: updatedChild.achiev_child_img,
      achiev_id_child: achievement.id,
    };
    // console.log(panelId);
    http
      .put(`/admin/achievements/${panelId}/child`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
      });
  };
  const handleDeleteChild = (childId) => {
    http
      .delete(`/admin/achievements/${childId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddChild = () => {
    const data = {
      achiev_name: "default",
      achiev_description: "default",
      achiev_child_img: "default.png",
      achiev_id_child: achievement.id,
    };
    http
      .post(`/admin/achievements/${achievement.id}/child`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleInstituteChange = (event) => {
    setAchievementIDinstitute(event.target.value);
  };
  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Редактировать институт</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Введите новое название изображения
        </DialogContentText>
        <TextField
          autoFocus
          margin="normal"
          id="name_inst"
          label="Название изображения"
          type="text"
          fullWidth
          variant="outlined"
          value={achievementImg}
          onChange={(e) => setAchievementImg(e.target.value)}
          required
        />
        {/* <UploadImage /> */}
        <TextField
          margin="normal"
          id="id_inst"
          label="Институт"
          select
          fullWidth
          value={achievementIDinstitute}
          onChange={handleInstituteChange}
          required
        >
          {institutes.map((inst) => (
            <MenuItem key={inst.id} value={inst.id}>
              {inst.inst_name}
            </MenuItem>
          ))}
        </TextField>
        <Divider />
        {achievement?.achiev_child &&
          achievement?.achiev_child.map((item, index) => (
            <AchievementChild
              key={index}
              achiev_child={item}
              panelId={item.id}
              onSave={handleSaveChild}
              onDelete={handleDeleteChild}
            />
          ))}
        <IconButton
          size="small"
          aria-label="edit"
          onClick={handleAddChild}
          sx={{ ml: 2 }}
        >
          <AddIcon />
        </IconButton>
      </DialogContent>

      <DialogActions>
        <Button onClick={close} color="primary">
          Отмена
        </Button>
        <Button onClick={handleSave} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAchievementModal;
