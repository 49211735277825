import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import React, { useState, useEffect } from "react";
import AuthUser from "../../../../Services/AuthUser";
import Heading from "../../../../Components/adminpanel/Heading";
import AddAchievementModal from "./Add";
import EditAchievementModal from "./Edit";
export default function ListIndex() {
  const [openDialog, setOpenDialog] = useState(false);
  const [achievements, setAchievements] = useState([]);
  const { token, http } = AuthUser();
  const [selectedAchievement, setSelectedAchievement] = useState(null);

  const buttonInfo = {
    value: "Добавить",
    onClick: () => setOpenDialog(true),
  };

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    // { field: "achiev_name", headerName: "Название", width: 200, flex: 0.5 },
    // {
    //   field: "achiev_description",
    //   headerName: "Описание",
    //   width: 200,
    //   flex: 1,
    // },
    {
      field: "achiev_img_name",
      headerName: "Изображение",
      width: 200,
      flex: 1,
    },
    {
      field: "achiev_child",
      headerName: "Количество детей",
      width: 200,
      flex: 1,
      valueGetter: (params) => {
        return params.row.achiev_child ? params.row.achiev_child.length : 0;
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      flex: 0,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => editAchievement(params.id)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => deleteAchievement(params.id)}
        />,
      ],
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get("/admin/achievements", {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        // console.log(response.data);
        setAchievements(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const editAchievement = (id) => {
    const institute = achievements.find((inst) => inst.id === id);
    setSelectedAchievement(institute);
  };

  const deleteAchievement = async (id) => {
    try {
      await http.delete(`/admin/achievements/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setAchievements((prevAchievements) =>
        prevAchievements.filter((institute) => institute.id !== id)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddAchievement = async () => {
    try {
      const response = await http.get("/admin/achievements", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setAchievements(response.data);
      setOpenDialog(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Heading title="Достижения" button={buttonInfo} />
      <Box sx={{ width: "100%", mt: 3 }}>
        <DataGrid rows={achievements} columns={columns} />
      </Box>
      <AddAchievementModal
        open={openDialog}
        close={() => setOpenDialog(false)}
        handleAddAchievement={handleAddAchievement}
      />
      <EditAchievementModal
        open={Boolean(selectedAchievement)}
        close={() => setSelectedAchievement(null)}
        achievement={selectedAchievement}
        setAchievements={setAchievements}
      />
    </>
  );
}
