import {
    getSpriteCharacterOfGender,
    getNamePlayer,
} from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_first_coridor = require("../../../../assets/imikn/backgrounds/imikn_first_coridor.jpg");

// character
const guide_smile = require("../../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let lessons_intro_5 = {
    Start: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "От усталости ещё с ног не валишься?",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Нет, здесь столько всего интересного!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Конечно! ИМиКН - мечты сбываются!",
                },
            ],
        },
        {
            exits: true,
        },
    ],
};

export default lessons_intro_5;
