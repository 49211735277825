import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_practice_02 = require("../../../../assets/imikn/backgrounds/practice 2/imikn_practice_2_classroom.jpg");

// character
const anas_suleim = require("../../../../assets/imikn/characters/anas_suleim.png");
const anas_suleim_angry = require("../../../../assets/imikn/characters/anas_suleim_angry.png");
const anas_suleim_smirk = require("../../../../assets/imikn/characters/anas_suleim_smirk.png");
const popov_npc_mask = require("../../../../assets/imikn/characters/popov_npc_mask.png");
const secretary = require("../../../../assets/imikn/characters/secretary.png");

//color
const player_color = "#D4D4D4";
const secretary_color = "#F5F2BF";
const anas_suleim_color = "#E1D5EF";
const popov_color = "#AEB0B0";
const student_color = "#CCDFFF";
const another_student_color = "#D8FFF6";

let lesson_programming_task_completed_correctly_lvl_1 = {
  Start: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: "",
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Анастасия Сулеймановна, подойдите пожалуйста!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Да, сейчас...",
        },
        {
          rightSprite: anas_suleim_smirk,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Замечательно! Вы отлично справились!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Держите конфетку в награду, заслужили, настроение мне приподняли!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ой, спасибо",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Надеюсь, Вам было интересно...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Занятие вот вот закончится, можете отдохнуть пока",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Хорошо",
          achiev_child_id: 29, // 3.15
        },
        {
          leftSprite: "",
          rightSprite: "",
          speakSprite: "",
          nameSpeak: "",
          speakColor: "",
          text: "*стук в дверь*",
        },
        {
          rightSprite: anas_suleim,
          leftSprite: secretary,
          speakSprite: "left",
          nameSpeak: "Секретарь",
          speakColor: secretary_color,
          text: "Анастасия Сулеймановна! Не забудьте, Вас ждут на защите проектов после этой пары",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Да, да, помню, спасибо!",
        },
        {
          leftSprite: "",
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Итак, у нас заканчивается время, кто-то ещё готов сдавать?",
        },
        {
          leftSprite: popov_npc_mask,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "Можно мы снова попробуем?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну, давайте, Михаил, первый вопрос...",
        },
        {
          leftSprite: "",
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*судорожно собирает вещи в рюкзак*",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Серёга, ты чего?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Да сейчас большая перемена будет, надо со всех ног в столовую бежать...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Иначе я снова покушать не успею...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Справедливо! Минута промедления и уже километровая очередь соберётся... ",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*тоже начинает быстро собирать вещи*",
        },
        {
          rightSprite: anas_suleim_angry,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Так! Чего зашуршали? Куда собираемся?! Пара ещё не окончена!",
        },
        {
          leftSprite: popov_npc_mask,
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Хорошо, Михаил, ставлю 7 баллов",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "А почему не 10?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "На вопросы плохо отвечали! Надо лучше готовиться к занятиям!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "Эх, ладно...",
        },
        {
          leftSprite: "",
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Итак, занятие окончено!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Если у кого-то есть вопросы - пишите мне на корпоративную почту",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Решим в индивидуальном порядке, можете быть свободными!",
        },
        {
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Бе-е-е-ж-и-и-и-м в сто-о-л-о-о-ву-у-ю-ю!!!!",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default lesson_programming_task_completed_correctly_lvl_1;
