import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import AuthUser from "../../../../Services/AuthUser";

const AddModal = (props) => {
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const { token, http } = AuthUser();
  const { handleAddRole } = props;

  const handleSubmit = () => {
    const data = {
      name: roleName,
      description: roleDescription,
    };
    http
      .post("/roles", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        handleAddRole((prevRoles) => [...prevRoles, res.data]);
        props.close();
      });
  };

  return (
    <Dialog open={props.open} onClose={props.close} fullWidth maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={props.close}
        sx={{
          position: "absolute",
          top: 12,
          right: 12,
          color: (theme) => theme.palette.secondary.main,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>Добавить роль</DialogTitle>

      <DialogContent>
        <DialogContentText>Введите название института</DialogContentText>
        <TextField
          autoFocus
          margin="normal"
          id="name_role"
          label="Название роли"
          type="text"
          fullWidth
          variant="outlined"
          value={roleName}
          onChange={(e) => setRoleName(e.target.value)}
          required
        />
         <TextField
          autoFocus
          margin="normal"
          id="desc_role"
          label="Описание роли"
          type="text"
          fullWidth
          variant="outlined"
          value={roleDescription}
          onChange={(e) => setRoleDescription(e.target.value)}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} variant="contained">
          Отмена
        </Button>
        <Button type="submit" variant="contained" onClick={handleSubmit}>
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddModal;
