import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import Toggle from "../Toggle";

import SuperAdminMenu from "./SuperAdminMenu";
import AdminMenu from "./AdminMenu";

const drawerWidth = 240;
const userdetail = JSON.parse(localStorage.getItem("user"));

const PermanentDrawerLeft = (props) => {
  const theme = useTheme();

  function getRoleCurrentUser() {
    if (userdetail.roles[0] === "SuperAdmin") {
      return <SuperAdminMenu />;
    } else {
      return <AdminMenu />;
    }
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          background: theme?.palette?.secondary.main,
        }}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            {userdetail.username}
          </Typography>

          <Toggle />
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Divider />
        {getRoleCurrentUser()}
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          minHeight: "100vh",
        }}
      >
        <Toolbar />
        {/* {props.heading} */}

        {props.main}
      </Box>
    </Box>
  );
};
export default PermanentDrawerLeft;
