export function checkVisibleLesson(nameLesson) {
  const lesson = JSON.parse(localStorage.getItem("lesson")) || {};
  return lesson[nameLesson].visible === true;
}

export function setVisibleLesson(nameLesson) {
  const lesson = JSON.parse(localStorage.getItem("lesson")) || {};
  lesson[nameLesson].visible = true;
  localStorage.setItem("lesson", JSON.stringify(lesson));
}

export function checkVisibleProjectProtection() {
  const teacher = JSON.parse(localStorage.getItem("teacher")) || {};
  const lesson = JSON.parse(localStorage.getItem("lesson")) || {};
  if (
    teacher["anas_suleim"].visible &&
    teacher["dobr_roman"].visible &&
    teacher["volan_yul"].visible
  ) {
    lesson["project_protection"].visible = true;
    localStorage.setItem("lesson", JSON.stringify(lesson));
    return true;
  } else return false;
}
