import {
    getSpriteCharacterOfGender,
    getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";
import { setVisibleTeacher } from "../../../../../Components/dialogueSystem/scripts/VisibleTeacher";

// background
const imikn_math = require("../../../assets/imikn/backgrounds/imikn_math.jpg");

// character
const guide_default = require("../../../assets/imikn/characters/guide.png");
const guide_smile = require("../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let direction_math = {
    Start: [
        {
            body: [
                {
                    background: imikn_math,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Вот оно! Направление с чистейшей наукой! Математика!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Здесь будет очень, ОЧЕНЬ много математики, но также немного физики и программирования на базовом уровне",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Сюда поступают люди, которые абсолютно не видят свою жизнь без математики и испытывают к ней великую любовь",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ведь кроме неё, больше нет наук достойных их внимания",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну или те, кому она нравится, но они не знают, что ещё может их заинтересовать",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Именно для этого есть элективные дисциплины и первые два года обучения",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Таким людям новая система образования поможет открыть глаза и указать путь!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ладно, это я так, отвлёкся от темы",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Как у тебя обстояли дела с математикой в школе?",
                },
            ],
        },
        {
            choices: [
                "Отлично!|direction_math_good",
                "Хорошо|direction_math_good",
                "Ну тако-о-о-е...|direction_math_bad",
            ],
        },
    ],

    direction_math_good: [
        {
            body: [
                {
                    background: imikn_math,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Замечательно!",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_math_continue",
        },
    ],

    direction_math_bad: [
        {
            body: [
                {
                    background: imikn_math,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Вот это уже не очень хорошо...",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_math_continue",
        },
    ],

    direction_math_continue: [
        {
            body: [
                {
                    background: imikn_math,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Понимаешь, чтобы преуспеть на этом направлении, для начала нужно иметь хорошую математическую базу знаний",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "В первую очередь, здесь будет изучаться математический анализ",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Можешь потом поподробнее узнать о нём у Людмилы Васильевны, она его преподаёт",
                    visibleTeacher: "lud_vas",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Хорошо",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Если не знать школьную программу, то будет очень сложно изучать эту дисциплину...",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Почему?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Потому что дальше всё будет только усложняться",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Поэтому если не хочется быть отстающим от программы учеником, нужно либо наверстать упущенное до поступления",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Либо прикладывать гигантские усилия во время первого курса!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Теперь понятно. А что будет изучаться после математического анализа?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Дискретная математика, дифференциальные уравнения, теория вероятностей и математическая статистика",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Функциональный анализ, теоретическая механика и много-много чего ещё...",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Звучит пугающе, на деле, наверное, ещё хуже...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Если хорошо знать школьную программу и матанализ, то нет, а вот если есть пробелы в знаниях, то конечно!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Также, я уже говорил, здесь изучается программирование на базовом уровне",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это как и классические языки программирования высокого уровня, так и специализированные - MatLab и Maxima, например",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Что значит “языки программирования высокого уровня”?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ой, я определение прям с ходу не вспомню, посмотри лучше в Азбуке, там точно есть расшифровка!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Хорошо",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    rightSprite: guide_smile,
                    text: "Кстати, ты знаешь, что у студентов данного направления довольно интересные темы дипломов, хочешь расскажу об этом?",
                },
            ],
        },
        {
            choices: [
                "Да, давай|direction_math_yes",
                "Думаю, мне это не нужно, раз без хорошей математики здесь никак...|direction_math_no",
            ],
        },
    ],

    direction_math_yes: [
        {
            body: [
                {
                    background: imikn_math,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Обычно дипломная работа включает в себя какое-либо исследование и разработку или же доработку чего-то нового",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Например, у педагогов, это может быть ",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "“Исследование методов решения квадратных уравнений и разработка методического пособия для учителей средней школы”",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "У направлений, связанных с программированием - “Разработка такого-то приложения и исследование работы таких-то методов”",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А математикам что разрабатывать? Открытий на всех не хватит!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Поэтому их работы чаще всего носят исключительно теоретический характер",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Например, решение какой-нибудь о-о-очень сложной задачи, возможно, не имеющей решения до сих пор!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Или исследование различных методов для решения задач",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Получается они только решают и решают задачи, там, примеры...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Получается, что так",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Ну это же не очень интересно, какая польза от этого?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Так задачи могут носить и практический характер! А также быть междисциплинарными!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Например, приходит какой-нибудь важный дяденька из Роснефти и просит помочь с расчётами...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Вот тебе и применение теоретических знаний!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А-а-а, теперь понятно",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Хорошо! Может у тебя ещё есть вопросы?",
                },
            ],
        },
        {
            choices: [
                "Кем можно работать после выпуска?|direction_math_professions",
                "*промолчать*|direction_math_exit",
            ],
        },
    ],
    
    direction_math_no: [
        {
            body: [
                {
                    background: imikn_math,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Хых, ладно...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Тогда пошли дальше",
                },
            ],
        },
        {
            exits: true,
        },
    ],

    direction_math_professions: [
        {
            body: [
                {
                    background: imikn_math,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "В плане профессии стоит понимать, что без дополнительных знаний после выпуска можно пойти только в научную деятельность",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "И то, только после обучения в магистратуре и аспирантуре",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Для всех остальных случаев, придётся потратить достаточно много усилий для изучения дополнительных областей",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Что значит научная деятельность?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это, например, преподавание в ВУЗе и изучение своей области, то есть написание научных статей, возможно даже докторской работы",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Или работа научным сотрудником в лаборатории университета",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "В общем, работа на науку!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Да, именно так!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Также, выпускники этого направления могут пойти работать на любую должность, связанную с математикой",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Но предварительно изучив специфику профессии и, возможно, получив дополнительное образование",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Например, курсы по профессиональной переподготовке, курсы повышения квалификации и подобное",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Для должности педагога-математика, необходимо пройти курсы по профессиональной переподготовке",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Чтобы получить необходимые для этой работы компетенции",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А какие ещё профессии доступны для выпускников?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Аналитик, инженер-математик, экономист-математик, репетитор, также часто идут в нефтяную отрасль и так далее",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    rightSprite: guide_smile,
                    text: "Вообще, скажу по-секрету, работодатели любят выпускников математики!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Так как у подобных людей, чаще всего, очень хорошо развита логика и структурированное мышление",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Которое достаточно полезно в работе, связанной с различными расчётами!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Во-о-о-от, что же ещё такого рассказать?! Хм...",
                }
            ],
        },
        {
            choices: [
                "А как тут с нагрузкой?|direction_math_load",
                "*промолчать*|direction_math_exit",
            ],
        },
    ],

    direction_math_load: [
        {
            body: [
                {
                    background: imikn_math,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Как посмотреть, для всех по-разному...",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Ну допустим, если у меня с математикой всё хорошо",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Тогда нагрузка будет средней, если и работать, то не раньше третьего курса и с очень гибким графиком",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Потому что даже на старших курсах пары ставят в разное время суток, не обязательно только вечером...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А если же с математикой не очень, то ни о какой работе и речи быть не может, при условии, что учёба важна, конечно...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Понятно",
                },
            ],
        },
        {
            choices: [
                "Расскажи, пожалуйста, ещё про практики|direction_math_practice",
                "Может пойдём к следующему направлению?|direction_math_exit",
            ],
        },
    ],

    direction_math_practice: [
        {
            body: [
                {
                    background: imikn_math,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Практики здесь проходят как и везде: учебная, производственная или технологическая и преддипломная",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Единственное отличие - они все проходят на кафедре, в рамках института, а не на предприятиях",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А почему?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Точного ответа у меня нет, но осмелюсь предположить, что это связано с программой",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "К моменту производственной практики у студентов ещё нет прикладных знаний",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А преддипломная - ориентирована на доработки диплома...",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_math_exit",
        },
    ],

    direction_math_exit: [
        {
            body: [
                {
                    background: imikn_math,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну чтож... Напоследок скажу, что обучение здесь, как и на многих других направлениях, достаточно трудное",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Но как говорится, тяжело в учении, легко в бою!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Пойдём дальше...",
                },
            ],
        },
        {
            exits: true,
        },
    ],
};

export default direction_math;
