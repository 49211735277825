import Drawer from "../../../Components/adminpanel/Drawer";
import ListIndex from "./components/ListIndex";

export default function Index() {
  return (
    <>
      <Drawer  main={<ListIndex />} />
    </>
  );
}
