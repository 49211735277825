import MiniProfile from "../../../Components/gameSection/MiniProfile";
import Header from "../../../Components/header/Header";
import Footer from "../../../Components/footer/Footer";
import Voculabury from "../../../Components/vocabulary/Vocabulary";
import { Grid, Box } from "@mui/material";
import ImageAvatarShadowMale from "../../../Assets/shadow_male.png";
import CardCarousel from "../../../Components/gameSection/CarouselGame";
import Card from "../../../Components/gameSection/CardGame";
import ImiknImage from "../../../Games/imikn/assets/cover_imikn.jpg";

const institutesList = [
  {
    image: ImiknImage,
    title: "Институт математики и компютерных наук",
    url: "/gamesection/imikn",
  },
  // {
  //   image: ImiknImage,
  //   title: "Институт ",
  //   url: "/gamesection/inbio",
  // },
];
export default function GameSection() {
  const intro = {
    direction: {
      count: 0,
      isVisited: false,
    },
    teacher: {
      count: 0,
      isVisited: false,
    },
    location: {
      count: 0,
      isVisited: false,
    },
  };
  const direction = {
    ib: { isVisited: false },
    ibas: { isVisited: false },
    isit: { isVisited: false },
    kb: { isVisited: false },
    math: { isVisited: false },
    mimm: { isVisited: false },
    mir: { isVisited: false },
    moiais: { isVisited: false },
    pi: { isVisited: false },
    ped: { isVisited: false },
  };
  const teacher = {
    anas_suleim: { visible:false,isVisited:false },
    ann_evst: { visible:false,isVisited:false },
    dobr_luc: { visible:false,isVisited:false },
    dobr_roman: {
      visible:false,isVisited:false,
    },
    emm_stal: { visible:false,isVisited:false },
    evd_pankr: { visible:false,isVisited:false },
    lud_vas: { visible:false,isVisited:false },
    pol_tim: { visible:false,isVisited:false },
    tal_yar: { visible:false,isVisited:false },
    volan_yul: { visible:false,isVisited:false },
  };
  const lesson = {
    lecture: {
      visible: false,
      isVisited: false,
    },
    networking_lesson: {
      visible: false,
      isVisited: false,
      networking_completed: false,
    },
    programming_lesson: {
      visible: false,
      isVisited: false,
      programmingLvl1_completed: false,
      programmingLvl2_completed: false,
    },
    project_protection: {
      visible: false,
      isVisited: false,
      lesson_project_protection_project_1_questions: false,
      lesson_project_protection_project_2_questions: false,
    },
  };
  if (!localStorage.getItem("direction")) {
    localStorage.setItem("direction", JSON.stringify(direction));
  }
  
  if (!localStorage.getItem("intro")) {
    localStorage.setItem("intro", JSON.stringify(intro));
  }
  
  if (!localStorage.getItem("lesson")) {
    localStorage.setItem("lesson", JSON.stringify(lesson));
  }
  
  if (!localStorage.getItem("teacher")) {
    localStorage.setItem("teacher", JSON.stringify(teacher));
  }
  // localStorage.setItem("intro", JSON.stringify(intro));
  // localStorage.setItem("direction", JSON.stringify(direction));
  // localStorage.setItem("teacher", JSON.stringify(teacher));
  // localStorage.setItem("lesson", JSON.stringify(lesson));
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <Voculabury instituteId={1} />
        <Grid container spacing={2}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <MiniProfile
              imageAvatar={ImageAvatarShadowMale}
              name="unauthorized"
              achievement={0}
            />
            <CardCarousel />
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {institutesList &&
                institutesList.map((item, i) => (
                  <Card
                    key={i}
                    image={item.image}
                    title={item.title}
                    url={item.url}
                  />
                ))}
            </Box>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        <Footer />
      </div>
    </>
  );
}
