import Footer from "../../Components/footer/Footer";
import Header from "../../Components/header/Header";
import CheckAuthMiniProfile from "./components/CheckAuthMiniProfile";
import { Grid } from "@mui/material";
import Voculabury from "../../Components/vocabulary/Vocabulary";
import { useParams } from "react-router-dom";

export default function SectionDirection(props) {

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <Voculabury instituteId={props.instID} />
        <Grid container spacing={2}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <CheckAuthMiniProfile />
            {props.main}
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        <Footer />
      </div>
    </>
  );
}
