import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Cards = ({ id, image, title, url }) => {
  const theme = useTheme();
  const intro_local = JSON.parse(localStorage.getItem("intro"));

  const handleIntroCount = () => {
    if (id === 1 && intro_local.direction.count < 5) {
      intro_local.direction.count += 1;
    } else if (id === 2 && intro_local.teacher.count < 7) {
      intro_local.teacher.count += 1;
    } else if (id === 3 && intro_local.location.count < 6) {
      intro_local.location.count += 1;
    }

    if (id === 1) {
      intro_local.direction.isVisited = true;
    } else if (id === 2) {
      intro_local.teacher.isVisited = true;
    } else if (id === 3) {
      intro_local.location.isVisited = true;
    }
    localStorage.setItem("intro", JSON.stringify(intro_local));
  };

  return (
    <Card
      onClick={handleIntroCount}
      sx={{
        maxWidth: 350,
        margin: 3,
        background: theme?.palette?.box.main,
        boxShadow: `0px 0px 15px 2px ${theme?.palette?.box.shadow}`,
        ":hover": { transform: "scale(1.05)" },
      }}
    >
      <CardActionArea href={url}>
        <CardMedia component="img" height="200" image={image} alt={image} />
        <CardContent sx={{ minHeight: 70 }}>
          <Typography gutterBottom variant="body" component="h5" align="center">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
export default Cards;
