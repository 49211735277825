import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import NotStoryBook from "../../Assets/Errors/error_no_stories.png";
import CircularProgress from "@mui/material/CircularProgress";

export default function CardStoriesNull(props) {
  const [showProgress, setShowProgress] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowProgress(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <Grid
      sx={{
        borderLeft: "2px solid rgb(200, 200, 200)",
        my: 6,
        width: 700,
        height: 407,
        maxWidth: "100%",
      }}
    >
      {showProgress ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography
              sx={{
                color: "black",
                maxWidth: "calc(100% - 60px)",
                fontSize: "calc(11px + 0.5vw)",
              }}
              variant="body1"
              align="center"
            >
              К сожалению по данному направлению нет доступных историй
            </Typography>
            <img
              src={NotStoryBook}
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
