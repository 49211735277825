import "./style.css";
import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import SuleimanovnaCircle from "../../../Assets/HomePage/suleimanovna_circle.png";
import CloudBlock from "../../../Assets/HomePage/cloud_block.png";
import Vocabulary from "../../../Assets/HomePage/Vocabulary_text.png";
import {
  Container,
  Typography,
  Box,
  InputAdornment,
  TextField,
  Grid,
} from "@mui/material";
import AbcIcon from "@mui/icons-material/Abc";
import SearchIcon from "@mui/icons-material/Search";

export default function VocabularyBlock() {
  const theme = useTheme();
  const [textState, setTextState] = useState("");
  const [isIconClicked, setIconClicked] = useState(false);
  const [animationStarted, setAnimationStarted] = useState(false);
  const [isTextComplete, setTextComplete] = useState(false);
  const word = "бакалавриат";

  useEffect(() => {
    let timerId;

    if (isIconClicked && !animationStarted) {
      setAnimationStarted(true);
      let i = 0;
      let text = textState;
      timerId = setInterval(() => {
        if (i < word.length) {
          text += word[i];
          setTextState(text);
          i++;
        } else {
          clearInterval(timerId);
          setTextComplete(true);
        }
      }, 100);
    }

    return () => clearInterval(timerId);
  }, [isIconClicked]);

  return (
    <Container maxWidth="lg" sx={{ mt: "3%", padding: 0, height:"auto"}}>
      <Box sx={{position: "relative"}}>
        <Box sx={{ position: "relative",
         width: "75%", 
        //  background:"blue",
        '@media (max-width: 800px)': {
          width: "100%"
              }}}>
          {/* <img
            src={Vocabulary}
            style={{
              width: "100%"
              // transform: "scale(-1, 1)"
            }}
          /> */}
          <Box style={{ width: "100%"}}>
            <img
              src={SuleimanovnaCircle}
              style={{
                width: "40%",
                transform: "scale(-1, 1)",
                marginTop: "35%"
              }}
            />
            <img
                src={CloudBlock}
                style={{
                  width: "75%",
                  transform: "scale(-1, 1)",
                  position:"absolute",
                  right:"0%",                  
                }}
            />     

          </Box>
          
          <Typography
            sx={{  
              position: "absolute",
              top: "17%",
              right: "11%",
              color: "black",
              width: "55%",
              fontSize: "1.3rem",
              textAlign: "center",
              '@media (max-width: 1400px) and (min-width: 800px)': {
                fontSize: '1.6vw',
                top: "10%",
              },
              '@media (max-width: 800px) and (min-width: 600px)': {
                fontSize: '2.0vw',
                top: "14%",
                lineHeight:"1.3",
              },
              '@media (max-width: 600px)': {
                fontSize: '2.3vw',
                top: "12%",
                lineHeight:"1.3",
              }
            }}
          >
            Это, так сказать, азбука для самых маленьких! Здесь мы постарались
            собрать и простым языком описать термины, которые могут быть
            неизвестными или непонятными для тебя. Не стесняйся и заглядывай
            сюда, если встретишь незнакомые слова!
          </Typography>
        </Box>
        <Box
          sx={{
            width:"30%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "60%",
            right: "5%",
            '@media (max-width: 800px)': {
              width:"45%",
              margin: "0 auto",
              position: "relative",
              top: "-10vw",
              right: "-15vw",
            }
          }}
        >
          {!isIconClicked ? (
            <div className="pulse" onClick={() => setIconClicked(true)}>
              <div className="bloc"></div>
              <div className="abc">
                <AbcIcon style={{ fontSize: "4rem" }}/>
              </div>
              <div className="text">Нажми меня</div>
            </div>
          ) : (
            <div 
            style={{ width: "100%" 
            }}
            >
              <TextField
                id="search"
                type="search"
                label="Поиск"
                fullWidth
                value={textState}
                sx={{
                  input: { color: theme.palette.box.border, 
                  '@media (max-width: 1200px) and (min-width: 800px)': {
                    fontSize: '1.7vw',
                  },
                  '@media (max-width: 800px) and (min-width: 600px)': {
                    fontSize: '2.7vw',
                  },
                  '@media (max-width: 600px)': {
                    fontSize: '2.8vw',
                  }},
                  label: { color: theme.palette.box.border, 
                  '@media (max-width: 1200px) and (min-width: 800px)': {
                    fontSize: '1.7vw',
                  },
                  '@media (max-width: 800px) and (min-width: 600px)': {
                    fontSize: '2.7vw',
                  },
                  '@media (max-width: 600px)': {
                    fontSize: '2.8vw',
                  } },
                  "& label.Mui-focused": {
                    color: theme.palette.box.border,
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: theme.palette.box.border,
                  },
                  "& .MuiInputLabel-root": {
                    color: theme.palette.box.border,
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: theme.palette.box.border,
                    },
                    "&:hover fieldset": {
                      borderColor: theme.palette.box.border,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: theme.palette.box.border,
                    },
                  }, 
                  
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ color: theme.palette.box.border }}
                    >
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {isTextComplete && (
                <div className="fadeIn" style={{ marginTop: 10}}>
                  <Typography variant="h6" sx={{
                      
                      '@media (max-width: 1200px) and (min-width: 800px)': {
                        fontSize: '1.7vw',
                      },
                      '@media (max-width: 800px) and (min-width: 600px)': {
                        fontSize: '2.7vw',
                      },
                      '@media (max-width: 600px)': {
                        fontSize: '2.8vw',
                      }
                    }}>Бакалавриат</Typography>
                  <Typography variant="body1" 
                  sx={{
                    
                    '@media (max-width: 1200px) and (min-width: 800px)': {
                      fontSize: '1.5vw',
                    },
                    '@media (max-width: 800px) and (min-width: 600px)': {
                      fontSize: '2.5vw',
                    },
                    '@media (max-width: 600px)': {
                      fontSize: '2.6vw',
                    }
                    }}>
                    система подготовки бакалавров в высших учебных заведениях,
                    длительность обучения - 4 года.
                  </Typography>
                </div>
              )}
            </div>
          )}
        </Box>
      </Box>
    </Container>
  );
}
