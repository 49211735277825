export function checkVisibleTeacher(nameTeacher) {
  const teacher = JSON.parse(localStorage.getItem("teacher")) || {};
  if (!teacher[nameTeacher]) {
    return false; // или любое другое значение по умолчанию
  }
  return teacher[nameTeacher].visible === true;
}

export function setVisibleTeacher(nameTeacher) {
  const teacher = JSON.parse(localStorage.getItem("teacher")) || {};
  if (!teacher[nameTeacher]) {
    teacher[nameTeacher] = {};
  }
  teacher[nameTeacher].visible = true;
  localStorage.setItem("teacher", JSON.stringify(teacher));
}
