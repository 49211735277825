import {
    getSpriteCharacterOfGender,
    getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";
import { setVisibleTeacher } from "../../../../../Components/dialogueSystem/scripts/VisibleTeacher";

// background
const imikn_mimm = require("../../../assets/imikn/backgrounds/imikn_mimm.jpg");

// character
const guide_default = require("../../../assets/imikn/characters/guide.png");
const guide_smile = require("../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let direction_mimm = {
    Start: [
        {
            body: [
                {
                    background: imikn_mimm,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Механика и математическое моделирование - одно из двух направлений, тесно связанных с физикой в нашем институте",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Из названия можно понять, что по большей части здесь изучаются различные разделы механики и математического моделирования",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "И в действительности это так! Первое впечатление не обманчиво!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Сначала поговорим о математике, как же без неё? Можно догадаться, что она занимает здесь ключевое место!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Сперва, как и у всех направлений, будет преподаваться математический анализ",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Затем аналитическая геометрия, линейная алгебра, дифференциальные уравнения",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "И так программа, в дальнейшем, будет только усложняться, пока не дойдёт до математического моделирования",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А что это такое?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Говоря простым языком, это процесс, когда всё что угодно можно описать на языке математики",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А нужно это для того, чтобы понять, как устроен конкретный объект или процесс",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Какова его структура, внутренние связи, свойства, законы развития и взаимодействия с окружающей средой",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "После того, как понимание будет достигнуто, можно научиться управлять этим объектом или процессом",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "И предсказывать, что будет дальше, если сделать так или иначе...",
                },
            ],
        },
        {
            choices: [
                "Как-то сложно... Можешь рассказать попроще?|direction_mimm_difficult",
                "Как математично и логично, ха-ха-ха|direction_mimm_funny",
            ],
        },
    ],

    direction_mimm_difficult: [
        {
            body: [
                {
                    background: imikn_mimm,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну давай объясню ещё проще, на примере огня",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Люди не знали поначалу что это и боялись. Затем они начали изучать огонь, поняли, что дерево горит, а камень нет",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Но это ещё не математическое моделирование",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Когда они узнали, что такое огонь с точки зрения науки, поняли, что ему нужно для существования",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Какие химические элементы горят, а какие нет, сколько это длится и так далее",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Они начали использовать его в промышленности, создали двигатель внутреннего сгорания и многие другие изобретения",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Вот это математическое моделирование - изучить объект или процесс, описать его математическим языком, а затем научиться использовать",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Ого! Звучит логично...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    rightSprite: guide_smile,
                    text: "Это да, хорошо подмечено!",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_mimm_continue",
        },
    ],

    direction_mimm_funny: [
        {
            body: [
                {
                    background: imikn_mimm,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ага, ха-ха-ха",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_mimm_continue",
        },
    ],

    direction_mimm_continue: [
        {
            body: [
                {
                    background: imikn_mimm,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Я тут подумал, раз у тебя неплохо с логикой, можешь на этот счёт поговорить с Воландом Юлиановичем",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Он, в этом плане, многими знаниями может поделиться и дать ценные советы!",
                    visibleTeacher: "volan_yul",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Хорошо",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    rightSprite: guide_default,
                    speakColor: guide_color,
                    text: "В учебном плане также присутствуют дисциплины, связанные с программированием, конечно, на начальном уровне, но всё же!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Помимо стандартных языков программирования, здесь можно узнать и о различных специализированных",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Так называемых «системах компьютерной математики», таких как MatLab, например",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это очень мощный инструментарий, который будет использоваться в основном для моделирования и решения технических задач",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну и одна из самых важных частей учебного процесса – физика, а точнее её конкретный раздел - механика",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Так как профиль этого направления - механика жидкости, газа и плазмы",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Упор физических предметов будет приходиться на изучение жидкостей и газов",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Как ведёт себя вязкая жидкость, например, нефть или газ в различных ситуациях с точки зрения и физики, и математического представления",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Скажу по секрету, некоторые практические занятия по таким дисциплинам будут проходить вместе со специальными приборами и, скажем так, “инвентарем”",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    rightSprite: guide_smile,
                    speakColor: guide_color,
                    text: "Вы будете видеть то, что изучаете на теоретическом уровне и проводить свои эксперименты!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Ого, как интересно!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну и показывать свои знания придётся с помощью курсовых работ",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А это что такое?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это, как бы задание, которое выполняется студентами в виде исследовательской работы по основным предметам специальности",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Примерно, как было в школе, только намного сложнее!",
                },
            ],
        },
        {
            choices: [
                "Ааа, понятно!|direction_mimm_yes",
                "У меня такого не было...|direction_mimm_no",
            ],
        },
    ],

    direction_mimm_yes: [
        {
            body: [
                {
                    background: imikn_mimm,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну вот и славненько!",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_mimm_exit",
        },
    ],
    
    direction_mimm_no: [
        {
            body: [
                {
                    background: imikn_mimm,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Настанет и твоё время!",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_mimm_exit",
        },
    ],

    direction_mimm_exit: [
        {
            body: [
                {
                    background: imikn_mimm,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А кем можно работать после окончания?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Выпустившись с корочкой данной специализации, прямая дорога в различные ресурсодобывающие компании",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Организации, связанные с архитектурами различных жидкостных систем",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Работать инженером, преподавателем физико-математических наук, а также пойти в науку",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Да-а, выбор не маленький",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это точно!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Предлагаю пойти дальше...",
                },
            ],
        },
        {
            exits: true,
        },
    ],
};

export default direction_mimm;
