import {
    getSpriteCharacterOfGender,
} from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_first_coridor = require("../../../../assets/imikn/backgrounds/imikn_first_coridor.jpg");

// character
const guide_smile = require("../../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let teachers_intro_7 = {
    Start: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Снова-здорова!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Преподаватели тебя ждут!",
                    achiev_child_id: 10, // 2.1
                },
            ],
        },
        {
            exits: true,
        },
    ],
};

export default teachers_intro_7;
