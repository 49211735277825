import React, { useState, useEffect } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import AbcIcon from "@mui/icons-material/Abc";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import AuthUser from "../../Services/AuthUser";

export default function Vocabulary(props) {
  const theme = useTheme();

  const VocabularyNavbar = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    right: "0",
    marginRight: "2%",
    zIndex: "9",
    top: "50%",
    transform: "translateY(-50%)",
  };

  const VocabularyMenuBars = {
    background: theme?.palette?.primary.main,
    padding: "0.9rem",
    borderRadius: "50%",
    display: "flex",
    fontSize: "2rem",
    justifyContent: "center",
    alignItems: "center",
    transition: "background-color 0.2s ease-in-out",
    "&:hover": {
      background: theme?.palette?.primary.light,
      color: "#ffffff",
    },
  };
  const VocabularySearch = {
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
    borderBottom: `3px solid ${theme?.palette?.box.border}`,
    "& input": {
      border: "none",
      outline: "none",
      backgroundColor: "transparent",
      padding: " 8px 16px",
      fontSize: "16px",
      width: "100%",
    },
  };

  const [vocabulary, setVocabulary] = useState({
    right: false,
  });

  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (!isSearchFocused) {
      setVocabulary({ ...vocabulary, [anchor]: open });
    }
  };

  const [terms, setTerms] = useState([]);
  const { http } = AuthUser();
  useEffect(() => {
    http
      .get("/terms-player", {
        params: {
          inst_id: props.instituteId,
        },
      })
      .then((response) => {
        setTerms(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const [searchTerm, setSearchTerm] = useState("");
  const searchHandler = (event) => {
    setSearchTerm(event.target.value);
  };

  const searchResults = terms.filter((item) =>
    item.term_title.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const stopPropagation = (event) => {
    event.stopPropagation();
  };
  const list = (anchor) => (
    <Box
      sx={{ width: 350, height: "100%", background: theme?.palette?.box.main }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box sx={VocabularySearch}>
        <input
          type="text"
          placeholder="Поиск"
          value={searchTerm}
          onChange={searchHandler}
          onClick={stopPropagation}
          style={{ color: "white" }}
          onFocus={() => setIsSearchFocused(true)}
          onBlur={() => setIsSearchFocused(false)}
        />
      </Box>
      <Divider />
      <List>
        {searchResults.map((item, i) => (
          <ListItem key={i}>
            <ListItemText
              primary={item.term_title}
              primaryTypographyProps={{
                display: "inline",
                fontSize: "1.3em",
                fontWeight: "bold",
                color:"black"
              }}
              secondary={item.term_description}
              secondaryTypographyProps={{
                display: "block",
                color:"black"
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Box sx={VocabularyNavbar}>
        <Box sx={VocabularyMenuBars}>
          <AbcIcon
            sx={{ fontSize: 40, color: "white" }}
            onClick={toggleDrawer("right", true)}
          />
        </Box>
      </Box>

      <SwipeableDrawer
        anchor="right"
        open={vocabulary["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        {list("right")}
      </SwipeableDrawer>
    </div>
  );
}
