import {
    getSpriteCharacterOfGender,
    getNamePlayer,
} from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_first_coridor = require("../../../../assets/imikn/backgrounds/imikn_first_coridor.jpg");

// character
const guide_default = require("../../../../assets/imikn/characters/guide.png");
const guide_smile = require("../../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let directions_intro_2 = {
    Start: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Мы что, не про все направления ещё поговорили?",
                },
            ],
        },
        {
            choices: [
                "Да вроде бы про все...|directions_intro_2_yes",
                "Не про все, у тебя очаговая амнезия что-ли? Ха-ха-ха|directions_intro_2_no",
            ],
        },
    ],

    directions_intro_2_yes: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А чего ты тут тогда?",
                },
                {
                    nameSpeak: getNamePlayer(),
                    speakSprite: "left",
                    speakColor: player_color,
                    text: "Хочу ещё раз послушать...",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "А-а-а, ну давай повторю!",
                },
            ],
        },
        {
            exits: true,
        },
    ],

    directions_intro_2_no: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Да-а-а, что-то я совсем хватку растерял...",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Хотя, это неудивительно... столько лет в одном и том же месте!",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Ну ладно, ладно, пошли, а то как дед разворчался тут, хе-хе",
                },
            ],
        },
        {
            exits: true,
        },
    ],
};

export default directions_intro_2;
