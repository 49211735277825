import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AuthUser from "../../../../Services/AuthUser";
import MenuItem from "@mui/material/MenuItem";

const AddTermModal = (props) => {
  const [termName, setTermName] = useState("");
  const [termDescription, setTermDescription] = useState("");
  const [termIDinstitute, setTermIDinstitute] = useState("");
  const { token, http } = AuthUser();
  const { handleAddTerm } = props;
  const [institutes, setInstitutes] = useState([]);

  const handleSubmit = () => {
    const data = {
      term_title: termName,
      term_description: termDescription,
      term_id_inst: termIDinstitute,
    };
    http
      .post("/terms", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        handleAddTerm((prevTerms) => [...prevTerms, res.data]); 
        props.close();
      });
  };
  
  useEffect(() => {
    const getInstitutes = async () => {
      const response = await http.get("/institutes", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setInstitutes(response.data);
    };
    getInstitutes();
  }, []);

  return (
    <Dialog open={props.open} onClose={props.close} fullWidth maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={props.close}
        sx={{
          position: "absolute",
          top: 12,
          right: 12,
          color: (theme) => theme.palette.secondary.main,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>Добавить термин</DialogTitle>

      <DialogContent>
          <TextField
            autoFocus
            margin="normal"
            id="name_dir"
            label="Название термина"
            type="text"
            fullWidth
            variant="outlined"
            value={termName}
            onChange={(e) => setTermName(e.target.value)}
            required
          />
          <TextField
            autoFocus
            margin="normal"
            id="name_dir"
            label="Описание термина"
            type="text"
            multiline
            fullWidth
            rows={10}
            variant="outlined"
            value={termDescription}
            onChange={(e) => setTermDescription(e.target.value)}
            required
          />
          <TextField
            margin="normal"
            id="dir_id_inst"
            label="Институт"
            select
            fullWidth
            value={termIDinstitute}
            onChange={(e) => setTermIDinstitute(e.target.value)}
            required
          >
            {institutes.map((inst) => (
              <MenuItem key={inst.id} value={inst.id}>
                {inst.inst_name}
              </MenuItem>
            ))}
          </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} variant="contained">
          Отмена
        </Button>
        <Button type="submit" variant="contained" onClick={handleSubmit}>
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddTermModal;
