import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import AuthUser from "../../../../Services/AuthUser";

const EditFeedbackModal = (props) => {
  const { token, http } = AuthUser();
  const { open, close, feedback, setFeedbacks } = props;
  const [feedbackUser, setFeedbackUser] = useState("");
  const [feedbackName, setFeedbackName] = useState("");
  const [feedbackEmail, setFeedbackEmail] = useState("");
  const [feedbackText, setFeedbackText] = useState("");

  useEffect(() => {
    setFeedbackUser(feedback?.feed_user || "");
    setFeedbackName(feedback?.feed_name || "");
    setFeedbackEmail(feedback?.feed_email || "");
    setFeedbackText(feedback?.feed_text || "");
  }, [feedback]);

  const handleSave = () => {
    const data = {
      feed_user: feedbackUser,
      feed_name: feedbackName,
      feed_email: feedbackEmail,
      feed_text: feedbackText,
    };
    http
      .put(`/feedbacks/${feedback.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFeedbacks((prevFeedbacks) =>
          prevFeedbacks.map((dir) =>
            dir.id === feedback.id
              ? {
                  ...dir,
                  feed_user: res.data.feed_user,
                  feed_name: res.data.feed_name,
                  feedbackEmail: res.data.feedbackEmail,
                  feedbackText: res.data.feedbackText,
                }
              : dir
          )
        );
        close();
      });
  };

  const handleCancel = () => {
    close();
  };
  const handleUserChange = (event) => {
    setFeedbackUser(event.target.value);
  };
  const handleNameChange = (event) => {
    setFeedbackName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setFeedbackEmail(event.target.value);
  };
  const handleTextChange = (event) => {
    setFeedbackText(event.target.value);
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Письмо</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Пользователь"
          type="text"
          fullWidth
          disabled
          value={feedbackUser}
          onChange={handleUserChange}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Название"
          type="text"
          fullWidth
          disabled
          value={feedbackName}
          onChange={handleNameChange}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Email"
          type="text"
          fullWidth
          disabled
          value={feedbackEmail}
          onChange={handleEmailChange}
        />{" "}
        <TextField
          autoFocus
          margin="dense"
          label="Текст"
          type="text"
          fullWidth
          disabled
          multiline
          rows={20}
          value={feedbackText}
          onChange={handleTextChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditFeedbackModal;
