import React, { useEffect, useState } from "react";
import AuthUser from "../../Services/AuthUser";
import CardMedia from "@mui/material/CardMedia";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Links from "@mui/material/Link";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";

const StyledCardMedia = styled(({ isIssued, ...otherProps }) => (
  <CardMedia {...otherProps} />
))(({ theme, isIssued }) => ({
  width: 125,
  filter: isIssued ? "none" : "brightness(0)",
}));

export default function CardAchievement(props) {
  const theme = useTheme();
  const [showProgress, setShowProgress] = useState(true);
  const { http } = AuthUser();
  const [achievements, setAchievements] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));

  const getAchievements = async () => {
    try {
      const response = await http.get(
        `/achievements/${props.instituteId}/${user.id}`
      );
      setAchievements(response.data);
      setShowProgress(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAchievements();
  }, []);
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ mt: 1 }}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {showProgress ? (
              <Grid item>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              </Grid>
            ) : (
              achievements.slice(0, 5).map((achievement) => (
                <Grid item key={achievement.id}>
                  <StyledCardMedia
                    component="img"
                    alt="achievement image"
                    image={require(`../../Assets/imikn/achievement/${achievement.achiev_img_name}`)}
                    title={achievement.achiev_name}
                    isIssued={achievement.is_issued}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Box>
        <Links href="/achievements/imikn" color="inherit">
          Все
        </Links>
      </Box>
    </>
  );
}
