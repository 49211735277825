import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import AuthUser from "../../../../Services/AuthUser";
import MenuItem from "@mui/material/MenuItem";

const AddAchievementModal = (props) => {
  const [achievementIDinstitute, setAchievementIDinstitute] = useState("");
  const [achievementImg, setAchievementImg] = useState("");
  const { token, http } = AuthUser();
  const { handleAddAchievement } = props;
  const [institutes, setInstitutes] = useState([]);

  const handleSubmit = () => {
    const data = {
      achiev_img_name: achievementImg,
      achiev_id_inst: achievementIDinstitute,
    };
    http
      .post("/admin/achievements", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.inst_name);
        handleAddAchievement((prevAchievements) => [
          ...prevAchievements,
          res.data,
        ]);
        props.close();
      });
  };

  useEffect(() => {
    const getInstitutes = async () => {
      const response = await http.get("/institutes", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setInstitutes(response.data);
    };
    getInstitutes();
  }, []);

  return (
    <Dialog open={props.open} onClose={props.close} fullWidth maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={props.close}
        sx={{
          position: "absolute",
          top: 12,
          right: 12,
          color: (theme) => theme.palette.secondary.main,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>Добавить достижение</DialogTitle>

      <DialogContent>
        <DialogContentText>Введите название изображения</DialogContentText>
        <TextField
          autoFocus
          margin="normal"
          id="name_inst"
          label="Название изображения"
          type="text"
          fullWidth
          variant="outlined"
          value={achievementImg}
          onChange={(e) => setAchievementImg(e.target.value)}
          required
        />
        <TextField
            margin="normal"
            id="id_inst"
            label="Институт"
            select
            fullWidth
            value={achievementIDinstitute}
            onChange={(e) => setAchievementIDinstitute(e.target.value)}
            required
          >
            {institutes.map((inst) => (
              <MenuItem key={inst.id} value={inst.id}>
                {inst.inst_name}
              </MenuItem>
            ))}
          </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} variant="contained">
          Отмена
        </Button>
        <Button type="submit" variant="contained" onClick={handleSubmit}>
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddAchievementModal;
