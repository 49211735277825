import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const department_place_01 = require("../../../assets/imikn/backgrounds/department_place_01.jpg");

// character
const guide_smile = require("../../../assets/imikn/characters/guide_smile.png");
const evd_pankr = require("../../../assets/imikn/characters/evd_pankr.png");
const evd_pankr_smile_hand_up = require("../../../assets/imikn/characters/evd_pankr_smile_hand_up.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";
const evd_pankr_color = "#D1E6ED";

let teacher_evd_pankr = {
  Start: [
    {
      body: [
        {
          background: department_place_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: evd_pankr,
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Добрый день! Не найдется ли у Вас минутки ответить на мои вопросы?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Здравствуйте! Конечно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Я всё ждала, когда Вы придете!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "У меня не так много времени, но общаться с новой кровью - люблю. Поэтому не могу упустить такую возможность!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ой, спасибо большое!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Всегда пожалуйста!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Кстати!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Вы знаете, что для той ситуации, в которой находитесь Вы и Ваши братья по несчастью, есть вполне реальная математическая задача?",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Нет, а какая?",
        },
        {
          rightSprite: evd_pankr_smile_hand_up,
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Задача о принятии решений",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Абитуриентам, в скором времени, предстоит сделать важные выборы такие, как направление, ВУЗ, город для учёбы и проживания...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "И это как раз то, о чем я говорила",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Я занимаюсь изучением и преподаванием дисциплин, связанных с методами поддержки принятия решений",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ого! А это как?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Это, скажем так, компьютерные программы",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Которые с помощью определенных методов математики, могут проанализировать ситуацию и посоветовать, какой выбор сделать человеку",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Искусственный интеллект какой-то?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Очень простенький, но можно и так сказать, а если поточнее, то это раздел машинного обучения",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "О, Богдан об этом говорил, точно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Подробнее об этом Вы узнаете, когда начнете учиться...",
        },
        {
          rightSprite: evd_pankr,
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Уже не терпится, если честно...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Вот бы сейчас была такая программа, которая сделала бы выбор за меня...",
        },
        {
          rightSprite: evd_pankr_smile_hand_up,
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "А с чего Вы взяли, что такой программы нет? Конечно же есть, но она нам не нужна!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Разве?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Конечно! Ведь важно, чтобы люди сами принимали решения, касательно своей жизни, ошибались и на этом круговороте учились",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "А если за нас всё будет выбирать программа, то мы не будем развиваться дальше и получать жизненный опыт",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Люди станут безалаберными и прекратят задумываться над последствиями",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Почему же?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Потому что люди, по своей природе, очень ленивые. Если они поймут, что программа за них всё решает, то будут только рады",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "А жизнь - очень сложная штука, на неё влияет бесконечное количество факторов. Алгоритм просто не получится обучить всему...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Какие решения тогда искусственный интеллект вообще сможет принимать?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "На данный момент, очень простенькие, решающие только довольно узкие задачи",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Какие-то конкретные случаи, чтобы было известно всё, что может повлиять на качество принятия решения",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Например, если задача: в какой верхней одежде сегодня пойти на улицу?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "То на решение может повлиять только погода в течении дня, вкусовые предпочтения человека и то, чем человек займётся на улице",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "То есть эти машины, пока ещё маленькие, растут и учатся как дети?",
        },
        {
          rightSprite: evd_pankr,
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Можно и так сказать! Поэтому данное направление очень перспективное для исследований и разработки!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Здорово!",
        },
        {
          centerSprite: guide_smile,
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "О, Богдан! И ты здесь! Какими судьбами? Это твой новый подопечный что-ли?",
        },
        {
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Да, здравствуйте! Решил вот снова взять на попечение юное дарование",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Похвально-похвально! Я в тебе никогда не сомневалась!",
        },
      ],
    },
    {
      choices: [
        "*Промолчать*|evd_pankr_no_question",
        "Расскажите, пожалуйста, про моделирование бизнес-процессов на Прикладной информатике!|evd_pankr_question",
      ],
    },
  ],

  evd_pankr_no_question: [
    {
      body: [
        {
          background: department_place_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: guide_smile,
          rightSprite: evd_pankr,
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Вы уже рассказали про моделирование бизнес-процессов на Прикладной информатике?",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Точно! Совсем забылось...",
        },
        {
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Я так и думал, что забудешь, спросить, поэтому зашёл узнать...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Так, ну очевидно, что ещё не рассказывала, ха-ха!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Но если нужно, то конечно!",
          achiev_child_id: 6, // 2.4
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "evd_pankr_continue",
    },
  ],

  evd_pankr_question: [
    {
      body: [
        {
          background: department_place_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: guide_smile,
          rightSprite: evd_pankr,
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Точно! Только в голове крутилось...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Спасибо, что напомнили!",
          achiev_child_id: 34, // 2.5
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "evd_pankr_continue",
    },
  ],

  evd_pankr_continue: [
    {
      body: [
        {
          background: department_place_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: evd_pankr,
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Моделирование бизнес процессов описывает логическую взаимосвязь всех элементов работы",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Оно дает возможность понять что и как именно нужно сделать, а также провести анализ организации, которая этим займётся",
        },
      ],
    },
    {
      choices: [
        "Мне не совсем понятно...|evd_pankr_question_2",
        "А-а-а, понятно|evd_pankr_exit",
      ],
    },
  ],

  evd_pankr_question_2: [
    {
      body: [
        {
          background: department_place_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: evd_pankr,
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Если простыми словами, то это, в какой-то степени, анализ предметной области - перевод с языка заказчика на язык программиста",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Например, заказчик хочет видеть сайт своего магазина",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Чтобы там было несколько вкладок и карточки товаров с ценой, описанием и другими подробностями",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "А на языке программиста - это будет звучать уже иначе...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Разработать веб-сайт, базу данных, сверстать n-ое количество веб-страниц, настроить переадресацию и прочее",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "То есть это перевод, скажем так, “хотелок” обычного человека, на конкретные технические задачи",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ого! И этому учат студентов прикладной информатики?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Именно!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Теперь понятно, спасибо!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "evd_pankr_exit",
    },
  ],

  evd_pankr_exit: [
    {
      body: [
        {
          background: department_place_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: evd_pankr,
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Что ж, у меня скоро начнётся пара. Хотелось бы ещё в столовую за чаем заскочить, поэтому я побежала!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Евдокия Панкратовна",
          speakColor: evd_pankr_color,
          text: "Хорошего дня, и приятно было пообщаться!",
        },
        {
          centerSprite: guide_smile,
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "До свидания!",
        },
        {
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "До встречи!",
        },
        {
          centerSprite: "",
          rightSprite: guide_smile,
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Она тебя видит? Почему именно она?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Понимаешь, я некоторое время следил за её парами, восхищался, как можно быть такой строгой, но при этом справедливой и мудрой!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "А потом решил, что хочу с ней дружить и общаться, чтобы она знала о своём невидимом и благодарном слушателе...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Так вот и познакомились",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Ну ладно, пошли дальше",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default teacher_evd_pankr;
