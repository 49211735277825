import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import "./style_prog.css";
import ProgrammingFon from "../../assets/BasicsOfProgramming/background_crop_code.png";
import lesson_programming_task_completed_correctly_lvl_2 from "../../../dialogues/data/imikn/lesson/lesson_programming/lesson_programming_task_completed_correctly_lvl_2";
import lesson_programming_task_not_completed_lvl_2 from "../../../dialogues/data/imikn/lesson/lesson_programming/lesson_programming_task_not_completed_lvl_2";

const ProgrammingGameLvl2 = ({
  setIsDialogue,
  setCurrentStories,
  setProgrammingLvl2,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleClicked, setModalVisibleClicked] = useState(false);
  const [selectedButton, setSelectedButton] = useState("white");
  const [inputValues, setInputValues] = useState({
    redButton: "",
    yellowButton: "",
    greenButton: "",
  });

  const openModal = (buttonId) => {
    if (!modalVisible) {
      setModalVisible(true);
      setModalVisibleClicked(true);
      setSelectedButton(buttonId);
    } else {
      setModalVisible(false);
      setModalVisibleClicked(false);
      setSelectedButton(null);
    }
  };

  const removeSpacesAndLowerCase = (str) => str.replace(/\s/g, "");

  const getButtonColor = (buttonId) => {
    switch (buttonId) {
      case "redButton":
        if (removeSpacesAndLowerCase(inputValues.redButton) === "Red;")
          setSelectedButton("red");
        else if (removeSpacesAndLowerCase(inputValues.redButton) === "Yellow;")
          setSelectedButton("yellow");
        else if (removeSpacesAndLowerCase(inputValues.redButton) === "Green;")
          setSelectedButton("green");
        else setSelectedButton("white");
        break;
      case "yellowButton":
        if (removeSpacesAndLowerCase(inputValues.yellowButton) === "Color.Red;")
          setSelectedButton("red");
        else if (
          removeSpacesAndLowerCase(inputValues.yellowButton) === "Color.Yellow;"
        )
          setSelectedButton("yellow");
        else if (
          removeSpacesAndLowerCase(inputValues.yellowButton) === "Color.Green;"
        )
          setSelectedButton("green");
        else setSelectedButton("white");
        break;
      case "greenButton":
        if (
          removeSpacesAndLowerCase(inputValues.greenButton) ===
          "BackColor=Color.Red;"
        )
          setSelectedButton("red");
        else if (
          removeSpacesAndLowerCase(inputValues.greenButton) ===
          "BackColor=Color.Yellow;"
        )
          setSelectedButton("yellow");
        else if (
          removeSpacesAndLowerCase(inputValues.greenButton) ===
          "BackColor=Color.Green;"
        )
          setSelectedButton("green");
        else setSelectedButton("white");
        break;
      default:
        setSelectedButton("white");
        break;
    }
  };

  const handleInputChange = (buttonId, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [buttonId]: value,
    }));
  };
  const handleDoneWork = () => {
    if (
      removeSpacesAndLowerCase(inputValues.redButton) === "Red;" &&
      removeSpacesAndLowerCase(inputValues.yellowButton) === "Color.Yellow;" &&
      removeSpacesAndLowerCase(inputValues.greenButton) ===
        "BackColor=Color.Green;"
    ) {
      const lesson = JSON.parse(localStorage.getItem("lesson"));
      lesson.programming_lesson.isVisited = true;
      lesson.programming_lesson.programmingLvl2_completed = true;
      localStorage.setItem("lesson", JSON.stringify(lesson));
      setProgrammingLvl2(false);
      setIsDialogue(true);
      setCurrentStories(lesson_programming_task_completed_correctly_lvl_2);
    } else {
      const lesson = JSON.parse(localStorage.getItem("lesson"));
      lesson.programming_lesson.isVisited = true;
      localStorage.setItem("lesson", JSON.stringify(lesson));
      setProgrammingLvl2(false);
      setIsDialogue(true);
      setCurrentStories(lesson_programming_task_not_completed_lvl_2);
    }
  };

  return (
    <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
      <img
        src={ProgrammingFon}
        alt=""
        style={{ width: "100%", height: "100%" }}
      />
      <div className="codeZoneC" id="redZone" data-zone="2">
        <input
          type="text"
          id="textInputRed"
          placeholder="Введите пропущенную часть"
          maxLength="20"
          // style={{ fontSize: "calc(0.7vw)" }}
          value={inputValues.redButton}
          onChange={(e) => handleInputChange("redButton", e.target.value)}
        />
      </div>
      <div className="codeZoneC" id="yellowZone" data-zone="3">
        <input
          type="text"
          id="textInputYellow"
          placeholder="Введите пропущенную часть"
          maxLength="30"
          // style={{ fontSize: "calc(0.7vw)" }}
          value={inputValues.yellowButton}
          onChange={(e) => handleInputChange("yellowButton", e.target.value)}
        />
      </div>
      <div className="codeZoneC" id="greenZone" data-zone="4">
        <input
          type="text"
          id="textInputGreen"
          placeholder="Введите пропущенную часть"
          maxLength="40"
          // style={{ fontSize: "calc(0.7vw)" }}
          value={inputValues.greenButton}
          onChange={(e) => handleInputChange("greenButton", e.target.value)}
        />
      </div>
      <button
        className={`image-button openButton ${
          modalVisibleClicked ? "modalOpen" : ""
        }`}
        onClick={() => openModal()}
      ></button>
      <Button
        variant="outlined"
        id="doneWork"
        onClick={handleDoneWork}
        sx={{
          position: "absolute",
          top: "82%",
          right: "10%",
          fontSize: "calc( 0.6vw)",
        }}
      >
        Завершить работу
      </Button>
      {modalVisible && (
        <div className="modalWindow">
          <div
            className="modal-content"
            id="modalContent"
            style={{
              backgroundImage: selectedButton
                ? `url(${require(`../../assets/BasicsOfProgramming/form_empty_${selectedButton}.png`)})`
                : `url(${require("../../assets/BasicsOfProgramming/form_empty_white.png")})`,
            }}
          >
            <button
              id="closeModalButton"
              className="image-button closeModal"
              onClick={() => openModal()}
            ></button>
            <button
              id="redButton"
              className="image-button targetElement"
              onClick={() => getButtonColor("redButton")}
            ></button>
            <button
              id="yellowButton"
              className="image-button targetElement"
              onClick={() => getButtonColor("yellowButton")}
            ></button>
            <button
              id="greenButton"
              className="image-button targetElement"
              onClick={() => getButtonColor("greenButton")}
            ></button>
          </div>
        </div>
      )}
    </Box>
  );
};

export default ProgrammingGameLvl2;
