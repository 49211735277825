import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const StyledCardMedia = styled(CardMedia)(({ theme, isachieved }) => ({
  filter: isachieved ? "none" : "brightness(0.05)",
  boxShadow: "0px 0px 37px 10px rgba(0, 0, 0, 0.26)",
}));

export default function CardAchievementChild(props) {
  return (
    <Card
      sx={{
        display: "flex",
        borderRadius: 3,
        boxShadow: "0px 0px 37px 10px rgba(0, 0, 0, 0.26)",
        mb: 3,
        position: "relative",
      }}
    >
      { props.img === "" ? (
        <></>
      ) : (
        <StyledCardMedia
          component="img"
          sx={{ width: 150 }}
          image={props.img}
          alt="achiev"
          isachieved={props.isAchieved}
        />
      )}

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h5">
            {props.title}
            {props.isAchieved ? (
              <CheckIcon
                size="small"
                aria-label="edit"
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: "green",
                  marginTop: "5px",
                  marginRight: "5px",
                }}
              />
            ) : (
              <CloseIcon
                size="small"
                aria-label="edit"
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: "red",
                  marginTop: "5px",
                  marginRight: "5px",
                }}
              />
            )}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            {props.desc}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
}
