import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { checkVisibleTeacher } from "../../../../Components/dialogueSystem/scripts/VisibleTeacher";
import ImiknAlbum from "../../assets/album/imikn_teachers_album_empty.jpg";

const teacherAlbum = [
  {
    lud_vas: {
      name: "lud_vas",
      title: checkVisibleTeacher("lud_vas")
        ? ""
        : "Ты ещё ничего не знаешь об этом преподавателе! Попробуй у кого-нибудь спросить...",
      placement: "right-start",
      img: checkVisibleTeacher("lud_vas")
        ? require("../../assets/album/imikn_teachers_album_01.png")
        : require("../../assets/album/imikn_teachers_album_01_noname.png"),
      width: "12%",
      positionLeft: "40%",
      positionTop: "20%",
    },
    pol_tim: {
      name: "pol_tim",
      title: checkVisibleTeacher("pol_tim")
        ? ""
        : "Ты ещё ничего не знаешь об этом преподавателе! Попробуй у кого-нибудь спросить...",
      placement: "right-start",
      img: checkVisibleTeacher("pol_tim")
        ? require("../../assets/album/imikn_teachers_album_02.png")
        : require("../../assets/album/imikn_teachers_album_02_noname.png"),
      width: "12%",
      positionLeft: "50%",
      positionTop: "16%",
    },
    emm_stal: {
      name: "emm_stal",
      title: checkVisibleTeacher("emm_stal")
        ? ""
        : "Ты ещё ничего не знаешь об этом преподавателе! Попробуй у кого-нибудь спросить...",
      placement: "right-start",
      img: checkVisibleTeacher("emm_stal")
        ? require("../../assets/album/imikn_teachers_album_03.png")
        : require("../../assets/album/imikn_teachers_album_03_noname.png"),
      width: "12%",
      positionLeft: "61%",
      positionTop: "14%",
    },
    ann_evst: {
      name: "ann_evst",
      title: checkVisibleTeacher("ann_evst")
        ? ""
        : "Ты ещё ничего не знаешь об этом преподавателе! Попробуй у кого-нибудь спросить...",
      placement: "right-start",
      img: checkVisibleTeacher("ann_evst")
        ? require("../../assets/album/imikn_teachers_album_04.png")
        : require("../../assets/album/imikn_teachers_album_04_noname.png"),
      width: "12%",
      positionLeft: "43%",
      positionTop: "39%",
    },
    tal_yar: {
      name: "tal_yar",
      title: checkVisibleTeacher("tal_yar")
        ? ""
        : "Ты ещё ничего не знаешь об этом преподавателе! Попробуй у кого-нибудь спросить...",
      placement: "right-start",
      img: checkVisibleTeacher("tal_yar")
        ? require("../../assets/album/imikn_teachers_album_05.png")
        : require("../../assets/album/imikn_teachers_album_05_noname.png"),
      width: "12%",
      positionLeft: "53%",
      positionTop: "34%",
    },
    evd_pankr: {
      name: "evd_pankr",
      title: checkVisibleTeacher("evd_pankr")
        ? ""
        : "Ты ещё ничего не знаешь об этом преподавателе! Попробуй у кого-нибудь спросить...",
      placement: "right-start",
      img: checkVisibleTeacher("evd_pankr")
        ? require("../../assets/album/imikn_teachers_album_06.png")
        : require("../../assets/album/imikn_teachers_album_06_noname.png"),
      width: "12.5%",
      positionLeft: "63.5%",
      positionTop: "34.5%",
    },
    dobr_luc: {
      name: "dobr_luc",
      title: checkVisibleTeacher("dobr_luc")
        ? ""
        : "Ты ещё ничего не знаешь об этом преподавателе! Попробуй у кого-нибудь спросить...",
      placement: "right-start",
      img: checkVisibleTeacher("dobr_luc")
        ? require("../../assets/album/imikn_teachers_album_07.png")
        : require("../../assets/album/imikn_teachers_album_07_noname.png"),
      width: "12%",
      positionLeft: "46.5%",
      positionTop: "58.5%",
    },
    volan_yul: {
      name: "volan_yul",
      title: checkVisibleTeacher("volan_yul")
        ? ""
        : "Ты ещё ничего не знаешь об этом преподавателе! Попробуй у кого-нибудь спросить...",
      placement: "right-start",
      img: checkVisibleTeacher("volan_yul")
        ? require("../../assets/album/imikn_teachers_album_08.png")
        : require("../../assets/album/imikn_teachers_album_08_noname.png"),
      width: "12%",
      positionLeft: "56.5%",
      positionTop: "55%",
    },
    dobr_roman: {
      name: "dobr_roman",
      title: checkVisibleTeacher("dobr_roman")
        ? ""
        : "Ты ещё ничего не знаешь об этом преподавателе! Попробуй у кого-нибудь спросить...",
      placement: "right-start",
      img: checkVisibleTeacher("dobr_roman")
        ? require("../../assets/album/imikn_teachers_album_09.png")
        : require("../../assets/album/imikn_teachers_album_09_noname.png"),
      width: "13%",
      positionLeft: "66.5%",
      positionTop: "54.5%",
    },
    anas_suleim: {
      name: "anas_suleim",
      title: checkVisibleTeacher("anas_suleim")
        ? ""
        : "Ты ещё ничего не знаешь об этом преподавателе! Попробуй у кого-нибудь спросить...",
      placement: "right-start",
      img: checkVisibleTeacher("anas_suleim")
        ? require("../../assets/album/imikn_teachers_album_10.png")
        : require("../../assets/album/imikn_teachers_album_10_noname.png"),
      width: "12%",
      positionLeft: "60%",
      positionTop: "74.5%",
    },
  },
];

export default function AlbomTeacher(props) {
  const handleClick = (event, message) => {
    props.onTeacherClick(message);
  };
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <img
        src={ImiknAlbum}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
      {teacherAlbum.map((teacher, i) =>
        Object.values(teacher).map((teacherData, j) => (
          <Tooltip
            key={i}
            title={teacherData.title}
            placement={teacherData.placement}
          >
            <img
              key={`${i}-${j}`}
              className="image-teacher"
              style={{
                position: "absolute",
                cursor: checkVisibleTeacher(teacherData.name)
                  ? "pointer"
                  : "default",
                width: teacherData.width,
                left: teacherData.positionLeft,
                top: teacherData.positionTop,
              }}
              onClick={
                checkVisibleTeacher(teacherData.name)
                  ? (event) => handleClick(event, teacherData.name)
                  : null
              }
              src={teacherData.img}
            />
          </Tooltip>
        ))
      )}
    </Box>
  );
}
