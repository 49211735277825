import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import AuthUser from "../../../../Services/AuthUser";
import MenuItem from "@mui/material/MenuItem";

const EditTermModal = (props) => {
  const { token, http } = AuthUser();
  const { open, close, term, setTerms } = props;
  const [termName, setTermName] = useState("");
  const [termDescription, setTermDescription] = useState("");
  const [termIDinstitute, setTermIDinstitute] = useState("");
  const [institutes, setInstitutes] = useState([]);

  useEffect(() => {
    setTermName(term?.term_title || "");
    setTermDescription(term?.term_description || "");
    setTermIDinstitute(term?.term_id_inst || "");
  }, [term]);

  const handleSave = () => {
    const data = {
      term_title: termName,
      term_description: termDescription,
      term_id_inst: termIDinstitute,
    };
    http
      .put(`/terms/${term.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTerms((prevTerms) =>
          prevTerms.map((ter) =>
            ter.id === term.id
              ? {
                  ...ter,
                  term_title: res.data.term_title,
                  term_description: res.data.term_description,
                  term_id_inst: res.data.term_id_inst,
                }
              : ter
          )
        );
        close();
      });
  };
  const handleCancel = () => {
    close();
  };

  const handleNameChange = (event) => {
    setTermName(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setTermDescription(event.target.value);
  };

  const handleInstituteChange = (event) => {
    setTermIDinstitute(event.target.value);
  };

  useEffect(() => {
    const getInstitutes = async () => {
      const response = await http.get("/institutes", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setInstitutes(response.data);
    };
    getInstitutes();
  }, []);

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Редактировать термин</DialogTitle>
      <DialogContent>
        <DialogContentText>Введите новое название термина:</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Название термина"
          type="text"
          fullWidth
          value={termName}
          onChange={handleNameChange}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Описание термина"
          type="text"
          fullWidth
          multiline
          rows={10}
          value={termDescription}
          onChange={handleDescriptionChange}
        />
        <TextField
          margin="normal"
          id="term_id_inst"
          label="Институт"
          select
          fullWidth
          value={termIDinstitute}
          onChange={handleInstituteChange}
          required
        >
          {institutes.map((inst) => (
            <MenuItem key={inst.id} value={inst.id}>
              {inst.inst_name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Отмена
        </Button>
        <Button onClick={handleSave} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTermModal;
