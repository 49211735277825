import LinearProgress from "./LinearProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import ListAchievement from "./ListAchievement";
import { useTheme } from "@mui/material/styles";

export default function CardAchievement(props) {
  const theme = useTheme();
  return (
    <>
      <Grid container spacing={2} columns={16} sx={{ mt: 2, mb: 5 }}>
        <Grid
          item
          xs={4}
          sx={{
            ml: 4,
          }}
        >
          {/* Институт*/}
          <Card
            sx={{
              background: theme?.palette?.box.main,
              boxShadow: `0px 0px 15px 2px ${theme?.palette?.box.shadow}`,
            }}
          >
            <CardMedia
              component="img"
              image={require("../../Assets/imikn/cover_imikn.jpg")}
            />
            <CardContent>
              <Typography variant="body" component="p" align="center">
                {props.nameInst}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={11}
          sx={{
            ml: 1,
          }}
        >
          <Card
            sx={{
              background: theme?.palette?.box.main,
              boxShadow: `0px 0px 15px 2px ${theme?.palette?.box.shadow}`,
            }}
          >
            <CardContent>
              <ListAchievement instituteId={1} />
              {/* Линия прогресса с достижениями */}
              <LinearProgress progress={props.progress} />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={11}
          style={{
            paddingLeft: "2em",
          }}
        ></Grid>
      </Grid>
    </>
  );
}
