import {
    getSpriteCharacterOfGender,
    getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";
import { setVisibleTeacher } from "../../../../../Components/dialogueSystem/scripts/VisibleTeacher";

// background
const imikn_kb = require("../../../assets/imikn/backgrounds/imikn_kb.jpg");

// character
const guide_default = require("../../../assets/imikn/characters/guide.png");
const guide_smile = require("../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let direction_kb = {
    Start: [
        {
            body: [
                {
                    background: imikn_kb,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Представляешь! Пять с половиной лет в университете... Ну почти как врачи, ха-ха-ха",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А почему так долго?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Потому что это специалитет!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    rightSprite: guide_smile,
                    text: "Кстати, ты знаешь, чем отличается бакалавриат от специалитета?",
                },
            ],
        },
        {
            choices: [
                "Да|direction_kb_yes",
                "Нет|direction_kb_no",
            ],
        },
    ],

    direction_kb_yes: [
        {
            body: [
                {
                    background: imikn_kb,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это хорошо, тогда давай я перейду к другим особенностям этого направления...",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_kb_continue",
        },
    ],

    direction_kb_no: [
        {
            body: [
                {
                    background: imikn_kb,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Смотри, раньше считалось, что диплом бакалавра – это неполное высшее образование",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "И чтобы сделать его полным, нужно было проучиться ещё 2 года в магистратуре",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Выпуск со специалитета считался полным высшим, и человек с таким дипломом становился настоящим профессионалом своего дела за 5-6 лет",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А сейчас не так?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Сейчас же совсем по-другому",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "При устройстве на работу никому не важно, что у тебя за диплом, главное, что есть высшее образование и всё!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А где-то и наличие диплома не обязательно, смотрят только на знания и навыки...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Единственное, что имеет значение при выборе бакалавриата или специалитета - хочешь ли ты пойти в научную деятельность",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "То есть в аспирантуру и затем в докторантуру, чтобы получить степень доктора каких-нибудь наук и стать учёным или профессором...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Если такое желание есть, то существует небольшая разница",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Чтобы попасть в аспирантуру, бакалавру нужно закончить ещё и магистратуру, то есть 4 + 2 = 6 лет обучения",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А специалист может сразу после окончания обучения поступать в аспирантуру, это в зависимости от ВУЗа и направления от 5 до 6 лет",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Вот и всё различие, но помимо квалификации, у этого направления есть и другие особенности, давай перейдём к ним...",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А-а-а, так вот чем различаются эти степени! Теперь понятно...",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_kb_continue",
        },
    ],
    
    direction_kb_continue: [
        {
            body: [
                {
                    background: imikn_kb,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Можно заметить, что это направление отличается от информационной безопасности только одним словом, но это играет важную роль!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Там ставится упор на защиту ИНФОРМАЦИИ, а здесь на защиту КОМПЬЮТЕРА. Это если простыми словами",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "На самом деле, и в том, и в другом случае цель – защитить информацию, а вот способы немного различаются",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "В данном случае под компьютером подразумеваются как ПК, так и целые компьютерные и операционные системы, а также сервера",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Среди всех изучаемых дисциплин, большее количество часов отведено криптографическим технологиям - защиту информации шифрованием",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Изучению работы компьютерных сетей, а также их настройки и обслуживанию операционных систем",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    rightSprite: guide_smile,
                    text: "Кстати, последний предмет ведёт Поликарп Тимофеевич, очень хороший преподаватель!",
                    visibleTeacher: "pol_tim",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "У него уникальные и интересные лекции по “операционкам”, если поступишь сюда, обязательно их посещай!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Хорошо, хорошо... Только выбор ещё не сделан...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ах да! Ну я так, на будущее! На чём я остановился?",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "На профильных предметах",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Точно!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Помимо перечисленных мной предметов, есть ещё и другие. Но они преподаются в более упрощённом формате, для ознакомления",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "И студенты уже сами решают, что из пройденного материала изучать более детально",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А так как у специалитета больше времени на обучение, нежели у бакалавриата, то и знания соответственно можно получить более глубокие",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Интересно, интересно... А кем могут работать выпускники?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    rightSprite: guide_default,
                    text: "Тут уж вариантов очень много",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Можно пойти в любую организацию, где есть компьютеры, сервера и тому подобное",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Выпускники будут уметь настраивать, управлять, защищать их, осуществлять проверки и консультации",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А если поподробнее, то например, разработчиком антивирусных программ в Лабораторию Касперского",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Или в отдел информационной безопасности какого-нибудь банка, если, конечно, будут вакансии...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Если нет желания заниматься именно компьютерной безопасностью",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "То можно пойти в область сетевого инжиниринга или системной администрации",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А теперь, предлагаю пойти дальше!",
                },
            ],
        },
        {
            exits: true,
        },
    ],
};

export default direction_kb;
