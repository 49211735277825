import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Chip,
  Divider,
} from "@mui/material";
import AuthUser from "../../../../Services/AuthUser";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import KeywordSelect from "../../../../Components/adminpanel/KeywordSelect";

const EditStoryModal = (props) => {
  const { token, http } = AuthUser();
  const { open, close, story, setStories } = props;
  const [storyName, setStoryName] = useState("");
  const [storyText, setStoryText] = useState("");
  const [storyIDirection, setStoryIDdirection] = useState("");
  const [isModerated, setIsModerated] = useState(false);
  const [directions, setDirections] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [newKeyword, setNewKeyword] = useState("");
 

  useEffect(() => {
    setStoryName(story?.story_name || "");
    setStoryText(story?.story_text || "");
    setStoryIDdirection(story?.story_id_dir || "");
    setIsModerated(story?.is_moderated || false);
    setKeywords(
      story?.keywords ? story?.keywords.map((keyword) => keyword.keyword) : []
    );
  }, [story]);

  const handleSave = () => {
    const data = {
      story_name: storyName,
      story_text: storyText,
      story_id_dir: storyIDirection,
      is_moderated: isModerated,
      keywords: keywords,
    };
    http
      .put(`/stories/${story.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setStories(prevStories => prevStories.map(st => st.id === story.id ? res.data : st)); // Обновить список институтов
        close();
      });
  };

  const handleCancel = () => {
    close();
  };

  const handleNameChange = (event) => {
    setStoryName(event.target.value);
  };
  const handleTextChange = (event) => {
    setStoryText(event.target.value);
  };
  const handleInstituteChange = (event) => {
    setStoryIDdirection(event.target.value);
  };
  const handleChangeIsModerated = (event) => {
    setIsModerated(event.target.checked);
  };

  useEffect(() => {
    const getDirections = async () => {
      const response = await http.get("/directions", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setDirections(response.data);
    };
    getDirections();
  }, []);
  
  const handleAddKeyword = (newKeyword) => {
    // console.log(newKeyword);
    
    http
      .post(`/stories/${story.id}/keywords`, {
        keyword: newKeyword,
      })
      .then((response) => {
        setKeywords([...keywords, newKeyword]);
        setNewKeyword("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteKeyword = (keyword) => {
    http
      .delete(`/stories/${story.id}/keywords`, {
        data: { keyword: keyword },
      })
      .then((response) => {
        setKeywords(keywords.filter((kw) => kw !== keyword));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Редактировать историю</DialogTitle>
      <DialogContent>
        <DialogContentText>Введите новое название истории:</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Название истории"
          type="text"
          fullWidth
          value={storyName}
          onChange={handleNameChange}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Текст истории"
          type="text"
          multiline
          rows={20}
          fullWidth
          value={storyText}
          onChange={handleTextChange}
        />
        <TextField
          margin="normal"
          id="story_id_dir"
          label="Направление"
          select
          fullWidth
          value={storyIDirection}
          onChange={handleInstituteChange}
          required
        >
          {directions.map((dir) => (
            <MenuItem key={dir.id} value={dir.id}>
              {dir.dir_name}
            </MenuItem>
          ))}
        </TextField>

        <div>
          {keywords.map((keyword, i) => (
            <Chip
              key={i}
              label={keyword}
              onDelete={() => handleDeleteKeyword(keyword)}
            />
          ))}
        </div>

        <KeywordSelect handleAddKeyword={handleAddKeyword} storyID={story?.id}/>
  
        <FormControlLabel
          control={
            <Checkbox
              checked={!!isModerated}
              onChange={handleChangeIsModerated}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Модерация"
          // sx={{ ml: 10 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Отмена
        </Button>
        <Button onClick={handleSave} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditStoryModal;
