import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import AuthUser from "../../../../Services/AuthUser";
import Heading from "../../../../Components/adminpanel/Heading";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ShowUserModal from './Show';

export default function ListIndex() {
  const [users, setUsers] = useState([]);
  const { token, http } = AuthUser();
  const [selectedUser, setSelectedUser] = useState(null);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "username", headerName: "Name", width: 130, flex: 0.5 },
    { field: "city", headerName: "Город", width: 200, flex: 0.5 },
    {
      field: "created_at",
      headerName: "Дата регистрации",
      width: 200,
      flex: 0.3,
    },
    { field: "roles", headerName: "Роль", width: 200, flex: 0.5 },
    {
      field: "actions",
      type: "actions",
      width: 80,
      flex: 0,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<RemoveRedEyeIcon />}
          label="eye"
          onClick={() => showUser(params.id)}
        />,
      ],
    },
  ];

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await http.get("/admin/users", {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getUsers();
  }, []);

  const showUser = (id) => {
    const user = users.find((us) => us.id === id);
    setSelectedUser(user);
  };

  return (
    <>
      <Heading title="Пользователи" />
      <Box sx={{ width: "100%", mt: 3 }}>
        <DataGrid rows={users} columns={columns} />
      </Box>
      <ShowUserModal
        open={Boolean(selectedUser)}
        close={() => setSelectedUser(null)}
        user={selectedUser}
        setUsers={setUsers}
      />
    </>
  );
}
