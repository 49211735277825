import {
    getSpriteCharacterOfGender,
    getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";
import { setVisibleTeacher } from "../../../../../Components/dialogueSystem/scripts/VisibleTeacher";

// background
const imikn_pi = require("../../../assets/imikn/backgrounds/imikn_computer_class.jpg");

// character
const guide_default = require("../../../assets/imikn/characters/guide.png");
const guide_smile = require("../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let direction_pi = {
    Start: [
        {
            body: [
                {
                    background: imikn_pi,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Данное направление славится своей прикладной значимостью",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "В отличие от других направлений, упор делается на практическое применение полученных теоретических знаний и реализацию IT-проектов",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Другими словами, практики здесь предостаточно!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Некоторые считают, это не совсем правильным",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ведь из-за огромного количества прикладных задач, остаётся мало времени на важную и нужную теорию",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ведь, если неправильно спроектировать, то даже самый красивый и дорогой дом рухнет... Образно говоря!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Вокруг твердят, что нужно больше практиковаться, а здесь же немного другая ситуация...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Предметы, изучаемые на данном направлении, довольно разнообразны",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Интернет-технологии, web-дизайн и web-программирование, разработка мобильных приложений",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А ещё проектирование баз данных и даже немного машинного обучения!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А что конкретно из машинного обучения?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Об этом тебе расскажет Евдокия Панкратовна, советую к ней зайти потом",
                    visibleTeacher: "evd_pankr",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ещё спроси у неё про моделирование бизнес-процессов",
                },
            ],
        },
        {
            choices: [
                "Зачем?|direction_pi_why",
                "Хорошо!|direction_pi_ok",
            ],
        },
    ],

    direction_pi_why: [
        {
            body: [
                {
                    background: imikn_pi,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это одна из наиважнейших дисциплин на данном направлении!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Только обязательно спроси, а то она забудет рассказать!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Хорошо",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_pi_continue",
        },
    ],

    direction_pi_ok: [
        {
            body: [
                {
                    background: imikn_pi,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Только обязательно спроси, а то она забудет рассказать!",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_pi_continue",
        },
    ],

    direction_pi_continue: [
        {
            body: [
                {
                    background: imikn_pi,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "В общем, работать после выпуска можно программистом-разработчиком в любой сфере",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Главное во время обучения выбрать, что больше нравится и требует больше времени на освоение",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "К примеру, прошли курс по мобильной разработке, очень понравилось и захотелось работать в этой области? Без проблем!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Берёшь и изучаешь тему глубже самостоятельно, а затем на работу!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Кстати, если ты заглянешь в Книгу студентов",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "То сможешь найти парочку реальных историй про разные дисциплины и про мобильную разработку в том числе!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Можно несколько конкретных примеров, а то не совсем понятно, чем занимаются “программисты-разработчики”?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну смотри, можно заниматься бэкендом и разрабатывать серверную часть мобильных приложений или веб-сайтов",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Причём на любом, подходящем для этого, языке программирования, например, PHP, C Sharp, C++, Go, Python и другие",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Можно заняться фронтендом, для этого нужны языки HTML, CSS, JavaScript и прочие",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Или пойти в 1с разработчики, а на крайний случай в техподдержку, помогать пользователям и разработчикам IT-продуктов",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Если в моём рассказе встретились незнакомые термины, ты всегда можешь обратиться к Азбуке, не забывай!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Хорошо, как много вариантов!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "И это я ещё не все перечислил!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    rightSprite: guide_smile,
                    text: "Кстати, по-секрету, говорят, здесь ч-у-у-у-точку легче учиться, чем на других специальностях",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "И вполне возможно совмещать учёбу и работу начиная, примерно, с третьего курса или чуть раньше",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Только - тс-с-с, я тебе ничего не говорил...",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Ха-ха-ха, хорошо, я унесу эту тайну с собой в могилу!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ах, да и про работу... Ты знаешь, как проходит учебная практика на этом направлении?",
                },
            ],
        },
        {
            choices: [
                "Наверное, как и на других|direction_pi_yes",
                "Нет|direction_pi_no",
            ],
        },
    ],

    direction_pi_yes: [
        {
            body: [
                {
                    background: imikn_pi,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Совершенно верно!",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_pi_exit",
        },
    ],
    
    direction_pi_no: [
        {
            body: [
                {
                    background: imikn_pi,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Да также как и на других направлениях",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Выполнение проекта или исследовательской работы, затем написание отчёта и защита того, что сделано",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_pi_exit",
        },
    ],

    direction_pi_exit: [
        {
            body: [
                {
                    background: imikn_pi,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А вот с производственной - всё совершенно по-другому!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Если на других направлениях есть вариант пройти практику на кафедре, то здесь нет!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Студентам прикладной информатики обязательно нужно попрактиковаться в реальной компании",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Университет, конечно, с этим помогает, но не всегда...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Поэтому нужно позаботиться о месте для практики заранее",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А защита всё равно есть?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Да, только защищают не какие-то учебные проекты, а рассказывают, чему такому новому научились у “взрослых” разработчиков",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну, или делают вид, ха-ха-ха",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Звучит действительно полезно, если и правда не “делают вид, что научились”",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А ещё, вот что я заметил",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Преподаватели нередко предлагают дополнительные баллы за написание научных статей и участие в различных олимпиадах",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А для тех, кто себя на данных мероприятиях проявил и вышел в следующий тур, даже организовывают дополнительные занятия для подготовки!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Похоже возможностей на данном направлении предостаточно...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это точно!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Так, мы что-то задержались, давай пойдём дальше...",
                },
            ],
        },
        {
            exits: true,
        },
    ],
};

export default direction_pi;
