import NEWSCAROUSEL_IMAGE_1 from "../../Assets/News/cover_imikn_release.jpg";
import NEWSCAROUSEL_IMAGE_2 from "../../Assets/News/cover_feedback.jpg";
import NEWSCAROUSEL_IMAGE_3 from "../../Assets/Members/MembersWOBorder.png";
import CARDNEWS_IMAGE_1 from "../../Assets/06.png";
// import CARDNEWS_IMAGE_2 from "../../Assets/imikn/cover_bots_vk_tg.jpg";
import CARDNEWS_IMAGE_3 from "../../Assets/imikn/cover_feedback.jpg";

export const NEWSCAROUSEL = [
  {
    id: 1,
    label: "Команда",
    image: NEWSCAROUSEL_IMAGE_1,
    description:
      "Никогда не идет на конфликт, старается быть благородным, очень общительный, но иногда ему требуется одиночество для того чтобы разгрузить мозг.",
    href: "/news/1",
    style: {
      maxHeight: '70vh',
    },
  },
  {
    id: 2,
    label: "Вторая",
    image: NEWSCAROUSEL_IMAGE_2,
    description:
      "Никогда не идет на конфликт, старается быть благородным, очень общительный, но иногда ему требуется одиночество для того чтобы разгрузить мозг.",
    href: "/news/3",
    style: {
      maxHeight: '70vh',
    },
  },
  {
    id: 3,
    label: "Третья",
    image: NEWSCAROUSEL_IMAGE_3,
    description:
      "Никогда не идет на конфликт, старается быть благородным, очень общительный, но иногда ему требуется одиночество для того чтобы разгрузить мозг.",
    href: "/aboutus",
    style: {
      maxHeight: '70vh',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
];

export const CARDNEWS = [
  {
    id: 1,
    label: "Первая",
    image: CARDNEWS_IMAGE_1,
    description: "Никогда не идет на конфликт, старается быть благородным",
    title: "Еекст-рыба, часто используемый в печати и вэб-дизайне",
    date: "05.10.2023",
    text: "Lorem Ipsum - это текст-рыба, часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной рыбой для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн. Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, программы электронной вёрстки типа Aldus PageMaker, в шаблонах которых используется Lorem Ipsum.",
    href: "/news/1",
  },
  {
    id: 2,
    label: "Ты студент?!",
    image: CARDNEWS_IMAGE_3,
    description:
      "У нас есть чат боты с помощью, которых вы можете поделиться своей историей",

    title: "Еекст-рыба, часто используемый в печати и вэб-дизайне",
    date: "05.10.2023",
    text: "Lorem Ipsum - это текст-рыба, часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной рыбой для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн. Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, программы электронной вёрстки типа Aldus PageMaker, в шаблонах которых используется Lorem Ipsum.",
    href: "/news/2",
  },
  {
    id: 3,
    label: "Уже поиграл?!",
    image: CARDNEWS_IMAGE_3,
    description:
      "Нашли баг?! Понравилось/Непонравилось?! Оставь отзыв! нам важно ваше мнение!",
    title: "Еекст-рыба, часто используемый в печати и вэб-дизайне",
    date: "05.10.2023",
    text: "Lorem Ipsum - это текст-рыба, часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной рыбой для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн. Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, программы электронной вёрстки типа Aldus PageMaker, в шаблонах которых используется Lorem Ipsum.",
    href: "/news/3",
  },
];
