import {
    getSpriteCharacterOfGender,
  } from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";
  
  // background
  const imikn_first_coridor = require("../../../../assets/imikn/backgrounds/imikn_first_coridor.jpg");
 
  // character
  const guide_default = require("../../../../assets/imikn/characters/guide.png");
  
  //color
  const player_color = "#D4D4D4";
  const guide_color = "#9DC7CC";
  
  let teachers_intro_3 = {
    Start: [
      {
        body: [
          {
            background: imikn_first_coridor,
            leftSprite: getSpriteCharacterOfGender("default"),
            centerSprite: "",
            rightSprite: guide_default,
            speakSprite: "right",
            nameSpeak: "Богдан",
            speakColor: guide_color,
            text: "Все бродишь? Надеюсь, хоть не напрасно...",
          },
        ],
      },
      {
        exits: true,
      },
    ],
  };
  
  export default teachers_intro_3;
  