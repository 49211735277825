import Footer from "../../Components/footer/Footer";
import Header from "../../Components/header/Header";
import CardAchievInst from "../../Components/achievement/CardAchievInst";

export default function AllAchievementPage() {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <CardAchievInst
          nameInst="Институт Математики и Компьютерных Наук"
          imageInst={require("../../Assets/imikn/cover_imikn.jpg")}
          instPath="imikn"
        />
        <Footer />
      </div>
    </>
  );
}
