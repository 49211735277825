import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";
import { setNetworking } from "../../../../../../Components/dialogueSystem/scripts/CheckVisited";

// background
const imikn_practice_01 = require("../../../../assets/imikn/backgrounds/practice 1/imikn_practice_01_students.jpg");

// character
const dobr_luc = require("../../../../assets/imikn/characters/dobr_luc.png");
const dobr_luc_smile = require("../../../../assets/imikn/characters/dobr_luc_smile.png");
const dobr_luc_angry = require("../../../../assets/imikn/characters/dobr_luc_angry.png");
const leader_gleb = require("../../../../assets/imikn/characters/leader_gleb.png");
const sleepyhead = require("../../../../assets/imikn/characters/sleepyhead.png");
const sleepyhead_sad = require("../../../../assets/imikn/characters/sleepyhead_sad.png");
const sleepyhead_sleeping = require("../../../../assets/imikn/characters/sleepyhead_sleeping.png");
const sleepyhead_woken = require("../../../../assets/imikn/characters/sleepyhead_woken.png");

//color
const player_color = "#D4D4D4";
const dobr_luc_color = "#D7DAED";
const leader_gleb_color = "#D2CDB5";
const sleepyhead_color = "#D2B4B4";
const student_color = "#CCDFFF";
const another_student_color = "#D8FFF6";
const student_girl_color = "#CFCAFF";

let lesson_networking_begin = {
  Start: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: "",
          centerSprite: "",
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Ох, на десять минут опоздал... Препод еще не пришел?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "Нет",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "О, повезло-повезло",
        },
        {
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Всем добрый день, кого не видел!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Это он про меня что-ли? Так я же был...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Я немного задержался, но ничего страшного!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Порядок вы знаете, занимайте очередь, я скажу, когда можно начать сдачу заданий, будут вопросы или проблемы, подходите...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студенты",
          speakColor: student_color,
          text: "*тишина*",
        },
        {
          rightSprite: dobr_luc_smile,
          leftSprite: getSpriteCharacterOfGender("default"),
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ого! Вы всё-таки нашли время зайти на практику! Как чудесно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Как Вам лекция?",
        },
      ],
    },
    {
      choices: [
        "Здорово! Мне очень понравилось!|lesson_networking_begin_ans_1",
        "Было интересно, но меня отвлекли...|lesson_networking_begin_ans_2",
        "Лекция была скучновата, если честно...|lesson_networking_begin_ans_3",
      ],
    },
  ],

  lesson_networking_begin_ans_1: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Отлично! Крайне рад такому ответу!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_networking_begin_continue",
    },
  ],

  lesson_networking_begin_ans_2: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну, что тут поделать",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Такое очень часто случается",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "А дальше выбор за Вами: игнорировать таких людей или идти на поводу",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Лично я студентов не ограничиваю, у всех свои приоритеты, хотят заниматься своими делами? Пожалуйста!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Главное, чтобы потом понимали, за что в конце семестра выставляется оценка...",
        },
        {
          leftSprite: "",
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Блин, что там в пятом задании?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "Да я сам не знаю, спроси у Лёхи, он шарит",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*громким шёпотом* Лёха! Что в пятом?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Лёха",
          speakColor: another_student_color,
          text: "Да там же всё просто, нужно всего лишь...",
        },
        {
          leftSprite: getSpriteCharacterOfGender("default"),
          rightSprite: dobr_luc_angry,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну-ка! Алексей! Не подсказывать!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ха-ха, а Вы говорили, что не ограничиваете...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну я же про обычные разговоры говорил, а не про списывание",
        },
        {
          leftSprite: "",
          rightSprite: "",
          speakSprite: "left",
          nameSpeak: "Лёха",
          speakColor: another_student_color,
          text: "*шёпотом* Так вот, там нужно...",
        },
        {
          leftSprite: getSpriteCharacterOfGender("default"),
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Конечно, я одобрительно отношусь к ситуациям, когда более, скажем, сильный студент помогает... м-м-м... отстающим...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Но этим нужно заниматься после занятий, А НЕ ВО ВРЕМЯ! АЛЕКСЕЙ!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_networking_begin_continue",
    },
  ],

  lesson_networking_begin_ans_3: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну что ж, спишем всё на отсутствие у Вас первичной подготовки к такому материалу...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Всё-таки, когда слышишь достаточно сложную информацию впервые, нужно иметь какую-никакую базу знаний...",
        },
        {
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Иначе и правда не сложно заскучать, хе-хе",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_networking_begin_continue",
    },
  ],

  lesson_networking_begin_continue: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну, а теперь о практике! Вы же, наверняка не знаете порядок проведения таких занятий, верно?",
        },
      ],
    },
    {
      choices: [
        "Не знаю, но надеюсь Вы расскажете|lesson_networking_begin_dont_know",
        "Судя по названию, на этих занятиях практикуются...|lesson_networking_begin_know",
      ],
    },
  ],

  lesson_networking_begin_dont_know: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Конечно расскажу!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_networking_begin_continue_2",
    },
  ],

  lesson_networking_begin_know: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Совершенно верно!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_networking_begin_continue_2",
    },
  ],

  lesson_networking_begin_continue_2: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Смотрите, у каждого преподавателя, разумеется, свои порядки, правила и атмосфера на паре...",
        },
        {
          rightSprite: "",
          leftSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Слушай, дай списать третье задание",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Давай я лучше тебе объясню, а то препод придет, спросит, а ты опять ничего не знаешь",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Ладно, давай...",
        },
        {
          leftSprite: getSpriteCharacterOfGender("default"),
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Лично я не стремлюсь мучить студентов, поэтому на моих парах - всё просто! И довольно стандартно...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "На лекции разбираем какую-то тему, затем я высылаю студентам задания или лабы, называйте, как хотите, по теме лекции",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "А на практических занятиях мы эти задания разбираем, я помогаю, если нужно...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну и в конце студент должен защитить свою работу",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Защитить от кого? Кто-то на неё нападает?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ха-ха, конечно же нет! В университете слово “защита” означает, скорее, объяснение чего-либо",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "В данном случае, студент объясняет мне как выполнил свою лабораторную работу",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Я смотрю решение, задаю вопросы и выставляю баллы",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А что за вопросы?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "В основном по теме, чтобы понять насколько студент понял новый материал и, если нужно, дополнительно разъяснить трудные моменты",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Бывает, конечно, что вопросы необходимы для того, чтобы проверить: сам ли студент делал задание или у кого-то списал!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Но, по секрету Вам скажу, таких людей видно сразу... Они так волнуются, что я ещё в коридоре знаю, кто списал, а кто делал сам, ха-ха-ха",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ого! Это почти суперспособность!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ха-ха, хорошо подмечено!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Вот знаете, из нашей с Вами беседы я понял, что у Вас отменная смекалка!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Не хотите попробовать свои силы и решить простенькое задание?",
        },
      ],
    },
    {
      choices: [
        "Ого, как интересно! Конечно же хочу!|lesson_networking_begin_exercise",
        "Даже не зна-а-аю...|lesson_networking_begin_dont_want",
      ],
    },
  ],

  lesson_networking_begin_dont_want: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну чего ж тут сомневаться? Вам же ничего не будет, если не получится!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Но при любом исходе - это опыт, а что может быть полезнее?",
        },
      ],
    },
    {
      choices: [
        "Да, Вы правы! Давайте я всё-таки попробую решить задание|lesson_networking_begin_exercise",
        "Нет, спасибо. Всё же хочу пока быть зрителем, а не участником|lesson_networking_begin_continuation_end",
      ],
    },
  ],

  lesson_networking_begin_exercise: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Замечательно! Садитесь за свободный компьютер и запускайте программу... Я через минутку к Вам подойду",
        },
        {
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: ["lecture_talk_neighbor", "Глеб", "Сосед по парте"],
          speakColor: leader_gleb_color,
          text: "Ха-ха, через “мину-у-утку”...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А что такого смешного в этой фразе?",
        },
        {
          speakSprite: "right",
          nameSpeak: ["lecture_talk_neighbor", "Глеб", "Сосед по парте"],
          speakColor: leader_gleb_color,
          text: "Да институтские компы - тормозы. По десять минут включаются...",
        },
        {
          speakSprite: "right",
          nameSpeak: ["lecture_talk_neighbor", "Глеб", "Сосед по парте"],
          speakColor: leader_gleb_color,
          text: "Поэтому большинство студентов ходят со своими ноутами на пары",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ну не может быть всё настолько плохо, ты, наверное, преувеличиваешь",
        },
        {
          speakSprite: "center",
          nameSpeak: "",
          speakColor: "",
          text: "*спустя девять минут*",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ну ладно, похоже ты не преувеличивал...",
        },
        {
          speakSprite: "right",
          nameSpeak: ["lecture_talk_neighbor", "Глеб", "Сосед по парте"],
          speakColor: leader_gleb_color,
          text: "Хе-хе, а я о чём!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Так, а какую программу запускать?",
        },
        {
          speakSprite: "right",
          nameSpeak: ["lecture_talk_neighbor", "Глеб", "Сосед по парте"],
          speakColor: leader_gleb_color,
          text: "Во-о-от эту",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ага, спасибо",
        },
        {
          rightSprite: "",
          speakSprite: "center",
          nameSpeak: "",
          speakColor: "",
          text: "*спустя три минуты*",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Наконец-то открылась!",
        },
        {
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "А вот и я, вижу Вы всё запустили, отлично!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Теперь давайте расскажу задание...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Вам необходимо создать компьютерную сеть, из трёх ПК и одного коммутатора, а также настроить IPv-4 так, чтобы ПК “пинговались”",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Думаю, что после посещения лекции, Вам не составит труда это сделать...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Как только всё выполните, нажмите галочку в правом нижнем углу и позовите меня для проверки",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Если же что-то будет не получаться, обязательно спрашивайте, я пренепременно Вам помогу!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "А теперь приступайте!",
        },
      ],
    },
    {
      // exits: true,
      game: "networking",
    },
  ],

  lesson_networking_begin_continuation_end: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну, как хотите, Ваше дело...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Главное, помните, что если всегда так отказываться, то Вы упустите важный аспект обучения!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Какой?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Нет лучшего способа усвоить новые знания, чем практика!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Понятно...",
          achiev_child_id: 21, // 3.6
        },
        {
          rightSprite: "",
          leftSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Как же лень все это делать... Может свалить? Сказать, что плохо себя чувствую... Как думаешь, прокатит?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Не знаю, я бы на твоём месте, все-таки хоть что-нибудь сделал",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Эта тема точно будет на контрольной, препод на прошлом занятии сам сказал",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Блинб... Не, всё-таки свалю... На улице такая погода классная, а мы тут сидим...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Добромир Люцианович! Можно уйти пораньше?",
        },
        {
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Что случилось?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Я себя плохо чувствую. Можно я домой пойду?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Пара скоро закончится, потерпи еще немного, тема важная как никак!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Эх...",
        },
        {
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Я же говорил...",
        },
        {
          leftSprite: sleepyhead_sleeping,
          rightSprite: dobr_luc_smile,
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "*громкий храп*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Баранов! Вы что же, опять уснули?",
        },
        {
          leftSprite: sleepyhead_woken,
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "А, что?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Чем же Вы ночью занимались, что спите весь день?",
        },
        {
          leftSprite: sleepyhead,
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "Так, лабу делал...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "И вместо того, чтобы сдавать, Вы решили поспать? Зачем же делали тогда?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "А, да, да, можно сдать?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Показывайте...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Так, тут понятно, тут странно сделано, но в принципе тоже подходит...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "А вот скажите, почему Вы здесь сделали именно так?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "Ну так придумал, да и в задании ничего про оптимизацию не было сказано...",
        },
        {
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Н-да, действительно... Самым простым способом, конечно...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "На работу Вас не взяли бы с такими знаниями...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "Да я знаю, как тут можно сделать лучше, мне просто лень было, ну и время поджимало, сами понимаете...",
        },
        {
          rightSprite: dobr_luc_angry,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Так у Вас неделя была на выполнение! Что значит время поджимало?!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Шесть дней в игры играли, а в последний вспомнили про задание? Типичные студенты!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Давайте, переделайте, чтобы всё красиво было!",
        },
        {
          leftSprite: sleepyhead_sad,
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "Хорошо-о-о *тяжёлый вздох*",
        },
        {
          leftSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Добромир Люцианович! Подойдите, пожалуйста!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Иду, иду",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Так... ага... стоп, не понял. Что это?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "А, ну это...",
        },
        {
          rightSprite: dobr_luc_angry,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "А это что? А здесь? Вы вообще это сами писали?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Д-да...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Понятно-понятно...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну вот подойдёте, когда сами выполните и сможете объяснить",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Но уже не на этой паре, видимо...",
        },
        {
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Итак, занятие окончено, можете быть свободными!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "До свидания!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Стойте, стойте, Вы же ключ брали, чтобы кабинет открыть?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Да",
        },
        {
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Значит это я Вам скажу до свидания, а Вы дождитесь пока все уйдут, закройте кабинет и сдайте его обратно!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Хорошо... Эхх, а хотел пораньше домой уйти...",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default lesson_networking_begin;
