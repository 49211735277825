import {
    getSpriteCharacterOfGender,
    getNamePlayer,
} from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_first_coridor = require("../../../../assets/imikn/backgrounds/imikn_first_coridor.jpg");

// сutscenes
const cutscene_begin_01 = require("../../../../assets/imikn/cutscenes/cutscene_begin_01.jpg");
const cutscene_begin_02 = require("../../../../assets/imikn/cutscenes/cutscene_begin_02.jpg");
const cutscene_begin_03 = require("../../../../assets/imikn/cutscenes/cutscene_begin_03.jpg");
const cutscene_begin_04 = require("../../../../assets/imikn/cutscenes/cutscene_begin_04.jpg");
const cutscene_begin_05 = require("../../../../assets/imikn/cutscenes/cutscene_begin_05.jpg");
const cutscene_begin_06 = require("../../../../assets/imikn/cutscenes/cutscene_begin_06.jpg");
const cutscene_begin_07 = require("../../../../assets/imikn/cutscenes/cutscene_begin_07.jpg");
const cutscene_begin_08 = require("../../../../assets/imikn/cutscenes/cutscene_begin_08.jpg");
const cutscene_begin_09 = require("../../../../assets/imikn/cutscenes/cutscene_begin_09.jpg");

// character
const guide_default = require("../../../../assets/imikn/characters/guide.png");
const guide_smile = require("../../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let directions_intro_1 = {
    Start: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "???",
                    speakColor: guide_color,
                    text: "О, привет! Ты только не пугайся, я очень мирный и дружелюбный призрак!",
                },
                {
                    nameSpeak: "???",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Судьба обрекла меня скитаться после смерти в стенах этого здания...",
                },
                {
                    nameSpeak: "???",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "И я решил знакомиться с абитуриентами и помогать им начать свой путь в университете...",
                },
                {
                    nameSpeak: "???",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Кстати, какая бестактность! Я совсем забыл представиться! Меня зовут Богдан. А как к тебе обращаться?",
                },
                {
                    nameSpeak: getNamePlayer(),
                    speakSprite: "left",
                    speakColor: player_color,
                    text: `Меня зовут ${getNamePlayer()}`,
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Приятно познакомиться!",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Ты, наверное, в недоумении, почему беседуешь с призраком... Хочешь, я расскажу тебе эту трагическую историю?",
                },
            ],
        },
        {
            choices: [
                "Давай!|directions_intro_1_story",
                "У меня не так много времени, давай в другой раз?|directions_intro_1_dont_story",
            ],
        },
    ],

    directions_intro_1_story: [
        {
            body: [
                {
                    background: cutscene_begin_01,
                    leftSprite: "",
                    centerSprite: "",
                    rightSprite: "",
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Когда-то давно, я тоже учился в этом институте. Эх, хорошие были времена!",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Но на третьем курсе случилось несчастье... ",
                },
                {
                    background: cutscene_begin_02,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Когда я вместе с другом-физиком шёл по лестнице в столовую",
                },
                {
                    background: cutscene_begin_03,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Один химик-недотёпа разбил страшный химикат, и ...",
                },
                {
                    background: cutscene_begin_04,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Мы с другом стали призраками",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А химику вообще досталось сполна!",
                },
                {
                    background: cutscene_begin_05,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Он мутировал и превратился в ЛЯГУШКУ!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ха-ха-ха, кто бы мог подумать! Бедняга и в новом обличии остался таким же недотепой... Зато человек хороший!",
                },
                {
                    background: cutscene_begin_06,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Вот тогда мы втроём и подружились, стали вместе искать разгадку этим странным перевоплощениям",
                },
                {
                    background: cutscene_begin_07,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Первое время мы веселились и довольствовались новыми возможностями, но шли дни, месяцы, годы...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Заняться было нечем, и мы погрузились в уныние...",
                },
                {
                    background: cutscene_begin_08,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Пока однажды, одному из нас не пришла в голову гениальная идея...",
                },
                {
                    background: cutscene_begin_09,
                    speakSprite: "right",
                    nameSpeak: "Варфоломей",
                    speakColor: guide_color,
                    text: "Ребята, я придумал!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Варфоломей",
                    speakColor: guide_color,
                    text: "А что если мы тут не случайно, что, если наша цель - кому-нибудь помочь?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Варфоломей",
                    speakColor: guide_color,
                    text: "Я, правда, не знаю с чем, поэтому нам надо попробовать все варианты!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Варфоломей",
                    speakColor: guide_color,
                    text: "Что, если начать с абитуриентов? Вспомните себя в их возрасте...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Варфоломей",
                    speakColor: guide_color,
                    text: "Абсолютное непонимание, что делать дальше, страх совершить ошибки, всё новое и неизвестное, как же это было ужасно!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Варфоломей",
                    speakColor: guide_color,
                    text: "Давайте станем их проводниками и наставниками?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Варфоломей",
                    speakColor: guide_color,
                    text: "Передадим все свои знания и опыт юным дарованиям и, возможно, после этого станем свободными?",
                },
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Так мы и решили стать, как бы так выразиться...",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Экскурсоводами! Для абитуриентов, конечно!",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "И дело хорошее, и занятие интересное",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Поделили обязанности по факультетам, на которых учились и стали пугать молодых!",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Ну, точнее помогать...",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Вот и вся история!",
                    achiev_child_id: 2, // 1.1
                },
                {
                    nameSpeak: getNamePlayer(),
                    speakSprite: "left",
                    speakColor: player_color,
                    text: "Какая... неоднозначная...",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Какая есть, ха-ха, я не унываю!",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "directions_intro_1_continue",
        },
    ],

    directions_intro_1_dont_story: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Так уж и быть...",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Никто Богдана не любит...",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "directions_intro_1_continue",
        },
    ],

    directions_intro_1_continue: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А как именно ты помогаешь абитуриентам?",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Ну смотри, я могу довольно подробно рассказать о каждом направлении в этом институте...",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "О том, что на них изучают, кем можно работать после выпуска, о различных особенностях и прочем",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Если у тебя будет желание, то помогу встретиться и поговорить с разными преподавателями и даже заглянуть на реальные занятия",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    text: "Чтобы у тебя сложилось полное представление об обучении здесь",
                },
                {
                    nameSpeak: getNamePlayer(),
                    speakSprite: "left",
                    speakColor: player_color,
                    text: "Ого! Чего же мы ждём?",
                },
                {
                    nameSpeak: "Богдан",
                    speakSprite: "right",
                    speakColor: guide_color,
                    rightSprite: guide_smile,
                    text: "Действительно! Скорее выбирай на карте первое направление и мы отправимся в это увлекательное путешествие по институту!",
                },
            ],
        },
        {
            exits: true,
        },
    ],
};

export default directions_intro_1;
