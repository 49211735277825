import React, { useState, useEffect } from "react";
import "../header/header.css";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import AuthUser from "../../Services/AuthUser";
import Image from "../../Assets/logo-white.png";
import ImageAvatarMale from "../../Assets/avatar_main_male.png";
import ImageAvatarFemale from "../../Assets/avatar_main_female.png";
import { useTheme } from "@mui/material/styles";
import Toggle from "../Toggle";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";

const pages = [
  {
    title: "Главная",
    className: "link-item",
    url: "/",
  },
  {
    title: "Персонажи",
    className: "link-item",
    url: "/characters",
  },

  {
    title: "Игровой раздел",
    className: "link-item",
    url: "/gamesection",
  },
  {
    title: "Книга студентов",
    className: "link-item",
    url: "/studentbook/intro",
  },
];
const userSettings = [
  {
    title: "Профиль",
    className: "link-item",
    url: "/profile",
  },
  {
    title: "Достижения",
    className: "link-item",
    url: "/achievements",
  },
];
const adminSettings = [
  {
    title: "Профиль",
    className: "link-item",
    url: "/profile",
  },
  {
    title: "Админ панель",
    className: "link-item",
    url: "/admin/dashboard",
  },
  {
    title: "Достижения",
    className: "link-item",
    url: "/achievements",
  },
];

const Logo = styled("div")`
  position: relative;
  width: 6.5em;
  height: 0;
`;

function AuthNav() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      const userAgent = navigator.userAgent;
      setIsMobile(
        /Android/i.test(userAgent) ||
        /BlackBerry/i.test(userAgent) ||
        /iPhone|iPad|iPod/i.test(userAgent) ||
        /Opera Mini/i.test(userAgent) ||
        /IEMobile/i.test(userAgent)
      );
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);
  const theme = useTheme();
  const { http, token, logout } = AuthUser();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const user = JSON.parse(localStorage.getItem("user"));

  function getGenderAvatar() {
    if (user.gender === 0) {
      return ImageAvatarMale;
    } else {
      return ImageAvatarFemale;
    }
  }

  function getRolesUser() {
    if (user.roles[0] === "Player") {
      return userSettings;
    } else {
      return adminSettings;
    }
  }

  const logoutUser = () => {
    if (token !== undefined) {
      logout();
    }
  };
  const [getRows, setRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [loadData, setData] = useState([]);

  const handleClose = () => setOpen(false);
  const handleOpen = (event) => {
    setAnchorElNav(event.currentTarget);
    loadList();
  };
  const updateNotification = () => {
    // Update Notification table
    setOpen(false);
    http
      .post(`/updateNotification/achievChild/${user.id}`)
      .then((result) => {
        setOpen(false);
        getLatestRowDetails();
        setAnchorElNav(null);
      })
      .catch(() => {
        alert("Error in the Code");
      });
  };

  const getLatestRowDetails = async () => {
    const results = await http.get(`/getCountStatus/achievChild/${user.id}`);
    setRows(results.data);
  };
  const loadList = async () => {
    const result = await http.get(`/list/achievChild/${user.id}`);
    setData(result.data);
  };

  // const setDataGame = () => {
  //   const combined = {
  //     direction: JSON.parse(localStorage.getItem("direction")),
  //     intro: JSON.parse(localStorage.getItem("intro")),
  //     lesson: JSON.parse(localStorage.getItem("lesson")),
  //     teacher: JSON.parse(localStorage.getItem("teacher")),
  //   };
  //   const jsonString = JSON.stringify(combined);
  //   console.log(jsonString);
  //   http.put(`/store-data-game/${user.id}`, { imikn: jsonString })
  //   .then((result) => {
  //     console.log(result.data);
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //   });
  // };

  useEffect(() => {
    loadList();
    getLatestRowDetails();
    // setDataGame();
    // const intervalId = setInterval(setDataGame, 60000);
    // return () => clearInterval(intervalId);
  }, []);

  return (
    <AppBar
      position="static"
      sx={{
        background: theme?.palette?.header.main,
        zIndex: "9",
      }}
    >
      <Container maxWidth="100%">
        <Toolbar>
          {/* При полном экране */}
          <Box
            className="box-style"
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "center", margin: "0 auto 0 9.5%"
            }}
          >
            <div className="link-item">
              <a href="/gamesection">Игровой раздел</a>
            </div>

            <div className="link-item">
              <a href="/characters">Персонажи</a>
            </div>
            <div>
              <Logo>
                <Box
                  sx={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "0",
                    paddingBottom: " 100%",
                    borderRadius: "100%",
                    marginTop: "-1em",
                    background: theme?.palette?.header.main,
                  }}
                >
                  <a href="/">
                    <img
                      src={Image}
                      alt="Logo"
                      className="link-item-logo"
                      style={{ width: " 100%", padding: "0.7em" }}
                    />
                  </a>
                </Box>
              </Logo>
            </div>
            <div className="link-item">
              <a href="/studentbook/intro">Книга студентов</a>
            </div>

            <div className="link-item">
              <a href="/aboutus">О команде</a>
            </div>
          </Box>
          {/* при сужении браузера */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              PaperProps={{
                sx: {
                  background: theme?.palette?.secondary.light,
                  maxHeight: "400px",
                },
              }}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {isMobile ? (
              <MenuItem onClick={handleCloseNavMenu}>
                  <div className="link-item">
                    <a href="/">Главная</a>
                  </div>
                </MenuItem>) :
                (
                  pages.map((page, i) => (
                  <MenuItem
                    key={i}
                    onClick={handleCloseNavMenu}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      "&:hover": {
                        background: "rgba(18, 18, 18,0.5)",
                      },
                    }}
                  >
                    <a
                      href={page.url}
                      style={{
                        color: "#ffffff",
                        textDecoration: "none",
                        padding: "3px",
                      }}
                    >
                      {page.title}
                    </a>
                  </MenuItem>
                ))
                )}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={Image} alt="" style={{ width: "5rem" }} />
          </Typography>
          <div>
            <IconButton
              size="large"
              aria-label="show notifications"
              color="inherit"
              sx={{ mr: 3, color: "white" }}
              onClick={handleOpen}
              aria-controls="notification-menu"
            >
              <Badge color="error" badgeContent={getRows == "0" ? 0 : getRows}>
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Menu
              id="notification-menu"
              anchorEl={anchorElNav}
              open={Boolean(anchorElNav)}
              onClose={() => setAnchorElNav(null)}
              sx={{ mt: "45px" }}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                sx: {
                  background: theme?.palette?.secondary.light,
                  maxHeight: "400px",
                  minWidth: 130,
                },
              }}
            >
              {loadData.map((notification, i) => (
                <MenuItem
                  key={i}
                  onClose={handleClose}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    "&:hover": {
                      background: "rgba(18, 18, 18,0.5)",
                    },
                  }}
                >
                  <a
                    target="_blank"
                    href={`/achievements/${notification.inst_url}/${notification.achiev_id_child}`}
                    style={{
                      color: "#ffffff",
                      textDecoration: "none",
                      padding: "3px",
                    }}
                  >
                    {notification.achiev_name}
                  </a>
                </MenuItem>
              ))}
              {loadData.length ? (
                <Typography
                  sx={{
                    p: 1,
                    textAlign: "center",
                    cursor: "pointer",
                    "&:hover": { textDecoration: "underline" },
                  }}
                  onClick={updateNotification}
                >
                  Очистить
                </Typography>
              ) : (
                <Typography sx={{ p: 1, textAlign: "center" }}>
                  Пусто
                </Typography>
              )}
            </Menu>
          </div>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={handleOpenUserMenu}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{
                p: 0,
                borderColor: "#ffffff",
                borderStyle: "solid",
                borderWidth: "1px",
              }}
            >
              <Avatar alt="Remy Sharp" src={getGenderAvatar()} />
            </IconButton>
          </Box>
          <Toggle />
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
            PaperProps={{
              sx: {
                background: theme?.palette?.secondary.light,
                maxHeight: "400px",
              },
            }}
          >
            {getRolesUser().map((setting, i) => (
              <MenuItem
                key={i}
                onClick={handleCloseUserMenu}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  "&:hover": {
                    background: "rgba(18, 18, 18,0.5)",
                  },
                }}
              >
                <a
                  href={setting.url}
                  style={{
                    color: "#ffffff",
                    textDecoration: "none",
                    padding: "3px",
                  }}
                >
                  {setting.title}
                </a>
              </MenuItem>
            ))}
            <MenuItem
              onClick={handleCloseUserMenu}
              sx={{
                display: "flex",
                justifyContent: "center",
                "&:hover": {
                  background: "rgba(18, 18, 18,0.5)",
                },
              }}
            >
              <a
                onClick={logoutUser}
                style={{
                  color: "#ffffff",
                  textDecoration: "none",
                  padding: "3px",
                }}
              >
                Выйти
              </a>
            </MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default AuthNav;
