import React, { useEffect, useState } from 'react';
import "../header/header.css";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import Image from "../../Assets/logo-white.png";
import { useTheme } from "@mui/material/styles";
import Toggle from "../Toggle";


const pages = [
  {
    title: "Главная",
    className: "link-item",
    url: "/",
  },
  {
    title: "Персонажи",
    className: "link-item",
    url: "/characters",
  },
  {
    title: "Игровой раздел",
    className: "link-item",
    url: "/gamesection",
  },
  {
    title: "Книга студентов",
    className: "link-item",
    url: "/studentbook/intro",
  },
  {
    title: "Войти",
    className: "link-item-sign-menu",
    url: "/login",
  },
];

const Logo = styled("div")`
  position: relative;
  width: 6.5em;
`;


function GuestNav() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      const userAgent = navigator.userAgent;
      setIsMobile(
        /Android/i.test(userAgent) ||
        /BlackBerry/i.test(userAgent) ||
        /iPhone|iPad|iPod/i.test(userAgent) ||
        /Opera Mini/i.test(userAgent) ||
        /IEMobile/i.test(userAgent)
      );
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const theme = useTheme();

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="static"
      sx={{
        background: theme?.palette?.header.main,
        zIndex: "9"
      }}
    >
      <Container maxWidth="xl">
        <Toolbar>
          {/* При полном экране */}
          <Box
            className="box-style"
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, margin: "0 auto"}}
          >
            <div className="link-item">
              <a href="/gamesection">Игровой раздел</a>
            </div>

            <div className="link-item">
              <a href="/characters">Персонажи</a>
            </div>
            <div>
              <Logo>
                <Box
                  sx={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "0",
                    paddingBottom: " 100%",
                    borderRadius: "100%",
                    marginTop: "-1em",
                    background: theme?.palette?.header.main,
                  }}
                >
                  <a href="/">
                    <img
                      src={Image}
                      alt="Logo"
                      className="link-item-logo"
                      style={{ width: " 100%", padding: "0.7em" }}
                    />
                  </a>
                </Box>
              </Logo>
            </div>

            <div className="link-item">
              <a href="/studentbook/intro">Книга студентов</a>
            </div>

            <div className="link-item">
              <a href="/aboutus">О команде</a>
            </div>
            <div
              className="link-item-sign"
              style={{
                position: "absolute",
                right: 0,
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <a href="/login">Войти</a>
              <Toggle />
            </div>
          </Box>

          {/* при сужении браузера */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, margin: "0 -5% 0 auto" }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              PaperProps={{
                sx: {
                  backgroundColor: "rgba(18,18,18,0.98)",
                  maxHeight: "400px",
                },
              }}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {isMobile ? (
              <MenuItem onClick={handleCloseNavMenu}>
                  <div className="link-item">
                    <a href="/">Главная</a>
                  </div>
                </MenuItem>) : (pages.map((page, i) => (
                <MenuItem key={i} onClick={handleCloseNavMenu}>
                  <div className={page.className}>
                    <a href={page.url}>{page.title}</a>
                  </div>
                </MenuItem>
              )))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img src={Image} alt="" style={{ width: "5rem" }} />
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default GuestNav;
