import Footer from "../../Components/footer/Footer";
import Header from "../../Components/header/Header";
import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import BookContent from "../../Components/studentBook/BookContent";
import IntroConclusion from "./components/IntroConclusion";
import { Typography } from "@mui/material";

export default function Intro() {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <Container component="main" maxWidth="xl" sx={{ padding: 9 }}>
          <Grid
            container
            spacing={3}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* Первая часть контейнера */}
            <Grid item xs={12} sm={4} md={4}>
              <BookContent />
            </Grid>
            {/* Вторая часть контейнера */}
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <IntroConclusion
                title="Введение"
                text={
                  <>
                    <Typography variant="h6" gutterBottom style={{ textIndent: "2em" }}>
                      В данной книге, дорогой друг, ты сможешь прочитать истории
                      студентов и выпускников Тюменского государственного
                      университета!
                    </Typography>
                    <Typography variant="h6" gutterBottom style={{ textIndent: "2em" }}>
                      Для удобства навигации, слева от тебя расположено
                      оглавление. С его помощью, ты можешь найти истории людей с
                      различных институтов. При переходе в любую из глав справа
                      появятся закладки, каждая из них соответствует одному
                      конкретному направлению в выбранном институте.
                    </Typography>
                    <Typography variant="h6" gutterBottom style={{ textIndent: "2em" }}>
                      Для каждой истории мы старательно выделили ключевые слова,
                      чтобы тебе было проще понять о чём рассказывается в
                      истории и стоит ли читать её полностью.
                    </Typography>
                    <Typography variant="h6" gutterBottom style={{ textIndent: "2em" }}>
                      Приступай к чтению, узнавай различную информацию об
                      интересующих направлениях, а также не забывай оценивать
                      истории, которые тебе больше всего понравились!
                    </Typography>
                  </>
                }
              />
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </div>
    </>
  );
}
