import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVk } from "@fortawesome/free-brands-svg-icons";
import { useTheme } from "@mui/material/styles";

import Toggle from "../Toggle";

export default function Footer() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      const userAgent = navigator.userAgent;
      setIsMobile(
        /Android/i.test(userAgent) ||
        /BlackBerry/i.test(userAgent) ||
        /iPhone|iPad|iPod/i.test(userAgent) ||
        /Opera Mini/i.test(userAgent) ||
        /IEMobile/i.test(userAgent)
      );
    };

    checkMobile();

    window.addEventListener('resize', checkMobile);
    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);
  const theme = useTheme();
  
  return (
    <Box
      component="footer"
      sx={{
        p: 5,
        width:"100%",
        marginTop: "auto",
        background: theme?.palette?.footer.main,
        color: theme?.palette?.text.secondary,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              О нас
            </Typography>
            <Typography variant="body2">
              Мы команда амбициозных студентов и выпускников ТюмГУ, объединенных
              общей целью - помочь абитуриентам в выборе направления обучения.
              Наш проект был разработан при поддержке Фонда содействия
              инновациям.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Связаться с нами
            </Typography>
            <Typography variant="body2">
              Email: zhmyak.technology@gmail.com
            </Typography>
            <Link href="https://www.vk.com/">
              <FontAwesomeIcon
                icon={faVk}
                style={{
                  fontSize: "33px",
                  paddingTop: "0.5em",
                  color: "white",
                }}
              />
            </Link>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6" gutterBottom>
              Быстрые ссылки
            </Typography>
            {isMobile ? ('') : (
            <Typography variant="body2">
              <Link href="/gamesection" color="inherit" underline="hover">
                Игровой раздел
              </Link>
            </Typography>
            )}
            {isMobile ? ('') : (
            <Typography variant="body2">
              <Link href="/studentbook" color="inherit" underline="hover">
                Книга студентов
              </Link>
            </Typography>
            )}
            <Typography variant="body2">
              <Link href="/aboutus" color="inherit" underline="hover">
                О нас
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="/feedback" color="inherit" underline="hover">
                Обратная связь
              </Link>
            </Typography>
            {/* <Toggle /> */}
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="body2" align="center">
            {"Все права защищены. © "}
            <Link color="inherit" href="https://zhmyak.com/">
              PUPA
            </Link>{" "}
            2023
            {"."}
          </Typography>
        </Box>
        {/* <Box sx={{ position: "absolute", bottom: 45, right: 30 }}>
          <Toggle />
        </Box> */}
      </Container>
    </Box>
  );
}
