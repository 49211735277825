import React, { useEffect, useState } from "react";
import AuthUser from "../../../Services/AuthUser";
import MiniProfile from "../../../Components/gameSection/MiniProfile";
import ImageAvatarMale from "../../../Assets/avatar_main_male.png";
import ImageAvatarFemale from "../../../Assets/avatar_main_female.png";
import ImageAvatarShadowMale from "../../../Assets/shadow_male.png";
function CheckAuthMiniProfile() {
  const { getToken } = AuthUser();
  const user = JSON.parse(localStorage.getItem("user"));
  const { http } = AuthUser();
  const [achievementsIssued, setAchievementsIssued] = useState([]);
  function getName() {
    if (user.nickname) return user.nickname;
    else return user.username;
  }
  function getGenderAvatar() {
    if (user.gender === 0) return ImageAvatarMale;
    else return ImageAvatarFemale;
  }

  useEffect(() => {
    if (getToken()) {
      getAchievementsIssued();
    }
  }, []);

  const getAchievementsIssued = async () => {
    const response = await http.get(`/achievements-player/${user.id}`);
    setAchievementsIssued(response.data);
  };

  if (!getToken()) {
    return (
      <MiniProfile
        imageAvatar={ImageAvatarShadowMale}
        name="unauthorized"
        achievement={0}
      />
    );
  } else {
    return (
      <MiniProfile
        imageAvatar={getGenderAvatar()}
        name={getName()}
        achievement={achievementsIssued.length}
      />
    );
  }
}

export default CheckAuthMiniProfile;
