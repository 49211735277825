import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_practice_01 = require("../../../../assets/imikn/backgrounds/practice 1/imikn_practice_01_students.jpg");
const imikn_practice_01_lecture_summary = require("../../../../assets/imikn/backgrounds/practice 1/imikn_practice_01_lecture_summary.jpg");
const imikn_lecture_blackboard_03 = require("../../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_blackboard_03.jpg");
const imikn_lecture_blackboard_04 = require("../../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_blackboard_04.jpg");
const imikn_lecture_blackboard_05 = require("../../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_blackboard_05.jpg");

// character
const dobr_luc = require("../../../../assets/imikn/characters/dobr_luc.png");
const dobr_luc_smile = require("../../../../assets/imikn/characters/dobr_luc_smile.png");
const dobr_luc_angry = require("../../../../assets/imikn/characters/dobr_luc_angry.png");
const leader_gleb = require("../../../../assets/imikn/characters/leader_gleb.png");
const sleepyhead = require("../../../../assets/imikn/characters/sleepyhead.png");
const sleepyhead_sad = require("../../../../assets/imikn/characters/sleepyhead_sad.png");
const sleepyhead_sleeping = require("../../../../assets/imikn/characters/sleepyhead_sleeping.png");
const sleepyhead_woken = require("../../../../assets/imikn/characters/sleepyhead_woken.png");

//color
const player_color = "#D4D4D4";
const dobr_luc_color = "#D7DAED";
const leader_gleb_color = "#D2CDB5";
const sleepyhead_color = "#D2B4B4";
const student_color = "#CCDFFF";
const another_student_color = "#D8FFF6";

let lesson_networking_task_not_completed = {
  Start: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: "",
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Добромир Люцианович, подойдите, пожалуйста!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Да, сейчас",
        },
        {
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Так, ну у Вас тут ошибка...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А где именно?",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: ["lecture_talk_neighbor", "lesson_networking_communicate_neighbor", "lesson_networking_listened_lecture"],
    },
  ],

  lesson_networking_listened_lecture: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну, это Вы сами должны понять...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Можете спросить то, что забыли или не поняли, я отвечу, но подсказывать не буду",
        },
      ],
    },
    {
      choices: [
        "Как настроить IPv-4, чтобы всё работало?|lesson_networking_task_not_completed_question_1",
        "Как проверить, что всё настроено правильно?|lesson_networking_task_not_completed_question_2",
        "Хмм... Сейчас подумаю, что же спросить...|lesson_networking_task_not_completed_other_questions",
      ],
    },
  ],

  lesson_networking_task_not_completed_other_questions: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Давайте, давайте",
        },
      ],
    },
    {
      choices: [
        "Повторите, пожалуйста, задание|lesson_networking_task_not_completed_question_3",
        "Да вроде бы всё помню, возможно ошибка от невнимательности. Я ещё раз всё проверю самостоятельно|lesson_networking_task_not_completed_question_4",
        "Я не понимаю в чём ошибка! Не хочу этим больше заниматься!|lesson_networking_task_not_completed_continuation_end",
      ],
    },
  ],

  lesson_networking_task_not_completed_question_1: [
    {
      body: [
        {
          background: imikn_lecture_blackboard_03,
          leftSprite: "",
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Смотрите, чтобы объединить ПК в одну сеть, необходимо, чтобы у них полностью совпадали первые три цифры в IPv-4",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "А вот последняя - это номер конкретного ПК, они должны быть различны",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Конечно, можно на последнее место поставить любую цифру, но обычно принято именно порядковый номер",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну и напомню, что все эти цифры должны быть в диапазоне от 0 до 255",
        },
        {
          leftSprite: getSpriteCharacterOfGender("default"),
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ааа, теперь понятно!",
        },
      ],
    },
    {
      // exits: true,
      game: "networking",
    },
  ],

  lesson_networking_task_not_completed_question_2: [
    {
      body: [
        {
          background: imikn_lecture_blackboard_04,
          leftSprite: "",
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Чтобы проверить работоспособность нужно ввести в консоль одного компьютера такую команду",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "В этой команде указывается IPv-4 другого компьютера",
        },
        {
          background: imikn_lecture_blackboard_05,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "В данном случае мы вводим команду в панель настройки первого компьютера, а IPv-4 указываем от второго компьютера",
        },
        {
          leftSprite: getSpriteCharacterOfGender("default"),
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ага, вроде теперь понятно",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Отлично, приступайте!",
        },
      ],
    },
    {
      // exits: true,
      game: "networking",
    },
  ],

  lesson_networking_task_not_completed_question_3: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Вам необходимо создать компьютерную сеть, из трёх ПК и одного коммутатора, а также настроить IPv-4 так, чтобы ПК “пинговались”",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Спасибо!",
        },
      ],
    },
    {
      // exits: true,
      game: "networking",
    },
  ],

  lesson_networking_task_not_completed_question_4: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Хорошо, приступайте!",
        },
      ],
    },
    {
      // exits: true,
      game: "networking",
    },
  ],

  lesson_networking_communicate_neighbor: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну, это Вы сами должны понять...",
        },
      ],
    },
    {
      choices: [
        "Повторите, пожалуйста, задание|lesson_networking_task_not_completed_question_3",
        "Да нет же тут ошибки! Всё тысячу раз перепроверено! Не хочу этим больше заниматься!|lesson_networking_task_not_completed_continuation_end",
        "Хорошо... Проверю всё ещё раз|lesson_networking_task_not_completed_check",
      ],
    },
  ],

  lesson_networking_task_not_completed_check: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Хорошо, приступайте!",
        },
        {
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Пс, у меня есть конспекты нашей отличницы, тебе дать их?",
        },
      ],
    },
    {
      choices: [
        "Да, давай|lesson_networking_task_not_completed_yes",
        "Нет, спасибо, самостоятельно разберусь|lesson_networking_task_not_completed_no",
      ],
    },
  ],

  lesson_networking_task_not_completed_yes: [
    {
      body: [
        {
          background: imikn_practice_01_lecture_summary,
          leftSprite: "",
          centerSprite: "",
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "",
          speakColor: "",
          text: "",
        },
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          rightSprite: leader_gleb,
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Большое спасибо!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Да ладно! Сам же тебя отвлёк от лекции...",
          achiev_child_id: 23, // 3.8
        },
      ],
    },
    {
      // exits: true,
      game: "networking",
    },
  ],

  lesson_networking_task_not_completed_no: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Ну, как хочешь... Если что, обращайся...",
        },
      ],
    },
    {
      // exits: true,
      game: "networking",
    },
  ],

  lesson_networking_task_not_completed_continuation_end: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Хм, чего же Вы так разнервничались!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Жаль, конечно, но не нужно так переживать, всё-таки задание не такое уж и простое для неподготовленного человека...",
          achiev_child_id: 22, // 3.7
        },
        {
          rightSprite: "",
          leftSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Как же лень все это делать... Может свалить? Сказать, что плохо себя чувствую... Как думаешь, прокатит?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Не знаю, я бы на твоём месте, все-таки хоть что-нибудь сделал",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Эта тема точно будет на контрольной, препод на прошлом занятии сам сказал",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Блинб... Не, всё-таки свалю... На улице такая погода классная, а мы тут сидим...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Добромир Люцианович! Можно уйти пораньше?",
        },
        {
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Что случилось?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Я себя плохо чувствую. Можно я домой пойду?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Пара скоро закончится, потерпи еще немного, тема важная как никак!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Эх...",
        },
        {
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Я же говорил...",
        },
        {
          leftSprite: sleepyhead_sleeping,
          rightSprite: dobr_luc_smile,
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "*громкий храп*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Баранов! Вы что же, опять уснули?",
        },
        {
          leftSprite: sleepyhead_woken,
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "А, что?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Чем же Вы ночью занимались, что спите весь день?",
        },
        {
          leftSprite: sleepyhead,
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "Так, лабу делал...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "И вместо того, чтобы сдавать, Вы решили поспать? Зачем же делали тогда?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "А, да, да, можно сдать?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Показывайте...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Так, тут понятно, тут странно сделано, но в принципе тоже подходит...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "А вот скажите, почему Вы здесь сделали именно так?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "Ну так придумал, да и в задании ничего про оптимизацию не было сказано...",
        },
        {
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Н-да, действительно... Самым простым способом, конечно...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "На работу Вас не взяли бы с такими знаниями...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "Да я знаю, как тут можно сделать лучше, мне просто лень было, ну и время поджимало, сами понимаете...",
        },
        {
          rightSprite: dobr_luc_angry,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Так у Вас неделя была на выполнение! Что значит время поджимало?!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Шесть дней в игры играли, а в последний вспомнили про задание? Типичные студенты!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Давайте, переделайте, чтобы всё красиво было!",
        },
        {
          leftSprite: sleepyhead_sad,
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "Хорошо-о-о *тяжёлый вздох*",
        },
        {
          leftSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Добромир Люцианович! Подойдите, пожалуйста!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Иду, иду",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Так... ага... стоп, не понял. Что это?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "А, ну это...",
        },
        {
          rightSprite: dobr_luc_angry,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "А это что? А здесь? Вы вообще это сами писали?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Д-да...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Понятно-понятно...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну вот подойдёте, когда сами выполните и сможете объяснить",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Но уже не на этой паре, видимо...",
        },
        {
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Итак, занятие окончено, можете быть свободными!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "До свидания!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Стойте, стойте, Вы же ключ брали, чтобы кабинет открыть?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Да",
        },
        {
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Значит это я Вам скажу до свидания, а Вы дождитесь пока все уйдут, закройте кабинет и сдайте его обратно!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Хорошо... Эхх, а хотел пораньше домой уйти...",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default lesson_networking_task_not_completed;
