import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_protection_class = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_class.jpg");
const imikn_protection_project1_01 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project1_01.jpg");
const imikn_protection_project1_02 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project1_02.jpg");
const imikn_protection_project1_03 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project1_03.jpg");
const imikn_protection_project1_04 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project1_04.jpg");
const imikn_protection_project1_05 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project1_05.jpg");
const imikn_protection_project1_06 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project1_06.jpg");
const imikn_protection_project1_07 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project1_07.jpg");
const imikn_protection_project1_08 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project1_08.jpg");
const imikn_protection_project1_09 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project1_09.jpg");
const imikn_protection_project1_10 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project1_10.jpg");
const imikn_protection_project2_01 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project2_01.jpg");
const imikn_protection_project2_02 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project2_02.jpg");
const imikn_protection_project2_03 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project2_03.jpg");
const imikn_protection_project2_04 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project2_04.jpg");
const imikn_protection_project2_05 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project2_05.jpg");
const imikn_protection_project2_06 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project2_06.jpg");
const imikn_protection_project2_07 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project2_07.jpg");
const imikn_protection_project2_08 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project2_08.jpg");
const imikn_protection_project2_09 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project2_09.jpg");
const imikn_protection_project2_10 = require("../../../assets/imikn/backgrounds/project protection/imikn_protection_project2_10.jpg");

// character
const anas_suleim = require("../../../assets/imikn/characters/anas_suleim.png");
const anas_suleim_angry = require("../../../assets/imikn/characters/anas_suleim_angry.png");
const anas_suleim_shout = require("../../../assets/imikn/characters/anas_suleim_shout.png");
const anas_suleim_smirk = require("../../../assets/imikn/characters/anas_suleim_smirk.png");
const dobr_roman = require("../../../assets/imikn/characters/dobr_roman.png");
const dobr_roman_smile = require("../../../assets/imikn/characters/dobr_roman_smile.png");
const emm_stal = require("../../../assets/imikn/characters/emm_stal.png");
const emm_stal_smile = require("../../../assets/imikn/characters/emm_stal_smile.png");
const emm_stal_smile_hand_behide = require("../../../assets/imikn/characters/emm_stal_smile_hand_behide.png");
const volan_yul = require("../../../assets/imikn/characters/volan_yul.png");
const volan_yul_serious = require("../../../assets/imikn/characters/volan_yul_serious.png");
const anna_npc = require("../../../assets/imikn/characters/anna_npc.png");
const anna_npc_right = require("../../../assets/imikn/characters/anna_npc_right.png");
const aleksandra_npc = require("../../../assets/imikn/characters/aleksandra_npc.png");
const olga_npc = require("../../../assets/imikn/characters/olga_npc.png");
const olga_npc_sad = require("../../../assets/imikn/characters/olga_npc_sad.png");
const olga_npc_confused = require("../../../assets/imikn/characters/olga_npc_confused.png");
const popov_npc = require("../../../assets/imikn/characters/popov_npc.png");
const popov_npc_mask = require("../../../assets/imikn/characters/popov_npc_mask.png");
const popov_npc_angry = require("../../../assets/imikn/characters/popov_npc_angry.png");
const popov_npc_indifference = require("../../../assets/imikn/characters/popov_npc_indifference.png");
const popov_npc_angry_right = require("../../../assets/imikn/characters/popov_npc_angry_right.png");
const popov_npc_indifference_right = require("../../../assets/imikn/characters/popov_npc_indifference_right.png");
const sleepyhead = require("../../../assets/imikn/characters/sleepyhead.png");
const sleepyhead_sad = require("../../../assets/imikn/characters/sleepyhead_sad.png");

//color
const player_color = "#D4D4D4";
const anas_suleim_color = "#E1D5EF";
const dobr_roman_color = "#BAA9A0";
const emm_stal_color = "#FEDCDD";
const volan_yul_color = "#8C8C8C";
const anna_npc_color = "#E0D0B8";
const aleksandra_npc_color = "#C8E0C1";
const olga_npc_color = "#FFC088";
const popov_npc_color = "#AEB0B0";
const sleepyhead_color = "#D2B4B4";
const student_color = "#CCDFFF";
const another_student_color = "#D8FFF6";
const student_girl_color = "#CFCAFF";
const guide_color = "#9DC7CC";

let lesson_project_protection = {
  Start: [
    {
      body: [
        {
          background: imikn_protection_class,
          leftSprite: "",
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Добрый день, дорогие студенты!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Сегодня у нас пройдёт финальная встреча, где вы представите свои работы по итогам семестра",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "В рамках прошлых трёх встреч уже выступило большинство команд, сегодня же послушаем оставшиеся три проекта",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Напоминаю регламент: выступление - пять минут, вопросы от преподавателей и слушателей - семь минут максимум",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Скидывайте свои презентации на этот компьютер, начнём через три минуты!",
        },
        {
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*громким шёпотом* Серёга!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*громким шёпотом* Серё-ё-ё-га-а!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Серёга",
          speakColor: another_student_color,
          text: "*громким шёпотом* Что?!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*громким шёпотом* У тебя есть флешка?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Серёга",
          speakColor: another_student_color,
          text: "*громким шёпотом* Да, сейчас...",
        },
        {
          leftSprite: getSpriteCharacterOfGender("default"),
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "О! Вы пришли!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Замечательно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Как у Вас дела, всё хорошо?",
        },
      ],
    },
    {
      choices: [
        "Да, мне тут нравится|lesson_project_protection_yes",
        "Нет, все эти занятия так утомительны...|lesson_project_protection_no",
      ],
    },
  ],

  lesson_project_protection_yes: [
    {
      body: [
        {
          background: imikn_protection_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Чудесно, чудесно!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_project_protection_why",
    },
  ],

  lesson_project_protection_no: [
    {
      body: [
        {
          background: imikn_protection_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Конечно, это всё для Вас пока непривычно и действительно может сильно утомить...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Но сейчас, я думаю, Вы можете отдохнуть и просто послушать наших студентов!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_project_protection_why",
    },
  ],

  lesson_project_protection_why: [
    {
      body: [
        {
          background: imikn_protection_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Кстати, Вы уже знаете как будет проходить эта пара и для чего она нужна?",
        },
      ],
    },
    {
      choices: [
        "Да|lesson_project_protection_yes_2",
        "Нет|lesson_project_protection_no_2",
      ],
    },
  ],

  lesson_project_protection_yes_2: [
    {
      body: [
        {
          background: imikn_protection_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Чудненько!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Надеюсь, Вы не заскучаете и может даже зададите несколько вопросов!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_project_protection_project_1",
    },
  ],

  lesson_project_protection_no_2: [
    {
      body: [
        {
          background: imikn_protection_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Сейчас исправим!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Смотрите, наши студенты весь семестр работали над проектами в группах по два-три человека",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Эти проекты абсолютно разные: у каждого своя тема, цели и задачи, проблема, которую они решают всевозможными способами",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Также программная реализация и результаты",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "А сегодня, все команды должны выступить с коротким докладом о проделанной работе, рассказать о самом важном и интересном",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "А главное, показать свою квалификацию!",
        },
      ],
    },
    {
      choices: [
        "А что такое квалификация?|lesson_project_protection_qualification",
        "Хм, интересно...|lesson_project_protection_why_continue",
      ],
    },
  ],

  lesson_project_protection_qualification: [
    {
      body: [
        {
          background: imikn_protection_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Это, в каком-то роде, уровень подготовки студентов",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Причём не только какие-то теоретические и практические профессиональные навыки, так называемые “hard skills”",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Но и не менее важные - “soft skills”",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "То есть разнообразные личные качества и умения, которые нужны практически во всех сферах жизни",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Какие например?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Хм, например, умение представлять свою работу, то есть уверенно и грамотно рассказать, показать, объяснить и ответить на вопросы слушателей",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "В каком-то смысле это ещё и проверка на знание и понимание студентом своей работы...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Сами понимаете, нередко бывают случаи списывания даже в таких крупных заданиях...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "И, продолжая тему, к “soft skills” также относится умение работать в команде, делегировать задачи",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "И находить общий язык с разными людьми, как с сокомандниками, так и с преподавателями...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Это всё очень важно, чтобы все участники команды работали на равных, учились друг у друга и получали опыт",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ого, теперь понятно",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_project_protection_why_continue",
    },
  ],

  lesson_project_protection_why_continue: [
    {
      body: [
        {
          background: imikn_protection_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Надеюсь, Вы не заскучаете и может даже зададите несколько вопросов!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_project_protection_project_1",
    },
  ],

  lesson_project_protection_project_1: [
    {
      body: [
        {
          background: imikn_protection_class,
          leftSprite: "",
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Ну что ж, все скинули свои презентации?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студенты",
          speakColor: student_color,
          text: "Да",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Тогда давайте начинать...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Первыми пойдут... так... Волкова Анна и Ковалёва Александра... прошу...",
        },
        {
          background: imikn_protection_project1_01,
          leftSprite: anna_npc,
          rightSprite: "",
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Добрый день!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Сегодня мы хотим Вам представить наш проект - “Разработка веб-приложения для подбора элективных дисциплин для студентов ТюмГУ”",
        },
        {
          background: imikn_protection_project1_02,
          leftSprite: aleksandra_npc,
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "*тихо* В связи с внедрением в Российские ВУЗы новой системы образования",
        },
        {
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "Включающей в себя возможность индивидуально выбирать элективные дисциплины...",
        },
        {
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Александра! Давайте погромче!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Если на первой парте не слышно, то о задних рядах и говорить не стоит...",
        },
        {
          rightSprite: "",
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "*чуть громче* Х-хорошо...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "Студенты столкнулись с проблемой нех-х-хватки информации о данных предметах",
        },
        {
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "Из-за чего осуществление выбора стало затруднительным для них",
        },
        {
          background: imikn_protection_project1_03,
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "Поэтому... В-возникла потребность в сервисе, в котором будет содержаться вся в-важная информация о таких-х дисциплинах",
        },
        {
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "А также вспомогательный набор функций",
        },
        {
          leftSprite: anna_npc,
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Наша цель заключалась в...",
        },
        {
          background: imikn_protection_class,
          leftSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Пс, пс-с-с",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Что?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* А у вас в презентации есть цели и задачи?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Ну да, проблема, актуальность, идея решения, цель и задачи...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Блин!!!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Что такое?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Да мы не успели презу доделать... Я думал уже всё готово, а потом вспомнил! Целей и задач-то нет!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Эх ты!",
        },
        {
          background: imikn_protection_project1_04,
          leftSprite: aleksandra_npc,
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "*тихо* На первом этапе нам было необх-х-ходимо собрать основную информацию об элективах, такую как",
        },
        {
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "Описание дисциплины, преподаватели, которые её в-ведут, необх-х-ходимые навыки для освоения, при наличии таковых и так далее...",
        },
        {
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Александра, мы не кусаемся... Давайте, всё-таки, погромче...",
        },
        {
          rightSprite: "",
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "*чуть громче* Для этого м-м-мы спарсили Модеус, получив доступ у нашего научного руководителя...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "Полученные данные были предобработаны, записаны в базу данных и использованы в веб-приложении",
        },
        {
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "Всего на данный момент имеется информация о 570 элективных д-д-дисциплин",
        },
        {
          background: imikn_protection_project1_05,
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "На втором этапе мы организовали систему отзывов и оценок",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Чтобы каждый желающий после прохождения предмета мог поделиться своим мнением о нём",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Все отзывы проходят модерацию: сначала автоматическую, для выявления ненормативной лексики и запрещённых высказываний",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "А затем проверку администратором",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "На текущий день уже был запущен прототип и собрано около тысячи отзывов студентов нашего университета",
        },
        {
          background: imikn_protection_class,
          rightSprite: "",
          leftSprite: "",
          speakSprite: "",
          nameSpeak: "",
          speakColor: "",
          text: "*громко открывается дверь*",
        },
        {
          rightSprite: emm_stal_smile,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Извините, задержался!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Продолжайте, продолжайте...",
        },
        {
          background: imikn_protection_project1_06,
          leftSprite: anna_npc,
          rightSprite: "",
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Одной из функций нашего приложения является автоматический подбор элективов по интересам пользователя",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Для определения интересов было разработано два варианта - автоматическое и с помощью анкеты",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "А также рекомендательная система на основе пройденных и оценённых дисциплин",
        },
        {
          background: imikn_protection_project1_07,
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Автоматическое определение интересов пользователя доступно для тех",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Кто даст согласие на использование своих персональных данных из социальной сети “Вконтакте”",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "С помощью VK API мы получаем список сообществ, на которые подписан пользователь",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "А далее составляем перечень интересов благодаря тегам этих сообществ",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "У каждого сообщества или группы в описании представлены теги, то есть те или иные темы, по которым выкладывается контент",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Именно их мы и считаем “интересами” студентов в нашей работе",
        },
        {
          background: imikn_protection_project1_08,
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Заполнение анкеты, в свою очередь, мы считаем полуавтоматическим определением интересов",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Она составлялась совместно со студентами нашего курса и научным руководителем",
        },
        {
          background: imikn_protection_project1_09,
          leftSprite: aleksandra_npc,
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "*тихо* Также был реализован поиск по названиям и описаниям элективов, фильтрация по областям знаний, сложности, преподавателям и рейтингу",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "*вздыхает*",
        },
        {
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "*чуть громче* Веб-приложение разраб-б-батывалось с и-и-и-использованием следующих тех-х-хнологий...",
        },
        {
          background: imikn_protection_class,
          leftSprite: "",
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Слу-у-ушай, а они почти то же самое сделали, что и мы...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "*шёпотом* Ха-а-а, после выступления будет шоу...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Ой-ой! Наверное их также завалят вопросами...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "*шёпотом* Не наверное, а точно!",
        },
        {
          background: imikn_protection_project1_10,
          leftSprite: anna_npc,
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Таким образом, мы разработали веб-приложение для подбора элективных дисциплин",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "В котором собрано большое количество информации, отзывы студентов, а также удобный функционал поиска и фильтрации",
        },
        {
          leftSprite: aleksandra_npc,
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "*тихо* На этом наш доклад о-о-окончен, готовы о-о-ответить на Ваши вопросы...",
        },
        {
          background: imikn_protection_project1_01,
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Ну что ж, спасибо за доклад! У меня сразу такой вопрос, откройте слайд 2",
        },
        {
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "*тихо* Д-да, сейчас...",
        },
        {
          background: imikn_protection_project1_02,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "С чего Вы взяли, что есть потребность в таком приложении?",
        },
        {
          leftSprite: anna_npc,
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Мы провели опрос среди студентов первых курсов нашего института и по его итогам выяснилось, что такое решение необходимо",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "А где посмотреть на эти результаты? Почему не сказали об этом в своём докладе?",
        },
        {
          leftSprite: anna_npc,
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Так как время на выступление ограничено, мы посчитали приоритетней рассказать о том, что непосредственно сделали",
        },
        {
          leftSprite: anna_npc,
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "А с результатами опроса Вы можете ознакомиться в отчёте на странице 15",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Так... ага... вижу. Хорошо...",
        },
        {
          rightSprite: emm_stal,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "А чего хорошего?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Ну ответили вам там, сколько? Ну 50 первокурсников, что хотели бы такое...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Так они и каникулы тоже хотят",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "И что? Сделаете приложение для легального пропуска занятий?!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Мы опросили столько человек, сколько успели и эт...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "В нашем ВУЗе не 50 человек на первом курсе! Может вам попались все, кому такое нужно, а остальным 97% окажется, что не нужно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Кто научный руководитель?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Кто одобрил такую тему?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Анастасия Сулеймановна",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Хм...",
        },
        {
          rightSprite: emm_stal_smile_hand_behide,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Ладно... Всё-таки я на середине доклада зашёл, может не понял до конца...",
        },
        {
          rightSprite: anas_suleim_smirk,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Да, Эммануил Сталинович, не нервничайте так сразу, поберегите Ваши нервы, хи-хи-хи",
        },
        {
          speakSprite: "",
          nameSpeak: "",
          speakColor: "",
          text: "*неловкое молчание*",
        },
        {
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Так, ну может быть ещё вопросы?",
        },
        {
          background: imikn_protection_project1_01,
          rightSprite: volan_yul,
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "А сколько этих “интересов”, как Вы говорите, используется в проекте?",
        },
        {
          leftSprite: aleksandra_npc,
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "*тихо* Мы используем 15, но",
        },
        {
          rightSprite: anas_suleim_smirk,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Сашенька, Вас снова не слышно...",
        },
        {
          rightSprite: volan_yul_serious,
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "*чуть громче* во-во-во “Вконтакте” их представлено бо-бо-больше, просто мы о-о-отобрали самые подходящие",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Что значит “самые подходящие”?!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "*чуть громче* Ну... те, которые по-по-подходят к элективам... там, например, есть категория “а-а-автомобили”",
        },
        {
          speakSprite: "left",
          nameSpeak: "Александра",
          speakColor: aleksandra_npc_color,
          text: "А среди элективов нет подх-х-ходящих к этому... “интересу”",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "А как Вы определяете какие интересы к каким элективам относятся?",
        },
        {
          leftSprite: anna_npc,
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Это определяли эксперты... То есть наш научный руководитель и ещё несколько преподавателей...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Анастасия Сулеймановна, Вы что, действительно этим занимались?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Их же там, сколько... Пятьсот с лишним, насколько я помню!",
        },
        {
          rightSprite: anas_suleim_smirk,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А что делать, долг службы, хе-хе",
        },
        {
          rightSprite: volan_yul_serious,
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Очень странно, конечно...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Лучше бы время потратили на то, чтобы автоматизировать этот процесс... Какое поколение... непродуманное!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Хорошо, спасибо за совет...",
        },
        {
          rightSprite: dobr_roman_smile,
          leftSprite: "",
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Есть ещё вопросы?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Преподаватели",
          speakColor: student_color,
          text: "*молчание*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Может быть у студентов есть вопросы?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студенты",
          speakColor: student_color,
          text: "*молчание*",
        },
      ],
    },
    {
      choices: [
        "У меня есть вопрос!|lesson_project_protection_project_1_questions",
        "Промолчать|lesson_project_protection_project_1_whist",
      ],
    },
  ],

  lesson_project_protection_project_1_whist: [
    {
      body: [
        {
          background: imikn_protection_class,
          leftSprite: "",
          centerSprite: "",
          rightSprite: anas_suleim_angry,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Напоминаю, что свою квалификацию нужно подтвердить не только выступлением, но и вопросами к своим коллегам!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А Вы молчите!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вот Михаил! Чего Вы молчите?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Задайте девочкам вопрос!",
        },
        {
          leftSprite: popov_npc_mask,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "*вздыхает*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Что, у Вас нет вопросов? Вам всё понятно? Или Вы сидели в ноутбуке и не слушали?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "*молчит*",
        },
        {
          rightSprite: anas_suleim_shout,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вам бы было приятно, если бы Вас тоже никто не слушал?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Да слушал я, просто мне всё понятно, что спрашивать-то?!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А Вы не огрызайтесь! Не может быть такого, что Вы слушали и всё поняли!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Тут два варианта: либо вы АБСОЛЮТНО НИЧЕГО не поняли, либо не слушали!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "*шёпотом* Она чё, за тупого меня держит?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ваши шушуканья прекрасно слышно!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "*вздыхает*",
        },
        {
          rightSprite: anas_suleim_angry,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Чтобы в следующий раз такого не было!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Иначе даже до выступления Вас не допустим!",
        },
        {
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "*громко и неловко* Гхм, вроде бы вопросов больше не-ет...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_project_protection_project_2",
    },
  ],

  lesson_project_protection_project_1_questions: [
    {
      body: [
        {
          background: imikn_protection_class,
          leftSprite: "",
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Чудесно! Прошу...",
        },
      ],
    },
    {
      choices: [
        "Что такое VK API?|lesson_project_protection_project_1_question_1",
        "Что такое “спарсить Модеус”?|lesson_project_protection_project_1_question_2",
        "Как происходит автоматическая модерация отзывов?|lesson_project_protection_project_1_question_3",
      ],
    },
  ],

  lesson_project_protection_project_1_question_1: [
    {
      body: [
        {
          background: imikn_protection_project1_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anna_npc_right,
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Это интерфейс, который позволяет получать информацию из базы данных Вконтакте с помощью http-запросов к специальному серверу",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "API упрощает создание кода, поскольку предоставляет набор готовых классов, функций или структур для работы с имеющимися данными",
        },
      ],
    },
    {
      choices: [
        "Извините, я абитуриент, можно простыми словами, а то ничего непонятно...|lesson_project_protection_project_1_question_1_2",
        "Хорошо, спасибо за ответ!|lesson_project_protection_project_2",
      ],
    },
  ],

  lesson_project_protection_project_1_question_1_2: [
    {
      body: [
        {
          background: imikn_protection_project1_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anna_npc_right,
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Попроще... хм...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Ну, это такой инструмент, который позволяет программно получить различные данные из Вконтакте",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Не только те, которые любой пользователь может посмотреть самостоятельно на сайте или в приложении, но и скрытые",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Например, те же теги сообществ... На сайте, к примеру, по ним можно только фильтровать группы...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Также, этот инструмент, можно сказать, облегчает жизнь программистам",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Так как с ним легко работать и не нужно самому что-то выдумывать, все функции уже реализованы, их нужно только использовать...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А-а-а, спасибо за ответ!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_project_protection_project_2",
    },
  ],

  lesson_project_protection_project_1_question_2: [
    {
      body: [
        {
          background: imikn_protection_project1_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anna_npc_right,
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Это значит программно получить информацию о чём-либо из базы данных сайта",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "В данном случае Модеуса - нашего корпоративного сервиса",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "На нём представлена информация об индивидуальном расписании, промежуточных и итоговых оценках",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Описания учебных дисциплин и там же осуществляется выбор элективов",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Понятно... Спасибо за ответ!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_project_protection_project_2",
    },
  ],

  lesson_project_protection_project_1_question_3: [
    {
      body: [
        {
          background: imikn_protection_project1_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anna_npc_right,
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Ну смотрите, текст отзыва поступает в базу данных, предобрабатывается...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "А затем специальными алгоритмами производится поиск запрещённых слов с помощью специального словаря",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Если хоть одно такое слово будет найдено, то отзыв модерацию не проходит и удаляется из базы данных",
        },
      ],
    },
    {
      choices: [
        "А зачем тогда потом вручную проверять их?|lesson_project_protection_project_1_question_3_2",
        "А-а-а, понятно, спасибо!|lesson_project_protection_project_1_question_3_continue",
      ],
    },
  ],

  lesson_project_protection_project_1_question_3_2: [
    {
      body: [
        {
          background: imikn_protection_project1_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anna_npc_right,
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Мы, наверное, неправильно выразились...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Такие отзывы не проходят повторной проверки",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Администратор проверяет отзывы, которые прошли автоматическую модерацию",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Чтобы исключить случаи, когда плохие слова как бы “зашифровывают” различными знаками и цифрами...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Действительно... Теперь понятно!",
        },
        {
          leftSprite: "",
          rightSprite: anas_suleim_smirk,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вот, видите девочки, какой внимательный абитуриент!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Обязательно потом поправьте этот момент, чтобы в отчёте всё было правильно изложено!",
        },
        {
          speakSprite: "center",
          nameSpeak: "Анна и Александра",
          speakColor: student_girl_color,
          text: "Хорошо...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_project_protection_project_1_question_3_continue",
    },
  ],

  lesson_project_protection_project_1_question_3_continue: [
    {
      body: [
        {
          background: imikn_protection_project1_01,
          leftSprite: anna_npc,
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "А вы сами составляли словарь этих, как Вы там выразились... “запрещённых слов”?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Нет, нашли готовый датасет...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Проверили его хоть?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Ну да",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "И что, там только плохие слова?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "*нетерпеливо вздыхает* Да!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Хм, ну всё же, мне кажется, что могут случаться программные ошибки, недоразумения...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Вам, наверное, лучше отправлять такие отзывы на модерацию человеком с пометочкой, мол вроде как тут было плохое словцо, хе-хе",
        },
        {
          speakSprite: "left",
          nameSpeak: "Анна",
          speakColor: anna_npc_color,
          text: "Но это же лишняя работа!",
        },
        {
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Анна, всё, хватит, это был совет и на него не нужно отвечать... Спасибо Добрыня Романович, мы с девочками всё поправим!",
        },
        {
          background: imikn_protection_class,
          leftSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Есть ещё вопросы?",
        },
        {
          speakSprite: "center",
          nameSpeak: "",
          speakColor: "",
          text: "*молчание*",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_project_protection_project_2",
    },
  ],

  lesson_project_protection_project_2: [
    {
      body: [
        {
          background: imikn_protection_class,
          leftSprite: "",
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Ну что ж, спасибо девочки!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Надеюсь, вы себе зафиксировали вопросы, замечания, советы и отразите исправление в итоговом отчёте по проекту, присаживайтесь...",
        },
        {
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* В смысле?! И это всё?!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "*шёпотом* Офигеть, нас полчаса мутузили, а их - раз! И всё?",
        },
        {
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Вот так вот, проекты почти одинаковые, но как важно кто твой научный руководитель...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "*шёпотом* М-м-да, нечестно, конечно, это всё...",
          achiev_child_id: 33, // 3.19
        },
        {
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Так... дальше Капустина Ольга и Попов Михаил ... пожалуйста...",
        },
        {
          background: imikn_protection_project2_01,
          rightSprite: "",
          leftSprite: popov_npc_mask,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "*неразборчиво* Всем добрый день! Сегодня...",
        },
        {
          rightSprite: anas_suleim_angry,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Михаил! Вас же почти не слышно... Снимите маску!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "*вздыхает*",
        },
        {
          leftSprite: popov_npc,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "А так, хорошо слышно?",
        },
        {
          rightSprite: anas_suleim_smirk,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Во-о-от, другое дело! Продолжайте...",
        },
        {
          rightSprite: "",
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Всем добрый день, сегодня мы хотим представить наш проект - “Конфигуратор ПК”",
        },
        {
          background: imikn_protection_project2_02,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Проблема, на решение которой нацелена данная работа, заключается в том, э-э-э",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Что представители целевой аудитории... ну как бы не в состоянии самостоятельно составить конфигурацию ПК...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Ну потому что не имеют нормальных... э-эм... достаточных... знаний о требованиях к комплектующим",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Ну и  этот процесс может быть достаточно... ну сложным и времязатратным, во-от...",
        },
        {
          background: imikn_protection_project2_03,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Поэтому идеей решения может стать “Конфигуратор ПК”!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Он облегчит процесс сборки ПК, подбирая совместимые комплектующие как бы за пользователя исходя из его потребностей и предпочтений...",
        },
        {
          background: imikn_protection_class,
          leftSprite: "",
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Прикинь, вчера, сижу делаю презентацию и комп вдруг вырубается!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Я в холодном поту судорожно пытаюсь его “реанимировать”, ведь ничего не успел сохранить...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Посмотреть может хоть что-то уцелело... Но нет, все тщетно...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Жесть, а что случилось-то с компом в итоге?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Оказывается, просто разрядился, а зарядка сломалась. Пришлось новую купить",
        },
        {
          speakSprite: "right",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Пха-ха-ха, Костян, ну ты и лошара! Вечно же с тобой нелепые ситуации случаются...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Костян",
          speakColor: student_color,
          text: "*шёпотом* Это да... снова презу так и не доделал...",
        },
        {
          background: imikn_protection_project2_04,
          leftSprite: popov_npc,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Наше приложение предлагает пользователю три различные режима сборки: простой, расширенный и ручной",
        },
        {
          background: imikn_protection_project2_05,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "В простом режиме пользователю нужно указать только общий бюджет на комп и его тип: домашний, игровой или офисный",
        },
        {
          background: imikn_protection_project2_06,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "В расширенном режиме - кроме общего бюджета, можно задать ну там... ограничения цен для каждого отдельного компонента и-и-и...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Ну другие вские условия... ну например, необходимый объём оперативки и прочее",
        },
        {
          background: imikn_protection_project2_07,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Ручной режим будет полезен умным пользователям, которые знают какие характеристики комплектующих им нужны",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Но не знают как там что... ну там подходит ли друг к другу... вот...",
        },
        {
          background: imikn_protection_project2_08,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Подбор конфигурации производился с помощью метода ветвей и границ, подробности можете посмотреть в отчёте на страницах 16-18",
        },
        {
          background: imikn_protection_project2_09,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Данные мы брали из интернет-магазина цифровой техники",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Веб-приложение разрабатывалось с помощью таких вот технологий...",
        },
        {
          background: imikn_protection_class,
          leftSprite: "",
          speakSprite: "left",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "*шёпотом* Господи, они что, делают сайт для решения той лабораторки?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Другая студентка",
          speakColor: another_student_color,
          text: "*шёпотом* Какой?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "*шёпотом* Ну помнишь, в которой надо было подобрать комплектующие, чтобы всё работало и посчитать итоговую цену ПК...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Другая студентка",
          speakColor: another_student_color,
          text: "*шёпотом* А-а-а... То-о-очно... А нафига это сейчас?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "*шёпотом* Понятия не имею...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Другая студентка",
          speakColor: another_student_color,
          text: "*шёпотом* Мда... Сделал бы тогда бота, который за нас диплом напишет...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "*шёпотом* Пха-ха-ха, реа-а-ально!",
        },
        {
          rightSprite: anas_suleim_angry,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "*громким шёпотом* Девочки! Давайте потише! Слушайте своих однокурсников!",
        },
        {
          background: imikn_protection_project2_10,
          rightSprite: "",
          leftSprite: popov_npc,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Ну и в ходе выполненных действий и реализации поставленных задач нам удалось создать веб-приложение",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Способное подбирать конфигурацию ПК на основе предпочтений пользователя... во-от...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Доклад окончен, готовы ответить на Ваши вопросы...",
        },
        {
          background: imikn_protection_project2_01,
          rightSprite: anas_suleim_smirk,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Михаил, а Вы можете без этих ваших “ну-у-у”, “как бы” и “во-от”?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Учитесь говорить красиво!",
        },
        {
          leftSprite: popov_npc_indifference,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "*вздыхает*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А Ольга вообще в Вашей команде? Или Вы один всё делали?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Э-эм, почему Вы так решили?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну, только Вы и рассказывали...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "*шёпотом обращается к Воланду Юлиановичу* Если это можно назвать рассказом ха-ха-ха-ха",
        },
        {
          speakSprite: "center",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "*тихо* ха-ха-ха, какое... поколение... неграмотное!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Ну-у это же не показатель... Мы решили, что я буду рассказывать, а Оля... ну как бы... на вопросы отвечать...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Сейчас посмотрим, как Ольга ответит...",
        },
        {
          background: imikn_protection_class,
          leftSprite: "",
          rightSprite: "",
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* О-о-о, начинается представление...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Да кошмар!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* В прошлый раз нас поругали, что мы втроём по очереди рассказывали, мол лучше пусть один говорит",
        },
        {
          speakSprite: "right",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* А теперь наоборот...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Правила меняются слишком быстро... Ка-а-арл...",
        },
        {
          background: imikn_protection_project2_02,
          rightSprite: anas_suleim,
          leftSprite: olga_npc,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Так, во-первых...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "У Вас в проблеме указано, что пользователи “не в состоянии” самостоятельно составить конфигурацию ПК, что это значит?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Ольга",
          speakColor: olga_npc_color,
          text: "В смысле, что значит?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "В прямом...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Ольга",
          speakColor: olga_npc_color,
          text: "Ну, они не знают как подобрать все детальки так, чтобы ПК работал...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "В таком случае, чего им не хватает? Мозгов?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Ольга",
          speakColor: olga_npc_color,
          text: "хи-хи-хи, знаний наверное",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ладно, допустим, но что это за формулировка “не в состоянии”... Надо исправить!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Во-вторых, где статистика? Хоть какая-нибудь?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Ольга",
          speakColor: olga_npc_color,
          text: "*молчит*",
        },
        {
          rightSprite: anas_suleim_angry,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Значит проблема не подтверждена! Значит её нет!",
        },
        {
          rightSprite: anas_suleim_shout,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А если нет проблемы, то зачем дальше рассматривать проект...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Никто не будет пользоваться Вашим решением без нужды...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "В самом же магазине цифровой техники есть возможность прийти и попросить помощи у консультантов",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Это быстрее и проще, чем разбираться с функционалом Вашего приложения...",
        },
        {
          leftSprite: popov_npc,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Но, почему это быст...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Михаил! Вы чётко сказали, что Ольга будет отвечать на вопросы, поэтому сохраняйте молчание, пожалуйста...",
        },
        {
          leftSprite: olga_npc_confused,
          speakSprite: "left",
          nameSpeak: "Ольга",
          speakColor: olga_npc_color,
          text: "Почему быстрее? А время на дорогу до магазина и...",
        },
        {
          rightSprite: anas_suleim_angry,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Подождите! Это был даже не вопрос... Возьмите на заметку и докажите, что Ваша проблема существует и её нужно решать...",
        },
        {
          speakSprite: "center",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Ну это, вообще-то, сразу нужно было делать...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну конечно, Добрыня Романович! Не понимаю, чем студенты слушают требования к проектам...",
        },
        {
          rightSprite: emm_stal,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "А что это за типы компьютеров? Домашний, игровой и офисный... Чем они различаются?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "У Вас ни в докладе, ни в отчёте не сказано...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Ольга",
          speakColor: olga_npc_color,
          text: "*молчит*",
        },
        {
          rightSprite: dobr_roman,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Ольга, чего Вы молчите?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Ольга",
          speakColor: olga_npc_color,
          text: "Думаю...",
        },
        {
          rightSprite: emm_stal,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Чего тут думать? Если Вы работали вместе с Михаилом над проектом, то должны точно знать...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Ольга",
          speakColor: olga_npc_color,
          text: "*молчит*",
        },
        {
          rightSprite: anas_suleim,
          leftSprite: olga_npc_sad,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "О-ольга, а-а-а-у...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Ольга",
          speakColor: olga_npc_color,
          text: "*грустно молчит*",
        },
        {
          rightSprite: volan_yul_serious,
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Поня-я-ятно! Халявщица!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Ольга",
          speakColor: olga_npc_color,
          text: "Ну... ну... *всхлипывает* я только этот момент не знаю...",
        },
        {
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Хорошо, Михаил, помогите Ольге узнать про отличия...",
        },
        {
          leftSprite: popov_npc,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "*вздыхает*",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Игровые ПК должны быть с большим объёмом оперативной памяти, с мощной видеокартой и процессором",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Домашний чуть менее мощный, но чтобы тянул простенькие игры, а офисный чисто для работы с документами...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Оля, запоминайте!",
        },
        {
          leftSprite: olga_npc_sad,
          speakSprite: "left",
          nameSpeak: "Ольга",
          speakColor: olga_npc_color,
          text: "Х-хорошо...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Но всё равно! Это всё слова! Где цифры? Описание? Чёткие требования к категориям?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Я вот вообще не понимаю чем домашний от офисного отличается...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А если мой домашний ПК должен быть игровым? Я вот дома в игры играю, например... Где это всё?",
        },
        {
          background: imikn_protection_class,
          rightSprite: "",
          leftSprite: "",
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Пха-ха-ха-ха-ха! Она-а-а! В и-и-игры-ы-ы! Пха-ха-ха-ха-ха!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Вот умора! Ха-ха-ха-ха-ха!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* В до-оту, сталкер, ха-ха-ха, или в майнкра-а-афт?! Ха-ха-ха-ха...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Пха-ха-ха-ха, скамит мамонтов!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Оба студента",
          speakColor: student_color,
          text: "*громко* А-ХА-ХА-ХА-ХА-ХА",
        },
        {
          background: imikn_protection_project2_01,
          leftSprite: popov_npc_indifference,
          rightSprite: anas_suleim_angry,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Так! Тишина!",
        },
        {
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну так что, Михаил?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Да, ладно, добавим...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Сразу надо было! У Вас времени в обрез на всё про всё!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ей-богу, как дети малые...",
        },
        {
          rightSprite: volan_yul_serious,
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "А где, собсна, математика?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Вы же в “институте МАТЕМАТИКИ”, в первую очередь, а во вторую - “компьютерных наук”...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Второе вижу, а где первое?!",
        },
        {
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Да о какой математике вообще может идти речь, если у них проект по сложности, как рядовая лабораторная работа?!",
        },
        {
          rightSprite: anas_suleim_angry,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "И ведь не первый раз вы это слышите, Михаил! Ольга!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вам же уже говорили, что проект ни о чём! Нужно дорабатывать, усложнять, улучшать... Или вообще другую тему взять...",
        },
        {
          speakSprite: "",
          nameSpeak: "",
          speakColor: "",
          text: "*молчание*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Чего молчите?",
        },
        {
          leftSprite: popov_npc_angry,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Я не хочу работать над тем, что мне не нравится!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "И я не думаю, что проект простой! Реализация была сложной для меня... ой... ДЛЯ НАС!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Михаил, мы тут не о хотелках, а о требованиях говорим!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "И вообще, что за тон?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "За пределами ВУЗа делайте всё, что Вашей душе угодно, а здесь есть чёткие требования!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Где они прописаны? Почему никто об этом никогда не говорит, а потом в конце сообщают, что моя работа “слишком лёгкая”?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Вы их на ходу придумываете???",
        },
        {
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Всё прописано: в положениях, в описании дисциплины, все документы на сайте института, нужно искать, смотреть...",
        },
        {
          rightSprite: volan_yul_serious,
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Вы что, как там говорится... Хм... Вспомнил! Вы что, ГУГЛИТЬ не умеете?! Вы не из “пипстеров”, которые знают “гугле” от А до Я?!",
        },
        {
          background: imikn_protection_class,
          leftSprite: "",
          rightSprite: "",
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Пип... что?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Пха-ха-ха, пипстеры и гугле!!! Ха-ха-ха!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Это типа хипстеры и гугл? А-ха-ха-ха",
        },
        {
          background: imikn_protection_project2_01,
          rightSprite: anas_suleim_smirk,
          leftSprite: popov_npc_angry,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "*злостно молчит*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вот и всё, говорить тут не о чем...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Одни отговорки и агрессия...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Мы же вам ПОМОЧЬ ХОТИМ!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "*шёпотом* Мд-а, помогли...",
        },
        {
          rightSprite: emm_stal,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Тут всё очевидно, работа выполнена на слабую тройку, а Ольга вообще ничего не делала, видимо...",
        },
        {
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Именно... ",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Не знаю, как хорошо Вы доделаете отчёт... Но с трудом верится, что он сможет повлиять на оценку в лучшую сторону...",
        },
        {
          speakSprite: "",
          nameSpeak: "",
          speakColor: "",
          text: "*неловкое молчание*",
        },
        {
          rightSprite: dobr_roman,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Ну-у-у... Надо работать... Ребята... Надо работать... Много чего упустили...",
        },
        {
          speakSprite: "",
          nameSpeak: "",
          speakColor: "",
          text: "*тишина*",
        },
        {
          leftSprite: "",
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Кх-м... ещё вопросы?",
        },
      ],
    },
    {
      choices: [
        "У меня есть вопрос!|lesson_project_protection_project_2_questions",
        "Промолчать|lesson_project_protection_project_2_whist",
      ],
    },
  ],

  lesson_project_protection_project_2_whist: [
    {
      body: [
        {
          background: imikn_protection_class,
          leftSprite: "",
          centerSprite: "",
          rightSprite: anas_suleim_angry,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "И снова вопросов мало!",
        },
        {
          rightSprite: anas_suleim_shout,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А если мало вопросов, значит ничего непонятно и проект не стоящий!",
        },
        {
          rightSprite: dobr_roman,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Ну, Анастасия Сулеймановна! Давайте успокоимся...",
        },
        {
          rightSprite: anas_suleim_angry,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Я абсолютно спокойна!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Как говорится, констатирую факт!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Нужно лучше работать! Чтобы речь была понятной, отчёт полным, выступление интересным!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А это...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Детский сад, честное слово...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_project_protection_project_3",
    },
  ],

  lesson_project_protection_project_2_questions: [
    {
      body: [
        {
          background: imikn_protection_project2_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Пожалуйста...",
        },
      ],
    },
    {
      choices: [
        "Вы обновляете данные по компонентам ПК?|lesson_project_protection_project_2_question_1",
        "А пользователь платит за услуги вашего приложения?|lesson_project_protection_project_2_question_2",
        "Заказать конфигурацию можно прямо с вашего сайта?|lesson_project_protection_project_2_question_3",
      ],
    },
  ],

  lesson_project_protection_project_2_question_1: [
    {
      body: [
        {
          background: imikn_protection_project2_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: popov_npc_indifference_right,
          speakSprite: "right",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Ага, данные парсятся с сайта магазина цифровой техники раз в сутки",
        },
        {
          speakSprite: "center",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А если цены поменяются в течении дня, а человек сделает заказ до обновления?",
        },
        {
          rightSprite: popov_npc_angry_right,
          speakSprite: "right",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "*с нотками пассивной агрессии* На нашем сайте нельзя сделать заказ!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А как, допустим, мне купить все детали, которые Ваш сайт мне порекомендует?",
        },
        {
          rightSprite: popov_npc_indifference_right,
          speakSprite: "right",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Вот так... По списку самостоятельно заказывать где угодно...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Ну или в магазине оффлайн... Без разницы...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ла-а-адно, хм, спасибо за ответ...",
        },
        {
          speakSprite: "center",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну разве это ответ?",
        },
        {
          speakSprite: "center",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Продумайте, как сделать процесс покупки по вашему списку удобным для пользователя, а не для вас...",
        },
        {
          rightSprite: popov_npc_angry_right,
          speakSprite: "right",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "*с нотками пассивной агрессии* Ага, подумаем...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_project_protection_project_2_questions_continue",
    },
  ],

  lesson_project_protection_project_2_question_2: [
    {
      body: [
        {
          background: imikn_protection_project2_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: popov_npc_indifference_right,
          speakSprite: "right",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Неа, сайт бесплатный",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_project_protection_project_2_questions_continue",
    },
  ],

  lesson_project_protection_project_2_question_3: [
    {
      body: [
        {
          background: imikn_protection_project2_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: popov_npc_indifference_right,
          speakSprite: "right",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Неа",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А как, допустим, мне купить все детали, которые Ваш сайт мне порекомендует?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Вот так... По списку самостоятельно заказывать где угодно...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "Ну или в магазине оффлайн... Без разницы...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ла-а-адно, хм, спасибо за ответ...",
        },
        {
          speakSprite: "center",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну разве это ответ?",
        },
        {
          speakSprite: "center",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Продумайте, как сделать процесс покупки по вашему списку удобным для пользователя, а не для вас...",
        },
        {
          rightSprite: popov_npc_angry_right,
          speakSprite: "right",
          nameSpeak: "Михаил",
          speakColor: popov_npc_color,
          text: "*с нотками пассивной агрессии* Ага, подумаем...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_project_protection_project_2_questions_continue",
    },
  ],

  lesson_project_protection_project_2_questions_continue: [
    {
      body: [
        {
          background: imikn_protection_class,
          leftSprite: "",
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Кхе, кхе... Может быть у кого-то есть ещё вопросы?",
        },
        {
          speakSprite: "",
          nameSpeak: "",
          speakColor: "",
          text: "*молчание*",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_project_protection_project_3",
    },
  ],

  lesson_project_protection_project_3: [
    {
      body: [
        {
          background: imikn_protection_class,
          leftSprite: "",
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Ну что ж, спасибо ребята, присаживайтесь...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Надеюсь, вы учтёте все замечания...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "А в завершении послушаем... Баранова Константина! Пожалуйста...",
        },
        {
          leftSprite: sleepyhead,
          speakSprite: "left",
          nameSpeak: "Константин",
          speakColor: sleepyhead_color,
          text: "Уфф... Я не готов...",
        },
        {
          rightSprite: dobr_roman,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Как это, “не готов”?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: " Совсем не о чем рассказывать?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Константин",
          speakColor: sleepyhead_color,
          text: "Да, я не успел сделать презентацию...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Плохо! Очень плохо!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Вы такими темпами двойку за проект получите...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Давайте серьёзнее относиться к учёбе!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Константин",
          speakColor: sleepyhead_color,
          text: "Да, хорошо...",
        },
        {
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Не поняла!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Добрыня Романович... А разве это уже не показатель?!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Два, Баранов!",
        },
        {
          leftSprite: sleepyhead_sad,
          speakSprite: "left",
          nameSpeak: "Константин",
          speakColor: sleepyhead_color,
          text: "Но...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "На пересдачу!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Константин",
          speakColor: sleepyhead_color,
          text: "Да я болел же почти месяц!",
        },
        {
          rightSprite: anas_suleim_angry,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Не надо оправдываться или спорить... У Вас всё равно было достаточно времени!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Константин",
          speakColor: sleepyhead_color,
          text: "*громко вздыхает*",
        },
      
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_project_protection_project_exit",
    },
  ],
  lesson_project_protection_project_exit: [
    {
      body: [
        {
          background: imikn_protection_class,
          leftSprite: "",
          centerSprite: "",
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Достаточно времени?!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "*шёпотом* Ага, я тоже в шоке...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Они что, считают нас сверхлюдьми? Или роботами? А когда спать, есть, отдыхать, да жить в конце концов?!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "*шёпотом* Видимо в гробу отоспимся...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "*смешок*",
        },
        {
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Ну что ж, похоже на этом мы можем заканчивать...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Списки с предварительными баллами по итогам выступлений я отправлю вам на почту до конца недели",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Готовьте отчёты, исправляйте ошибки и замечания, увидимся на следующей встрече по расписанию, кстати когда она?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Через неделю",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Ну вот, через неделю... На этом всё, всем спасибо, до свидания!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "До свидания!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "До свидания!",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default lesson_project_protection;
