import DialogueSystem from "../../../Components/dialogueSystem/DialogueSystem";
import BoxGame from "../../../Components/gameSection/BoxGame";
import MapDirection from "./components/MapDirection";
import SectionDirection from "../../../Pages/gameSection/SectionDirectionPage";
import { useState, useEffect } from "react";
import AuthUser from "../../../Services/AuthUser";
import direction_intro_1 from "../../dialogues/data/imikn/direction/intro/directions_intro_1";
import direction_intro_2 from "../../dialogues/data/imikn/direction/intro/directions_intro_2";
import direction_intro_3 from "../../dialogues/data/imikn/direction/intro/directions_intro_3";
import direction_intro_4 from "../../dialogues/data/imikn/direction/intro/directions_intro_4";
import direction_intro_5 from "../../dialogues/data/imikn/direction/intro/directions_intro_5";

import direction_mir from "../../dialogues/data/imikn/direction/direction_mir";
import direction_ib from "../../dialogues/data/imikn/direction/direction_ib";
import direction_ibas from "../../dialogues/data/imikn/direction/direction_ibas";
import direction_isit from "../../dialogues/data/imikn/direction/direction_isit";
import direction_kb from "../../dialogues/data/imikn/direction/direction_kb";
import direction_math from "../../dialogues/data/imikn/direction/direction_math";
import direction_mimm from "../../dialogues/data/imikn/direction/direction_mimm";
import direction_moiais from "../../dialogues/data/imikn/direction/direction_moiais";
import direction_pi from "../../dialogues/data/imikn/direction/direction_pi";
import direction_ped from "../../dialogues/data/imikn/direction/direction_ped";

const intro = {
  intro_1: { path: direction_intro_1 },
  intro_2: { path: direction_intro_2 },
  intro_3: { path: direction_intro_3 },
  intro_4: { path: direction_intro_4 },
  intro_5: { path: direction_intro_5 },
};

const dir_dialogue = {
  ib: { path: direction_ib, isVisited: false },
  ibas: { path: direction_ibas, isVisited: false },
  isit: { path: direction_isit, isVisited: false },
  kb: { path: direction_kb, isVisited: false },
  math: { path: direction_math, isVisited: false },
  mimm: { path: direction_mimm, isVisited: false },
  mir: { path: direction_mir, isVisited: false },
  moiais: { path: direction_moiais, isVisited: false },
  pi: { path: direction_pi, isVisited: false },
  ped: { path: direction_ped, isVisited: false },
};

export default function DirectionPage() {
  const intro_local = JSON.parse(localStorage.getItem("intro"));
  const direction = JSON.parse(localStorage.getItem("direction"));
  const teacher = JSON.parse(localStorage.getItem("teacher"));
  const lesson = JSON.parse(localStorage.getItem("lesson"));
  const [currentStories, setCurrentStories] = useState(null);
  const [isDialogue, setIsDialogue] = useState(false);
  const { http } = AuthUser();
  const user = JSON.parse(localStorage.getItem("user"));

  const setDataGame = async () => {
    const combined = {
      direction: JSON.parse(localStorage.getItem("direction")),
      intro: JSON.parse(localStorage.getItem("intro")),
      lesson: JSON.parse(localStorage.getItem("lesson")),
      teacher: JSON.parse(localStorage.getItem("teacher")),
    };
    const jsonString = JSON.stringify(combined);
    try {
      const response = await http.put(`/store-data-game/${user.id}`, {
        imikn: jsonString,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const checkIntro = () => {
    if (intro_local.direction.isVisited) {
      setCurrentStories(intro[`intro_${intro_local.direction.count}`].path);
      setIsDialogue(true);
    } else setIsDialogue(false);
  };

  const getAchievements = async () => {
    try {
      const response = await http.get(`/achievement/imikn/${user.id}`);
      sessionStorage.setItem("achievements", JSON.stringify(response.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkIntro();
    setDataGame();
    getAchievements();

    const intervalId = setInterval(setDataGame, 60000);
    return () => clearInterval(intervalId);
  }, []);
  const handleMapClick = (click) => {
    if (intro_local.direction.isVisited) {
      intro_local.direction.isVisited = false;
      localStorage.setItem("intro", JSON.stringify(intro_local));
    }
    storiesClick(click);
  };
  const storiesClick = (nameClick) => {
    const path = getDirectionPath(nameClick);
    setCurrentStories(path);
    setIsDialogue(true);
    const direction_local = JSON.parse(localStorage.getItem("direction"));
    direction_local[nameClick].isVisited = true;
    localStorage.setItem("direction", JSON.stringify(direction_local));
  };
  const getDirectionPath = (direction) => {
    return dir_dialogue[direction]?.path;
  };
  const checkAchievDirection = () => {
    const achiev_session = JSON.parse(sessionStorage.getItem("achievements"));
    if (achiev_session && achiev_session[0]) {
      if (
        achiev_session[0].achiev_child[1].is_issued === 0 &&
        Object.values(direction).every((item) => item.isVisited === true)
      ) {
        achiev_session[0].achiev_child[1].is_issued = 1;
        sessionStorage.setItem("achievements", JSON.stringify(achiev_session));
        return 9;
      }
      if (
        achiev_session[0].achiev_child[0].is_issued === 0 &&
        Object.values(direction).every((item) => item.isVisited === true) &&
        Object.values(teacher).every((item) => item.isVisited === true) &&
        Object.values(lesson).every((item) => item.isVisited === true)
      ) {
        achiev_session[0].achiev_child[0].is_issued = 1;
        sessionStorage.setItem("achievements", JSON.stringify(achiev_session));
        return 1;
      }
    }
  };
  return (
    <>
      <SectionDirection
        instID={1}
        main={
          <BoxGame
            main={
              isDialogue ? (
                <DialogueSystem
                  stories={currentStories}
                  setIsDialogue={setIsDialogue}
                  checkAchievParams={checkAchievDirection}
                />
              ) : (
                <MapDirection onMapClick={handleMapClick} />
              )
            }
          />
        }
      />
    </>
  );
}
