import * as React from "react";
import Card from "@mui/material/Card";
import { CardActionArea, CardActions } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

const StyledCardMedia = styled(CardMedia)(({ theme, is_issued }) => ({
  filter: is_issued ? "none" : "brightness(0)",
  boxShadow: `0px 0px 15px 2px ${theme?.palette?.box.shadow}`,
}));

export default function CardAchievement(props) {
  const theme = useTheme();
  return (
    <Card
      sx={{
        maxWidth: 345,
        background: theme?.palette?.box.main,
        boxShadow: `0px 0px 15px 2px ${theme?.palette?.box.shadow}`,
      }}
    >
      <CardActionArea
        component={Link}
        to={`/achievements/${props.instName}/${props.id}`}
      >
        <StyledCardMedia
          component="img"
          image={props.img}
          alt={props.img}
          is_issued={props.is_issued} 
        />
      </CardActionArea>
      <CardActions>
        {props.achievChild.length !== 0 ? (
          <Typography
            component="div"
            variant="subtitle1"
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              width: "fit-content",
              margin: "0 auto",
              padding: "1px",
            }}
          >
            Получено {""}
            {
              props.achievChild.filter((item) => item.is_issued === 1).length
            } / {props.achievChild.length}
          </Typography>
        ) : (
          <>{""}</>
        )}
      </CardActions>
    </Card>
  );
}
