import {
    getSpriteCharacterOfGender,
    getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";
import { setVisibleTeacher } from "../../../../../Components/dialogueSystem/scripts/VisibleTeacher";

// background
const imikn_isit = require("../../../assets/imikn/backgrounds/imikn_isit.jpg");

// character
const guide_default = require("../../../assets/imikn/characters/guide.png");
const guide_smile = require("../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let direction_isit = {
    Start: [
        {
            body: [
                {
                    background: imikn_isit,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это направление ориентировано на подготовку “классических” программистов, если так можно выразиться. То есть веб-разработчиков",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Сейчас интернет развивается как никогда и работа по созданию различных сайтов - очень прибыльное и востребованное дело...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Здесь студенты изучают веб-программирование, причём как фронтенд, так и бэкенд, но в основном большинство выбирают первое",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А можно поподробнее?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Знаешь, я в этом не эксперт, поговори лучше с Анной Евстафьевной на данную тему, она действительно в этом разбирается!",
                    visibleTeacher: "ann_evst",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Или посмотри определение в Азбуке...",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Хорошо",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Также здесь изучается веб-дизайн, интернет-технологии и моделирование бизнес-процессов",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А что такое моделирование бизнес-процессов?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    rightSprite: guide_smile,
                    text: "Это очень интересная область знаний!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Она включает в себя методы управления проектными командами разработчиков",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "То есть как сделать так, чтобы команда разработчиков, качественно и в срок выполняла свою работу",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Чтобы обязанности были грамотно разделены между членами команды",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "И не получалось так, что кто-то занимается проектированием",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Например, базы данных, но не имеет достаточных знаний, а другой человек, знает даже больше, чем нужно, но занимается чем-то другим...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А ещё в моделирование бизнес-процессов включаются знания о том",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Как сделать так, чтобы программы, приложения, сервисы, веб-сайты и прочие IT-продукты работали эффективно",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Чётко, слаженно и качественно поддерживая все рабочие процессы",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А что это значит?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну простыми словами, чтобы заказчик был доволен и IT-продукт работал в точности так, как было задумано",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ещё стоит кое-что отметить!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это, пожалуй, единственное направление, на котором преподаватели о-очень лояльно относятся к студентам, работающим по профессии",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "И наверное, это даже улучшает их отношение к таким людям",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Ого! Звучит здорово! А нет ли каких-то подводных камней?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Есть некоторый нюанс",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Понимаешь, если появляется какая-нибудь прибыльная специальность, то очень многие идут учиться и работать именно на неё...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Поэтому среди веб-разработчиков достаточно высокая конкуренция",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Необходимо иметь хорошие компетенции, быстро учиться и следить за новинками в этой области знаний",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ведь новые технологии появляются почти каждый день и нужно поспевать за ними, чтобы быть конкурентоспособным",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Словом, просто хорошо учиться здесь недостаточно, нужно дополнительно развиваться и проявлять себя",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "И, видимо, начинать работать как можно раньше...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    rightSprite: guide_default,
                    text: "Не в ущерб учёбе, конечно, но да, всё верно",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ещё забыл рассказать про практики, ты знаешь что это?",
                },
            ],
        },
        {
            choices: [
                "Да|direction_isit_yes",
                "Нет|direction_isit_no",
            ],
        },
    ],

    direction_isit_yes: [
        {
            body: [
                {
                    background: imikn_isit,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Хорошо, тогда расскажу как они проходят именно на данном направлении",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_isit_continue",
        },
    ],

    direction_isit_no: [
        {
            body: [
                {
                    background: imikn_isit,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Практика - это специально выделенный период, когда студент только практикуется и показывает чему научился",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "В большинстве случаев таких периода три: учебная, производственная или технологическая и преддипломная практика",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_isit_continue",
        },
    ],
    
    direction_isit_continue: [
        {
            body: [
                {
                    background: imikn_isit,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "На большинстве направлений в нашем институте практики проходят примерно одинаково",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "На учебной практике студенты работают над проектом, тему которого придумали сами или же взяли у научного руководителя",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Затем они пишут отчёт",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "То есть документ, в котором подробно описано, что сделано и как, и защищают свой проект перед комиссией из преподавателей",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "На производственной или технологической, её по-разному называют, происходит всё то же самое, если студент проходит её на своей кафедре",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А её можно проходить где-то ещё?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну смотри, она не даром названа производственной",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Здесь появляется выбор и кроме кафедры практику можно пройти на реальном предприятии",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Университет предлагает предприятия или нужно искать самостоятельно?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Когда как, если есть компании, которые приглашают к себе стажёров из ВУЗов, тогда университет советует своих студентов",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Когда нет - нужно искать самому, а возможно кто-то уже к тому моменту работает и может пройти её у себя",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А если не работаешь, то получится ли совместить с учёбой?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Для этого чаще всего производственная практика ставится летом, после сессии",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А когда отдыхать? Это же так грустно...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Да, забудь о трёх месяцах блаженства, без практики их будет примерно два или полтора, а вместе с ней, если повезёт, то месяц!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Так вот, даже если студент проходит практику в реальной компании, ему всё равно нужно писать отчёт и защищать свои рабочие задачи",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А преддипломная говорит сама за себя! Она должна быть связана с дипломной работой студента",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Проходить её также можно на предприятии или на кафедре, всё также отчёт и всё также защита, уф, ничего нового!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Судя по всему она ставится перед дипломом, это что же их получают в августе!?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    rightSprite: guide_smile,
                    text: "Нееет, что ты",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Последний семестр бакалавриата обычно очень короткий",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Примерно два с половиной месяца, поэтому не переживай, примерно в июле и будет получение дипломов",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Главное - доучиться до этого момента...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну что ж, нам пора идти дальше",
                },
            ],
        },
        {
            exits: true,
        },
    ],
};

export default direction_isit;
