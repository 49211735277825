import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_isit = require("../../../assets/imikn/backgrounds/imikn_isit.jpg");

// character
const ann_evst = require("../../../assets/imikn/characters/ann_evst.png");
const ann_evst_angry = require("../../../assets/imikn/characters/ann_evst_angry.png");
const ann_evst_indicate = require("../../../assets/imikn/characters/ann_evst_indicate.png");

//color
const player_color = "#D4D4D4";
const ann_evst_color = '#F2BEB8';

let teacher_ann_evst = {
  Start: [
    {
      body: [
        {
          background: imikn_isit,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: ann_evst_angry,
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Добрый день!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Уже не добрый!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Я же говорила что не надо ко мне подходить с просьбой поставить зачет!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Эм-м, но я абитуриент, зачёт мне не нужен, ха-ха",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          rightSprite: ann_evst,
          text: "А, я думала Вы студент. Ну, остальные видимо спят, пока учеба идет...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Вы, наверное, пришли поговорить про направление “Информационные системы и технологии”...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Ну что сказать, на нём преподаются разные предметы, в частности, мои. И одно из них - веб-программирование",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Вот, скажем, открываете Вы браузер, скроллите всякие новостные ленты, открываете сайты",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Отображение интерфейса и данных сайта, вот то, что относится к данной области",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Кажется, быть веб-программистом очень востребовано",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Так и есть!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Многие наши студенты в течение обучения уже находят себе работу в разных компаниях",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Так как сайты нужны каждой забегаловке и даже самой маломальской фирме",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Но не думайте, что всё тут так просто. Качественный сайт еще нужно постараться сделать!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Важно, чтобы он отображался корректно на разных устройствах и в браузерах",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Некоторые думают, что сверстать сайт - это написать HTML-верстку, подсоединить стили, и все уже готово!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Вы ведь слышали, как создаются сайты?",
        },
      ],
    },
    {
      choices: [
        "Да, немного знаю, у нас на информатике было такое|ann_evst_yes",
        "Нет, Ваши последние слова вообще мне не понятны...|ann_evst_no",
      ],
    },
  ],

  ann_evst_yes: [
    {
      body: [
        {
          background: imikn_isit,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: ann_evst_indicate,
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Тогда я Вам скажу, что какие-нибудь сайты-визитки могут ограничиться подобным, но, если будет уже что-то покрупнее...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Для сайтов с большим количеством информации подключают базы данных",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Которые будут хранить данные - будь то текст пьесы или же список понравившихся записей в социальной сети",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Также многие сайты для быстроты работы используют разные фреймворки, но это уже совсем другая история",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Вам пока знать это необязательно, а то уже вижу по глазам, становится страшно...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "ann_evst_continue",
    },
  ],

  ann_evst_no: [
    {
      body: [
        {
          background: imikn_isit,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: ann_evst_indicate,
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Каждый сайт, который ты видишь, это своего рода конструктор в руках опытного веб-разработчика",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Веб-разработчик правильно компонует элементы по, так скажем, контейнерам...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Что-то будет всегда оставаться наверху, например, меню сайта, что-то внизу, например, контакты компании и так далее",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Затем, чтобы все выглядело красиво, к подобному конструктору подключается файл со стилями",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Там указывается как должен выглядеть элемент или группа элементов...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Если для Вас это все еще кажется сложным, то не переживайте, с практикой станет намного проще!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "ann_evst_continue",
    },
  ],

  ann_evst_continue: [
    {
      body: [
        {
          background: imikn_isit,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: ann_evst,
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "В общем, о веб-программировании школьникам мало что известно, а если что-то и знают, то думают, что это очень просто",
        },
        {
          rightSprite: ann_evst_angry,
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Это не так! Иначе такого предмета не было бы в хорошем учебном заведении!",
        },
        {
          rightSprite: ann_evst,
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Что бы Вам ещё рассказать? Даже не знаю...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А есть ли какие-то требования на Ваших занятиях? И, в целом, как они проходят?",
        },
        {
          rightSprite: ann_evst_angry,
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Пары как пары, чего тут размусоливать?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "На лекциях рассказываю материал, на практиках принимаю лабораторные работы",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Если вижу, что студент, ну, ничего не понимает или понимать не хочет, то чего мне с ним возиться?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Я помочь не против, если попросят, а если нет, то лучше своё время подарить более способным и подающим надежды студентам...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "И, возможно, из-за этого некоторые считают, что я вредная: отчисляю людей, потому что просто так захотела...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Но, во-первых, отчисляю не я, у меня нет таких полномочий",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Во-вторых, если они даже комиссии не могут доказать, что хоть что-то знают, то причём тут я вообще?",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А что такое комиссия?",
        },
        {
          rightSprite: ann_evst,
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Подождите, Вы же знаете про сессию, экзамены, зачёты, пересдачи?",
        },
      ],
    },
    {
      choices: [
        "Да|ann_evst_yes_2",
        "Нет|ann_evst_no_2",
      ],
    },
  ],

  ann_evst_yes_2: [
    {
      body: [
        {
          background: imikn_isit,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: ann_evst_indicate,
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Ну вот, если человек на пересдаче мне плохо ответит, я ставлю неудовлетворительную оценку, и он идёт на комиссию",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "А комиссия как пересдача, только там принимают все преподаватели кафедры...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Чтобы исключить ситуации, когда студент с преподавателем друг друга не любят, и последний предвзято относится...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Ну-у-у... Пытается отчислить, другими словами",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "ann_evst_exit",
    },
  ],

  ann_evst_no_2: [
    {
      body: [
        {
          background: imikn_isit,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: ann_evst_indicate,
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Вот смотрите, сессия - это период, в который ставят экзамены по предметам за семестр",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "До неё зачётная неделя, где проходят зачёты",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Если студент пришёл на зачёт или экзамен и не продемонстрировал, что хоть что-то знает... что он учился...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "То ему ставят 2, и отправляют на пересдачу!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "На пересдаче точно такая же система, если он снова не подготовился, то ставят 2 и отправляют на комиссию",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "А комиссия как пересдача, только там принимают все преподаватели кафедры...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Чтобы исключить ситуации, когда студент с преподавателем друг друга не любят и последний предвзято относится...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Ну-у-у... Пытается отчислить, другими словами",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "ann_evst_exit",
    },
  ],

  ann_evst_exit: [
    {
      body: [
        {
          background: imikn_isit,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: ann_evst,
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Так вот, не слушайте других!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Помните, что человек, с которым Вы общаетесь, может быть двоечником и только поэтому не любит какого-то конкретного преподавателя...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анна Евстафьевна",
          speakColor: ann_evst_color,
          text: "Что-то я засиделась, пора бы уже идти. До скорого!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "До свидания!",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default teacher_ann_evst;
