import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import AlertDialog from "../../Components/AlertDialog";
import AuthUser from "../../Services/AuthUser";

export default function FormFeedback({ alignItemsValue }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const [feedUser, setFeedUser] = useState("");
  const [feedName, setFeedName] = useState("");
  const [feedEmail, setFeedEmail] = useState("");
  const [feedText, setFeedText] = useState("");

  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const { http } = AuthUser();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    http
      .post("/feedback-player", {
        feed_user: data.get("feedUser"),
        feed_name: data.get("feedName"),
        feed_email: data.get("feedEmail"),
        feed_text: data.get("feedText"),
      })
      .then((response) => {
        // console.log(response.data);
        if (response.data.status === 200) {
          setOpen(true);
          // console.log("Отправлено");
          setFeedUser("");
          setFeedName("");
          setFeedEmail("");
          setFeedText("");
          setErrors({});
        } else {
          setErrors(response.data.validate_err);
        }
      });
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/");
  };
  
  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          my: 4,
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          alignItems: alignItemsValue,
        }}
      >
        <Typography variant="h4" component="h5" align="center" 
        sx={{ 
          mt: 5,
          '@media (max-width: 1200px) and (min-width: 800px)': {
            fontSize: '3.5vw',
          },
          '@media (max-width: 800px) and (min-width: 600px)': {
            fontSize: "5vw", 
            mt: 0,
          },
          '@media (max-width: 600px)': {
            fontSize: "6vw", 
            mt: 0,
          }
          }}>
          Форма обратной связи
        </Typography>

        <TextField
          id="outlined-basic"
          label="Имя"
          variant="outlined"
          name="feedUser"
          fullWidth
          sx={{
            input: { color: theme?.palette?.text.primary },
            label: { color: theme?.palette?.text.primary },
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                borderColor: theme?.palette?.text.primary,
              },
              "&.Mui-focused fieldset": {
                borderColor: theme?.palette?.text.primary,
              },
            },
            "& label.Mui-focused": {
              color: theme?.palette?.text.primary,
            },
            marginTop: 3,
            width: "75%",
            '@media (max-width: 800px)': {
              width: "100%",
            }
          }}
          value={feedUser}
          onChange={(e) => setFeedUser(e.target.value)}
          error={errors && errors.feed_user ? true : false}
          helperText={errors && errors.feed_user ? errors.feed_user : ""}
        />
        <TextField
          id="outlined-basic"
          label="Название"
          name="feedName"
          variant="outlined"
          sx={{
            input: { color: theme?.palette?.text.primary },
            label: { color: theme?.palette?.text.primary },
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                borderColor: theme?.palette?.text.primary,
              },
              "&.Mui-focused fieldset": {
                borderColor: theme?.palette?.text.primary,
              },
            },
            "& label.Mui-focused": {
              color: theme?.palette?.text.primary,
            },
            marginTop: 3,
            width: "75%",
            '@media (max-width: 800px)': {
              width: "100%",
            }
          }}
          value={feedName}
          onChange={(e) => setFeedName(e.target.value)}
          error={errors && errors.feed_name ? true : false}
          helperText={errors && errors.feed_name ? errors.feed_name : ""}
        />
        <TextField
          id="outlined-basic"
          label="Email"
          name="feedEmail"
          variant="outlined"
          sx={{
            input: { color: theme?.palette?.text.primary },
            label: { color: theme?.palette?.text.primary },
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                borderColor: theme?.palette?.text.primary,
              },
              "&.Mui-focused fieldset": {
                borderColor: theme?.palette?.text.primary,
              },
            },
            "& label.Mui-focused": {
              color: theme?.palette?.text.primary,
            },
            marginTop: 3,
            width: "75%",
            '@media (max-width: 800px)': {
              width: "100%",
            }
          }}
          value={feedEmail}
          onChange={(e) => setFeedEmail(e.target.value)}
          error={errors && errors.feed_email ? true : false}
          helperText={errors && errors.feed_email ? errors.feed_email : ""}
        />
        <TextField
          id="outlined-multiline-static"
          label="Текст сообщения"
          name="feedText"
          multiline
          rows={10}
          sx={{
            input: { color: theme?.palette?.text.primary },
            label: { color: theme?.palette?.text.primary },
            "& .MuiOutlinedInput-root": {
              "&:hover fieldset": {
                borderColor: theme?.palette?.text.primary,
              },
              "&.Mui-focused fieldset": {
                borderColor: theme?.palette?.text.primary,
              },
            },
            "& label.Mui-focused": {
              color: theme?.palette?.text.primary,
            },
            marginTop: 3,
            width: "75%",
            '@media (max-width: 800px)': {
              width: "100%",
            }
          }}
          value={feedText}
          onChange={(e) => setFeedText(e.target.value)}
          error={errors && errors.feed_text ? true : false}
          helperText={errors && errors.feed_text ? errors.feed_text : ""}
        />
        <Button
          type="submit"
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            background: theme?.palette?.primary.main,
            borderColor: theme?.palette?.primary.main,
            borderStyle: "solid",
            borderWidth: "1px",
            // "&:hover": {
            //   backgroundColor: "#f4f4f4",
            //   // color: theme?.palette?.primary.main,
            //   // borderColor: theme?.palette?.primary.main,
            //   borderStyle: "solid",
            //   borderWidth: "1px",
            // },
          }}
        >
          Отправить
        </Button>
        <AlertDialog
          open={open}
          title="Успех"
          description="Форма успешно отправлена."
          onClose={handleClose}
        />
      </Box>
    </>
  );
}
