import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState, useEffect } from "react";
import AuthUser from "../../../../Services/AuthUser";
import Heading from "../../../../Components/adminpanel/Heading";
import AddRoleModal from './Add';
import EditRoleModal from './Edit';

export default function ListIndex() {
  const [openDialog, setOpenDialog] = useState(false);
  const [roles, setRoles] = useState([]);
  const { token, http } = AuthUser();
  const [selectedRole, setSelectedRole] = useState(null);

  const buttonInfo = {
    value: "Добавить",
    onClick: () => setOpenDialog(true),
  };

  const columns = [
    { field: "name", headerName: "Название", width: 130 },
    { field: "description", headerName: "Описание", width: 200, flex: 1 },
    {
      field: "actions",
      type: "actions",
      width: 80,
      flex: 0,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => editRole(params.id)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => deleteRole(params.id)}
        />,
      ],
    },
  ];

  useEffect(() => {
    const getRoles = async () => {
      try {
        const response = await http.get("/roles", {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setRoles(response.data);
        // console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getRoles();
  }, []);

  const editRole = (id) => {
    const role = roles.find((rol) => rol.id === id);
    setSelectedRole(role);
  };

  const deleteRole = async (id) => {
    try {
      await http.delete(`/roles/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setRoles((prevRoles) => prevRoles.filter((role) => role.id !== id));
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddRole = async () => {
    try {
      const response = await http.get("/roles", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setRoles(response.data);
      setOpenDialog(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Heading title="Роли" button={buttonInfo} />
      <Box sx={{ width: "100%", mt: 3 }}>
        <DataGrid rows={roles} columns={columns} />
      </Box>
      <AddRoleModal
        open={openDialog}
        close={() => setOpenDialog(false)}
        handleAddRole={handleAddRole}
      />
        <EditRoleModal
        open={Boolean(selectedRole)}
        close={() => setSelectedRole(null)}
        role={selectedRole}
        setRoles={setRoles}
      />
    </>
  );
}
