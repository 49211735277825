import React, { useEffect, useState } from "react";
import "./styleHome.css";
import { useTheme } from "@mui/material/styles";

export default function CardCarouselHome(props) {
    const theme = useTheme();
    const [selectedId, setSelectedId] = useState(2);
  return (
    <>
      <input
        type="radio"
        name="card-carusel-home"
        id="t-1"
        style={{ display: "none" }}
      />
      <input
        type="radio"
        name="card-carusel-home"
        id="t-2"
        defaultChecked
        style={{ display: "none" }}
      />
      <input
        type="radio"
        name="card-carusel-home"
        id="t-3"
        style={{ display: "none" }}
      />
      <div className="card-carusels-home">
        {props.data.map((item, i) => (
          <label
            key={i}
            className="item-home"
            htmlFor={`t-${item.id}`}
            onClick={() => setSelectedId(item.id)}
            
          >
            <h2>{item.title}</h2>
            <img
              src={item.img}
              style={{
                width: "100%",
                border: `1px solid ${theme.palette.box.main}`,
              }}
            />
          </label>
        ))}
      </div>
      {/* <br /> */}
      <div className="dots-home">
        <label for="t-1"></label>
        <label for="t-2"></label>
        <label for="t-3"></label>
      </div>
    </>
  );
}
