import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import AuthUser from "../../../../Services/AuthUser";

const ShowUserModal = (props) => {
  const { token, http } = AuthUser();
  const { open, close, user, setUsers } = props;
  const [username, setUsername] = useState("");
  const [nickname, setNickname] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [city, setCity] = useState("");
  const [questionID, setQuestionID] = useState("");
  const [answer, setAnswer] = useState("");
  const [createdAt, setCreatedAt] = useState("");

  const [questions, setQuestions] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");

  const [userID, setUserID] = useState("");
  const [roleID, setRoleID] = useState("");

  useEffect(() => {
    setUserID(user?.id || "");
    setUsername(user?.username || "");
    setNickname(user?.nickname || "");
    setAge(user?.age || "");
    setGender(getGender(user?.gender));
    setCity(user?.city || "");
    setQuestionID(getQuestionsName(user?.question_id));
    setAnswer(user?.answer || "");
    setCreatedAt(user?.created_at || "");

    const role = roles.find((role) => role.name === user?.roles[0]);
    setSelectedRole(role ? role.name : "");
  }, [roles, user]);

  useEffect(() => {
    const getQuestions = async () => {
      const response = await http.get("/questions", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setQuestions(response.data);
    };
    const getRoles = async () => {
      try {
        const response = await http.get("/roles", {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setRoles(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    getQuestions();
    getRoles();
  }, [http, token]);

  function getGender(genderValue) {
    if (genderValue === 0) {
      return "Женский";
    } else {
      return "Мужской";
    }
  }

  function getQuestionsName(params) {
    const question = questions.find((ques) => ques.id === params);
    return question ? question.text : "";
  }

  const handleRoleChange = (event) => {
    const selectedRoleName = event.target.value;
    const selectedRole = roles.find((role) => role.name === selectedRoleName);
    setSelectedRole(selectedRoleName);
    setRoleID(selectedRole.id);
  };

  const handleSave = () => {
    const data = {
      user_id: userID,
      role_id: roleID,
    };
    http
      .put(`/update-role-user`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUsers((prevUsers) =>
          prevUsers.map((us) =>
            us.id === user.id
              ? {
                  ...us,
                  roles: [selectedRole],
                }
              : us
          )
        );
        close();
      });
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Пользователь</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Логин"
          type="text"
          fullWidth
          disabled
          value={username}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Никнейм"
          type="text"
          fullWidth
          disabled
          value={nickname}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Возраст"
          type="text"
          fullWidth
          disabled
          value={age}
        />{" "}
        <TextField
          autoFocus
          margin="dense"
          label="Пол"
          type="text"
          fullWidth
          disabled
          value={gender}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Город"
          type="text"
          fullWidth
          disabled
          value={city}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Вопрос"
          type="text"
          fullWidth
          disabled
          value={questionID}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Ответ"
          type="text"
          fullWidth
          disabled
          value={answer}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Дата регистрации"
          type="text"
          fullWidth
          disabled
          value={createdAt}
        />
        <TextField
          margin="normal"
          id="role"
          label="Роль"
          select
          fullWidth
          value={selectedRole}
          onChange={handleRoleChange}
          required
        >
          {roles.map((rol) => (
            <MenuItem key={rol.id} value={rol.name}>
              {rol.name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Закрыть
        </Button>
        <Button onClick={handleSave} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShowUserModal;
