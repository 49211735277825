import {
    getSpriteCharacterOfGender,
    getNamePlayer,
  } from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";
  
  // background
  const imikn_first_coridor = require("../../../../assets/imikn/backgrounds/imikn_first_coridor.jpg");
  
  // character
  const guide_smile = require("../../../../assets/imikn/characters/guide_smile.png");
  
  //color
  const player_color = "#D4D4D4";
  const guide_color = "#9DC7CC";
  
  let teachers_intro_6 = {
    Start: [
      {
        body: [
          {
            background: imikn_first_coridor,
            leftSprite: getSpriteCharacterOfGender("default"),
            centerSprite: "",
            rightSprite: guide_smile,
            speakSprite: "right",
            nameSpeak: "Богдан",
            speakColor: guide_color,
            text: "“И снова седая ночь... И только ей доверяю я...” ",
          },
          {
            speakSprite: "right",
            nameSpeak: "Богдан",
            speakColor: guide_color,
            text: "Ой, ты здесь, не заметил... Как разговоры с преподавателями?",
          },
          {
            speakSprite: "left",
            nameSpeak: getNamePlayer(),
            speakColor: player_color,
            text: "Продвигаюсь потихоньку",
          },
          {
            speakSprite: "right",
            nameSpeak: "Богдан",
            speakColor: guide_color,
            text: "А, ну давай-давай...",
          },
          {
            speakSprite: "right",
            nameSpeak: "Богдан",
            speakColor: guide_color,
            text: "“... и темнота твоя... Мне одному совсем, совсем ни к чему...”",
          },
        ],
      },
      {
        exits: true,
      },
    ],
  };
  
  export default teachers_intro_6;
  