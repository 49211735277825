import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import { Box } from "@mui/material";
import CiscoFon from "../../assets/ComputerNetworks/networks_background_empty.png";
import "./style_cisco.css";
import NotificanSnackbar from "../../../../Components/dialogueSystem/components/NotificanSnackbar";

import lesson_networking_task_completed_correctly from "../../../dialogues/data/imikn/lesson/lesson_networking/lesson_networking_task_completed_correctly";
import lesson_networking_task_not_completed from "../../../dialogues/data/imikn/lesson/lesson_networking/lesson_networking_task_not_completed";

const CiscoGame = ({ setIsDialogue, setCurrentStories,setNetworking }) => {
  const [isVisiblePC1, setIsVisiblePC1] = useState(false);
  const [isVisiblePC2, setIsVisiblePC2] = useState(false);
  const [isVisibleCommutator, setIsVisibleCommutator] = useState(false);
  const [isVisibleLine1, setIsVisibleLine1] = useState(false);
  const [isVisibleLine2, setIsVisibleLine2] = useState(false);
  const [isVisibleWindow1, setIsVisibleWindow1] = useState(false);
  const [isVisibleConsole1, setIsVisibleConsole1] = useState(false);
  const [isVisibleIpV4_1, setIsVisibleIpV4_1] = useState(true);
  const [consoleButtonClicked1, setConsoleButtonClicked1] = useState(false);
  const [isVisibleWindow2, setIsVisibleWindow2] = useState(false);
  const [isVisibleConsole2, setIsVisibleConsole2] = useState(false);
  const [isVisibleIpV4_2, setIsVisibleIpV4_2] = useState(true);
  const [consoleButtonClicked2, setConsoleButtonClicked2] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState(false);
  const handleSuccess = () => {
    // Обработка успешной отправки запроса
    setShowSnackbar(true);
    // Скрыть компонент через 5 секунд
    setTimeout(() => {
      setShowSnackbar(false);
    }, 5000);
  };
  const handleClose = () => {
    setShowSnackbar(false);
  };
  const handleButtonClick = (buttonType) => {
    switch (buttonType) {
      case "pc":
        if (!isVisiblePC1) {
          setIsVisiblePC1(!isVisiblePC1);
        } else if (isVisiblePC1 && !isVisiblePC2) {
          setIsVisiblePC2(!isVisiblePC2);
        }
        break;
      case "commutator":
        if (!isVisibleCommutator) {
          setIsVisibleCommutator(!isVisibleCommutator);
        }
        break;
      case "line":
        if (!isVisibleLine1 && isVisibleCommutator && isVisiblePC1) {
          setIsVisibleLine1(!isVisibleLine1);
        } else if (
          isVisibleLine1 &&
          !isVisibleLine2 &&
          isVisibleCommutator &&
          isVisiblePC2
        ) {
          setIsVisibleLine2(!isVisibleLine2);
        }
        break;
      case "window1":
        if (!isVisibleWindow2) {
          setIsVisibleWindow1(!isVisibleWindow1);
        } else if (isVisibleWindow2) {
          setIsVisibleWindow2(!isVisibleWindow2);
          setIsVisibleWindow1(!isVisibleWindow1);
        }
        break;
      case "window2":
        if (!isVisibleWindow1) {
          setIsVisibleWindow2(!isVisibleWindow2);
        } else if (isVisibleWindow1) {
          setIsVisibleWindow2(!isVisibleWindow2);
          setIsVisibleWindow1(!isVisibleWindow1);
        }
        break;
      case "console":
        if (isVisibleIpV4_1 && isVisibleWindow1) {
          setIsVisibleConsole1(!isVisibleConsole1);
          setIsVisibleIpV4_1(!isVisibleIpV4_1);
          setConsoleButtonClicked1(true);
        } else if (isVisibleIpV4_2 && isVisibleWindow2) {
          setIsVisibleConsole2(!isVisibleConsole2);
          setIsVisibleIpV4_2(!isVisibleIpV4_2);
          setConsoleButtonClicked2(true);
        }
        break;
      case "ipv4":
        if (isVisibleConsole1 && isVisibleWindow1) {
          setIsVisibleIpV4_1(!isVisibleIpV4_1);
          setIsVisibleConsole1(!isVisibleConsole1);
          setConsoleButtonClicked1(false);
          setInputValueConsole1("");
          setMessagePC1("");
        } else if (isVisibleConsole2 && isVisibleWindow2) {
          setIsVisibleIpV4_2(!isVisibleIpV4_2);
          setIsVisibleConsole2(!isVisibleConsole2);
          setConsoleButtonClicked2(false);
          setInputValueConsole2("");
          setMessagePC2("");
        }
        break;
      case "abort":
        const lesson = JSON.parse(localStorage.getItem("lesson"));
        lesson.networking_lesson.isVisited = true;
        localStorage.setItem("lesson", JSON.stringify(lesson));
        setNetworking(false);
        setIsDialogue(true);
        setCurrentStories(lesson_networking_task_not_completed);
        break;
      case "work-done":
        workComplite();
        break;
      default:
        break;
    }
  };

  const [inputValuePC1, setInputValuePC1] = useState("");
  const [inputValuePC2, setInputValuePC2] = useState("");
  const [saveIPv4PC1, setSaveIPv4PC1] = useState("");
  const [saveIPv4PC2, setSaveIPv4PC2] = useState("");

  const isValidIP = (ip) => {
    const ipPattern =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipPattern.test(ip);
  };

  const handleBlur1 = () => {
    if (isValidIP(inputValuePC1)) {
      setSaveIPv4PC1(inputValuePC1);
    } else {
      setInputValuePC1("");
      setSaveIPv4PC1("");
    }
  };

  const handleBlur2 = () => {
    if (isValidIP(inputValuePC2)) {
      setSaveIPv4PC2(inputValuePC2);
    } else {
      setInputValuePC2("");
      setSaveIPv4PC2("");
    }
  };

  const validateInputPC1 = (event) => {
    let value = event.target.value.replace(/[^0-9.]/g, "");
    setInputValuePC1(value);
    if (isValidIP(value)) {
      setSaveIPv4PC1(value);
    } else setSaveIPv4PC1("");
  };

  const validateInputPC2 = (event) => {
    let value = event.target.value.replace(/[^0-9.]/g, "");
    setInputValuePC2(value);
    if (isValidIP(value)) {
      setSaveIPv4PC2(value);
    } else setSaveIPv4PC2("");
  };

  const [inputValueConsole1, setInputValueConsole1] = useState("");
  const [inputValueConsole2, setInputValueConsole2] = useState("");
  const [messagePC1, setMessagePC1] = useState("");
  const [messagePC2, setMessagePC2] = useState("");

  useEffect(() => {
    document.addEventListener("keydown", (event) =>
      handleEnterKeyPress("PC1", event)
    );
    return () => {
      document.removeEventListener("keydown", (event) =>
        handleEnterKeyPress("PC1", event)
      );
    };
  }, [isVisibleConsole1, inputValueConsole1]);

  useEffect(() => {
    document.addEventListener("keydown", (event) =>
      handleEnterKeyPress("PC2", event)
    );
    return () => {
      document.removeEventListener("keydown", (event) =>
        handleEnterKeyPress("PC2", event)
      );
    };
  }, [isVisibleConsole2, inputValueConsole2]);

  const handleConsoleChange = (consoleType, event) => {
    switch (consoleType) {
      case "PC1":
        setInputValueConsole1(event.target.value);
        break;
      case "PC2":
        setInputValueConsole2(event.target.value);
        break;
      default:
        break;
    }
  };

  const handleBlurConsole1 = () => {
    const inputValueLowerCase = inputValueConsole1.toLowerCase();
    if (inputValueLowerCase.includes("ping")) {
      console.log(inputValueLowerCase);
      if (
        inputValueLowerCase.includes(saveIPv4PC2) &&
        saveIPv4PC1 !== saveIPv4PC2 &&
        saveIPv4PC2 !== "" &&
        isVisibleLine1 &&
        isVisibleLine2
      ) {
        setMessagePC1("Проверка прошла успешно!");
      } else if (inputValueLowerCase.includes(saveIPv4PC1)) {
        setMessagePC1("Проверка прошла успешно!");
      } else {
        setMessagePC1("Ошибка!");
      }
    } else if (inputValueLowerCase === "") {
      setMessagePC1("");
    } else setMessagePC1("Ошибка!");
  };

  const handleEnterKeyPress = (consoleType, event) => {
    if (event.key === "Enter") {
      switch (consoleType) {
        case "PC1":
          handleBlurConsole1();
          break;
        case "PC2":
          handleBlurConsole2();
          break;
        default:
          break;
      }
    }
  };

  const handleBlurConsole2 = () => {
    const inputValueLowerCase = inputValueConsole2.toLowerCase();
    if (inputValueLowerCase.includes("ping")) {
      if (
        inputValueLowerCase.includes(saveIPv4PC1) &&
        saveIPv4PC2 !== saveIPv4PC1 &&
        saveIPv4PC1 !== "" &&
        isVisibleLine1 &&
        isVisibleLine2
      ) {
        setMessagePC2("Проверка прошла успешно!");
      } else if (inputValueLowerCase.includes(saveIPv4PC2)) {
        setMessagePC2("Проверка прошла успешно!");
      } else {
        setMessagePC2("Ошибка!");
      }
    } else if (inputValueLowerCase === "") {
      setMessagePC2("");
    } else setMessagePC2("Ошибка!");
  };

  function workComplite() {
    if (
      saveIPv4PC1 !== saveIPv4PC2 &&
      isVisiblePC1 &&
      isVisiblePC2 &&
      isVisibleLine1 &&
      isVisibleLine2 &&
      isVisibleCommutator &&
      saveIPv4PC1 !== "" &&
      saveIPv4PC2 !== ""
    ) {
      const lesson = JSON.parse(localStorage.getItem("lesson"));
      lesson.networking_lesson.isVisited = true;
      lesson.networking_lesson.networking_completed = true;
      localStorage.setItem("lesson", JSON.stringify(lesson));
      setNetworking(false);
      setIsDialogue(true);
      setCurrentStories(lesson_networking_task_completed_correctly);
    } else {
      setMessageSnackbar("Что-то не так... Попробуй еще раз");
      handleSuccess();
    }
  }

  return (
    <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
      <img src={CiscoFon} alt="" style={{ width: "100%", height: "100%" }} />
      <NotificanSnackbar
        showSnackbar={showSnackbar}
        message={messageSnackbar}
        handleClose={handleClose}
      />
      <div className="button-items">
        <Tooltip title="Нажми для установки компьютера" arrow>
          <button
            className="panel-button"
            id="pc-button"
            onClick={() => handleButtonClick("pc")}
          ></button>
        </Tooltip>
        <Tooltip title="Нажми для установки коммутатора" arrow>
          <button
            className="panel-button"
            id="commutator-button"
            onClick={() => handleButtonClick("commutator")}
          ></button>
        </Tooltip>
        <Tooltip title="Нажми для соединения проводов" arrow>
          <button
            className="panel-button"
            id="line-button"
            onClick={() => handleButtonClick("line")}
          ></button>
        </Tooltip>
      </div>
      <div className="button-work">
        <Tooltip title="Отказаться от работы" arrow>
          <button
            className="panel-button"
            id="abort"
            onClick={() => handleButtonClick("abort")}
          ></button>
        </Tooltip>

        <Tooltip title="Завершить работу" arrow>
          <button
            className="panel-button"
            id="work-done"
            onClick={() => handleButtonClick("work-done")}
          ></button>
        </Tooltip>
      </div>
      <div className="items">
        {isVisibleCommutator && (
          <div className="commutator" id="commutator"></div>
        )}
        {isVisiblePC1 && (
          <Tooltip title="Открыть/закрыть консоль" arrow>
            <button
              className="pc"
              id="pc1"
              onClick={() => handleButtonClick("window1")}
            ></button>
          </Tooltip>
        )}
        {isVisiblePC2 && (
          <Tooltip title="Открыть/закрыть консоль" arrow>
            <button
              className="pc"
              id="pc2"
              onClick={() => handleButtonClick("window2")}
            ></button>
          </Tooltip>
        )}
        {isVisibleLine1 && <div className="line" id="line1"></div>}
        {isVisibleLine2 && <div className="line" id="line2"></div>}
      </div>
      {isVisibleWindow1 && (
        <div className="window1" style={{ width: "35%", height: "80%" }}>
          <div
            className={`modalContent1 ${
              consoleButtonClicked1 ? "console-background" : ""
            }`}
            style={{ width: "100%", height: "100%" }}
          >
            {isVisibleIpV4_1 && (
              <div className="ipv4">
                <input
                  type="text"
                  id="textIPv4PC1"
                  placeholder="192.168.1.1"
                  maxLength="15"
                  value={inputValuePC1}
                  onBlur={handleBlur1}
                  onChange={(event) => validateInputPC1(event)}
                ></input>
              </div>
            )}
            <button
              className="console-button"
              id="buttonConsolePC1"
              onClick={() => handleButtonClick("console")}
            ></button>
            {isVisibleConsole1 && (
              <div className="console">
                <textarea
                  type="text"
                  id="textConsolePC1"
                  placeholder="Введите..."
                  style={{
                    resize: "none",
                    width: "40%",
                    height: "50%",
                    fontSize: "calc(0.8vw)",
                  }}
                  value={inputValueConsole1}
                  onBlur={handleBlurConsole1}
                  onChange={(event) => handleConsoleChange("PC1", event)}
                ></textarea>
                <div id="messagePC1">{messagePC1}</div>
              </div>
            )}
            <button
              className="console-button"
              id="buttonIPv4PC1"
              onClick={() => handleButtonClick("ipv4")}
            ></button>
          </div>
        </div>
      )}
      {isVisibleWindow2 && (
        <div className="window2" style={{ width: "35%", height: "80%" }}>
          <div
            className={`modalContent2 ${
              consoleButtonClicked2 ? "console-background" : ""
            }`}
            style={{ width: "100%", height: "100%" }}
          >
            {isVisibleIpV4_2 && (
              <div className="ipv4">
                <input
                  type="text"
                  id="textIPv4PC2"
                  placeholder="192.168.1.1"
                  maxLength="15"
                  value={inputValuePC2}
                  onBlur={handleBlur2}
                  onChange={(event) => validateInputPC2(event)}
                ></input>
              </div>
            )}
            <button
              className="console-button"
              id="buttonConsolePC2"
              onClick={() => handleButtonClick("console")}
            ></button>
            {isVisibleConsole2 && (
              <div className="console">
                <textarea
                  type="text"
                  id="textConsolePC2"
                  placeholder="Введите..."
                  style={{
                    resize: "none",
                    width: "40%",
                    height: "50%",
                    fontSize: "calc(0.8vw)",
                  }}
                  value={inputValueConsole2}
                  onBlur={handleBlurConsole2}
                  onChange={(event) => handleConsoleChange("PC2", event)}
                ></textarea>
                <div id="messagePC2">{messagePC2}</div>
              </div>
            )}
            <button
              className="console-button"
              id="buttonIPv4PC2"
              onClick={() => handleButtonClick("ipv4")}
            ></button>
          </div>
        </div>
      )}
    </Box>
  );
};

export default CiscoGame;
