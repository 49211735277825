import { Box, Button } from "@mui/material";
import "./style_prog.css";
import React, { useState, useRef } from "react";
import ProgrammingFon from "../../assets/BasicsOfProgramming/background_empty.png";
import lesson_programming_task_completed_correctly_lvl_1 from "../../../dialogues/data/imikn/lesson/lesson_programming/lesson_programming_task_completed_correctly_lvl_1";
import lesson_programming_task_not_completed_lvl_1 from "../../../dialogues/data/imikn/lesson/lesson_programming/lesson_programming_task_not_completed_lvl_1";

const ProgrammingGameLvl1 = ({
  setIsDialogue,
  setCurrentStories,
  setProgrammingLvl1,
}) => {
  const [draggedItem, setDraggedItem] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleClicked, setModalVisibleClicked] = useState(false);
  const [selectedButton, setSelectedButton] = useState("white");
  const [highlightedDropZone, setHighlightedDropZone] = useState(null);
  const allowMultipleZoneRef = useRef(null);

  const dropZones = document.querySelectorAll(".dropZone");

  const openModal = (buttonId) => {
    if (!modalVisible) {
      setModalVisible(true);
      setModalVisibleClicked(true);
      setSelectedButton(buttonId);
    } else {
      setModalVisible(false);
      setModalVisibleClicked(false);
      setSelectedButton(null);
    }
  };
  const getButtonColor = (buttonId) => {
    switch (buttonId) {
      case "redButton":
        if (getButtonColorRed() === "red") {
          setSelectedButton("red");
        } else if (getButtonColorRed() === "yellow") {
          setSelectedButton("yellow");
        } else if (getButtonColorRed() === "green") {
          setSelectedButton("green");
        }
        break;
      case "yellowButton":
        if (getButtonColorYellow() === "red") {
          setSelectedButton("red");
        } else if (getButtonColorYellow() === "yellow") {
          setSelectedButton("yellow");
        } else if (getButtonColorYellow() === "green") {
          setSelectedButton("green");
        }
        break;
      case "greenButton":
        if (getButtonColorGreen() === "red") {
          setSelectedButton("red");
        } else if (getButtonColorGreen() === "yellow") {
          setSelectedButton("yellow");
        } else if (getButtonColorGreen() === "green") {
          setSelectedButton("green");
        }
        break;
      default:
        setSelectedButton("white");
        break;
    }
  };
  const getButtonColorRed = () => {
    const redCode = document.getElementById("red");
    if (redCode.classList.contains("red")) {
      return "red";
    } else if (redCode.classList.contains("yellow")) {
      return "yellow";
    } else if (redCode.classList.contains("green")) {
      return "green";
    } else return "white";
  };

  const getButtonColorYellow = () => {
    const yellowCode = document.getElementById("yellow");
    if (yellowCode.classList.contains("red")) {
      return "red";
    } else if (yellowCode.classList.contains("yellow")) {
      return "yellow";
    } else if (yellowCode.classList.contains("green")) {
      return "green";
    } else return "white";
  };
  const getButtonColorGreen = () => {
    const greenCode = document.getElementById("green");
    if (greenCode.classList.contains("red")) {
      return "red";
    } else if (greenCode.classList.contains("yellow")) {
      return "yellow";
    } else if (greenCode.classList.contains("green")) {
      return "green";
    } else return "white";
  };

  const handleDragStart = (event) => {
    if (event.target.classList.contains("dragItem")) {
      event.target.classList.add("dragItem--active");
      setDraggedItem(event.target);
    }
  };

  const handleDragEnd = (event) => {
    if (!event.target.classList.contains("dropZone")) {
      event.target.classList.remove("dragItem--active");
      setDraggedItem(null);
    }
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    const targetDropZone = event.target;

    if (!targetDropZone.classList.contains("allow-multiple-items")) {
      targetDropZone.classList.add("dropZone--active");
      setHighlightedDropZone(targetDropZone);
    }
  };

  const handleDragLeave = (event) => {
    event.target.classList.remove("dropZone--active");
    setHighlightedDropZone(null);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const colors = ["red", "yellow", "green"];
    const isAllowMultipleItems = event.target.classList.contains(
      "allow-multiple-items"
    );

    if (!draggedItem || !draggedItem.classList.contains("dragItem")) {
      dropZones.forEach((zone) => zone.classList.remove("dropZone--active"));
      return;
    }

    const targetDropZone = event.target;
    if (
      targetDropZone.classList.contains("codeZone") &&
      targetDropZone.children.length > 0
    ) {
      const existingItem = targetDropZone.children[0];

      const allowMultipleZone = document.querySelector(".allow-multiple-items");
      allowMultipleZone.appendChild(existingItem);
      targetDropZone.appendChild(draggedItem);

      for (const color of colors) {
        targetDropZone.classList.remove(color);
      }

      for (const color of colors) {
        if (draggedItem.classList.contains(`${color}Code`)) {
          targetDropZone.classList.add(color);
          setSelectedButton(color.toLowerCase());
          break;
        }
      }
    } else {
      const previousDropZone = draggedItem.parentElement;

      if (previousDropZone) {
        previousDropZone.classList.remove("draggedItem");

        for (const color of colors) {
          if (previousDropZone.classList.contains(color)) {
            previousDropZone.classList.remove(color);
          }
        }
      }

      for (const color of colors) {
        if (
          (draggedItem.classList.contains(`${color}Code`) &&
            targetDropZone.classList.contains("buttonZone")) ||
          (draggedItem.classList.contains(`${color}Button`) &&
            targetDropZone.classList.contains("codeZone"))
        ) {
          dropZones.forEach((zone) =>
            zone.classList.remove("dropZone--active")
          );
          return;
        }
      }

      if (
        isAllowMultipleItems ||
        targetDropZone === draggedItem.parentElement
      ) {
        targetDropZone.appendChild(draggedItem);
      } else if (targetDropZone.children.length === 0) {
        targetDropZone.appendChild(draggedItem);
      } else {
        const allowMultipleZone = document.querySelector(
          ".allow-multiple-items"
        );
        const existingItem = targetDropZone.children[0];
        allowMultipleZone.appendChild(existingItem);
        targetDropZone.appendChild(draggedItem);
      }

      for (const color of colors) {
        targetDropZone.classList.remove(color);
      }

      for (const color of colors) {
        if (draggedItem.classList.contains(`${color}Code`)) {
          targetDropZone.classList.add(color);
          setSelectedButton(color.toLowerCase());
          break;
        }
      }

      if (!colors.some((color) => targetDropZone.classList.contains(color))) {
        targetDropZone.classList.add("default");
      }

      if (highlightedDropZone === null) {
        allowMultipleZoneRef.current.appendChild(draggedItem);
      }

      if (highlightedDropZone) {
        highlightedDropZone.classList.remove("dropZone--active");
        setHighlightedDropZone(null);
      }
    }
    dropZones.forEach((zone) => zone.classList.remove("dropZone--active"));
  };

  const handleDoneWork = () => {
    const redCode = document.getElementById("red");
    const yellowCode = document.getElementById("yellow");
    const greenCode = document.getElementById("green");

    if (
      redCode.classList.contains("red") &&
      yellowCode.classList.contains("yellow") &&
      greenCode.classList.contains("green")
    ) {
      const lesson = JSON.parse(localStorage.getItem("lesson"));
      lesson.programming_lesson.isVisited = true;
      lesson.programming_lesson.programmingLvl1_completed = true;
      localStorage.setItem("lesson", JSON.stringify(lesson));
      setProgrammingLvl1(false);
      setIsDialogue(true);
      setCurrentStories(lesson_programming_task_completed_correctly_lvl_1);
    } else {
      const lesson = JSON.parse(localStorage.getItem("lesson"));
      lesson.programming_lesson.isVisited = true;
      localStorage.setItem("lesson", JSON.stringify(lesson));
      setProgrammingLvl1(false);
      setIsDialogue(true);
      setCurrentStories(lesson_programming_task_not_completed_lvl_1);
    }
  };

  return (
    <Box>
      <img
        src={ProgrammingFon}
        alt=""
        style={{ width: "100%", height: "100%" }}
        draggable={false}
      />
      <div
        ref={allowMultipleZoneRef}
        draggable={false}
        className="dropZone d-flex allow-multiple-items"
        data-zone="1"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {Array.from({ length: 3 }).map((_, index) => (
          <div
            key={index}
            className={`dragItem ${
              index === 0 ? "redCode" : index === 1 ? "yellowCode" : "greenCode"
            }`}
            draggable={true}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            data-item={index + 1}
          ></div>
        ))}
      </div>
      <div
        className="dropZone codeZone"
        id="red"
        draggable={false}
        data-zone="2"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      ></div>
      <div
        className="dropZone codeZone"
        id="yellow"
        draggable={false}
        data-zone="3"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      ></div>
      <div
        className="dropZone codeZone"
        id="green"
        draggable={false}
        data-zone="4"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      ></div>

      <button
        className={`image-button openButton ${
          modalVisibleClicked ? "modalOpen" : ""
        }`}
        onClick={() => openModal()}
      ></button>
      <Button
        variant="outlined"
        id="doneWork"
        draggable={false}
        onClick={handleDoneWork}
        sx={{
          position: "absolute",
          top: "82%",
          right: "10%",
          fontSize: "calc( 0.6vw)",
        }}
      >
        Завершить работу
      </Button>
      {modalVisible && (
        <div className="modalWindow">
          <div
            className="modal-content"
            id="modalContent"
            style={{
              backgroundImage: selectedButton
                ? `url(${require(`../../assets/BasicsOfProgramming/form_empty_${selectedButton}.png`)})`
                : `url(${require("../../assets/BasicsOfProgramming/form_empty_white.png")})`,
            }}
          >
            <button
              id="closeModalButton"
              className="image-button closeModal"
              onClick={() => openModal()}
            ></button>
            <button
              id="redButton"
              className="image-button targetElement"
              onClick={() => getButtonColor("redButton")}
            ></button>
            <button
              id="yellowButton"
              className="image-button targetElement"
              onClick={() => getButtonColor("yellowButton")}
            ></button>
            <button
              id="greenButton"
              className="image-button targetElement"
              onClick={() => getButtonColor("greenButton")}
            ></button>
          </div>
        </div>
      )}
    </Box>
  );
};

export default ProgrammingGameLvl1;
