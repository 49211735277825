import * as React from "react";
import Container from "@mui/material/Container";

import ALEKSANDRA_NPC from "../../Assets/imikn/character/aleksandra_npc.png";
import ANAS_SULEIM_SMIRK from "../../Assets/imikn/character/anas_suleim_smirk.png";
import OLGA_NPC from "../../Assets/imikn/character/olga_npc.png";
import POPOV_NPC_MASK from "../../Assets/imikn/character/popov_npc_mask.png";
import SLEEPYHEAD from "../../Assets/imikn/character/sleepyhead.png";
import GUIDE from "../../Assets/imikn/character/guide.png";
import { useTheme } from "@mui/material/styles";

import "./character.css";

export default function Character() {
  const theme = useTheme();
  return (
    <>
      <Container component="main" maxWidth="lg" sx={{ padding: 7 }}>
        <div className="container">
          <div
            className="box box-1"
            style={{ "--img": `url(${GUIDE})` }}
            data-text="Богдан"
          ></div>
          <div
            className="box box-2"
            style={{ "--img": `url(${ALEKSANDRA_NPC})` }}
            data-text="Александра"
          ></div>
          <div
            className="box box-3"
            style={{ "--img": `url(${ANAS_SULEIM_SMIRK})` }}
            data-text="Анастасия Сулеймановна"
          ></div>
          <div
            className="box box-4"
            style={{ "--img": `url(${OLGA_NPC})` }}
            data-text="Ольга"
          ></div>
          <div
            className="box box-5"
            style={{ "--img": `url(${POPOV_NPC_MASK})` }}
            data-text="Попов"
          ></div>
          <div
            className="box box-6"
            style={{ "--img": `url(${SLEEPYHEAD})` }}
            data-text="Спящий чел"
          ></div>
        </div>
      </Container>
    </>
  );
}
