import { Box } from "@mui/material";
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import TransitionLines from "../../assets/Lesson/transition_lines.png";
import {
  checkVisibleLesson,
  checkVisibleProjectProtection,
} from "../../../../Components/dialogueSystem/scripts/VisibleLesson";

export default function LessonDoor(props) {
  const handleClick = (event, message) => {
    props.onLessonClick(message);
  };

  const doors = [
    {
      title: checkVisibleLesson("lecture")
        ? "Лекционная аудитория. Пара по сетям"
        : "Похоже - заперто! Попробуй разузнать об этом месте у кого-нибудь",
      placement: "top-end",
      img: require("../../assets/Lesson/door_01.png"),
      imgOpen: require("../../assets/Lesson/door_01_open.png"),
      position: {
        left: "2.5%",
        top: "17.1%",
      },
      cursor: checkVisibleLesson("lecture") ? "pointer" : "default",
      message: "lecture",
      onClick: checkVisibleLesson("lecture")
        ? (event) => handleClick(event, "lecture")
        : null,
    },
    {
      title: checkVisibleLesson("networking_lesson")
        ? "Практическая аудитория 301. Пара по сетям"
        : "Похоже - заперто! Попробуй разузнать об этом месте у кого-нибудь",
      placement: "top-end",
      img: require("../../assets/Lesson/door_02.png"),
      imgOpen: require("../../assets/Lesson/door_02_open.png"),
      position: {
        left: "28%",
        top: "17.1%",
      },
      cursor: checkVisibleLesson("networking_lesson") ? "pointer" : "default",
      message: "networking_lesson",
      onClick: checkVisibleLesson("networking_lesson")
        ? (event) => handleClick(event, "networking_lesson")
        : null,
    },
    {
      title: checkVisibleLesson("programming_lesson")
        ? "Практическая аудитория 411. Пара по программированию"
        : "Похоже - заперто! Попробуй разузнать об этом месте у кого-нибудь",
      placement: "top-end",
      img: require("../../assets/Lesson/door_03.png"),
      imgOpen: require("../../assets/Lesson/door_03_open.png"),
      position: {
        left: "53%",
        top: "17.1%",
      },
      cursor: checkVisibleLesson("programming_lesson") ? "pointer" : "default",
      message: "programming_lesson",
      onClick: checkVisibleLesson("programming_lesson")
        ? (event) => handleClick(event, "programming_lesson")
        : null,
    },
    {
      title: checkVisibleProjectProtection()
        ? "Конференц зал 410"
        : "Похоже - заперто! Попробуй разузнать об этом месте у кого-нибудь",
      placement: "top-end",
      img: require("../../assets/Lesson/door_04.png"),
      imgOpen: require("../../assets/Lesson/door_04_open.png"),
      position: {
        left: "78%",
        top: "15.5%",
      },
      cursor: checkVisibleProjectProtection() ? "pointer" : "default",
      message: "project_protection",
      onClick: checkVisibleProjectProtection()
        ? (event) => handleClick(event, "project_protection")
        : null,
    },
  ];
  const [doorState, setDoorState] = useState(doors);

  const handleMouseEnter = (index) => {
    const updatedDoors = [...doorState];
    updatedDoors[index].isHovered = true;
    setDoorState(updatedDoors);
  };

  const handleMouseLeave = (index) => {
    const updatedDoors = [...doorState];
    updatedDoors[index].isHovered = false;
    setDoorState(updatedDoors);
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <img
        src={TransitionLines}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
      {doorState.map((door, index) => (
        <Tooltip key={index} title={door.title} placement={door.placement}>
          <img
            key={door.message}
            src={
              checkVisibleLesson(door.message)
                ? door.isHovered
                  ? door.imgOpen
                  : door.img
                : door.img
            }
            style={{
              position: "absolute",
              cursor: door.cursor,
              width: "20%",
              ...door.position,
              top: checkVisibleLesson(door.message)
                ? door.isHovered
                  ? "16.9%"
                  : door.position.top
                : door.position.top,
            }}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
            onClick={door.onClick}
          />
        </Tooltip>
      ))}
    </Box>
  );
}
