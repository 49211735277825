import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import React, { useState, useEffect } from "react";
import AuthUser from "../../../../Services/AuthUser";
import Heading from "../../../../Components/adminpanel/Heading";
import AddInstituteModal from "./Add";
import EditInstituteModal from "./Edit";

export default function ListIndex() {
  const [openDialog, setOpenDialog] = useState(false);

  const buttonInfo = {
    value: "Добавить",
    onClick: () => setOpenDialog(true),
  };

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "inst_name", headerName: "Название", width: 200, flex: 1 },
    {
      field: "actions",
      type: "actions",
      width: 80,
      flex: 0,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => editInstitute(params.id)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => deleteInstitute(params.id)}
        />,
      ],
    },
  ];


  const [institutes, setInstitutes] = useState([]);
  const { token, http } = AuthUser();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await http.get("/institutes", {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
      // console.log(response.data);
        setInstitutes(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);


  const [selectedInstitute, setSelectedInstitute] = useState(null);

  const editInstitute = (id) => {
    const institute = institutes.find((inst) => inst.id === id);
    setSelectedInstitute(institute);
  };

  const deleteInstitute = async (id) => {
    try {
      await http.delete(`/institutes/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setInstitutes((prevInstitutes) =>
        prevInstitutes.filter((institute) => institute.id !== id)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddInstitute = async () => {
    try {
      const response = await http.get("/institutes", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setInstitutes(response.data);
      setOpenDialog(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Heading title="Инстуты" button={buttonInfo} />
      <AddInstituteModal
        open={openDialog}
        close={() => setOpenDialog(false)}
        handleAddInstitute={handleAddInstitute}
      />
      <Box sx={{ width: "100%", mt: 3 }}>
        <DataGrid rows={institutes} columns={columns} />
      </Box>
      <EditInstituteModal open={Boolean(selectedInstitute)} close={() => setSelectedInstitute(null)} institute={selectedInstitute} setInstitutes={setInstitutes} />    </>
  );
}
