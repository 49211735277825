import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import AuthUser from "../../Services/AuthUser";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTheme } from "@mui/material/styles";

const genderOptions = [
  { value: 0, label: "Мужской" },
  { value: 1, label: "Женский" },
];

function CardDataAccount(props) {
  const theme = useTheme();
  const [localUser, setLocalUser] = useState(
    JSON.parse(localStorage.getItem("user"))
  );
  const [user, setUser] = useState({
    username: props.dataUser.username,
    nickname: props.dataUser.nickname,
    age: props.dataUser.age,
    gender: props.dataUser.gender,
    city: props.dataUser.city,
  });
  const [loading, setLoading] = React.useState(false);

  function getGenderString() {
    if (user.gender === 0) {
      return "Мужской";
    } else {
      return "Женский";
    }
  }

  const handleUsernameChange = (event) => {
    setUser({ ...user, username: event.target.value });
  };

  const handleNicknameChange = (event) => {
    setUser({ ...user, nickname: event.target.value });
  };

  const handleAgeChange = (event) => {
    setUser({ ...user, age: event.target.value });
  };

  const handleGenderChange = (event) => {
    setUser({ ...user, gender: event.target.value });
  };

  const handleCityChange = (event) => {
    setUser({ ...user, city: event.target.value });
  };
  const { http } = AuthUser();
  const [errors, setErrors] = useState({});

  const handleSaveClick = (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      let data = {
        username: user.username,
        gender: user.gender,
      };
      if (user.nickname) {
        data.nickname = user.nickname;
      }
      if (user.age) {
        data.age = user.age;
      }
      if (user.city) {
        data.city = user.city;
      }
      // console.log(data.user);
      http
        .post(`/update-player/${localUser.id}`, data)
        .then((response) => {
          if (response.data.status === 200) {
            // console.log(response.data.message);
            props.onSave(user);
            setIsEditing(false);
            handleSetLocalStorageUser();
          } else {
            setErrors(response.data.validate_err);
            // console.log(response);
          }
        })
        .catch((error) => {
          setErrors(error.response.data.validate_err);
        });
    } catch (error) {
      setErrors(error.response.data.errors);
    } finally {
      setLoading(false);
    }
  };
  const [isEditing, setIsEditing] = useState(false); // добавляем состояние isEditing

  const handleEditClick = () => {
    setIsEditing(true); // устанавливаем состояние isEditing в true при нажатии на кнопку "Edit"
  };

  const handleCancelClick = () => {
    setUser(localUser);
    setIsEditing(false);
  };

  const handleSetLocalStorageUser = () => {
    const tempDataUser = JSON.parse(localStorage.user);
    tempDataUser.username = user.username;
    tempDataUser.nickname = user.nickname;
    tempDataUser.age = user.age;
    tempDataUser.gender = user.gender;
    tempDataUser.city = user.city;
    // console.log(tempDataUser);
    localStorage.setItem("user", JSON.stringify(tempDataUser));
  };
  return (
    <Card
      sx={{
        background: theme?.palette?.box.main,
        boxShadow: `0px 0px 15px 2px ${theme?.palette?.box.shadow}`,
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} container alignItems="center">
            <Typography variant="body" component="h6">
              Username
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {isEditing ? (
              <TextField
                variant="outlined"
                size="small"
                value={user.username}
                onChange={handleUsernameChange}
                error={errors && errors.username ? true : false}
                helperText={errors && errors.username ? errors.username : ""}
              />
            ) : (
              <Typography variant="body" component="p">
                {user.username}
              </Typography>
            )}
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} container alignItems="center">
            <Typography variant="body" component="h6">
              Nickname
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {isEditing ? (
              <TextField
                variant="outlined"
                size="small"
                value={user.nickname}
                onChange={handleNicknameChange}
                error={errors && errors.nickname ? true : false}
                helperText={errors && errors.nickname ? errors.nickname : ""}
              />
            ) : (
              <Typography variant="body" component="p">
                {user.nickname}
              </Typography>
            )}
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} container alignItems="center">
            <Typography variant="body" component="h6">
              Возраст
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {isEditing ? (
              <TextField
                variant="outlined"
                size="small"
                value={user.age}
                onChange={handleAgeChange}
              />
            ) : (
              <Typography variant="body" component="p">
                {user.age}
              </Typography>
            )}
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} container alignItems="center">
            <Typography variant="body" component="h6">
              Пол
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {isEditing ? (
              //   <TextField
              //     variant="outlined"
              //     size="small"
              //     value={user.gender}
              //     onChange={handleGenderChange}
              //   />
              <Select
                label="Пол"
                name="gender"
                value={user.gender}
                onChange={handleGenderChange}
                size="small"
              >
                {genderOptions.map((option, i) => (
                  <MenuItem key={i} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Typography variant="body" component="p">
                {getGenderString()}
              </Typography>
            )}
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} container alignItems="center">
            <Typography variant="body" component="h6">
              Город
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {isEditing ? (
              <TextField
                variant="outlined"
                size="small"
                value={user.city}
                onChange={handleCityChange}
              />
            ) : (
              <Typography variant="body" component="p">
                {user.city}
              </Typography>
            )}
          </Grid>
        </Grid>
        <hr />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {isEditing ? (
            <>
              <Button
                variant="contained"
                size="small"
                onClick={handleCancelClick}
                sx={{
                  mr: 1,
                  boxShadow: `0px 0px 15px 2px ${theme?.palette?.box.shadow}`,
                }}
              >
                Отмена
              </Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                size="small"
                onClick={handleSaveClick}
              >
                Сохранить
              </LoadingButton>
            </>
          ) : (
            <Button
              variant="contained"
              size="small"
              onClick={handleEditClick}
              // sx={{
              //   boxShadow: `0px 0px 15px 2px ${theme?.palette?.box.shadow}`,
              // }}
            >
              Редактировать
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
export default CardDataAccount;
