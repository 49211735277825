import {
    getSpriteCharacterOfGender,
    getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";
import { setVisibleTeacher } from "../../../../../Components/dialogueSystem/scripts/VisibleTeacher";

// background
const imikn_moiais = require("../../../assets/imikn/backgrounds/imikn_moiais.jpg");

// character
const guide_default = require("../../../assets/imikn/characters/guide.png");
const guide_smile = require("../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let direction_moiais = {
    Start: [
        {
            body: [
                {
                    background: imikn_moiais,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Смотри, на всех остальных направлениях примерно понятно по какой профессии можно работать после выпуска",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А фишка этого направления, что здесь список специальностей очень обширный и бывает, даже не понимаешь, кем выпустишься",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Как так? Можно работать кем угодно?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Практически кем угодно в сфере IT",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Раньше это направление было специалитетом",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "То есть учились на полтора года больше и вникали в предметы более глубоко, сейчас же ситуация изменилась и теперь это бакалавриат",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Тут преподают дисциплины из разных областей информационных технологий, не сильно углубляясь",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Сделано это для того, чтобы студент мог попробовать всё и выбрать ту сферу, которая более интересна конкретно для него",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А изучить досконально можно и самостоятельно",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Ого, звучит логично",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Базой тут является, как и на большинстве направлений - высшая математика в больших количествах",
                    achiev_child_id: 8, // 1.6
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    rightSprite: guide_smile,
                    text: "Надеюсь тебя это не пугает?",
                },
            ],
        },
        {
            choices: [
                "Нет, конечно, ради этого я здесь!|direction_moiais_funny",
                "Ну, у нас с математикой напряжённые отношения|direction_moiais_difficult",
            ],
        },
    ],

    direction_moiais_difficult: [
        {
            body: [
                {
                    background: imikn_moiais,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Тогда даже не знаю...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Если не исправить их сейчас, то возможно стоит задуматься о другом направлении...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Или проявить стойкость, выдержку и терпение для освоения этой специальности",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_moiais_continue",
        },
    ],

    direction_moiais_funny: [
        {
            body: [
                {
                    background: imikn_moiais,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Отлично!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ведь на МОиАИС изучается обширный список математических предметов",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_moiais_continue",
        },
    ],

    direction_moiais_continue: [
        {
            body: [
                {
                    background: imikn_moiais,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Понимаешь, для некоторых областей программирования, например, веб-программирования необходима только простейшая математика",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Поэтому от специалистов данной сферы можно услышать, что им и без неё прекрасно живётся",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Но эта “свобода” рушится, когда дело касается области больших данных, машинного обучения, компьютерного зрения... и чего-то ещё...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Забыл... А!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Искусственного интеллекта, статистики и анализа данных!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Также на основе высшей математики построены очень многие алгоритмы, используемые в других направлениях IT",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А что, всё это изучается на данном направлении?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    rightSprite: guide_smile,
                    text: "Да! Это самое удивительное!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Если хочешь, я могу рассказать тебе подробнее. Когда-то давно я и сам учился здесь, знаю разные тонкости и нюансы. Что думаешь?",
                },
            ],
        },
        {
            choices: [
                "С радостью послушаю!|direction_moiais_long_story",
                "Давай в другой раз, у меня не так много времени|direction_moiais_short_story",
            ],
        },
    ],

    direction_moiais_long_story: [
        {
            body: [
                {
                    background: imikn_moiais,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Отлично! Программирования тут очень много, ты научишься «программировать математику»!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Казалось бы, это просто переносить формулы из учебника в код, но нет! Там всё куда серьёзнее, чем кажется",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Но не буду пугать тебя заранее... Всему своё время!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Как я уже говорил, математика здесь точно пригодится!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Много времени будет посвящено Data Science, то есть науке о данных",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "В неё входит математика и статистика, машинное обучение, информатика, разработка программного обеспечения",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Знания бизнеса и доменной области, а также традиционные научные исследования",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Если ты поступишь сюда, то будешь проектировать пользовательский интерфейс, сможешь создать своё первое оконное приложение",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Где будет куча кнопочек, картинок, разных переключателей, анимаций и многое другое",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Как только ты разберешься с простым приложением",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "То сможешь отточить свои уже имеющиеся навыки и получить новый опыт в добавлении в свои приложения базы данных",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Для хранения и управления информацией, интеграции разных языков программирования и, представь, сможешь создать свой собственный фотошоп!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Что-о-о, Ф-О-Т-О-Ш-О-П?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Да, да... фотошоп!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "На одной из дисциплин тебе будут рассказывать про то, как устроено изображение, как работает тот или иной фильтр",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "И любая другая обработка изображения",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "На самом деле это действительно интересно",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Причем ты будешь это делать как говорится: «Ручками, все ручками», позже расскажу, что это значит",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Также интересным, на мой взгляд, будет предмет «Алгоритмы параллельных вычислений»",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Если у тебя процессор имеет несколько ядер, то ты сможешь разбить свою программу на несколько потоков",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Звучит сложно...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну ничего, сейчас тебе не обязательно это понимать, но представлять о своих возможностях после прохождения курса никто не запрещал...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А если простыми словами, то кусочки твоей программы будут выполняться практически одновременно, благодаря распараллеливанию!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Какой-то маховик времени получается!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну почти...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ещё, тебя научат обслуживать вычислительные машины - сервера, компьютерные сети и так далее",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "На эту тему будет много лекций и практик",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Где вы будете осуществлять разные ситуации, как в реальном времени, так и в виртуальном пространстве Циско",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_moiais_continue_2",
        },
    ],

    direction_moiais_short_story: [
        {
            body: [
                {
                    background: imikn_moiais,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Хорошо, тогда расскажу вкратце",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Основной упор здесь ставится на Data Science, то есть науку о данных",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "В неё входит математика и статистика, машинное обучение, информатика, разработка программного обеспечения",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Знания бизнеса и доменной области, а также традиционные научные исследования",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Помимо этого студент МОиАИС изучает базы данных, основы веб- и мобильного программирования",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Основы администрирования и обслуживания информационных систем, то есть компьютерных сетей, серверов и так далее",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_moiais_continue_2",
        },
    ],

    direction_moiais_continue_2: [
        {
            body: [
                {
                    background: imikn_moiais,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Немаловажной частью обучения также является научная деятельность",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А это как? Когда делаешь открытие?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну, не всегда именно открытие, но близко",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Все открытия совершались не за один день и не просто так",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Им предшествовали долгие годы исследований, изучения науки, проведение экспериментов и подобного",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Так и здесь, студенты придумывают темы проектов, изучают предметную область, экспериментируют",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А дальше пишут научные статьи о полученных результатах и выступают на конференциях, чтобы поделиться с миром своим опытом",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Студенты занимаются этим самостоятельно?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Конечно нет, в последнее время такие работы выполняются в команде из нескольких человек",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Но даже если студент делает проект в одиночку, то к нему обязательно прикрепляется научный руководитель, знаешь кто это?",
                },
            ],
        },
        {
            choices: [
                "Да|direction_moiais_yes",
                "Нет|direction_moiais_no",
            ],
        },
    ],

    direction_moiais_yes: [
        {
            body: [
                {
                    background: imikn_moiais,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну вот и славненько!",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_moiais_exit",
        },
    ],
    
    direction_moiais_no: [
        {
            body: [
                {
                    background: imikn_moiais,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Научный руководитель - это преподаватель, который отвечает за научную работу или проект определённого студента или команды студентов",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "К нему можно обращаться по любым вопросам, связанным с работой",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Напомню, если ты встретил какое-то незнакомое слово или фразу, ты всегда можешь посмотреть определение в Азбуке",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Хорошо, только зачем? Ты же мне всё объясняешь!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А вдруг я исчезну-у-у-у-у...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    rightSprite: guide_smile,
                    text: "Ха-ха-ха, да я шучу! Вдруг забудешь, а я два раза не повторяю!",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_moiais_exit",
        },
    ],

    direction_moiais_exit: [
        {
            body: [
                {
                    background: imikn_moiais,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "В общем, научные руководители бывают разные - кто-то всегда рад помочь, а кто-то не хочет видеть своих студентов и прячется от них",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "В Книге студентов даже есть история на эту тему - “Поймать научрука” называется, советую почитать...",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Интересно, надо потом туда зайти",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А у тебя был научный руководитель?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    rightSprite: guide_smile,
                    text: "Конечно! Если будет желание, то ты даже можешь с ним сегодня даже побеседовать!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это Анастасия Сулеймановна - преподаватель, ответственная за направление МОиАИС",
                    visibleTeacher: "anas_suleim",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Здорово! Похоже сегодня не очень загруженный день, раз преподаватели могут уделить мне время...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А как же, я постарался!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну что ж, вернёмся к нашей теме",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    rightSprite: guide_default,
                    text: "Ах да, научная деятельность! Стоит заметить, что это очень полезно, как для студента, так и для науки",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А ещё, помимо ценных знаний студент может получить повышенную стипендию за свои труды, имей это в виду!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Это сколько?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Чтобы не соврать, не скажу",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Довольно часто эта цифра меняется, лучше “погугли” потом: “Стипендии ТюмГУ” и увидишь актуальные цифры!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Забыл совсем сказать о том, что на данном направлении есть ещё одна особенность",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Преподаватели разрешают пользоваться готовыми решениями и технологиями только под конец учёбы...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А до этого, будь добр, «пиши всё ручками», как говорится!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А что это значит?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну смотри, допустим двадцать лет назад кто-то придумал суперклассный алгоритм, который применяется сейчас абсолютно везде",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Изобретатель написал код и «упаковал его в пакетик», сделал из него, так называемую, «библиотеку»",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это значит, что другому человеку не нужно писать алгоритм с нуля",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Он просто устанавливает библиотеку и в паре строчек кода использует ее, не вдаваясь в подробности работы и реализации метода...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А «писать ручками» значит досконально разобраться в принципе действия алгоритма и написать его с нуля!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Ух! Наверное, это очень сложно...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Поначалу да. А потом уже не так тяжело, но ты не представляешь, как это полезно!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну и напоследок о работе",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Выпускники МОиАИС могут пойти работать программистами-разработчиками в мобильное и веб-программирование (в основном бэкенд)",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Администраторами баз данных и компьютерных сетей, педагогами и преподавателями математики и информатики",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Системными администраторами, специалистами по нейросетям, а главное - Data Scientist-ами",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Если ты действительно хочешь писать код, разрабатывать что-то новое",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Заниматься администрированием и работать с современными IT-технологиями, то это направление точно для тебя!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А ещё, похоже “если не знаешь в какую область IT пойти”...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    rightSprite: guide_smile,
                    text: "И это тоже, видимо наш разговор не прошёл впустую! А теперь давай пойдём дальше...",
                },
            ],
        },
        {
            exits: true,
        },
    ],
};

export default direction_moiais;
