import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import AuthUser from "../../../../Services/AuthUser";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import Heading from "../../../../Components/adminpanel/Heading";
import EditFeedbackModal from "./Edit";

export default function ListIndex() {
  const [feedbacks, setFeedbacks] = useState([]);
  const { token, http } = AuthUser();
  const [selectedFeedback, setSelectedFeedback] = useState(null);

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "feed_user", headerName: "Пользователь", width: 200, flex: 0.2 },
    { field: "feed_name", headerName: "Название", width: 200, flex: 0.3 },
    { field: "feed_text", headerName: "Название", width: 200, flex: 0.5 },
    { field: "created_at", headerName: "Дата", width: 200 },
    {
      field: "actions",
      type: "actions",
      width: 80,
      flex: 0,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<RemoveRedEyeIcon />}
          label="see"
          onClick={() => editFeedback(params.id)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => deleteFeedback(params.id)}
        />,
      ],
    },
  ];

  useEffect(() => {
    const getFeedbacks = async () => {
      const response = await http.get("/feedbacks", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data);
      setFeedbacks(response.data);
    };
    getFeedbacks();
  }, []);

  const editFeedback = (id) => {
    const feedback = feedbacks.find((feed) => feed.id === id);
    setSelectedFeedback(feedback);
  };

  const deleteFeedback = async (id) => {
    try {
      await http.delete(`/feedbacks/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setFeedbacks((prevtFeedbacks) =>
        prevtFeedbacks.filter((feedback) => feedback.id !== id)
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Heading title="Обратная связь" />
      <Box sx={{ mt: 3 }}>
        <DataGrid rows={feedbacks} columns={columns} />
      </Box>
      <EditFeedbackModal
        open={Boolean(selectedFeedback)}
        close={() => setSelectedFeedback(null)}
        feedback={selectedFeedback}
        setFeedbacks={setFeedbacks}
      />
    </>
  );
}
