import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_practice_01 = require("../../../../assets/imikn/backgrounds/practice 1/imikn_practice_01_students.jpg");

// character
const dobr_luc = require("../../../../assets/imikn/characters/dobr_luc.png");
const dobr_luc_smile = require("../../../../assets/imikn/characters/dobr_luc_smile.png");
const dobr_luc_angry = require("../../../../assets/imikn/characters/dobr_luc_angry.png");
const sleepyhead = require("../../../../assets/imikn/characters/sleepyhead.png");
const sleepyhead_sad = require("../../../../assets/imikn/characters/sleepyhead_sad.png");
const sleepyhead_sleeping = require("../../../../assets/imikn/characters/sleepyhead_sleeping.png");
const sleepyhead_woken = require("../../../../assets/imikn/characters/sleepyhead_woken.png");

//color
const player_color = "#D4D4D4";
const dobr_luc_color = "#D7DAED";
const sleepyhead_color = "#D2B4B4";
const student_color = "#CCDFFF";
const another_student_color = "#D8FFF6";

let lesson_networking_task_completed_correctly = {
  Start: [
    {
      body: [
        {
          background: imikn_practice_01,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: "",
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Добромир Люцианович, подойдите, пожалуйста!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Да, сейчас",
        },
        {
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Отлично! Безупречно правильно!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Урааа",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Хвалю, хвалю!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну-с, больше заданий у меня на примете нет, да и Вы устали наверняка...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Посидите, отдохните, пара уж скоро и закончится",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Хорошо, спасибо!",
          achiev_child_id: 24, // 3.9
        },
        {
          rightSprite: "",
          leftSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Как же лень все это делать... Может свалить? Сказать, что плохо себя чувствую... Как думаешь, прокатит?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Не знаю, я бы на твоём месте, все-таки хоть что-нибудь сделал",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Эта тема точно будет на контрольной, препод на прошлом занятии сам сказал",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Блинб... Не, всё-таки свалю... На улице такая погода классная, а мы тут сидим...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Добромир Люцианович! Можно уйти пораньше?",
        },
        {
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Что случилось?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Я себя плохо чувствую. Можно я домой пойду?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Пара скоро закончится, потерпи еще немного, тема важная как никак!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Эх...",
        },
        {
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Я же говорил...",
        },
        {
          leftSprite: sleepyhead_sleeping,
          rightSprite: dobr_luc_smile,
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "*громкий храп*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Баранов! Вы что же, опять уснули?",
        },
        {
          leftSprite: sleepyhead_woken,
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "А, что?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Чем же Вы ночью занимались, что спите весь день?",
        },
        {
          leftSprite: sleepyhead,
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "Так, лабу делал...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "И вместо того, чтобы сдавать, Вы решили поспать? Зачем же делали тогда?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "А, да, да, можно сдать?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Показывайте...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Так, тут понятно, тут странно сделано, но в принципе тоже подходит...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "А вот скажите, почему Вы здесь сделали именно так?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "Ну так придумал, да и в задании ничего про оптимизацию не было сказано...",
        },
        {
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Н-да, действительно... Самым простым способом, конечно...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "На работу Вас не взяли бы с такими знаниями...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "Да я знаю, как тут можно сделать лучше, мне просто лень было, ну и время поджимало, сами понимаете...",
        },
        {
          rightSprite: dobr_luc_angry,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Так у Вас неделя была на выполнение! Что значит время поджимало?!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Шесть дней в игры играли, а в последний вспомнили про задание? Типичные студенты!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Давайте, переделайте, чтобы всё красиво было!",
        },
        {
          leftSprite: sleepyhead_sad,
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "Хорошо-о-о *тяжёлый вздох*",
        },
        {
          leftSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Добромир Люцианович! Подойдите, пожалуйста!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Иду, иду",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Так... ага... стоп, не понял. Что это?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "А, ну это...",
        },
        {
          rightSprite: dobr_luc_angry,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "А это что? А здесь? Вы вообще это сами писали?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Д-да...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Понятно-понятно...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну вот подойдёте, когда сами выполните и сможете объяснить",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Но уже не на этой паре, видимо...",
        },
        {
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Итак, занятие окончено, можете быть свободными!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "До свидания!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Стойте, стойте, Вы же ключ брали, чтобы кабинет открыть?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Да",
        },
        {
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Значит это я Вам скажу до свидания, а Вы дождитесь пока все уйдут, закройте кабинет и сдайте его обратно!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Хорошо... Эхх, а хотел пораньше домой уйти...",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default lesson_networking_task_completed_correctly;
