import AuthUser from "../../Services/AuthUser";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../Components/header/Header";
import Footer from "../../Components/footer/Footer";
import CardDetails from "../../Components/achievement/CardDetails";

const AchievementDetails = () => {
  const { id, instName } = useParams();
  const { http } = AuthUser();
  const [achievements, setAchievements] = useState([]);
  const [achievement, setAchievement] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const getAchievements = async () => {
    try {
      const response = await http.get(
        `/achievement/${instName}/${user.id}`
      );
      setAchievements(response.data);
      sessionStorage.setItem("achievements", JSON.stringify(response.data));
      setAchievement(response.data.find((a) => a.id === parseInt(id)));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAchievements();
  }, []);
  // const achievements = JSON.parse(sessionStorage.getItem("achievements"));
  // const achievement = achievements.find((a) => a.id === parseInt(id));

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <CardDetails
          id={achievement.id}
          // img={require(`../../Assets/imikn/achievement/${achievement.achiev_img_name}`)}
          img={achievement && achievement.achiev_img_name ? require(`../../Assets/imikn/achievement/${achievement.achiev_img_name}`) : null}
          title={achievement.achiev_name}
          desc={achievement.achiev_description}
          isAchieved={achievement.is_issued}
          childs={achievement.achiev_child}
        />
        <Footer />
      </div>
    </>
  );
};

export default AchievementDetails;
