import "./style.css";
import Feed from "../../../Assets/HomePage/Feed.png";
import EvdokiaCircle from "../../../Assets/HomePage/evdokia_circle.png";
import CloudBlock from "../../../Assets/HomePage/cloud_block.png";
import { Container, Typography, Box } from "@mui/material";
import FormFeedback from "../../../Components/feedback/FormFeedback";
import Grid from "@mui/material/Grid";

export default function FormFeedbackBlock() {
  return (
    <Container maxWidth="lg">
      <Box container sx={{display:"flex", flexWrap: "wrap", flexBasis: "auto"}}>
        <Box sx={{ position: "relative", width:"60%",
        '@media (max-width: 1200px)': {
          height:"60vw",
          width:"100%",
        },} }>
          {/* <img
            src={Feed}
            style={{
              width: "100%",
            }}
          /> */}
          <img
            src={EvdokiaCircle}
            className="imgEvdokiaCircle"
          />
          <img
            src={CloudBlock}
            className="cloudBlock"
          />
          <Typography
            component="h5"
            variant="body3"
            sx={{
              position: "absolute",
              top: "16%",
              right: "5%",
              color: "black",
              width: "60%",
              fontSize: "1.3rem",
              textAlign: "center",
              '@media (max-width: 1470px) and (min-width: 1200px)': {
                width: "60%",
                top: "14%",
                right: "5%",
                fontSize:"1.4vw",
              },
              '@media (max-width: 1200px) and (min-width: 800px)': {
                top: "10%",
                fontSize:"1.9vw",
                width: "50%",
                right: "15%",
              },
              '@media (max-width: 800px)': {
                top: "8.5%",
                right: "17%",
                fontSize:"1.9vw",
                width: "45%",
              },
            }}
          >
            У вас есть возможность оставлять обратную связь по нашей игре и
            сайту. Вы можете делиться своими пожеланиями, замечаниями и
            предложениями, а также сообщать о каких-либо багах или проблемах.
            Если Вы хотите, чтобы мы смогли Вам ответить можете оставить свою
            почту
          </Typography>
        </Box>
        <Box sx={{ width:"40%", height:"100%", 
          '@media (max-width: 1200px)': {
            width:"100%",
          },}}>
          <FormFeedback alignItemsValue="end"/>
        </Box>
      </Box>
    
    </Container>
  );
}
