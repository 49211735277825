import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const department_place_04 = require("../../../assets/imikn/backgrounds/department_place_04.jpg");

// character
const guide_smile = require("../../../assets/imikn/characters/guide_smile.png");
const emm_stal = require("../../../assets/imikn/characters/emm_stal.png");
const emm_stal_smile = require("../../../assets/imikn/characters/emm_stal_smile.png");
const emm_stal_smile_hand_behide = require("../../../assets/imikn/characters/emm_stal_smile_hand_behide.png");
const emm_stal_phone = require("../../../assets/imikn/characters/emm_stal_phone.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";
const emm_stal_color = "#FEDCDD";

let teacher_emm_stal = {
  Start: [
    {
      body: [
        {
          background: department_place_04,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: emm_stal_smile,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "День добрый!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Здравствуйте!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Ну как Вам тут? Интересно?",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Да, очень! Не ответите на несколько моих вопросов?",
        },
        {
          rightSprite: emm_stal_phone,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Конечно, только через минутку, срочный звонок...",
        },
        {
          rightSprite: guide_smile,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Да уж, преподаватели связанные с информационной и компьютерной безопасностью все очень занятые и деловые...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Заметно",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Это вроде и хорошо, понимаешь, что преподают настоящие специалисты...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Но с другой стороны - они частенько заняты и из-за этого сложно поймать свободную минуту преподавателя для разговора...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Кстати, ты знаешь, что Эммануил Сталинович тоже учился в ТюмГУ? А ещё, он очень любит разные соревнования и олимпиады",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ого! Вот это новости...",
        },
        {
          rightSprite: emm_stal_smile,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "А вот и я! Рад, что у Вас хорошее настроение, просто светитесь!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Готов помочь, какие у Вас ко мне вопросы?",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Правда, что в студенческие времена Вы учились здесь?",
        },
        {
          rightSprite: emm_stal_smile_hand_behide,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Как говорится: где родился, там и пригодился, только в отношении места обучения и работы...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Мне приятно, что я могу помочь своей альма-матер!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Да и знаете, специалисты в моей области как горячие пирожки, поэтому достаточно трудно найти желающих работать преподавателем...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А как же Вы? Почему решили работать именно здесь?",
        },
        {
          rightSprite: emm_stal_smile,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "А кто Вам сказал, что я работаю ТОЛЬКО здесь?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "По секрету скажу, среди преподавателей компьютерной и информационной безопасности нет ни одного, кто бы работал в одном месте!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Поэтому они так часто говорят по телефону?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Именно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Это достаточно трудно, совмещать две работы, но, когда ты нужен и там, и там, расписание подстраивают под тебя, что очень удобно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Ну и отвечая на Ваш вопрос, мне нравится моя работа, но преподавательская деятельность тоже очень увлекательная!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Теперь всё понятно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Ещё вопросы?",
        },
      ],
    },
    {
      choices: [
        "Я абсолютно ничего не знаю про образовательные мероприятия, говорят у Вас большой опыт в этом деле, не поделитесь со мной?|emm_stal_question_1",
        "Вопросов нет!|emm_stal_exit",
      ],
    },
  ],

  emm_stal_question_1: [
    {
      body: [
        {
          background: department_place_04,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: emm_stal,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Ну смотрите, для студентов есть множество олимпиад, хакатонов, чемпионатов и бизнес-игр",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Это объясняется тем, что потенциальный работодатель хочет нанимать молодых, энергичных и находчивых людей",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Людей, которые ещё не потеряли амбициозности и энтузиазма...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А с годами все теряют эти качества?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Нет, что Вы! Конечно же, не все... Но многие! И найти таких же людей в старшей возрастной категории уже намного сложнее, чем в юной...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Ну и молодым нужно открывать дороги, давать возможности для развития, чтобы они не разочаровались в жизни...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Как интересно! А у Вас есть любимое мероприятие?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Хм, дайте-ка подумать...",
        },
        {
          rightSprite: emm_stal_smile_hand_behide,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Да, мне очень нравится CTF или Capture the flag, в переводе - «Захват флага»",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Это командное соревнование в области компьютерной безопасности",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Я провожу дополнительные добровольные занятия по подготовке к этому соревнованию",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Вы бы могли как-нибудь прийти посмотреть, попробовать себя!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Вау, как будет время обязательно загляну посмотреть, очень заманчивое предложение!",
        },
        {
          rightSprite: emm_stal_smile,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Но серьёзно заниматься подобным, можно только проучившись какое-то время...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Почему?",
        },
        {
          rightSprite: emm_stal,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Потому что это не просто развлечение. Это сложное дело, где нужно обладать некоторыми знаниями в компьютерной безопасности",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Ведь такие мероприятия – способ показать себя, проверить свои знания и умения на практике",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Поконкурировать с другими студентами так же, как придётся при устройстве на реальную работу в будущем...",
        },
        {
          rightSprite: emm_stal_smile_hand_behide,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Такие дела...",
        },
      ],
    },
    {
      choices: [
        "Мне немного непонятно, чем различаются направления ИБ, КБ и ИБАС, не могли бы Вы объяснить?|emm_stal_question_2",
        "*промолчать*|emm_stal_exit",
      ],
    },
  ],

  emm_stal_question_2: [
    {
      body: [
        {
          background: department_place_04,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: emm_stal_smile,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Ох, Вы бы знали, как часто я слышу этот вопрос!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Все три направления изучают схожие предметы, работают в примерно одной области знаний и имеют схожие названия...",
        },
        {
          rightSprite: emm_stal_smile_hand_behide,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Но поверьте мне, они разные!",
        },
        {
          rightSprite: emm_stal,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Студенты направления ИБ больше изучают компьютерную/информационную безопасность с точки зрения права",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "То есть, в какой-то степени похожи на юристов, но в узкой области",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Также они умеют программировать",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Знают криптографические методы и другие науки необходимые в работе, но на уровень ниже, чем студенты КБ и ИБАСа...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Всё-таки это бакалавриат...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Их готовят в большей степени заниматься аудитом",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "То есть независимой проверкой и оценкой, в данном случае, компьютерной безопасности предприятий",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "А также консультацией по вопросам данной области различными правовыми и административными методами",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Студенты КБ нацелены на то, чтобы защищать информацию внутри компьютеров - шифрованием",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Они больше знают о работе файловых и операционных систем",
        },
        {
          rightSprite: emm_stal_smile,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Проще говоря, создают вирусы и защищают от них!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А зачем им создавать вирусы, если они на светлой стороне?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Как зачем? Если знать, как работает твой враг, проще найти метод избавления от него...",
        },
        {
          rightSprite: emm_stal_smile_hand_behide,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Как говорят детективы в фильмах? Чтобы поймать убийцу, нужно мыслить, как убийца! Это образно, конечно...",
        },
        {
          rightSprite: emm_stal_smile,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Ну и цель студентов ИБАСа – обезопасить компьютерные системы на предприятии от угроз как с внешней, так и с внутренней стороны...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Чтобы никто не запустил вирус изнутри, и он не мог проникнуть снаружи самостоятельно, так сказать",
        },
        {
          rightSprite: emm_stal_smile_hand_behide,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Вот и всё различие! Надеюсь, я Вас ещё больше не запутал!",
          achiev_child_id: 13, // 2.6
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "emm_stal_exit",
    },
  ],

  emm_stal_exit: [
    {
      body: [
        {
          background: department_place_04,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: emm_stal,
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Что же это такое, снова звонят! Давайте я отвечу и продолжим разговор?",
        },
        {
          rightSprite: emm_stal_phone,
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Да нет-нет, мне уже пора, не хочу Вас отвлекать...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Спасибо за Ваше время!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Эммануил Сталинович",
          speakColor: emm_stal_color,
          text: "Всегда пожалуйста, заходите ещё, поболтаем!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "До свидания!",
        },
        {
          rightSprite: guide_smile,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Что-то Эммануил Сталинович заскромничал...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Почему?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Знаешь, я давно слежу за внеучебной деятельностью студентов ИБ, КБ и ИБАСа и она очень увлекательна!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Они часто проявляют инициативу, участвуют в различных олимпиадах и чемпионатах",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "И у них это очень хорошо получается!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Ни один месяц не может пройти без новостей о том, что ребята с кафедры информационной безопасности где-то выиграли",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Я так за них рад!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "И ты, если поступишь сюда, тоже сможешь окунуться в атмосферу участия и побед!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ого! Звучит круто!",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default teacher_emm_stal;
