import Character from "../Components/character/Character";
import Footer from "../Components/footer/Footer";
import Header from "../Components/header/Header";
import "../Components/character/character.css";

export default function CharacterPage() {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <Character />
        <Footer />
      </div>
    </>
  );
}
