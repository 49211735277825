import Footer from "../../Components/footer/Footer";
import Header from "../../Components/header/Header";
import StudentBook from "../../Components/studentBook/ListStoriesBook";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import BookContent from "../../Components/studentBook/BookContent";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import AuthUser from "../../Services/AuthUser";
import PanelFiltr from "../../Components/studentBook/PanelFiltr";

export default function StudentBookPage() {
  const { directionName, instName } = useParams();
  const { http, getToken } = AuthUser();
  const [allStories, setAllStories] = useState([]);
  const [stories, setStories] = useState([]);
  const [storyToShow, setStoryToShow] = useState(null);
  const [directions, setDirections] = useState([]);
  const [instDir, setInstDir] = useState([]);

  const getAllStories = async () => {
    try {
      const response = await http.get(`/stories`);
      setAllStories(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getStories = async () => {
    try {
      const response = await http.get(`/stories/${instName}/${directionName}`);
      // console.log(response.data.data);
      setStories(response.data.data);
      setStoryToShow(response.data.data[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const getDirections = async () => {
    const response = await http.get("/inst-dir", {
      params: {
        inst_url: instName,
      },
    });
    setDirections(response.data);
  };
  const getInstDir = async () => {
    const response = await http.get("/inst-dir-player");
    setInstDir(response.data.data);
  };

  useEffect(() => {
    getAllStories();
    getStories();
    getDirections();
    getInstDir();
  }, []);

  const handleSearchChange = (value) => {
    const filteredStories = allStories.filter((story) =>
      story.story_text.toLowerCase().includes(value.toLowerCase())
    );
    setStories(filteredStories);
    setStoryToShow(filteredStories[0]);
  };

  const handleRatingChange = (checked) => {
    if (!checked) {
      const filteredStories = allStories.filter(
        (story) => story.average_rating >= 4
      );
      setStories(filteredStories);
      setStoryToShow(filteredStories[0]);
    } else getStories();
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <Container component="main" maxWidth="xl" sx={{ padding: 9 }}>
          <Grid
            container
            spacing={3}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* Первая часть контейнера */}
            <Grid item xs={12} sm={4} md={3}>
              <BookContent instDir={instDir} />
              <PanelFiltr
                onSearchChange={handleSearchChange}
                onCheckedRating={handleRatingChange}
              />
            </Grid>
            {/* Вторая часть контейнера */}
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <StudentBook
                instName={instName}
                stories={stories}
                setStories={setStories}
                storyToShow={storyToShow}
                setStoryToShow={setStoryToShow}
                directions={directions}
              />
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </div>{" "}
    </>
  );
}
