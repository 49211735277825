export function checkVisitedNode(nodeName) {
  const visitedNodes = JSON.parse(localStorage.getItem("visitedNodes")) || {};
  return visitedNodes[nodeName] === true;
}

export function setVisitedNode(nodeName) {
  const visitedNodes = JSON.parse(localStorage.getItem("visitedNodes")) || {};
  visitedNodes[nodeName] = true;
  localStorage.setItem("visitedNodes", JSON.stringify(visitedNodes));
}

export function jumpCheckVisited(nodeName, nameCheck, nameUnCheck) {
  if (checkVisitedNode(nodeName)) return nameCheck;
  else return nameUnCheck;
}

export function setVisitedNodeDirection(nameLocalStorage, nameClick, nodeName) {
  const visitedNodes = JSON.parse(localStorage.getItem(nameLocalStorage)) || {};
  visitedNodes[nameClick][nodeName] = true;
  localStorage.setItem(nameLocalStorage, JSON.stringify(visitedNodes));
}
