import React, { useEffect, useState } from "react";
import CardCarousel from "../../Components/card/CardCarousel";
import Footer from "../../Components/footer/Footer";
import Header from "../../Components/header/Header";
import { useTheme } from "@mui/material/styles";
import * as NEWSCONTENT from "../news/NewsContent";
import { Box, Container, Grid, Typography } from "@mui/material";
import FormFeedback from "../../Components/feedback/FormFeedback";
import { IconButton } from "@mui/material";
import { ArrowUpward } from "@mui/icons-material";

import Imikn from "../../Assets/HomePage/cover_imikn_blured.png";

import VocabularyBlock from "./components/VocabularyBlock";
import StudentBookBlock from "./components/StudentBookBlock";
import FormFeedbackBlock from "./components/FormFeedbackBlock";
import CardSectionBlock from "./components/CardSectionBlock";
import NewsCardsBlock from "./components/NewsCardsBlock";

export default function Home() {
  const theme = useTheme();

  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    if (scrollTop > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", handleScroll);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh"
        }}
      >
        <Header />
        <CardCarousel content={NEWSCONTENT.NEWSCAROUSEL} />
        <NewsCardsBlock />

        <div
          style={{
            position: "relative",
            minHeight: "22vw",
            marginBottom: "2%",
            width:"100%",
            overflow:"hidden" 
          }}
        >
          <img src={Imikn} style={{width: "100%", display: "block"}} />
          <Typography
            variant="h4"
            align="center"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontWeight: "bold",
              textShadow: "1px 1px 2px black, 0 0 1em black, 0 0 0.2em grey",
              color: "white",
              '@media (max-width: 800px)': {
                fontSize: '5vw',
              },
            }}
          >
            Добро пожаловать в P.U.P.A. по Институту математики и компьютерных
            наук!
          </Typography>
        </div>
        <Box sx={{width:"80vw", margin: "0 auto"}}>
        <CardSectionBlock />
        <VocabularyBlock />
        <StudentBookBlock />
        <FormFeedbackBlock />
        
        </Box>
        <Footer />
        {isVisible && (
          <IconButton
            sx={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              zIndex: 9,
            }}
            onClick={scrollToTop}
          >
            <ArrowUpward sx={{ fontSize: 100 }} />
          </IconButton>
        )}
      </div>
    </>
  );
}
