import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_ped = require("../../../assets/imikn/backgrounds/imikn_ped.jpg");

// character
const guide_smile = require("../../../assets/imikn/characters/guide_smile.png");
const tal_yar = require("../../../assets/imikn/characters/tal_yar.png");
const tal_yar_smile = require("../../../assets/imikn/characters/tal_yar_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";
const tal_yar_color = "#E6DBCA";

let teacher_tal_yar = {
  Start: [
    {
      body: [
        {
          background: imikn_ped,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: tal_yar_smile,
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Добрый день!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "День добрый! Хотели поговорить со мной о чем-то?",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Да, я абитуриент, вот знакомлюсь с институтом, направлениями и преподавателями... Можно задать пару вопросов?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Конечно, давайте-давайте!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Вот что-что, а поговорить я люблю!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Как-то раз на зачете студент меня заболтал, так я даже и не заметил, как 40 минут прошло!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ну, это не много...",
        },
        {
          rightSprite: tal_yar,
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Что значит не много? А что такое 40 минут, по-вашему?",
        },
      ],
    },
    {
      choices: [
        "Время|tal_yar_time",
        "Урок|tal_yar_lesson",
        "Не знаю|tal_yar_Dontknow",
      ],
    },
  ],

  tal_yar_time: [
    {
      body: [
        {
          background: imikn_ped,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: tal_yar,
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Понятно, что время, а конкретнее?",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Не знаю...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Ладно, вижу Вы не философ и не мечтатель. Жаль, конечно, жаль...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Для математика это же пренепременно полезнейшие качества!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "tal_yar_continue",
    },
  ],

  tal_yar_lesson: [
    {
      body: [
        {
          background: imikn_ped,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: tal_yar,
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Какой еще урок?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Помню, в мое время занятие 45 минут длилось. А сейчас что, 40?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "К перемене время добавили?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Эх, что за времена пошли!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "tal_yar_continue",
    },
  ],

  tal_yar_Dontknow: [
    {
      body: [
        {
          background: imikn_ped,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: tal_yar,
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Эх, вы!",
        },
        {
          rightSprite: tal_yar_smile,
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "За 40 минут можно мир захватить!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "tal_yar_continue",
    },
  ],


  tal_yar_continue: [
    {
      body: [
        {
          background: imikn_ped,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: tal_yar_smile,
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Ну, собсна, что бы Вам такого интересного рассказать-то... Даже и не знаю...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Давно ли Вы занимаетесь математикой?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Ой-ой-ой как давно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Ваши родители еще пешком под стол учились ходить, когда я поступал в институт",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Вот, знаете, всегда любил математику и преподавание. Порой, после школы, подрабатывал репетитором для соседских детишек",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Поэтому, когда дело дошло до выбора специальности, я без колебаний пошел в математические науки и сферу образования!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ой, наверное, то что Вы изучали тогда, сильно отличается от того, что преподаете сейчас",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Вовсе нет. Хочу сказать по секрету: только язык математики и логики всегда будет современным и актуальным!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Он никогда не изменится настолько кардинально",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Например, прилетят инопланетяне, и что?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Они же не знают русского языка, а вот математические законы, формулы и теоремы - знают, иначе не добрались бы до нас!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Поэтому и хочу донести мысль, что наука сближает не только народы, но и целые цивилизации!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Ой, что-то я совсем в дебри залез. Я тут разглагольствую, а Вы и рады ушить развесить ха-ха-ха",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Задавайте свои вопросы, слушаю внимательно",
        },
      ],
    },
    {
      choices: [
        "Сложно ли учиться на направлении “Педагогическое образование”?|tal_yar_question_1",
        "Вам нравится тут работать?|tal_yar_question_2",
      ],
    },
  ],

  tal_yar_question_1: [
    {
      body: [
        {
          background: imikn_ped,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: tal_yar_smile,
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Конечно! Как и везде!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Где легко учиться, там не учат!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Я вот что скажу Вам - люди, которые занимаются математикой, не страдают от слабоумия в старости!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Ученые не просто так это выяснили...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "И почему же после такого откровения весь мир не начал изучать математику? Не понимаю...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "А, что касается направления, то лично моё мнение таково",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Педагоги, как и я сам, в молодости полны энтузиазма, но спустя время легко могут разочароваться!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А можно ли этого как-то избежать?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Конечно! Больше практики = больше знаний, а следовательно больше интереса",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Надеюсь, если Вы поступите на педагогическое образование, Вы не разочаруетесь и будете хорошо учиться!",
        },
      ],
    },
    {
      choices: [
        "Какие предметы Вы преподаёте?|tal_yar_question_3",
        "Как Вы обучаете студентов?|tal_yar_question_4",
        "У меня больше нет вопросов|tal_yar_exit",
      ],
    },
  ],

  tal_yar_question_2: [
    {
      body: [
        {
          background: imikn_ped,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: tal_yar_smile,
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Конечно! Преподавание - ведь дело творческое. Надо умело заинтересовать, грациозно всё разъяснить и филигранно обучить",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Только вот бюрократия, бумажки... Вот это все совсем не интересно, скукотища!",
        },
      ],
    },
    {
      choices: [
        "Какие предметы Вы преподаёте?|tal_yar_question_3",
        "Как Вы обучаете студентов?|tal_yar_question_4",
        "У меня больше нет вопросов|tal_yar_exit",
      ],
    },
  ],

  tal_yar_question_3: [
    {
      body: [
        {
          background: imikn_ped,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: tal_yar_smile,
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Ха, да разве же Вы поймёте о чём пойдёт речь? Сейчас в школах совсем не так учат, как раньше...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Всего каких-то 30 лет назад абитуриенты знали программу первого курса университета",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "А теперь некоторых и считать-то приходится учить... Ужас...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "А преподаю я линейную алгебру, аналитическую геометрию...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Что ж у Вас глаза-то так погрустнели? Не расстраивайтесь, это очень занимательные предметы, между прочим",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Ничего страшного там нет...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Вот дифференциальная геометрия и топология уже могут привести в ужас неподготовленного человека",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Но волноваться тоже не стоит!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Стоит лишь помнить, что любая кружка может быть пончиком, если Вы понимаете о чём я *подмигивает*",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "tal_yar_exit",
    },
  ],

  tal_yar_question_4: [
    {
      body: [
        {
          background: imikn_ped,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: tal_yar_smile,
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Как, как? А вот так! Хотите мои секретики узнать, а вот и не расскажу! Поступайте к нам на педагогическое и сами все увидите...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Хотя, ладно, пару моментов можно раскрыть",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Я уважаю студентов пытливых, кто выполняет мои задания дома, много практикуется и занимается самостоятельно",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Конечно, бывают случаи, когда студент работает на пределе своих возможностей, старается, но предмет все никак не дается",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Я это вижу, уважаю труд и понимаю, что сложно, поэтому всегда готов помочь",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Но чтоб сильно не расслаблялись, иногда придумываю что-нибудь эдакое каждую недельку. Порой даже припугиваю",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Что-что, простите?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Ха! А как же Вы хотели! Студенты всегда должны быть в тонусе!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Кто, как не любимый преподаватель, может сделать это лучше всего?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Например, устроить внезапную контрольную или решать задачки на время. Ха!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "tal_yar_exit",
    },
  ],

  tal_yar_exit: [
    {
      body: [
        {
          background: imikn_ped,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: tal_yar_smile,
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Тааакс, у меня вот-вот лекция, побегу-ка я...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Надеюсь, у Вас получится определиться, куда поступать, а коль не понравится, переведётесь!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Талалай Ярославович",
          speakColor: tal_yar_color,
          text: "Сейчас-то это уже не проблема...",
        },
        {
          rightSprite: guide_smile,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Уф, я уж думал, он тебя никогда не отпустит...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "А про пончик и кружку ты поймёшь, если топологию будешь изучать. Так что не забивай себе голову",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Да уж, даже как-то и не разговор, а монолог какой-то получился",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Не переживай! Это не в тебе проблема, просто Талалай Ярославович любитель заболтать собеседника, поэтому он меня и не видит...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Если бы его время не поджимало, то он бы часами мог рассказывать тебе обо всём на свете...",
          achiev_child_id: 11, // 2.2
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default teacher_tal_yar;
