import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";
import { setVisibleTeacher } from "../../../../../Components/dialogueSystem/scripts/VisibleTeacher";

// background
const imikn_ib = require("../../../assets/imikn/backgrounds/imikn_ib.jpg");

// character
const guide_default = require("../../../assets/imikn/characters/guide.png");
const guide_smile = require("../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let direction_ib = {
  Start: [
    {
      body: [
        {
          background: imikn_ib,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_smile,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "На самом деле, мне пришлось несколько лет торчать на этой кафедре, чтобы понять, чем они занимаются!",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "А главное, чем различаются эти три направления с почти одинаковыми предметами и названиями...",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "И я нашёл ответы! Но давай по порядку",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Во-первых, для того чтобы понять суть направления, необходимо изучить базовые предметы...",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Высшую математику, компьютерные сети и операционные системы",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Далее знания, полученные на этих предметах начнут обретать последовательность, порядок и главное – смысл",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Математику ты вспомнишь, когда начнётся изучение криптографии",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "А сети и операционные системы на занятиях - администрирования и управления",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "И не стоит забывать про программирование, как же без него, оно везде...",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Изучив всё это, начнётся самое интересное - практика!",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Реализация методов шифрования и атаки своего, а потом и чужого, шифра, настройка защиты ПК и серверов",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "Как же интересно!",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Я даже видел, как для своего проекта один студент атаковал сайт иностранного маленького магазинчика и тот упал на 4 часа...",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Стоит отметить, проект оценили на ура!",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Отличительной чертой конкретно этого направления, является упор на право, то есть на законы, которые нужны для защиты информации",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "На КБ и ИБАСе оно тоже преподаётся, но в ознакомительном формате, здесь же более детально!",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Также ключевыми предметами являются основы информационной безопасности и компьютерные сети",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Кстати пары по второй дисциплине будут проходить сегодня, можешь посетить и пообщаться лично с преподавателем - Добромиром Люциановичем",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "Хорошо, я подумаю",
          visibleTeacher: "dobr_luc",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          rightSprite: guide_default,
          text: "Стоит заметить, что область информационной безопасности очень обширна!",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Во время обучения студентам предстоит выбрать, какую узкую специализацию изучать более досконально",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Чтобы затем успешно устроиться на работу",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "А чем больше знаний в этой области человек получит",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Тем более высокую заработную плату он может запрашивать прямо сразу после выпуска из университета или даже во время обучения!",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Но нагрузка здесь очень высокая, за счёт того, что это бакалаврская программа и обучение длится всего 4 года",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Поэтому я бы посоветовал идти работать не раньше 4 курса, чтобы учёба не пострадала!",
        },
      ],
    },
    {
      choices: [
          "А расскажи о нескольких узких специализациях информационной безопасности, а то я ничего об этом не знаю...|direction_ib_story",
          "Понятно|direction_ib_continue",
        ],
    },
  ],

  direction_ib_story: [
    {
      body: [
        {
          background: imikn_ib,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_default,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Хорошо!",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Например, есть такая область, как “пентест” или тестирование на проникновение",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Специалисты данного вида информационной безопасности проверяют надёжность IT-систем",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Например: компьютеров, целых компьютерных сетей, сайтов, и в целом, различных IT-продуктов",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Для этого тестировщики сами пытаются их взломать или получить контроль над данными",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "То есть имитируют реальную атаку на сеть или приложение",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "Получается они, как “белые хакеры”?",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Ну что-то в этом роде",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Ещё есть область под названием “брутфорс”",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Люди, работающие в этой сфере занимаются изучением методов угадывания паролей",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "А также создают передовые технологии для защиты пользователей от взломов",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Следующая область - “Бумажный ИБ”, как её в шутку называют специалисты",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Эти люди должны хорошо знать право в области защиты информации",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Они могут консультировать компании и проверять их компьютерную безопасность",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Это, лишь несколько из всевозможного разнообразия узких направлений ИБ, об остальных ты можешь узнать уже во время обучения!",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "Всего несколько? Уже звучит так, как будто их очень много...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "direction_ib_continue",
    },
  ],

  direction_ib_continue: [
    {
      body: [
        {
          background: imikn_ib,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_default,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "В общем, специалисты, окончившие это направление, могут и настроить защиту компании, и атаковать другие для проверки",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Знают законы, чтобы всё было правильно и справедливо, а также могут консультировать по вопросам информационной безопасности",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "То есть, такие люди нужны везде, где есть компьютеры. Не правда ли это чудесно?!",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "Ага!",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "А как “правильно” называются профессии, по которым можно будет пойти работать после выпуска?",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          rightSprite: guide_smile,
          text: "Ну например, пентест специалист, SOC аналитик, менеджер управления рисками ИБ, специалист ИБ, DevSecOps и так далее",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Их правда очень много!",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "Ладно, теперь верю, что много...",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Помни, что ты всегда можешь заглянуть в Азбуку и прочитать о незнакомых терминах!",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "Хорошо",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "А ещё я знаю один интересный факт, рассказать напоследок?",
        },
      ],
    },
    {
      choices: [
          "Давай|direction_ib_yes",
          "Нет, хочу пойти дальше|direction_ib_no",
        ],
    },
  ],

  direction_ib_yes: [
    {
      body: [
        {
          background: imikn_ib,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_smile,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Довольно часто студентов старших курсов и выпускников приглашают в следственный комитет для оказания помощи",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Видишь ли, студенты изучают также файловые системы",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "А через них можно находить и восстанавливать данные, которые удалили с жесткого диска",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Очень полезные знания в криминалистике...",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Особенно когда знаешь, что абсолютно любую информацию нельзя просто так удалить, почистив корзину на своём ноутбуке...",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "Ого! Ничего себе!",
          achiev_child_id: 7, // 1.5
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Ха-ха, да, ладненько, пошли дальше...",
        },
      ],
    },
    {
      exits: true,
    },
  ],

  direction_ib_no: [
    {
      body: [
        {
          background: imikn_ib,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_default,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Ну да, Богдана не любят... Ладно, пошли",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default direction_ib;
