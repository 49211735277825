import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const department_place_03 = require("../../../assets/imikn/backgrounds/department_place_03.jpg");

// character
const guide_smile = require("../../../assets/imikn/characters/guide_smile.png");
const anas_suleim = require("../../../assets/imikn/characters/anas_suleim.png");
const anas_suleim_angry = require("../../../assets/imikn/characters/anas_suleim_angry.png");
const anas_suleim_shout = require("../../../assets/imikn/characters/anas_suleim_shout.png");
const anas_suleim_smirk = require("../../../assets/imikn/characters/anas_suleim_smirk.png");
const anas_suleim_speaking = require("../../../assets/imikn/characters/anas_suleim_speaking.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";
const anas_suleim_color = "#E1D5EF";

let teacher_anas_suleim = {
  Start: [
    {
      body: [
        {
          background: department_place_03,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Здравствуйте! Вы опоздали, я Вас уже давно жду!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Добрый день! Извините, мы вроде бы не к определённому времени договаривались...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А я Вас ждала к определённому!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Богдан все уши прожужжал про Вас! Говорит, наконец-то новенький должен прийти! Так давно никого не было...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А у нас довольно часто мероприятия для абитуриентов проходят, с чего бы ему так радоваться?",
        },
        {
          centerSprite: guide_smile,
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Предчувствовал, что прийдёт особенный! Ха-ха-ха",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          rightSprite: anas_suleim_angry,
          text: "Ох! Шутник! У меня вон вся кафедра шутников! Вчера целый поток с пары сбежал!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Добрыня Романович говорит, не при делах, а я же знаю, снова их «пораньше» отпустил за полтора часа до конца пары!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А пара разве не длится всего полтора часа?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну, конечно, а я о чём! Ему надо отчёты их проверить, а им лишь бы не учиться! Нашли точки соприкосновения...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ему очень много лет, а их почти сто человек!!!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ничего не понимаю! Как же так?!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Богдан, ты бы вместо того, чтобы вчера прыгать от счастья, лучше бы напугал их, чтобы учиться начали, шалопаи!",
        },
        {
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Ха-ха, хорошо, я Вас понял Анастасия Сулеймановна!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А что такое отчёты? Я знаю, что у взрослых на работе есть что-то с таким названием...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Это же вроде с деньгами связано? Зачем студентам такое делать, они же только начинающие программисты!?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          rightSprite: anas_suleim_shout,
          text: "Они лентяи, а не программисты!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "И ни с какими деньгами отчёты не связаны!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вообще, если поступите, забудьте о деньгах! Об учёбе думать нужно в первую очередь!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А отчёты – это текстовый документ, в котором подробно расписывают результаты о проделанной работе",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну там о проектах, курсовых, хоть их сейчас и нет, ммм... о практиках, вот!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Расшатали мне нервы с утра пораньше, теперь даже от простого вопроса про отчёт злюсь!!!",
        },
        {
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Анастасия Сулеймановна, не злитесь! Всё хорошо!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          rightSprite: anas_suleim_angry,
          text: "Ой... Давай, пропадай с глаз моих, всё равно всё слышать будешь, но хоть из зоны видимости исчезнешь...",
        },
        {
          centerSprite: "",
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А Вы чего зеваете? Не зевайте!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Почему?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Кто зевает, у того мозг не работает! А Вы сюда пришли новую информацию получать, и где она поселится, если мозг заблокирован?",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Хорошо, не буду... Расскажите о направлении МОиАИС... Пожалуйста...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Конечно, расскажу, не переживайте! Главное внимательно слушайте и не зевайте, а ты Богдан не подслушивай!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Знаю, сел где-нибудь и уши развесил, невидимка!",
        },
        {
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "*шёпотом* Неправда-а...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          rightSprite: anas_suleim,
          text: "Вы знаете, как расшифровывается МОиАИС?",
        },
      ],
    },
    {
      choices: [
        "Да|anas_suleim_yes",
        "Нет|anas_suleim_no",
      ],
    },
  ],

  anas_suleim_yes: [
    {
      body: [
        {
          background: department_place_03,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вот и хорошо!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "anas_suleim_continue",
    },
  ],

  anas_suleim_no: [
    {
      body: [
        {
          background: department_place_03,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну вот потом сами разузнайте, а то я Вам сейчас скажу, у Вас глаза округлятся",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "anas_suleim_continue",
    },
  ],

  anas_suleim_continue: [
    {
      body: [
        {
          background: department_place_03,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Главное, помнить, что первое слово направления – математическое",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А значит математики будет много и непозволительно в своих проектах её избегать, как некоторые любят...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Наши студенты отличаются высоким посещением образовательных мероприятий: олимпиад, чемпионатов, хакатонов и прочего",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          rightSprite: anas_suleim_smirk,
          text: "А результатом таких посещений зачастую являются победы, имейте в виду...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А что, большинство сами хотят участвовать или их заставляют?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Конечно... Сами!",
        },
        {
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "*тихий смешок*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          rightSprite: anas_suleim,
          text: "Ну ладно, приходится их иногда мотивировать, ставить дополнительные баллы за посещение и хороший результат!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ещё научные работы наших студентов часто публикуют в научных журналах и сборниках. Вы же знаете, что это такое?",
        },
      ],
    },
    {
      choices: [
        "Да, Богдан рассказывал немного|anas_suleim_yes_2",
        "Богдан рассказывал, но не очень понятно...|anas_suleim_no_2",
      ],
    },
  ],

  anas_suleim_yes_2: [
    {
      body: [
        {
          background: department_place_03,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну вот и хорошо",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "anas_suleim_exit",
    },
  ],

  anas_suleim_no_2: [
    {
      body: [
        {
          background: department_place_03,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim_speaking,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну смотрите, у нас есть несколько предметов, по которым студенты должны придумать тему проекта",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Собрать команду и реализовать его за семестр. Это как маленький учебный стартап",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Кстати, сегодня будет проходить защита подобных проектов, загляните, чтобы увидеть своими глазами!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Х-хорошо...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "В конце семестра студенты пишут отчёт",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Где расписывают всю теорию, которую изучили и применили в процессе работы над проектом",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "И практическую часть, то есть, что делали и сделали в итоге",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Если работа получается стоящей",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "То есть студенты провели большое исследование и его результаты имеют какой-то вес в научной деятельности института",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "То мы предлагаем им написать научную статью и опубликоваться",
        },
        {
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "*шёпотом* П-ха-ха-ха-ха, предлагаем!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "В статье кратко описывается проблема, которую проект решает, цели, задачи, аналоги...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Теоретическая и практическая часть, а в заключении – результаты",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          rightSprite: anas_suleim_smirk,
          text: "Ничего сложного!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "anas_suleim_exit",
    },
  ],

  anas_suleim_exit: [
    {
      body: [
        {
          background: department_place_03,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim_angry,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "И не слушайте Богдана, он любит всякие гадости наговорить про своё направление, это всё ВЫ-ДУМ-КИ!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Учёба – самое важное и лучшее, что Вы можете себе подарить в молодости",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Поэтому не пренебрегайте возможностью учиться, пока есть силы и желание!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          rightSprite: anas_suleim_smirk,
          text: "Приходите ко мне на практику по программированию сегодня. Получите хоть немного новых знаний, чтобы день прошёл не зря!",
          visibleLesson: "programming_lesson",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А у меня встреча через 6 минут, поэтому давайте прощаться. До встречи на практике!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Хорошо! До свидания!",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default teacher_anas_suleim;
