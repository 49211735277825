import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import AuthUser from "../../../../Services/AuthUser";

const EditInstituteModal = (props) => {
  const { token, http } = AuthUser();
  const { open, close, institute, setInstitutes } = props;
  const [instituteName, setInstituteName] = useState(institute?.inst_name || "");
  const handleSave = () => {
    const instData = {
      inst_name: instituteName,
    };
    http
      .put(`/institutes/${institute.id}`, instData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data.inst_name);
        setInstitutes(prevInstitutes => prevInstitutes.map(inst => inst.id === institute.id ? res.data : inst)); // Обновить список институтов
        close();
      });
  };

  const handleCancel = () => {
    close();
  };

  const handleNameChange = (event) => {
    setInstituteName(event.target.value);
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Редактировать институт</DialogTitle>
      <DialogContent>
        <DialogContentText>Введите новое название института:</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Название института"
          type="text"
          fullWidth
          defaultValue={institute?.inst_name}
          onChange={handleNameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Отмена
        </Button>
        <Button onClick={handleSave} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditInstituteModal;
