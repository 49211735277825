import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_server_class = require("../../../assets/imikn/backgrounds/imikn_server_class.jpg");

// character
const guide_smile = require("../../../assets/imikn/characters/guide_smile.png");
const dobr_luc = require("../../../assets/imikn/characters/dobr_luc.png");
const dobr_luc_smile = require("../../../assets/imikn/characters/dobr_luc_smile.png");
const dobr_luc_speaking = require("../../../assets/imikn/characters/dobr_luc_speaking.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";
const dobr_luc_color = "#D7DAED";


let teacher_dobr_luc = {
  Start: [
    {
      body: [
        {
          background: imikn_server_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Здравствуйте! Итак, давайте я Вам расскажу всё, что знаю",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Нечасто встретишь настолько неравнодушного абитуриента, так чудесно!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Здравствуйте! Ого, Вас, похоже, успели предупредили о моём визите!",
        },
        {
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Конечно! Так, с чего бы начать? Хм...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Я преподаю предметы, связанные с компьютерными сетями, их безопасностью, операционными системами, разработкой и защитой веб-приложений",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Все эти дисциплины изучают студенты информационной безопасности, кстати говоря",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А на других направлениях такого нет?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Есть, разумеется! Только в меньших объёмах и не все сразу...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Например, на первом курсе, вне зависимости от направления, все изучают “Компьютерные сети”",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Кстати, сегодня будет проходить лекция и практика по данному предмету...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Можете заглянуть, узнать, как именно проходят занятия в высшем учебном заведении!",
          visibleLesson: "lecture",
        },
      ],
    },
    {
      choices: [
        "Обязательно!|dobr_luc_ok",
        "Если хватит времени...|dobr_luc_ok",
      ],
    },
  ],

  dobr_luc_ok: [
    {
      body: [
        {
          background: imikn_server_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "А Вы знаете, что значит “защита информации”?",
        },
      ],
    },
    {
      choices: [
        "Да|dobr_luc_yes",
        "Нет|dobr_luc_no",
      ],
    },
  ],

  dobr_luc_yes: [
    {
      body: [
        {
          background: imikn_server_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Чудесно! Тогда не будем на этом останавливаться",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "dobr_luc_continue",
    },
  ],

  dobr_luc_no: [
    {
      body: [
        {
          background: imikn_server_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc_speaking,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Защита информации - это деятельность по предотвращению утечки, использования и других непредвиденных действий...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "С какой-либо информацией",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Проще говоря, чтобы хакеры, скажем, не украли Ваши паспортные данные или номер телефона, хе-хе",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "А самый простой пример защиты - обычный антивирус!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ого, так вот, что это значит! Буду иметь в виду...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "dobr_luc_continue",
    },
  ],

  dobr_luc_continue: [
    {
      body: [
        {
          background: imikn_server_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Вас уже привлекло какое-нибудь направление?",
        },
      ],
    },
    {
      choices: [
        "Да, оно давно определенно, просто хотелось узнать и про остальные. Это всё так интересно!|dobr_luc_yes_2",
        "Да, но я пока сомневаюсь...|dobr_luc_yes_3",
        "Нет, я совсем не знаю, что мне выбрать|dobr_luc_no_2",
      ],
    },
  ],

  dobr_luc_yes_2: [
    {
      body: [
        {
          background: imikn_server_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Это чудесно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Сейчас многие из абитуриентов находятся в затруднительном положении... Время выбора приближается, а в голове кавардак",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Хорошо, что Вы не из их числа! Поздравляю Вас!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "dobr_luc_continue_2",
    },
  ],

  dobr_luc_yes_3: [
    {
      body: [
        {
          background: imikn_server_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Чего это Вы? Не бойтесь! Выбирайте то, что чувствуете, а там два года на раздумья будет предостаточно",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Это всё эти самые, давят на детей, пугают, а потом ругают, если те отчисляются спустя год",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Если Вы боитесь, что попадёте в такое же положение - не бойтесь! Это не должно быть чем-то страшным",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Тем более, никто не знает, что будет дальше, поэтому просто живите жизнь!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "dobr_luc_continue_2",
    },
  ],

  dobr_luc_no_2: [
    {
      body: [
        {
          background: imikn_server_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну и ничего страшного!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Понимаете, сейчас время такое, можно не торопиться или всё изменить в любой момент",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Если чувствуете, что нравятся компьютеры, интернет, учить других людей или математика",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "То поступайте на любое направление: два года учитесь, размышляйте, а потом и выбирайте...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Если позже поймёте, что хочется совсем в другое место - переводитесь, кто ж Вам запретит?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "В общем, не бойтесь ошибок. Жизнь можно в любом возрасте изменить. Второе высшее, в конце концов, никто не запрещал!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Как говорится: кто не ошибается, тот не пьёт шампанское!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "dobr_luc_continue_2",
    },
  ],

  dobr_luc_continue_2: [
    {
      body: [
        {
          background: imikn_server_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Так, хорошо, о Вас чуть узнал, а мы здесь совсем и не для этого!",
        },
        {
          rightSprite: dobr_luc_speaking,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Смотрите, я на своих предметах многого не требую, лишь самого необходимого...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Посещать лекции, выполнять и сдавать лабораторные работы...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "По ним, кстати дедлайнов нет, но конечно в последний день семестра я принимать уже не буду, это уже наглость...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну и стараюсь всем автоматом оценки выставлять, Вы же знаете, что это такое?",
        },
      ],
    },
    {
      choices: [
        "Да|dobr_luc_yes_4",
        "Нет|dobr_luc_no_3",
      ],
    },
  ],

  dobr_luc_yes_4: [
    {
      body: [
        {
          background: imikn_server_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Вот и славненько!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "dobr_luc_exit",
    },
  ],

  dobr_luc_no_3: [
    {
      body: [
        {
          background: imikn_server_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc_speaking,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну смотрите, есть лабораторные работы, ну как сказать, это не то, что было в школе: пробирки там всякие - нет...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Это, скорее, как домашняя работа, только иногда её делать нужно на занятиях...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Если необходимо определённое оборудование, которого нет дома, например...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Так вот, есть допустим 9 лабораторных работ, каждая оценивается в 10 баллов",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "И например 10 баллов за посещение всех лекций, в сумме получается 100 баллов",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Автомат - это как бы автоматическая оценка",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Если вы набрали за семестр достаточное количество баллов на оценку, которая Вас устраивает",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "То можно не сдавать экзамен или зачёт по предмету, а получить её автоматом",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Сейчас у нас такие порядки, 61-75 баллов - тройка, 76-90 - четвёрка и 91-100 - пятёрка",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Но за этим надо следить, уточнять, говорят, цифры могут измениться в любое время...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Понятно...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "dobr_luc_exit",
    },
  ],

  dobr_luc_exit: [
    {
      body: [
        {
          background: imikn_server_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Я автоматы люблю! Прекрасный мотиватор для студентов побыстрее всё сдать и отдыхать... Да и работы в сессию меньше, чудо чудесное!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Так, мне звонят, похоже что-то срочное. Отлично пообщались, и удачи Вам! Обязательно загляните на мои пары сегодня!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "И Вам! Хорошо!",
        },
        {
          rightSprite: guide_smile,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Какой же приятный человек он всё-таки! Цени таких как он, их осталось не так много...",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default teacher_dobr_luc;
