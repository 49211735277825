import LoginComponent from "../../Components/auth/Login";

export default function LoginPage() {
  return (
    <>
      {/* <Header /> */}
        <LoginComponent />
    </>
  );
}
