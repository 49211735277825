import {
    getSpriteCharacterOfGender,
    getNamePlayer,
} from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_first_coridor = require("../../../../assets/imikn/backgrounds/imikn_first_coridor.jpg");

// character
const guide_smile = require("../../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let teachers_intro_1 = {
    Start: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Итак, по институту мы пробежались, пора бы познакомится с преподавателями и направлениями",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Было бы неплохо. С чего начнем?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Во-первых, преподаватели здесь тебе не NPC из игр, которые просто стоят и ждут, пока ты на них кликнешь",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Но сегодня тебе повезло: они собрались, чтоб обсудить важные изменения в институте... Ах, да, еще я договорился с ними",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "О чем? Я же ещё абитуриент, а ты уже с кем-то договариваешься",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Эх, ты, балда, я договорился, чтоб они рассказали тебе о своих направлениях",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Ааа... Много преподавателей собралось?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Тебе повезло! От каждого направления по одному, в сумме десять получилось",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Не мало... И со всеми нужно обязательно поговорить?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Всех ты вряд ли сейчас застанешь, некоторые преподаватели могут опоздать из-за занятости",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Так что в процессе изучения можешь их здесь “поймать”",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "В смысле?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну, если ты вдруг услышишь, перешептывания студентов о каком-либо преподавателе",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Или как секретарь упоминает по телефону чье-то имя, знай, что этот человек пришел на собрание, где ты сможешь его встретить",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "О, понятно. Спасибо! Пойду кого-нибудь найду и расспрошу",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Удачи!",
                },
            ],
        },
        {
            exits: true,
        },
    ],
};

export default teachers_intro_1;
