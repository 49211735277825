import { createTheme } from "@mui/material/styles";
const baseTheme = createTheme({
  typography: {
    fontFamily: "'Work Sans', sans-serif",
    fontSize: 14,
    fontFamilySecondary: "'Roboto Condensed', sans-serif",
  },
});
const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    type: "dark",
    background: {
      default: "rgba(18,18,18,0.97)",
      paper: "#9C9C9C",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "rgba(255,255,255,0.7)",
      disabled: "rgba(255,255,255,0.5)",
    },
    header: {
      main: "rgb(18,18,18)",
    },
    footer: {
      main: "rgb(18,18,18)",
    },
    bookButton: {
      main: "rgb(0,170,237)",
    },
    box: {
      main: "rgb(18,18,18,0.5)",
      shadow: "rgb(255,255,255,0.2)",
      border: "rgb(255,255,255,0.2)",
    },
    primary: {
      main: "#579ce5",
      light: "rgb(120, 175, 234)",
      dark: "rgb(60, 109, 160)",
      contrastText: "#000",
    },
    secondary: {
      main: "rgb(18,18,18,0.5)",
      light: "rgb(65, 65, 65)",
      dark: "rgb(12, 12, 12)  ",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#f44336",
      light: "#E57373",
      dark: "#D32F2F",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FFA726",
      light: "#FFB74D",
      dark: "#F57C00",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    info: {
      main: "#0288d1",
      light: "rgb(52, 159, 218)",
      dark: "rgb(1, 95, 146)",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#66BB6A",
      light: "#81C784",
      dark: "#388E3C",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    divider: "rgb(255,255,255)",
  },
});
const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    type: "light",
    background: {
      default: "#ffffff",
      paper: "rgb(199,229,253)",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(255,255,255,0.9)",
      disabled: "rgba(255,255,255,0.5)",
    },
    header: {
      main: "rgb(18,18,18)",
    },
    footer: {
      main: "rgba(0,0,0,0.80)",
    },
    bookButton: {
      main: "rgb(0,170,237)",
    },
    box: {
      main: "rgb(255,255,255,1)",
      shadow: "rgb(18,18,18,0.5)",
      border: "rgb(18,18,18,0.5)",
    },
    primary: {
      main: "#87b8fa",
      light: "rgb(159, 198, 251)",
      dark: "rgb(94, 128, 175)",
      contrastText: "#000",
    },
    secondary: {
      main: "#1976d2",
      light: "rgb(103,103,103)",
      dark: "rgb(17,82,147)",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "#f44336",
      light: "#E57373",
      dark: "#D32F2F",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FFA726",
      light: "#FFB74D",
      dark: "#F57C00",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    info: {
      main: "#0288d1",
      light: "rgb(52, 159, 218)",
      dark: "rgb(1, 95, 146)",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#66BB6A",
      light: "#81C784",
      dark: "#388E3C",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    divider: "rgb(0, 0, 0, .125)",
  },
});
export { darkTheme, lightTheme };
