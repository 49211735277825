import React from "react";
import Carousel from "react-material-ui-carousel";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import * as NEWSCONTENT from "../../Pages/news/NewsContent";

const useStyles = {
    root: {
        margin: "10px 10px",
        display: "flex",
        flexDirection: "column",
        zIndex:'5',
    },
    header: {
        flexGrow: 1,
    },
 
    paper: {
        width: 300,
    },
    image: {
        width: "100%",
    },
    typo: {
        textAlign: "center",
    },
    mx: {
        margin: "6px 0px",
    },
    card: {
        width: 300,
    },

};

const CardCarousel = () => {
    return (
        <Carousel classeName={useStyles.root}  >
            {NEWSCONTENT.NEWSCAROUSEL.map((item, i) => (
                <Cards key={i} item={item} />
            ))}
        </Carousel>
    );
};

function Cards(props) {
    return (
        <div classes={useStyles.root} >
            <Card className={`${useStyles.root} ${useStyles.card}`}>
                <CardActionArea sx={{height:"20vh"}} >
                    <CardMedia
                        component="img"
                        // height="400"
                        image={props.item.image}
                        title="Тут при наведении"
                        sx={{ objectFit: 'cover', height: '100%' }}
                    />
                </CardActionArea>
            </Card>
        </div>
    );
}

export default CardCarousel;