import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import StarIconComponent from "./StarIconComponent";
import { Tooltip } from "@mui/material";
 
export default function CardStoriesGuest(props) {
  const [value, setValue] = React.useState(0);
  const { directionButton, currentStory, instName,onChipClick } = props;
  const [selectedChip, setSelectedChip] = React.useState("");

  const handleChipClick = (value) => {
    setSelectedChip(value);
    onChipClick(value);
  };

  return (
    <Grid sx={{ borderLeft: "2px solid rgb(200, 200, 200)", my: 6 }}>
      <Grid
        container
        wrap="nowrap"
        spacing={2}
        sx={{
          mx: 4,
        }}
      >
        <Grid item xs>
          <Typography
            sx={{
              color: "black",
              maxWidth: "calc(100% - 60px)",
              fontSize: "calc(16px + 0.5vw)",
            }}
            variant="h5"
            align="center"
          >
            {currentStory?.story_name}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        wrap="nowrap"
        spacing={2}
        sx={{
          mx: 4,
          p: 2,
        }}
      >
        <Grid item xs>
          <Typography
            sx={{
              color: "black",
              maxWidth: "calc(100% - 60px)",
              textAlign: "justify",
              fontSize: "calc(11px + 0.5vw)",
            }}
            variant="body1"
          >
            {currentStory?.story_text}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          mx: 4,
          p: 2,
          pb: 0,
        }}
      >
        <Grid item xs>
          <Box
            direction="row"
            spacing={1}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              maxWidth: "calc(100% - 60px)",
            }}
          >
            {currentStory?.keywords && currentStory?.keywords.map((item, index) => (
              <Chip
                sx={{
                  background: "#0085FF",
                  padding: 1,
                  flex: "1 1 auto",
                  mt: 1,
                  ml: 1,
                }}
                label={item.keyword}
                key={index}
                onClick={() => handleChipClick(item.keyword)}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        wrap="nowrap"
        spacing={2}
        sx={{
          mx: 4,
          mt: 2,
          display: "flex",
          flexFlow: "column wrap",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs>
        <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Rating
              disabled={true}
              name="simple-controlled"
              value={value}
              size="large"
              sx={{
                fontSize: "calc(1rem + 3vw)",
                flexWrap: "wrap",
              }}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
            <StarIconComponent count={currentStory?.average_rating} />
          </Box>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            top: 0,
            right: -60,
          }}
        >
          {directionButton &&
            directionButton.map((item, index) => (
              <Tooltip key={index} title={item.dir_name}  placement="right-start">
                <Button
                  variant="contained"
                  sx={{
                    background: colorBackground[index],
                    height: 30,
                    px: 5,
                    marginTop: 1,
                    "&:hover": { background: colorBackgroundHover[index] },
                  }}
                  key={index}
                  href={"/studentbook/" + instName + "/" + item.dir_url}
                />
              </Tooltip>
            ))}
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Pagination
          count={props.count}
          page={props.page}
          onChange={props.onChange}
          variant="outlined"
          color="primary"
          sx={{ "& .MuiPaginationItem-root": { color: "black" }, mt: 3 }}
        />
      </Grid>
    </Grid>
  );
}

const colorBackground = [
  "rgb(255, 0, 0)",
  "rgb(255, 165, 0)",
  "rgb(255, 255, 0)",
  "rgb(0, 128, 0)",
  "rgb(173, 216, 230)",
  "rgb(0, 0, 255)",
  "rgb(0, 0, 139)",
  "rgb(128, 0, 128)",
  "rgb(255, 192, 203)",
  "rgb(139, 0, 0)",
  "rgb(0, 0, 0)",
];

const colorBackgroundHover = [
  "rgb(139, 0, 0)",
  "rgb(128, 64, 0)",
  "rgb(128, 128, 0)",
  "rgb(0, 100, 0)",
  "rgb(100, 149, 237)",
  "rgb(0, 0, 128)",
  "rgb(0, 0, 70)",
  "rgb(64, 0, 64)",
  "rgb(128, 80, 90)",
  "rgb(64, 0, 0)",
  "rgb(0, 0, 0)",
];
