import PasswordChangeComponent from "../../Components/auth/PasswordChange";

export default function PasswordChangePage() {

  return (
    <>

        <PasswordChangeComponent />
    </>
  );
}
