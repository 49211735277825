import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const department_place_06 = require("../../../assets/imikn/backgrounds/department_place_06.jpg");

// character
const pol_tim = require("../../../assets/imikn/characters/pol_tim.png");
const pol_tim_lowered = require("../../../assets/imikn/characters/pol_tim_lowered.png");
const pol_tim_lowered_smile = require("../../../assets/imikn/characters/pol_tim_lowered_smile.png");

//color
const player_color = "#D4D4D4";
const pol_tim_color = "#D9C1B0";

let teacher_pol_tim = {
  Start: [
    {
      body: [
        {
          background: department_place_06,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: pol_tim,
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Здравствуйте!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color, 
          text: "Ой, я забыл! Минут через 15 подойду",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Извините, Вы о чём?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Про занятия по информатике, Вы разве не оттуда?",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Нет, я абитуриент, у которого сегодня ознакомительная экскурсия...",
        },
        {
          rightSprite: pol_tim_lowered_smile,
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Ааа, точно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Просто я веду дополнительные занятия для школьников по подготовке к ЕГЭ по информатике...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Думал, забыл про одно из них, и Вы пришли меня огорошить...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Но да, оно не сегодня, а завтра! Что-то я совсем замотался",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Ну что ж, давайте я расскажу Вам немного о себе как о преподавателе и про обучение в целом",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Будет очень здорово!",
        },
        {
          rightSprite: pol_tim_lowered,
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Я считаю, что университет – колыбель отличных специалистов, за исключением получения опыта...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Поэтому всегда говорю своим студентам начинать работать ещё во время обучения, а лучше открыть свой бизнес",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "По этой причине, спокойно отношусь к работающим студентам, если, конечно, работа по специальности...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "А иногда приходят эти, как их там... Бариста!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Или официанты, хотят автоматы за то, что отлично готовят кофе или приносят вовремя еду...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Знаете, я пацифист, поэтому на моих дисциплинах автоматов нет!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Но для работающих по специальности есть небольшие поблажки: отсрочки по дедлайнам или, скажем, отсутствие штрафов за пропуски",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Ведь, они по сути своей на работе получают половину всех знаний. Практика – сила!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "А Вы что думаете на этот счёт?",
        },
      ],
    },
    {
      choices: [
        "Даже не знаю, некоторые считают, что работать и учиться одновременно – значит плохо делать и то и другое...|pol_tim_yes",
        "Наверное, Вы правы, работа – это и опыт, и заработок|pol_tim_no",
      ],
    },
  ],

  pol_tim_yes: [
    {
      body: [
        {
          background: department_place_06,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: pol_tim_lowered,
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Возможно на некоторых направлениях действительно так, но не на всех",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Например, у нас на компьютерной безопасности многие начинают работать уже на 3 курсе",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Но я бы не рекомендовал так рано...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Почему?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Потому что нагрузка в разных семестрах может отличаться",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "И не всегда по нарастающей...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "То есть иногда осенний семестр легче, чем весенний",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "А студенты думают: “О, уже стало легче, значит можно пойти работать”. А в следующем семестре их отчисляют за неуспеваемость...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Так всегда происходит?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Нет, конечно! Всё зависит от индивидуальных особенностей человека...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Кому-то нужно меньше времени на изучение нового, кому-то больше",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А как узнать, сколько нужно мне?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "На практике!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Если начнёте понимать, что хорошо учитесь и остаётся очень много свободного времени",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "То Вы из числа первых, а если наоборот - из числа вторых!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Но всё равно, если студент и хочет совмещать учёбу с работой",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Я бы рекомендовал искать ту, что с неполным рабочим днём или с гибким графиком",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Так как студент очной формы обучения должен посещать все пары очно!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Почему?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Потому что это его обязанность и если будет слишком много прогулов, то его просто отчислят! Вот так вот...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "pol_tim_continue",
    },
  ],

  pol_tim_no: [
    {
      body: [
        {
          background: department_place_06,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: pol_tim_lowered_smile,
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Конечно! Правильно мыслите!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Я слышал, некоторые преподаватели даже думать студентам запрещают о том...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Что они когда-нибудь будут зарабатывать своими знаниями и умениями деньги!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Какая чушь! Именно для этого они и здесь, а не потому, что им очень нравится решать математические задачки!",
          achiev_child_id: 14, // 2.7
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "pol_tim_continue",
    },
  ],

  pol_tim_continue: [
    {
      body: [
        {
          background: department_place_06,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: pol_tim_lowered,
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "В общем, как Вы уже, наверное, поняли, я из тех людей...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Которые пытаются вселить в студентов уверенность, что работа – это не страшно и не невозможно, а интересно и полезно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "И у каждого это обязательно получится, при наличии желания, конечно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Главное захотеть и правильно рассчитать свои ресурсы, чтобы не отчислили!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Понятно, спасибо за советы, Вы оказали на меня полезное влияние",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Это я ещё не старался!",
        },
        {
          rightSprite: pol_tim,
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "А если захотите увидеть мои старания, заходите как-нибудь на мои занятия или поступайте к нам!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Будем видеться чаще... или нет, если будете работать ха-ха-ха!",
        },
      ],
    },
    {
      choices: [
        "А какие дисциплины Вы преподаёте?|pol_tim_exit_1",
        "Ха-ха-ха, хорошо, ещё раз спасибо Вам! До свидания!|pol_tim_exit_2",
      ],
    },
  ],

  pol_tim_exit_1: [
    {
      body: [
        {
          background: department_place_06,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: pol_tim,
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Операционные системы и их администрирование",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "То есть обучаете пользоваться Windows?",
        },
        {
          rightSprite: pol_tim_lowered_smile,
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Ха-ха-ха, вот это шутка!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Конечно же нет! На моих предметах, студенты узнают об истории операционных систем, об их видах",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Есть же не только Windows, но и, к примеру, Linux, MacOS, Unix и много-много других, почитайте на досуге!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "У них есть сходства и различия, всё это описывается в их архитектуре, что также изучается на моих предметах",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Ещё я рассказываю, как их обслуживать или администрировать",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А что это значит?",
        },
        {
          rightSprite: pol_tim_lowered,
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Это значит, как и где искать решение возникающих в ОС проблем, как их правильно настраивать...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Как защитить от уязвимостей и прочее",
        },
        {
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Вот так сходу всего и не рассказать, лучше поступайте и узнайте всё самостоятельно!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Хорошо, я подумаю...",
        },
        {
          rightSprite: pol_tim_lowered_smile,
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Ладно, оставлю Вас с Вашими мыслями. Мне уже нужно уходить. Надеюсь мы ещё встретимся! До скорого!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Спасибо! До свидания!",
        },
      ],
    },
    {
      exits: true,
    },
  ],

  pol_tim_exit_2: [
    {
      body: [
        {
          background: department_place_06,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: pol_tim_lowered_smile,
          speakSprite: "right",
          nameSpeak: "Поликарп Тимофеевич",
          speakColor: pol_tim_color,
          text: "Уверен, мы ещё встретимся! До скорого!",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default teacher_pol_tim;
