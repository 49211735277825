import AuthUser from '../../Services/AuthUser';
import GameGuest from './components/GameSectionGuest';
import GameAuth from './components/GameSectionAuth';

function GameSectionPage() {
  const {getToken} = AuthUser();
  if(!getToken()){
    return <GameGuest />
  }
  return (
      <GameAuth />
  );
}

export default GameSectionPage;