import React, { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { ListBookContent } from "./Content";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import AuthUser from "../../Services/AuthUser";
import { useTheme } from "@mui/material/styles";



export default function BookContent(props) {
  const [expanded, setExpanded] = React.useState("panel");
  const theme = useTheme();
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.box.main,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={
        <ArrowForwardIosSharpIcon
          sx={{ fontSize: "1rem",color:theme.palette.box.border, }}
        />
      }
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: theme.palette.box.main,
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [instDir, setInstDir] = useState([]);
  const { http } = AuthUser();
  useEffect(() => {
    http
      .get("/inst-dir-player")
      .then((response) => {
        setInstDir(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <Accordion>
        <ListItemButton component="a" href="/studentbook/intro">
          <ListItemText sx={{ ml: 3 }}>Введение</ListItemText>
        </ListItemButton>
      </Accordion>
      {instDir &&
        instDir.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
          >
            <AccordionSummary
              aria-controls={`panel${index}d-content`}
              id={`panel${index}d-header`}
            >
              <Typography>{item.inst_name}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              {item.directions && (
                <List component="div" disablePadding>
                  {item.directions.map((child, j) => (
                    <ListItemButton
                      sx={{ pl: 4 }}
                      key={j}
                      value={child.dir_name}
                      component="a"
                      href={
                        "/studentbook/" + item.inst_url + "/" + child.dir_url
                      }
                    >
                      <ListItemText primary={child.dir_name} />
                    </ListItemButton>
                  ))}
                </List>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      <Accordion>
        <ListItemButton component="a" href="/studentbook/conclusion">
          <ListItemText sx={{ ml: 3 }}>Заключение</ListItemText>
        </ListItemButton>
      </Accordion>
    </div>
  );
}
