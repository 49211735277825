import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AuthUser from "../../../../Services/AuthUser";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';

const AddStoryModal = (props) => {
  const [storyName, setStoryName] = useState("");
  const [storyText, setStoryText] = useState("");
  const [storyIDdirection, setStoryIdirection] = useState("");
  const [isModerated, setIsModerated] = useState(false);
  const { token, http } = AuthUser();
  const { handleAddStory } = props;
  const [directions, setDirections] = useState([]);

  const handleSubmit = () => {
    const data = {
      story_name: storyName,
      story_text: storyText,
      story_id_dir: storyIDdirection,
      is_moderated: isModerated,
    };
    http
      .post("/stories", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        handleAddStory((prevStories) => [...prevStories, res.data]);
        props.close();
      });
  };

  useEffect(() => {
    const getDirections = async () => {
      const response = await http.get("/directions", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setDirections(response.data);
    };
    getDirections();
  }, []);

  const handleChangeIsModerated = (event) => {
    setIsModerated(event.target.checked);
  };

  return (
    <Dialog open={props.open} onClose={props.close} fullWidth maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={props.close}
        sx={{
          position: "absolute",
          top: 12,
          right: 12,
          color: (theme) => theme.palette.secondary.main,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>Добавить историю</DialogTitle>

      <DialogContent>
        <TextField
          autoFocus
          margin="normal"
          id="name_dir"
          label="Название истории"
          type="text"
          fullWidth
          variant="outlined"
          value={storyName}
          onChange={(e) => setStoryName(e.target.value)}
          required
        />
        <TextField
          autoFocus
          margin="normal"
          id="name_dir"
          label="Текст истории"
          type="text"
          fullWidth
          variant="outlined"
          multiline
          rows={20}
          value={storyText}
          onChange={(e) => setStoryText(e.target.value)}
          required
        />
        <TextField
          margin="normal"
          id="dir_id_inst"
          label="Направление"
          select
          fullWidth
          value={storyIDdirection}
          onChange={(e) => setStoryIdirection(e.target.value)}
          required
        >
          {directions.map((dir) => (
            <MenuItem key={dir.id} value={dir.id}>
              {dir.dir_name}
            </MenuItem>
          ))}
        </TextField>
        <FormControlLabel
          control={
            <Checkbox
              checked={isModerated}
              onChange={handleChangeIsModerated}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Модерация"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} variant="contained">
          Отмена
        </Button>
        <Button type="submit" variant="contained" onClick={handleSubmit}>
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddStoryModal;
