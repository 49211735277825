import "./components/style.css";
import React, { useEffect, useState } from "react";
import Footer from "../../Components/footer/Footer";
import Header from "../../Components/header/Header";
import { Box, Container } from "@mui/material";
import CardAbout from "./components/CardAbout";
import { AboutUs } from "./components/AboutContent";
import Typography from "@mui/material/Typography";

export default function AboutUsPage() {
  const [selectedId, setSelectedId] = useState(3);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <Container maxWidth="xl" sx={{ mt: 7 }}>
          <Box
            sx={{
              width: "90%",
              margin: "0 auto"
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h2"
                align="center"
                mb={3}
                sx={{
                  fontWeight: "bold",
                  '@media (max-width: 500px)': {
                    fontSize: '8vw',
                  },
                }}
              >
                Команда создателей
              </Typography>
            </Box>
            <Typography variant="h5" gutterBottom align="center"
            sx={{
              '@media (max-width: 800px) and (min-width: 500px)': {

              },
              '@media (max-width: 500px)': {
                fontSize: '4vw',
                textAlign: "justify"
              },
            }}>
              Мы команда амбициозных студентов и выпускников ТюмГУ, объединенных
              общей целью - помочь абитуриентам в выборе направления обучения.
              Именно для этого, при при поддержке Фонда содействия инновациям и
              была разработана P.U.P.A. Наша команда уделяла особое внимание
              тщательному исследованию образовательных программ ТюмГУ, чтобы
              предоставить пользователям максимально точную информацию о каждом
              направлении обучения. Мы не только собрали все необходимые данные,
              чтобы игра стала надежным источником информации для принятия
              важного решения, но и постарались изложить материал в
              увлекательном формате. Надеемся, что P.U.P.A. поможет молодым
              людям найти свое призвание и достичь успеха в выбранной сфере! Мы
              всегда открыты для обратной связи и готовы совершенствовать
              P.U.P.A., чтобы она оставалась актуальной и полезной!
            </Typography>
            <Box
            style={{
              height:"100%",
              width: "100%",
              marginTop: "50px",
              '@media (max-width: 800px)': {
                fontSize: '5vw',
              },
            }}
          >
            <input
              type="radio"
              name="card-carusel"
              id="t-1"
              style={{ display: "none" }}
            />
            <input
              type="radio"
              name="card-carusel"
              id="t-2"
              style={{ display: "none" }}
            />
            <input
              type="radio"
              name="card-carusel"
              id="t-3"
              defaultChecked
              style={{ display: "none" }}
            />
            <input
              type="radio"
              name="card-carusel"
              id="t-4"
              style={{ display: "none" }}
            />
            <input
              type="radio"
              name="card-carusel"
              id="t-5"
              style={{ display: "none" }}
            />
            <div className="card-carusels">
              {AboutUs.map((item, i) => (
                <label
                  key={i}
                  className="item"
                  htmlFor={`t-${item.id}`}
                  onClick={() => setSelectedId(item.id)}
                >
                  <CardAbout
                    image={item.image}
                    positionY={item.positionY}
                    color={item.color}
                    colorGradient={item.colorGradient}
                    name={item.name}
                    label={item.label}
                    desc={item.desc}
                    id={item.id}
                    selectedId={selectedId}
                  />
                </label>
              ))}
            </div>
            {/* <br /> */}
            <div className="dots">
              <label for="t-1"></label>
              <label for="t-2"></label>
              <label for="t-3"></label>
              <label for="t-4"></label>
              <label for="t-5"></label>
            </div>
          </Box>
          </Box>
          
        </Container>

        <Footer />
      </div>
    </>
  );
}
