import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_math_class = require("../../../assets/imikn/backgrounds/imikn_math_class_.jpg");

// character
const guide_smile = require("../../../assets/imikn/characters/guide_smile.png");
const volan_yul = require("../../../assets/imikn/characters/volan_yul.png");
const volan_yul_serious = require("../../../assets/imikn/characters/volan_yul_serious.png");
const volan_yul_serious_smile = require("../../../assets/imikn/characters/volan_yul_serious_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";
const volan_yul_color = "#8C8C8C";

let teacher_volan_yul = {
  Start: [
    {
      body: [
        {
          background: imikn_math_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: volan_yul,
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Здравствуйте! Не уделите ли мне немного своего времени, пожалуйста?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Здрасьте, конечно, можем и поговорить",
        },
        {
          rightSprite: volan_yul_serious,
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Что ж, сказали мне, значит, рассказать Вам про всякое и советов дать",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Только понять не могу, с каких пор молодые советов просят",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Вы же дети твиттера и инстаграмма, куда вам до опыта старших. А впрочем ладно...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Сказать, значит, вот что хочу: учитесь, а в обучении ищите саму суть всего",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Необходимо досконально обозревать, вникать, а главное иметь понимание всего, что изучаете",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Я не совсем понимаю...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Вот дают задачку",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Петя едет на велосипеде со скоростью 5 км/ч, а Вова 3 км/ч",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Вова выезжает первым, а через 5 минут за ним следует Петя. Через сколько минут Петя догонит Вову?",
        },
      ],
    },
    {
      choices: [
        "Через семь с половиной минут|volan_yul_ans_1",
        "Через 5 минут|volan_yul_ans_2",
        "Не знаю|volan_yul_ans_3",
      ],
    },
  ],

  volan_yul_ans_1: [
    {
      body: [
        {
          background: imikn_math_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: volan_yul_serious_smile,
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Похвально, похвально!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Слёту посчитали, молодец!",
          achiev_child_id: 12, //2.3
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "volan_yul_continue",
    },
  ],

  volan_yul_ans_2: [
    {
      body: [
        {
          background: imikn_math_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: volan_yul_serious,
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Какое у вас поколение-то...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Глупое!!!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Ну не можете Вы в голове посчитать, так попросите же ручку и бумагу. Не просто так ведь их придумали!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Хоспади, дай мне сил учить таких... блогеров и стримеров...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "volan_yul_continue",
    },
  ],

  volan_yul_ans_3: [
    {
      body: [
        {
          background: imikn_math_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: volan_yul_serious,
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Вы что, с математикой в ссоре?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Или она Вас бросила?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "А впрочем, не важно...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "volan_yul_continue",
    },
  ],

  volan_yul_continue: [
    {
      body: [
        {
          background: imikn_math_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: volan_yul_serious,
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Но Вы лучше задумайтесь, почему это Вова выезжает первым?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Что за правила в этой гонке? Почему один едет быстрее второго?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Дальше вычисление...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Почему именно такая формула, откуда она взялась, что за буквы в ней...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Почему именно такие буквы и в таком порядке? И так далее, понимаете?",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Хмм, кажется понимаю...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Да ничего Вы не понимаете, бананово-клубничное поколение!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Суть обучения-то в ВО-ПРО-САХ!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Если их не задавать, а просто решать по заданному алгоритму, то чем же Вы от машин отличаетесь?",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Наверное Вы правы... В таком случае, у меня к Вам несколько вопросов",
        },
        {
          rightSprite: volan_yul_serious_smile,
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Вот! Правильный подход, задавайте!",
        },
      ],
    },
    {
      choices: [
        "Какие предметы Вы преподаёте?|volan_yul_ans_4",
        "А правда, что китайцы скоро захватят мир?|volan_yul_ans_5",
      ],
    },
  ],

  volan_yul_ans_5: [
    {
      body: [
        {
          background: imikn_math_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: volan_yul_serious_smile,
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Конечно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Чем меньше студентов хорошо учатся, тем больше новых территорий захватывают китайцы!",
        },

        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Поэтому нужно изучать мои предметы!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "volan_yul_ans_4",
    },
  ],

  volan_yul_ans_4: [
    {
      body: [
        {
          background: imikn_math_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: volan_yul_serious,
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Очень важные!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Алгебру, математическую логику, теорию чисел",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Но это ещё без элективных дисциплин, которые просто для души",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Про них ничего не расскажу Вам, а то испугаетесь и не выберете...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "В целом, могу сказать - дисциплины полезные и важные! Повторюсь!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Стоит их изучать абсолютно всем без исключения ещё в школе, но министерство образования не слушает...",
        },
      ],
    },
    {
      choices: [
        "Как Вы проводите занятия?|volan_yul_ans_6",
        "Спасибо, у меня больше нет вопросов|volan_yul_continue_2",
      ],
    },
  ],

  volan_yul_ans_6: [
    {
      body: [
        {
          background: imikn_math_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: volan_yul_serious,
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "На лекциях я консервативен, диктую студентам материал из головы, а студенты записывают",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Ведь только написав вручную, можно хоть что-то запомнить!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Важно, что лекция должна быть не по учебнику, ведь издания и редакция меняются, определения искажаются...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "А необходимо придерживаться точных формулировок, поэтому моя голова надёжнее!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "А практические занятия недаром так названы... Главное - практика, испокон веков так было, есть и будет",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "volan_yul_continue_2",
    },
  ],

  volan_yul_continue_2: [
    {
      body: [
        {
          background: imikn_math_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: volan_yul_serious,
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Хотелось бы вот что ещё добавить...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Помните - управление временем - самое главное, оно поможет во всём - в обучении, работе, семейной жизни и так далее...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Времени отведено не так много и необходимо грамотно им управлять",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "А мое как раз поджимает!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Мне нужно спешить на защиту проектов...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Кстати, не хотите ли со мной пойти? Вам точно будет не только интересно, но и очень полезно",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А в чем полезность?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Вы же тоже столкнетесь с защитой по ходу обучения на любом направлении. Так что, пойдете?",
        },
      ],
    },
    {
      choices: [
        "Да, почему нет|volan_yul_yes",
        "Нет, мне пора идти|volan_yul_no",
      ],
    },
  ],

  volan_yul_yes: [
    {
      body: [
        {
          background: imikn_math_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: volan_yul_serious,
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Отлично, жду Вас в аудитории",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "volan_yul_exit",
    },
  ],

  volan_yul_no: [
    {
      body: [
        {
          background: imikn_math_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: volan_yul_serious,
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Жаль, но дело Ваше... *Пожимает плечами*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Воланд Юлианович",
          speakColor: volan_yul_color,
          text: "Если все же надумаете, можете подходить в аудиторию. Только заходите тихо. До свидания!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "До свидания!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "volan_yul_exit",
    },
  ],

  volan_yul_exit: [
    {
      body: [
        {
          background: imikn_math_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_smile,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Да уж, Воланд Юлианович как всегда в своём репертуаре...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Интересная личность...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: ["volan_yul_ans_5", "volan_yul_exit_2", "volan_yul_exit_1"],
    },
  ],

  volan_yul_exit_1: [
    {
      body: [
        {
          background: imikn_math_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_smile,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Ладно, пойдём дальше...",
        },
      ],
    },
    {
      exits: true,
    },
  ],

  volan_yul_exit_2: [
    {
      body: [
        {
          background: imikn_math_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_smile,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "А что за вопрос про китайцев?",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Да это была шутка, но кажется он этого не понял...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Ха-ха-ха, да ничего! Он может и понял, просто подыграл. Пойдём дальше",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default teacher_volan_yul;
