import {
    getSpriteCharacterOfGender,
    getNamePlayer,
} from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_first_coridor = require("../../../../assets/imikn/backgrounds/imikn_first_coridor.jpg");

// character
const guide_smile = require("../../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let teachers_intro_2 = {
    Start: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Как успехи? Нашелся кто-нибудь?",
                },
            ],
        },
        {
            choices: [
                "Да|teachers_intro_2_yes",
                "Нет|teachers_intro_2_no",
            ],
        },
    ],

    teachers_intro_2_yes: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну так вперед, расспрашивай обо всем преподавателя, а не со мной разговаривай. Я-то никуда не денусь...",
                },
                {
                    nameSpeak: getNamePlayer(),
                    speakSprite: "left",
                    speakColor: player_color,
                    text: "Точно, пойду...",
                },
            ],
        },
        {
            exits: true,
        },
    ],

    teachers_intro_2_no: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Может ещё раз пройдешься и поищешь кого-нибудь? Наверняка кто-то остался незамеченным...",
                },
            ],
        },
        {
            exits: true,
        },
    ],
};

export default teachers_intro_2;
