import Drawer from "../../../Components/adminpanel/Drawer";
import ListIndex from "./components/ListIndex";
import Heading from "../../../Components/adminpanel/Heading";

export default function Index() {
  return (
    <>
      <Drawer  main={<ListIndex />} />
    </>
  );
}
