import Drawer from "../../Components/adminpanel/Drawer";
import ListWidjet from "./widjet/ListWidjet";

export default function Dashboard() {
  const userdetail = JSON.parse(localStorage.getItem("user"));

  function renderElement() {
    if (userdetail) {
      return (
        <>
          {<ListWidjet />}{" "}
          <div>
            <h4>Username</h4>
            <p>{userdetail.username}</p>
            <h4>Gender</h4>
            <p>{userdetail.gender}</p>
            <h5>{userdetail.roles}</h5>
          </div>
        </>
      );
    } else {
      return <p>Loading.....</p>;
    }
  }

  return (
    <>
      <Drawer main={renderElement()} />
    </>
  );
}
