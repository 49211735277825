import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_computer_class = require("../../../assets/imikn/backgrounds/imikn_computer_class.jpg");

// character
const dobr_roman = require("../../../assets/imikn/characters/dobr_roman.png");
const dobr_roman_smile = require("../../../assets/imikn/characters/dobr_roman_smile.png");
const secretary = require("../../../assets/imikn/characters/secretary.png");

//color
const player_color = "#D4D4D4";
const dobr_roman_color = "#BAA9A0";
const secretary_color = "#F5F2BF";

let teacher_dobr_roman = {
  Start: [
    {
      body: [
        {
          background: imikn_computer_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Здравствуйте, здравствуйте! Очень рад, очень рад!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Добрый день!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Ну что ж, очень рад, что Вы к нам сюда заглянули!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Вы не волнуйтесь, вербовать Вас на какое-то конкретное направление я не буду, это дело других преподавателей, хе-хе-хе",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Я просто хотел с Вами поговорить, узнать, как дела, определились ли Вы с направлением?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Не знаю, где Вы уже были, а где нет, может ко мне зашли в последнюю очередь и выбор уже сделан...",
        },
      ],
    },
    {
      choices: [
        "Да, есть уже определённые мысли, куда поступать|dobr_roman_yes",
        "Нет, я ещё в растерянности...|dobr_roman_no",
      ],
    },
  ],

  dobr_roman_yes: [
    {
      body: [
        {
          background: imikn_computer_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Это хорошо, очень хорошо!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Очень рад за Вас!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Конечно, если передумаете, ничего страшного, будет время всё изменить",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Я думаю, хорошо они это придумали, меньше стресса у детей станет...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Стресс вообще, знаете, дело такое неприятное",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Поэтому я очень за Вас рад!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "dobr_roman_exit",
    },
  ],

  dobr_roman_no: [
    {
      body: [
        {
          background: imikn_computer_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_roman,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Ну ничего, ничего",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Редко, когда человек полностью уверен при выборе чего-либо",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Сомнения – наша неотъемлемая часть жизни",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Поэтому ничего страшного!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Думаю, нет, уверен, у Вас всё будет просто замечательно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Главное не волнуйтесь, а то волнения приводят к стрессу, а это очень неприятная штука!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Хорошо!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "dobr_roman_exit",
    },
  ],

  dobr_roman_exit: [
    {
      body: [
        {
          background: imikn_computer_class,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Знаете, у Вас точно всё будет хорошо!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Спасибо, Вы очень добры",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Я просто оптимист, так сказать",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Пытаюсь во всём видеть исключительно плюсы. Ведь в нашей жизни и так хватает невзгод, зачем каждый день о них думать?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Давайте я Вам что-нибудь расскажу... Придумал!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Давайте, очень интересно будет послушать! Интригующе...",
        },
        {
          rightSprite: dobr_roman,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Во-первых, хотел бы сказать насчёт одной вещи, которой пугают в школе",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "“Мол, тут я за тобой Вовочка бегаю, чтобы ты два в четверти не получил, а в университете всем будет плевать, отчислят и всё тут”",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Отчасти это действительно так, но в большинстве случаев точно так же бегают!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Во мне, в таких случаях, борются два мнения...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "«Они уже взрослые, пусть сами отвечают за свои поступки»",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "И «они же ещё дети, ничего не знают, сейчас вот бьют баклуши, а завтра плакать будут»",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Поэтому у меня к Вам будет только одна просьба: не доводите ситуацию до такой степени, чтобы за Вами пришлось бегать...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Вы же понимаете, преподаватели уже не молодые, хоть и есть исключения, студентов много, времени мало...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Ещё и своя собственная жизнь есть, семья, дети...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Вы же не только своё, но и наше время отнимать будете, а там стресс...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Конечно! У меня даже в планах не было, кого-то донимать, и учиться я собираюсь хорошо. А что во-вторых?",
        },
        {
          rightSprite: dobr_roman_smile,
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Ха-ха, а Вы очень внимательно слушали... похвально",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "А, во-вторых, если вы сюда пришли в надежде, что вас будут чему-то учить, то вас нагло обманули!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Что? Это как же так?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "А вот так!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Не пугайтесь Вы так! У меня не начался бред, всё вполне логично, послушайте",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "В школе учатся дети, у них легко рушится дисциплина, их нужно воспитывать и учить одновременно",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Поэтому заведено, что учитель – учит, то есть говорит вещи, которые ученик должен дословно запоминать и не ставить под сомнение...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "А в университете – преподаватель. Он преподаёт. Это совсем другое...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Задача преподавателя не научить, а рассказать о чём-либо, обсудить со студентом, подискутировать с ним...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Развить критическое мышление, чтобы у него появилось собственное мнение по любому вопросу",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "То есть любые слова преподавателя студент может ставить под сомнение, понимаете?",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Сомневаюсь, может быть это не правда?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Да Вы очень способны! Ха-ха-ха, сейчас я не преподаю, а учу!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Тогда всё понятно, спасибо!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Вот так вот, очень рад, очень рад",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Мне сказали, что Вы проводите учебную практику у студентов механики и робототехники, это правда?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Не знаю, кто Ваш информатор, но он совершенно прав! Хе-хе-хе",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А можете поведать мне о ней, говорят, там происходит что-то очень интересное!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "И тут Вы абсолютно правы!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Практика у студентов МиРа, какое милое сокращение направления, хе-хе-хе, проходит с использованием станций Festo",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Это такое специальное оборудование, которое можно как конструктор собирать и пересобирать в различные приборы",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ого! А что за приборы?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Говорю же, раз-лич-ны-е! Хе-хе-хе",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "На самом деле, сейчас Вам просто нет необходимости знать о них что-то конкретное, это обязанность студентов МиРа!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "В процессе сборки и использования таких приборов, студенты могут на практике применить лекционные знания, то есть теорию!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Я считаю, что это полезнее для будущих специалистов, чем подготовка каких-то не прикладных проектов!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Конечно, проекты тоже бывают разные...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Кстати, сегодня как раз будет проходить защита некоторых студенческих работ",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Можете заглянуть и на деле посмотреть, чем занимаются люди в университете!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Хорошо!",
        },
        {
          centerSprite: secretary,
          speakSprite: "center",
          nameSpeak: "Секретарь",
          speakColor: secretary_color,
          text: "Добрыня Романович! Вам уже нужно идти на совещание...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Да, передайте, сейчас приду!",
        },
        {
          centerSprite: "",
          speakSprite: "right",
          nameSpeak: "Добрыня Романович",
          speakColor: dobr_roman_color,
          text: "Спасибо, что зашли ко мне, надеюсь был полезен и Вам это поможет в будущем...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Вам спасибо! Вы рассказали вещи, о которых мне не приходилось задумываться до этого времени. До свидания!",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default teacher_dobr_roman;
