import React, { useState, useEffect } from "react";
import axios from "axios";
import { Autocomplete, Button, TextField } from "@mui/material";
import AuthUser from "../../Services/AuthUser";
import Chip from "@mui/material/Chip";
import DoneIcon from "@mui/icons-material/Done";
import LoadingButton from "@mui/lab/LoadingButton";

export default function KeywordSelect(props) {
  const { http } = AuthUser();
  const [existingKeywords, setExistingKeywords] = useState([]);
  const [newKeyword, setNewKeyword] = useState("");

  useEffect(() => {
    http
      .get("/keywords")
      .then((response) => {
        setExistingKeywords(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleAddKeyword = () => {
    if (newKeyword) {
      // console.log(newKeyword);
      props.handleAddKeyword(newKeyword.keyword);
      setNewKeyword("");
    }
  };

  const [keywordPython, setKeywordPython] = useState([]);
  const [isPython, setIsPython] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleGenerateKeyword = () => {
    setLoading(true);

    // console.log(props.storyID);
    http
      .get(`/keywords/${props.storyID}/python`)
      .then((response) => {
        // console.log(response.data);
        setKeywordPython(response.data);
        setIsPython(true);
        console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (index, keyword) => {
    const updatedKeywords = [...keywordPython];
    updatedKeywords.splice(index, 1);
    // console.log(updatedKeywords);
    setKeywordPython(updatedKeywords);
    props.handleAddKeyword(keyword);
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        <Autocomplete
          options={existingKeywords.concat({ keyword: newKeyword })}
          fullWidth
          getOptionLabel={(option) => option.keyword || ""}
          value={newKeyword}
          onChange={(event, newValue) => setNewKeyword(newValue)}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label="Ключевое слово"
              fullWidth
              margin="normal"
            />
          )}
        />
      </div>

      <Button variant="contained" onClick={handleAddKeyword}>
        Добавить слово
      </Button>
      <LoadingButton
        onClick={handleGenerateKeyword}
        loading={loading}
        loadingIndicator="Loading…"
        variant="contained"
        sx={{ m: 1 }}
      >
        <span>Сгенерировать ключевые слова</span>
      </LoadingButton>
      {/* <Button variant="contained" onClick={handleGenerateKeyword} sx={{ m: 1 }}>
        Сгенерировать ключевые слова
      </Button> */}
      {isPython &&
        keywordPython.map((keyword, index) => (
          <Chip
            key={index}
            label={keyword}
            onDelete={() => handleDelete(index, keyword)}
            deleteIcon={<DoneIcon />}
            variant="outlined"
          />
        ))}
    </>
  );
}
