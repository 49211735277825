import AuthUser from '../../Services/AuthUser';
import Guest from '../navbar/GuestNav';
import Auth from '../navbar/AuthNav';

function Header() {
  const {getToken} = AuthUser();
  if(!getToken()){
    return <Guest />
  }
  return (
      <Auth />
  );
}

export default Header;

