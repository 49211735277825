import { Box, Container } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import TextBox from "./components/TextBox";
import { setVisitedNode, jumpCheckVisited } from "./scripts/CheckVisited";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import AuthUser from "../../Services/AuthUser";
import NotificanSnackbar from "./components/NotificanSnackbar";
import { setVisibleTeacher } from "./scripts/VisibleTeacher";
import { setVisibleLesson } from "./scripts/VisibleLesson";

function DialogueSystem({
  stories,
  setIsDialogue,
  setGame,
  checkAchievParams,
}) {
  const handle = useFullScreenHandle();
  const [currentNode, setCurrentNode] = useState("Start"); // отслеживаем текущий узел истории
  const [currentBodyIndex, setCurrentBodyIndex] = useState(0); // отслеживаем текущий индекс элемента body
  const [showChoiceButton, setShowChoiceButton] = useState(false); // отслеживаем, нужно ли отображать

  const { body = {} } = stories[currentNode]?.[0] || {};
  const { choices = [] } = stories[currentNode]?.[1] || [];
  const { jump = "" } = stories[currentNode]?.[2] || "";

  const [previous, setPrevios] = useState({
    background: null,
    spriteLeft: null,
    spriteCenter: null,
    spriteRight: null,
  });

  const current = {
    background: body[currentBodyIndex]?.background || previous.background,
    spriteLeft: body[currentBodyIndex]?.leftSprite || previous.spriteLeft,
    spriteCenter: body[currentBodyIndex]?.centerSprite || previous.spriteCenter,
    spriteRight: body[currentBodyIndex]?.rightSprite || previous.spriteRight,
  };

  useEffect(() => {
    // const intervalId = setInterval(checkAchievParams()?setAchievChildUser(checkAchievParams()):null, 10000); // Запускаем каждые 10 секунд

    // if (checkAchievParams()) {
    //   setAchievChildUser(checkAchievParams());
    // }
    setCurrentBodyIndex(0);
    setPrevios({
      background:
        body[currentBodyIndex]?.background !== "" &&
        body[currentBodyIndex]?.background
          ? body[currentBodyIndex]?.background
          : null,
      spriteLeft:
        body[currentBodyIndex]?.leftSprite !== "" &&
        body[currentBodyIndex]?.leftSprite
          ? body[currentBodyIndex]?.leftSprite
          : null,
      spriteCenter:
        body[currentBodyIndex]?.centerSprite !== "" &&
        body[currentBodyIndex]?.centerSprite
          ? body[currentBodyIndex]?.centerSprite
          : null,
      spriteRight:
        body[currentBodyIndex]?.rightSprite !== "" &&
        body[currentBodyIndex]?.rightSprite
          ? body[currentBodyIndex]?.rightSprite
          : null,
    });
    setShowChoiceButton(false);
    // return () => {
    //   clearInterval(intervalId);
    // };
  }, [currentNode, body]);

  const localUser = JSON.parse(localStorage.getItem("user"));
  const { http, token } = AuthUser();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [messageSnackbar, setMessageSnackbar] = useState(false);
  const handleSuccess = () => {
    // Обработка успешной отправки запроса
    setShowSnackbar(true);
    // Скрыть компонент через 5 секунд
    setTimeout(() => {
      setShowSnackbar(false);
    }, 5000);
  };
  const handleClose = () => {
    setShowSnackbar(false);
  };
  const setAchievChildUser = async (child_id) => {
    if (localUser) {
      const res = await http.get(`/achievements/${child_id}`);
      console.log(res.data);
      if (res.data === 0) {
        await http
          .put(`/users/${localUser.id}/achievements/${child_id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setMessageSnackbar("Получено новое достижение");
            handleSuccess();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  const teacher = JSON.parse(localStorage.getItem("teacher"));
  const lesson = JSON.parse(localStorage.getItem("lesson"));
  // обработчик для кнопки "Next"
  const handleNext = () => {
    setVisitedNode(currentNode); // отмечаем текущий узел как посещенный

    if (stories[currentNode] && currentBodyIndex < body.length - 1) {
      if (checkAchievParams()) {
        setAchievChildUser(checkAchievParams());
      }
      setCurrentBodyIndex(currentBodyIndex + 1);
      setPrevios({
        background:
          body[currentBodyIndex + 1]?.background !== ""
            ? body[currentBodyIndex]?.background || previous.background
            : null,
        spriteLeft:
          body[currentBodyIndex + 1]?.leftSprite !== ""
            ? body[currentBodyIndex]?.leftSprite || previous.spriteLeft
            : null,
        spriteCenter:
          body[currentBodyIndex + 1]?.centerSprite !== ""
            ? body[currentBodyIndex]?.centerSprite || previous.spriteCenter
            : null,
        spriteRight:
          body[currentBodyIndex + 1]?.rightSprite !== ""
            ? body[currentBodyIndex]?.rightSprite || previous.spriteRight
            : null,
      });
      if (body[currentBodyIndex + 1]?.achiev_child_id) {
        console.log(
          "achiev_child_id",
          body[currentBodyIndex + 1]?.achiev_child_id
        );
        setAchievChildUser(body[currentBodyIndex + 1]?.achiev_child_id);
      }
      if (body[currentBodyIndex + 1]?.visibleTeacher) {
        if (!teacher[body[currentBodyIndex + 1]?.visibleTeacher].visible) {
          setVisibleTeacher(body[currentBodyIndex + 1]?.visibleTeacher);
          setMessageSnackbar("Открыт новый преподаватель");
          handleSuccess();
        }
      }
      if (body[currentBodyIndex + 1]?.visibleLesson) {
        if (!lesson[body[currentBodyIndex + 1]?.visibleLesson].visible) {
          setVisibleLesson(body[currentBodyIndex + 1]?.visibleLesson);
          setMessageSnackbar("Открыта новая локация");
          handleSuccess();
        }
      }
    } else if (stories[currentNode]?.[1] && stories[currentNode]?.[1].game) {
      setIsDialogue(false);
      setGame(stories[currentNode]?.[1].game);
    } else if (stories[currentNode]?.[1] && stories[currentNode]?.[1].exits) {
      setIsDialogue(false);
    }
    // отобразить выборы если они есть
    else if (choices && !jump) {
      setShowChoiceButton(true);
    }
    // прыжок в узел без выбора
    else if (jump) {
      if (Array.isArray(jump)) {
        const checkJump = jumpCheckVisited(jump[0], jump[1], jump[2]);
        setCurrentNode(checkJump);
        setCurrentBodyIndex(0);
      } else {
        setCurrentNode(jump);
        setCurrentBodyIndex(0);
      }
    }
  };
  const checkNameSpeakCharacter = (name) => {
    if (Array.isArray(name)) {
      const checkName = jumpCheckVisited(name[0], name[1], name[2]);
      return checkName;
    } else return name;
  };
  // обработчик для выбора варианта
  const handleChoice = (choice) => {
    const [nextNode] = choice.split("|")[1].split(":");
    setCurrentNode(nextNode);
    setCurrentBodyIndex(0);
  };
  const [imageWidth, setImageWidth] = useState(0);
  const imgRef = useRef();

  const handleImageLoad = (event) => {
    setImageWidth(event.target.offsetWidth);
    //console.log(event.target.offsetWidth);
    //console.log(imageWidth)
  };
  useEffect(() => {
    const handleResize = () => {
      if (imgRef.current) {
        // console.log(imgRef.current.offsetWidth);
        setImageWidth(imgRef.current.offsetWidth);
        console.log(imgRef.current.offsetWidth);
        // console.log(imageWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const checkSpeakSrite = (positionSprite) => {
    if (body[currentBodyIndex]?.speakSprite === positionSprite) return true;
    else return false;
  };

  const checkSpeakSriteFullscreen = (positionSprite) => {
    if (body[currentBodyIndex]?.speakSprite === positionSprite) return true;
    else return false;
  };
  const getSpriteStyle = (positionSprite) => {
    if (handle.active) return checkSpeakSriteFullscreen(positionSprite);
    else return checkSpeakSrite(positionSprite);
  };
  return (
    <>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: "100%",
        }}
        onClick={handleNext}
      >
        <img
          src={current.background ? current.background : "none"}
          alt={current.background ? current.background : "none"}
          style={{
            backgroundSize: "cover",
            position: "relative",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
        />
        <NotificanSnackbar
          showSnackbar={showSnackbar}
          message={messageSnackbar}
          handleClose={handleClose}
        />

        <div
          style={{
            position: "absolute",
            left: "0%",
            bottom: 0,
            width: "33.3333333333%",
            height: "100%",
          }}
        >
          {current.spriteLeft ? (
            <img
              src={current.spriteLeft}
              alt={current.spriteLeft}
              style={{
                objectFit: "contain",
                // width: "100%",
                transform: "translateX(0%) translateY(0%)",
                height: "100%",
                transition: "transform 0.3s ease-out, filter 0.3s ease-out",
                transform: getSpriteStyle("left") ? "scale(1.03)" : "scale(1)",
                transformOrigin: "bottom",
                filter: getSpriteStyle("left")
                  ? "brightness(1)"
                  : "brightness(0.5)",
              }}
            />
          ) : (
            <></>
          )}
        </div>
        <div
          style={{
            position: "absolute",
            left: "50%",
            bottom: 0,
            width: "100%",
            height: "100%",
            transform: "translateX(-50%) translateY(0%)",
          }}
        >
          {current.spriteCenter ? (
            <img
              src={current.spriteCenter}
              alt={current.spriteCenter}
              style={{
                objectFit: "contain",
                // top: "50%",
                margin: "0 auto",
                display: "block",
                // transform: "translateX(0%) translateY(0%)",
                height: "100%",
                transition: "transform 0.3s ease-out, filter 0.3s ease-out",
                transform: getSpriteStyle("center")
                  ? "scale(1.03)"
                  : "scale(1)",
                transformOrigin: "bottom",
                filter: getSpriteStyle("center")
                  ? "brightness(1)"
                  : "brightness(0.5)",
              }}
            />
          ) : (
            <></>
          )}
        </div>
        <div
          style={{
            position: "absolute",
            // right: imageWidth > 265 ? `calc(45% - ${imageWidth}px)` : 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            // width:
            //   imageWidth > 220 ? "100%" : "33.33%",
          }}
        >
          {current.spriteRight ? (
            <img
              // ref={imgRef}\
              src={current.spriteRight}
              alt={current.spriteRight}
              onLoad={handleImageLoad}
              style={{
                objectFit: "contain",
                objectPosition: "right bottom",
                // transform: `translateX(70%) translateY(0%)`,

                height: "100%",
                transition: "transform 0.3s ease-out, filter 0.3s ease-out",
                transform: getSpriteStyle("right") ? "scale(1.03)" : "scale(1)",
                transformOrigin: "bottom",
                filter: getSpriteStyle("right")
                  ? "brightness(1)"
                  : "brightness(0.5)",
                // marginLeft: imageWidth > 220 ? "-40%" : 0,
                // marginLeft:
                //   imageWidth > 220 ? `calc(100% - ${imageWidth}px)` : 0,
                // right:  `calc(100% - ${imageWidth}px)`,
                float: "right",
                marginRight: "1.5%",
              }}
            />
          ) : (
            <></>
          )}
        </div>

        <TextBox
          characterName={checkNameSpeakCharacter(
            body[currentBodyIndex]?.nameSpeak
          )}
          colorName={body[currentBodyIndex]?.speakColor}
          dialogueText={body[currentBodyIndex]?.text}
          choices={choices}
          handleChoice={handleChoice}
          showChoiceButton={showChoiceButton}
          // displayText={displayText}
          // setDisplayText={setDisplayText}
          // stopAnimation={stopAnimation}
        />
      </Box>
    </>
  );
}
export default DialogueSystem;
