import { useState, useEffect } from "react";
import DialogueSystem from "../../../Components/dialogueSystem/DialogueSystem";
import BoxGame from "../../../Components/gameSection/BoxGame";
import SectionDirection from "../../../Pages/gameSection/SectionDirectionPage";
import AlbomTeacher from "./components/AlbomTeacher";
import AuthUser from "../../../Services/AuthUser";
import teacher_intro_1 from "../../dialogues/data/imikn/teacher/intro/teachers_intro_1";
import teacher_intro_2 from "../../dialogues/data/imikn/teacher/intro/teachers_intro_2";
import teacher_intro_3 from "../../dialogues/data/imikn/teacher/intro/teachers_intro_3";
import teacher_intro_4 from "../../dialogues/data/imikn/teacher/intro/teachers_intro_4";
import teacher_intro_5 from "../../dialogues/data/imikn/teacher/intro/teachers_intro_5";
import teacher_intro_6 from "../../dialogues/data/imikn/teacher/intro/teachers_intro_6";
import teacher_intro_7 from "../../dialogues/data/imikn/teacher/intro/teachers_intro_7";

import teacher_anas_suleim from "../../dialogues/data/imikn/teacher/teacher_anas_suleim";
import teacher_ann_evst from "../../dialogues/data/imikn/teacher/teacher_ann_evst";
import teacher_dobr_luc from "../../dialogues/data/imikn/teacher/teacher_dobr_luc";
import teacher_dobr_roman from "../../dialogues/data/imikn/teacher/teacher_dobr_roman";
import teacher_emm_stal from "../../dialogues/data/imikn/teacher/teacher_emm_stal";
import teacher_evd_pankr from "../../dialogues/data/imikn/teacher/teacher_evd_pankr";
import teacher_lud_vas from "../../dialogues/data/imikn/teacher/teacher_lud_vas";
import teacher_pol_tim from "../../dialogues/data/imikn/teacher/teacher_pol_tim";
import teacher_tal_yar from "../../dialogues/data/imikn/teacher/teacher_tal_yar";
import teacher_volan_yul from "../../dialogues/data/imikn/teacher/teacher_volan_yul";

const intro = {
  intro_1: { path: teacher_intro_1 },
  intro_2: { path: teacher_intro_2 },
  intro_3: { path: teacher_intro_3 },
  intro_4: { path: teacher_intro_4 },
  intro_5: { path: teacher_intro_5 },
  intro_6: { path: teacher_intro_6 },
  intro_7: { path: teacher_intro_7 },
};

const teacher_dialogue = {
  anas_suleim: { path: teacher_anas_suleim, isVisited: false },
  ann_evst: { path: teacher_ann_evst, isVisited: false },
  dobr_luc: { path: teacher_dobr_luc, isVisited: false },
  dobr_roman: { path: teacher_dobr_roman, isVisited: false },
  emm_stal: { path: teacher_emm_stal, isVisited: false },
  evd_pankr: { path: teacher_evd_pankr, isVisited: false },
  lud_vas: { path: teacher_lud_vas, isVisited: false },
  pol_tim: { path: teacher_pol_tim, isVisited: false },
  tal_yar: { path: teacher_tal_yar, isVisited: false },
  volan_yul: { path: teacher_volan_yul, isVisited: false },
};

export default function TeacherPage() {
  const intro_local = JSON.parse(localStorage.getItem("intro"));
  const direction = JSON.parse(localStorage.getItem("direction"));
  const teacher = JSON.parse(localStorage.getItem("teacher"));
  const lesson = JSON.parse(localStorage.getItem("lesson"));
  const [isDialogue, setIsDialogue] = useState(false);
  const [currentClick, setCurrentClick] = useState(null);
  const [currentStories, setCurrentStories] = useState(null);
  const { http } = AuthUser();
  const user = JSON.parse(localStorage.getItem("user"));
  const setDataGame = async () => {
    const combined = {
      direction: JSON.parse(localStorage.getItem("direction")),
      intro: JSON.parse(localStorage.getItem("intro")),
      lesson: JSON.parse(localStorage.getItem("lesson")),
      teacher: JSON.parse(localStorage.getItem("teacher")),
    };
    const jsonString = JSON.stringify(combined);
    try {
      const response = await http.put(`/store-data-game/${user.id}`, {
        imikn: jsonString,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const checkIntro = () => {
    if (intro_local.teacher.isVisited) {
      setCurrentStories(intro[`intro_${intro_local.teacher.count}`].path);
      setIsDialogue(true);
    } else setIsDialogue(false);
  };

  const getAchievements = async () => {
    try {
      const response = await http.get(`/achievement/imikn/${user.id}`);
      sessionStorage.setItem("achievements", JSON.stringify(response.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkIntro();
    setDataGame();
    getAchievements();

    const intervalId = setInterval(setDataGame, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const handleTeacherClick = (click) => {
    if (intro_local.teacher.isVisited) {
      intro_local.teacher.isVisited = false;
      localStorage.setItem("intro", JSON.stringify(intro_local));
    }
    setCurrentClick(click);
    storiesClick(click);
  };
  const storiesClick = (nameClick) => {
    const path = teacher_dialogue[nameClick]?.path;
    setCurrentStories(path);
    setIsDialogue(true);
    const teacher_local = JSON.parse(localStorage.getItem("teacher"));
    teacher_local[nameClick].isVisited = true;
    localStorage.setItem("teacher", JSON.stringify(teacher_local));
  };
  const checkAchievTeacher = () => {
    const achiev_session = JSON.parse(sessionStorage.getItem("achievements"));
    if (
      achiev_session[0].achiev_child[0].is_issued === 0 &&
      Object.values(direction).every((item) => item.isVisited === true) &&
      Object.values(teacher).every((item) => item.isVisited === true) &&
      Object.values(lesson).every((item) => item.isVisited === true)
    ) {
      achiev_session[0].achiev_child[0].is_issued = 1;
      sessionStorage.setItem("achievements", JSON.stringify(achiev_session));
      return 1;
    }
    if (
      achiev_session[0].achiev_child[3].is_issued === 0 &&
      Object.values(teacher).every((item) => item.isVisited === true)
    ) {
      achiev_session[0].achiev_child[3].is_issued = 1;
      sessionStorage.setItem("achievements", JSON.stringify(achiev_session));
      return 15;
    }
  };
  return (
    <>
      <SectionDirection
        instID={1}
        main={
          <BoxGame
            main={
              isDialogue ? (
                <DialogueSystem
                  stories={currentStories}
                  setIsDialogue={setIsDialogue}
                  checkAchievParams={checkAchievTeacher}
                />
              ) : (
                <AlbomTeacher onTeacherClick={handleTeacherClick} />
              )
            }
          />
        }
      />
    </>
  );
}
