import Cookies from "js-cookie";
const sprite_female_default = require("../../../Games/dialogues/assets/playerSprites/sprite_female.png");
const sprite_male_default = require("../../../Games/dialogues/assets/playerSprites/sprite_male.png");
const sprite_female_jacket = require("../../../Games/dialogues/assets/playerSprites/sprite_female_jacket.png");
const sprite_male_jacket = require("../../../Games/dialogues/assets/playerSprites/sprite_male_jacket.png");
// background
const background_female = require("../../../Games/dialogues/assets/inbio/backgrounds/background_female.jpg");
const background_male = require("../../../Games/dialogues/assets/inbio/backgrounds/background_male.jpg");

function getGender() {
  const getToken = Cookies.get("jwt_token");
  if (!getToken) return 0;
  else return JSON.parse(localStorage.getItem("user")).gender;
}

function getMaleOfFemale() {
  if (getGender() === 0) return "male";
  else return "female";
}

// Получение спрайта игрока взависимости от пола
export function getSpriteCharacterOfGender(pose) {
  if (getMaleOfFemale() === "male") {
    if (pose === "default") return sprite_male_default;
    if (pose === "jacket") return sprite_male_jacket;
  } else {
    if (pose === "default") return sprite_female_default;
    if (pose === "jacket") return sprite_female_jacket;
  }
}

// Получение имени игрока
export function getNamePlayer() {
  const getToken = Cookies.get("jwt_token");
  if (!getToken) return "Игрок";
  else {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user.nickname) return user.nickname;
    else return user.username;
  }
}

// Получение фона взависимости от пола
export function genBackgroundMaleOfFEmale() {
  if (getGender() === 0) return background_male;
  else return background_female;
}
