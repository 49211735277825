import React from "react";
import Typography from "@mui/material/Typography";
import StarIcon from "@mui/icons-material/Star";

export default function StarIconComponent(props) {
  return (
    <div
      style={{
        position: "relative",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "calc(0.7rem + 1vw)",
      }}
    >
      <StarIcon
        color="primary"
        sx={{
          fontSize: "calc(4rem + 4vw)",
          color: "#FAAF00",
        }}
      />
      <Typography
        component="span"
        sx={{
          position: "absolute",
          lineHeight: 1,
          fontSize: "1em",
        }}
      >
        {props.count}
      </Typography>
    </div>
  );
}
