import Footer from "../Components/footer/Footer";
import Header from "../Components/header/Header";
import Profile from "../Components/profile/Profile";
export default function ProfilePage() {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <Profile />
        <Footer />
      </div>
    </>
  );
}
