import Footer from "../../Components/footer/Footer";
import Header from "../../Components/header/Header";
import * as React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import BookContent from "../../Components/studentBook/BookContent";
import IntroConclusion from "./components/IntroConclusion";
import { Typography } from "@mui/material";

export default function Conclusion() {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        {" "}
        <Header />
        <Container component="main" maxWidth="xl" sx={{ padding: 9 }}>
          <Grid
            container
            spacing={3}
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* Первая часть контейнера */}
            <Grid item xs={12} sm={4} md={4}>
              <BookContent />
            </Grid>
            {/* Вторая часть контейнера */}
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <IntroConclusion
                title="Заключение"
                text={
                  <>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ textIndent: "2em" }}
                    >
                      Поздравляем, дорогой друг! Тебе удалось прочитать всю
                      книгу студентов ТюмГУ!
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ textIndent: "2em" }}
                    >
                      Надеемся, что она помогла тебе хорошо провести время,
                      узнать подробности студенческой жизни и интересные факты
                      об обучении. Но не забывай, что наша книга живая и со
                      временем тут появятся новые истории, поэтому ждём тебя
                      снова!
                    </Typography>
                  </>
                }
              />
            </Grid>
          </Grid>
        </Container>
        <Footer />
      </div>
    </>
  );
}
