import "./style.css";
import { Box } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import ImiknMap from "../../assets/direction/imikn_map.jpg";

const mapIcon = [
  {
    title: "Мехатроника и робототехника",
    placement: "right-end",
    img: require("../../assets/direction/imikn_icon_mir.png"),
    width: "7%",
    left: "22.5%",
    top: "22%",
    onclick: "mir",
  },
  {
    title:
      "Математическое обеспечение и администрирование информационных систем",
    placement: "bottom",
    img: require("../../assets/direction/imikn_icon_moiais.png"),
    width: "6%",
    left: "33.5%",
    top: "19%",
    onclick: "moiais",
  },
  {
    title: "Прикладная информатика",
    placement: "bottom",
    img: require("../../assets/direction/imikn_icon_pi.png"),
    width: "6%",
    left: "44%",
    top: "19%",
    onclick: "pi",
  },
  {
    title: "Компьютерная безопасность",
    placement: "bottom",
    img: require("../../assets/direction/imikn_icon_kb.png"),
    width: "6%",
    left: "55%",
    top: "20%",
    onclick: "kb",
  },
  {
    title: "Информационная безопасность",
    placement: "bottom",
    img: require("../../assets/direction/imikn_icon_ib.png"),
    width: "8%",
    left: "64.5%",
    top: "19%",
    onclick: "ib",
  },
  {
    title: "Информационные системы и технологии",
    placement: "right-end",
    img: require("../../assets/direction/imikn_icon_isit.png"),
    width: "7%",
    left: "74%",
    top: "25%",
    onclick: "isit",
  },
  {
    title: "Математика",
    placement: "right-end",
    img: require("../../assets/direction/imikn_icon_math.png"),
    width: "7%",
    left: "74%",
    top: "48%",
    onclick: "math",
  },
  {
    title: "Механика и математическое моделирование",
    placement: "right-end",
    img: require("../../assets/direction/imikn_icon_mimm.png"),
    width: "7%",
    left: "22.5%",
    top: "45%",
    onclick: "mimm",
  },
  {
    title: "Информационная безопасность автоматизированных систем",
    placement: "right-end",
    img: require("../../assets/direction/imikn_icon_ibas.png"),
    width: "7%",
    left: "22.5%",
    top: "67%",
    onclick: "ibas",
  },
  {
    title: "Педагогическое образование с 2 профилями",
    placement: "right-end",
    img: require("../../assets/direction/imikn_icon_ped.png"),
    width: "7%",
    left: "44%",
    top: "67%",
    onclick: "ped",
  },
];
export default function MapDirection(props) {
  const handleClick = (event, message) => {
    props.onMapClick(message);
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <img
        src={ImiknMap}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
      {mapIcon.map((icon, index) => (
        <Tooltip key={index} title={icon.title} placement={icon.placement}>
          <img
            src={icon.img}
            className="image"
            style={{
              position: "absolute",
              cursor: "pointer",
              width: `${icon.width}`,
              left: `${icon.left}`,
              top: `${icon.top}`,
            }}
            onClick={(event) => handleClick(event, `${icon.onclick}`)}
          />
        </Tooltip>
      ))}
    </Box>
  );
}
