import React, { useState, useEffect } from "react";
import "./textbox.css";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export default function TextBox(props) {
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    let currentText = "";
    const timer = setInterval(() => {
      if (currentText.length < props.dialogueText.length) {
        currentText += props.dialogueText[currentText.length];
        setDisplayText(currentText);
      } else {
        clearInterval(timer);
      }
    }, 20); // скорость печатания
    return () => clearInterval(timer);
  }, [props.dialogueText]);

  return (
    <>
      {props.dialogueText ? (
        <Box className="container-textbox noselect" >
          {props.characterName ? (
            <Box
              className="box-character-name"
              sx={{ background: props.colorName }}
            >
              {props.characterName}
            </Box>
          ) : (
            <></>
          )}
          <Box className="box-dialogue-text noselect">
            {props.showChoiceButton ? (
              props.choices.map((choice, index) => (
                <div
                  key={index}
                  className="choice"
                  onClick={() => props.handleChoice(choice)}
                >
                  {choice.split("|")[0]}
                </div>
              ))
            ) : (
              <>
                <Typography
                  sx={{
                    fontSize: "calc(100% + 0.2vw)",

                  }}
                >
                  {displayText}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}
