import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState, useEffect } from "react";
import AuthUser from "../../../../Services/AuthUser";
import Heading from "../../../../Components/adminpanel/Heading";
import AddStoryModal from "./Add";
import EditStoryModal from "./Edit";

export default function ListIndex() {
  const { token, http } = AuthUser();
  const [stories, setStories] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [directions, setDirections] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedStory, setSelectedStory] = useState(null);

  const buttonInfo = {
    value: "Добавить",
    onClick: () => setOpenDialog(true),
  };

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "story_name", headerName: "Название", width: 130, flex: 0.5 },
    // { field: "story_text", headerName: "Текст", width: 130, flex: 0.3 },
    {
      field: "story_id_dir",
      headerName: "Направление",
      width: 200,
      flex: 0.4,
      valueGetter: (params) => {
        const direction = directions.find(
          (dir) => dir.id === params.row.story_id_dir
        );
        return direction ? direction.dir_name : "";
      },
    },
    {
      field: "is_moderated",
      type: "boolean",
      headerName: "Модерация",
      width: 200,
    },
    {
      field: "keywords",
      headerName: "Ключевые слова",
      width: 200,
      valueGetter: (params) => {
        const story = params.row;
        const keywords = story.keywords ? story.keywords.map((keyword) => keyword.keyword).join(", ") : "";
        return keywords;
      },
    },
    {
      field: "created_at",
      headerName: "Дата",
      width: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        const formattedDate = `${date.getDate().toString().padStart(2, "0")}.${(
          date.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}.${date
          .getFullYear()
          .toString()
          .padStart(4, "0")} ${date
          .getHours()
          .toString()
          .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
        return formattedDate;
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      flex: 0,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => editStory(params.id)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => deleteStories(params.id)}
        />,
      ],
    },
  ];

  useEffect(() => {
    const getStories = async () => {
      try {
        const response = await http.get("/stories", {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setStories(response.data.data);
        // console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getDirections = async () => {
      const response = await http.get("/directions", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data);
      setDirections(response.data);
    };
    getStories();
    getDirections();
  }, []);

  const editStory = (id) => {
    const story = stories.find((st) => st.id === id);
    setSelectedStory(story);
  };

  const deleteStories = async (id) => {
    try {
      await http.delete(`/stories/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setStories((prevStories) =>
        prevStories.filter((story) => story.id !== id)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddStory = async () => {
    try {
      const response = await http.get("/stories", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setStories(response.data);
      setOpenDialog(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Heading title="Истории" button={buttonInfo} />
      <Box sx={{ width: "100%", mt: 3 }}>
        <DataGrid rows={stories} columns={columns} />
      </Box>
      <AddStoryModal
        open={openDialog}
        close={() => setOpenDialog(false)}
        handleAddStory={handleAddStory}
      />
      <EditStoryModal
        open={Boolean(selectedStory)}
        close={() => setSelectedStory(null)}
        story={selectedStory}
        setStories={setStories}
      />
    </>
  );
}
