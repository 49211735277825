import {
    getSpriteCharacterOfGender,
    getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";
import { setVisibleTeacher } from "../../../../../Components/dialogueSystem/scripts/VisibleTeacher";

// background
const imikn_ibas = require("../../../assets/imikn/backgrounds/imikn_ibas.jpg");

// character
const guide_default = require("../../../assets/imikn/characters/guide.png");
const guide_smile = require("../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let direction_ibas = {
    Start: [
        {
            body: [
                {
                    background: imikn_ibas,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Итак, Информационная безопасность автоматизированных систем или кратко ИБАС",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это одно из трёх направлений, связанных с информационной безопасностью",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Также, как и КБ, это программа специалитета, но учиться придётся на полгода меньше",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Профильные дисциплины на этих схожих направлениях, в большей степени тоже совпадают, но всё же различия имеются...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Здесь, как и на двух других, базой является высшая математика",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Но в большей мере, упор идёт на компьютерные сети, операционные, файловые системы и криптографию",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Именно последняя дисциплина наиболее важна для работы",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Криптография - это наука о способах и методах шифрования информации",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Она предназначена для того, чтобы передавать данные в закрытом виде так, чтобы их мог прочитать только тот, кому они адресованы",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Звучит как действительно важный предмет! А кем работать после окончания университета?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Выпускники занимаются обеспечением безопасности на предприятиях",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Их цель – обезопасить сервера, ПК и компьютерные сети от ошибок и взломов, как с внешней, так и с внутренней стороны",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А зачем защищать изнутри? От шпионов?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Возможны и такие ситуации...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну, в первую очередь ты помнишь как в школе на уроках информатики нельзя было установить какую-нибудь программу из интернета самостоятельно?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Не было прав администратора... Или перейти на некоторые сайты?",
                },
            ],
        },
        {
            choices: [
                "Да|direction_ibas_yes",
                "Нет|direction_ibas_no",
            ],
        },
    ],

    direction_ibas_yes: [
        {
            body: [
                {
                    background: imikn_ibas,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Так вот это дело администратора компьютерной безопасности!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Сделать так, чтобы доступ к информации, к примеру, был защищён, только у определённых лиц",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_ibas_continue",
        },
    ],

    direction_ibas_no: [
        {
            body: [
                {
                    background: imikn_ibas,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Похоже ваш системный администратор отлынивал от работы!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Потому что это забота администратора компьютерной безопасности",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну, а в школе - сисадмина. Сделать так, чтобы доступ к информации, к примеру, был защищён, только у определённых лиц",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_ibas_continue",
        },
    ],

    direction_ibas_continue: [
        {
            body: [
                {
                    background: imikn_ibas,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Или чтобы человек случайно не запустил вирусы из интернета, когда пытался что-то скачать и установить...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это всё необходимо, чтобы данные компании были защищены",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А производственный процесс не остановился из-за чьей-то глупости или незнания...",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Ого! А можешь сказать конкретные профессии?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Конечно!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Можно пойти работать специалистом по информационной безопасности",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Пентест специалистом или по-другому - тестировщиком на проникновение",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Аналитиком безопасности, архитектором ИБ или специалистом по поиску уязвимостей",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Этот список можно долго продолжать. Главное, что все эти должности связаны с информационной и компьютерной безопасностью",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Здорово!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Хм, что же ещё рассказать?!",
                },
            ],
        },
        {
            choices: [
                "Давай про практику!|direction_ibas_practice",
                "А как на этом направлении с образовательными мероприятиями?|direction_ibas_events",
                "Да вроде бы достаточно информации, пошли дальше?|direction_ibas_exit",
            ],
        },
    ],

    direction_ibas_practice: [
        {
            body: [
                {
                    background: imikn_ibas,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну смотри, практики здесь проходят также, как и на других направлениях, кроме производственной",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Тут она такая же как и на прикладной информатике, рассказать или ты уже в курсе?",
                },
            ],
        },
        {
            choices: [
                "Давай, расскажи!|direction_ibas_practice_continue",
                "Нет, не нужно, я помню|direction_ibas_practice_exit",
            ],
        },
    ],

    direction_ibas_practice_continue: [
        {
            body: [
                {
                    background: imikn_ibas,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Если на других направлениях есть вариант пройти производственную практику на кафедре, то здесь нет!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Студентам обязательно нужно попрактиковаться в реальной компании, университет, конечно, с этим помогает, но не всегда...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Поэтому нужно позаботиться о месте для практики заранее",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "А защита всё равно есть?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Да, только защищают не какие-то учебные проекты, а рассказывают, чему такому новому научились у опытных специалистов",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Звучит действительно полезно!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это да... Хочешь расскажу тебе ещё и про то, как проходят образовательные мероприятия на этом направлении?",
                },
            ],
        },
        {
            choices: [
                "Хочу!|direction_ibas_events",
                "Да вроде бы мне достаточно информации, пошли дальше?|direction_ibas_exit",
            ],
        },
    ],

    direction_ibas_practice_exit: [
        {
            body: [
                {
                    background: imikn_ibas,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Вот и славненько!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Хочешь расскажу тебе про то, как проходят образовательные мероприятия на этом направлении?",
                },
            ],
        },
        {
            choices: [
                "Хочу!|direction_ibas_events",
                "Да вроде бы мне достаточно информации, пошли дальше?|direction_ibas_exit",
            ],
        },
    ],

    direction_ibas_events: [
        {
            body: [
                {
                    background: imikn_ibas,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "О-о-о-очень интересная тема!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это направление славится большой любовью к различным мероприятиям",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Частенько студенты совместно с преподавателями составляют викторины по информационной безопасности",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Участвуют в хакатонах и бизнес-акселераторах",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Знаешь, что это такое?",
                },
            ],
        },
        {
            choices: [
                "Да|direction_ibas_events_yes",
                "Нет|direction_ibas_events_no",
            ],
        },
    ],
    
    direction_ibas_events_yes: [
        {
            body: [
                {
                    background: imikn_ibas,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Отлично!",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_ibas_events_continue",
        },
    ],

    direction_ibas_events_no: [
        {
            body: [
                {
                    background: imikn_ibas,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Можешь посмотреть определения в Азбуке, специально тебе не расскажу, чтобы был повод её посетить, ха-ха!",
                },
            ],
        },
        {
            choices: [],
        },
        {
            jump: "direction_ibas_events_continue",
        },
    ],

    direction_ibas_events_continue: [
        {
            body: [
                {
                    background: imikn_ibas,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Также здесь очень любят профессиональное соревнование Capture the flag или Захват флага, если по-русски",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Звучит интересно, что это?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Я не знаю всех тонкостей, лучше тебе спросить это у Эммануила Сталиновича",
                    visibleTeacher: "emm_stal",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Он очень любит данное соревнование и даже организовал кружок по подготовке к чемпионатам!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Ух-ты, надо будет обязательно к нему заглянуть!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Это точно!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ладно, пойдём дальше",
                },
            ],
        },
        {
            exits: true,
        },
    ],

    direction_ibas_exit: [
        {
            body: [
                {
                    background: imikn_ibas,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Любой каприз, как говорится!",
                },
            ],
        },
        {
            exits: true,
        },
    ],
};

export default direction_ibas;
