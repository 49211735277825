import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box/Box";
import AuthUser from "../../../Services/AuthUser";
import Cards from "../../../Components/card/Cards";

export default function NewsCardsBlock() {
  const { http } = AuthUser();
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    getNewsList();
  }, []);

  const getNewsList = async () => {
    const response = await http.get(`/news`);
    setNewsList(response.data);
  };
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
      {newsList &&
        newsList.map((item, i) => (
          <Cards
            key={i}
            image={require(`../../../Assets/News/${item.new_name_img}`)}
            label={item.new_label}
            description={item.new_desc}
            href={`/news/${item.id}`}
          />
        ))}
    </Box>
  );
}
