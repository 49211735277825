import React, { useState, useEffect } from "react";
import Footer from "../../Components/footer/Footer";
import Header from "../../Components/header/Header";
import CheckAuthMiniProfile from "./components/CheckAuthMiniProfile";
import { Grid } from "@mui/material";
import Voculabury from "../../Components/vocabulary/Vocabulary";
import ListSectionCard from "../../Components/gameSection/ListSection";
import { useParams } from "react-router-dom";
import DirectionSectionImage from "../../Assets/imikn/cover_directions.jpg";
import TeacherSectionImage from "../../Assets/imikn/cover_teachers_book.jpg";
import AddLocationSectionImage from "../../Assets/imikn/cover_additional_locations.jpg";
import AuthUser from "../../Services/AuthUser";
const sectionList = {
  imikn: {
    name: "Институт математики и компютерных наук",
    imageDirection: DirectionSectionImage,
    imageTeacher: TeacherSectionImage,
    imageAddLocation: AddLocationSectionImage,
  },
  // inbio: {
  //   name: "Институт Биологии",
  //   imageDirection: DirectionSectionImage,
  //   imageTeacher: TeacherSectionImage,
  //   imageAddLocation: AddLocationSectionImage,
  // },
};

export default function GameSectionInstPage() {
  const { instName } = useParams();
  const { http } = AuthUser();
  const user = JSON.parse(localStorage.getItem("user"));

  const setDataGame = async () => {
    const combined = {
      direction: JSON.parse(localStorage.getItem("direction")),
      intro: JSON.parse(localStorage.getItem("intro")),
      lesson: JSON.parse(localStorage.getItem("lesson")),
      teacher: JSON.parse(localStorage.getItem("teacher")),
    };
    const jsonString = JSON.stringify(combined);
    console.log(jsonString);
    try {
      const data = {};
      data[instName] = jsonString;
      const response = await http.put(`/store-data-game/${user.id}`, data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setDataGame();
    const intervalId = setInterval(setDataGame, 60000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <Voculabury instituteId={1} />
        <Grid container spacing={2}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <CheckAuthMiniProfile />
          </Grid>
          <Grid item xs={3}></Grid>
          <ListSectionCard instName={instName} data={sectionList[instName]} />
        </Grid>
        <Footer />
      </div>
    </>
  );
}
