import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_mir = require("../../../assets/imikn/backgrounds/imikn_mir.jpg");

// character
const guide_default = require("../../../assets/imikn/characters/guide.png");
const guide_smile = require("../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let direction_mir = {
  Start: [
    {
      body: [
        {
          background: imikn_mir,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_default,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "На первый взгляд может показаться, что здесь учат собирать роботов, программировать андроидов и мастерить костюм железного человека",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Но это не так...",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "После окончания этой бакалаврской программы выпускники данного направления могут заниматься проектированием и эксплуатацией мехатронных и робототехнических систем",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "А нужны они для применения в автоматизированном производстве и других областях",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "Звучит сложно...",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Не бойся",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Автоматизацией производства, в данном случае, называют процесс замены человеческого труда, к примеру, на заводе - машинами",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Разными приборами, станками и так далее",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "То есть, скажем был завод с сотней рабочих, выполнявших одно и то же действие целыми днями: отливали детали, например",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          speakSprite: guide_smile,
          text: "Как в школьной задачке, ха-ха-ха",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "А в один прекрасный день, пришёл человек, окончивший это направление, сконструировал станок, который работает сам и всё!",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Вместо сотни человек теперь понадобится лишь один, который будет обслуживать и следить за станками",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "С одной стороны, люди лишились работы...",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Но с другой, не очень же интересно заниматься делом, с которым справляется глупая машина, не так ли?",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "Ну да...",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Главное помнить, что робототехника – это не только про роботов-собак и прочие фантастические приборы",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Но и про более приземлённые, необходимые в настоящее время машины",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "А если тебе интересно, то у меня есть для тебя совет, озвучить?",
        },
      ],
    },
    {
      choices: [
        "Да!|direction_mir_yes",
        "Нет, если нельзя будет выбрать темой дипломной работы - костюм железного человека, то я пас!|direction_mir_no",
      ],
    },
  ],

  direction_mir_yes: [
    {
      body: [
        {
          background: imikn_mir,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_smile,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Как и на любом направлении здесь нужно учиться и не отлынивать от работы, даже если стало грустно и скучно...",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Студентам преподают большое количество теории, но поверь, когда дело дойдёт до практики, она будет ой-ой-ой как необходима!",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Но самой практики предусмотрено не очень-то много, на самом деле, и лучше самому обеспечить себя ею",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Наняться, например, практикантом в соответствующую организацию во время производственной практики",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "А затем остаться там в качестве стажёра, если всё пойдёт хорошо",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Главное не сдаваться и много работать!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "direction_mir_no_continue",
    },
  ],

  direction_mir_no: [
    {
      body: [
        {
          background: imikn_mir,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_smile,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Ха-ха",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Хорошо!",
          achiev_child_id: 5, // 1.4
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "direction_mir_no_continue",
    },
  ],

  direction_mir_no_continue: [
    {
      body: [
        {
          background: imikn_mir,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_default,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Вообще, на мой взгляд, это направление всё равно очень интересное, чего только стоит учебная практика!",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "Расскажи поподробнее",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          rightSprite: guide_smile,
          speakColor: guide_color,
          text: "Побеседуй лучше с Добрыней Романовичем на этот счёт, он этим занимается и сможет больше рассказать, чем я!",
          visibleTeacher: "dobr_roman",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "Хорошо, обязательно загляну к нему! А можно узнать про предметы, которые здесь изучаются?",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Конечно можно!",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Учебный план включает в себя знакомство с электротехникой, инженерной графикой и мехатроникой",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Проектирование и конструирование робототехнических систем и приборов, а также создания для них программного обеспечения",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "А что такое мехатроника в данном случае?",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Вообще мехатроника может сама за себя рассказать, если расшифровать название. Оно образовано от двух слов “механика” и “электроника”",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Считается, что в настоящее время, по данным ЮНЕСКО, мехатроника входит в десятку самых перспективных направлений инженерии в мире!",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Представляешь!",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "Вау, получается, довольно перспективная область",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Да, это точно! А ещё в ближайшие десятилетия специалисты со знаниями мехатроники точно не останутся без работы!",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "Это здорово, но я не совсем понимаю, чем конкретно придётся заниматься на данной дисциплине...",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Если в прикладном смысле, то программированием промышленных контроллеров и разработкой различных схем для приборов",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "И здесь программирование?",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Конечно! Только оно отличается от стандартного представления",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Контроллеры программируются на языках LAD, FBD и С++",
        },
      ],
    },
    {
      choices: [
        "О первых двух языках мне совсем ничего неизвестно|direction_mir_choice_1",
        "О, здорово, слышал обо всех языках, получается я уже в теме!|direction_mir_choice_2",
      ],
    }
  ],

  direction_mir_choice_1: [
    {
      body: [
        {
          background: imikn_mir,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_default,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Потому что это специальные графические языки программирования для инженеров!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "direction_mir_continue",
    },
  ],

  direction_mir_choice_2: [
    {
      body: [
        {
          background: imikn_mir,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_smile,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Вот видишь, как здорово! Надо поступать, значит!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "direction_mir_continue",
    },
  ],

  direction_mir_continue: [
    {
      body: [
        {
          background: imikn_mir,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_default,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Также студенты мехатроники и робототехники изучают python",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "Чтобы писать чат-боты?",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Неет!",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Чтобы писать программы для различных математических и физических расчётов",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Ведь недостаточно просто сконструировать какой-то прибор, нужно чтобы он не сломался и работал правильно!",
        },
        {
          nameSpeak: getNamePlayer(),
          speakSprite: "left",
          speakColor: player_color,
          text: "А кем работают выпускники этого направления?",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Можно, к примеру, пойти работать слесарем КИПиА - контрольно-измерительных приборов и автоматики",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Это такой сотрудник, который обслуживает, ремонтирует и эксплуатирует различное контрольно-измерительное оборудование",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "И системы автоматического управления, другими словами - приборы на предприятиях",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Также выпускники могут работать инженерами-программистами",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "То есть специалистами по программированию различных электронных устройств на языках, о которых мы уже говорили",
        },
        {
          nameSpeak: "Богдан",
          speakSprite: "right",
          speakColor: guide_color,
          text: "Или инженерами-проектировщиками, то есть разработкой и доработкой различных чертежей архитектуры приборов",
        },
      ],
    },
    {
      choices: [
        "Звучит сложно...|direction_mir_difficult",
        "Звучит интересно!|direction_mir_interesting",
      ],
    }
  ],

  direction_mir_difficult: [
    {
      body: [
        {
          background: imikn_mir,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_smile,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "В процессе обучения все знания встанут на свои места, как пазлы и ты поймёшь о чём я говорил. А теперь, предлагаю пойти дальше",
        },
      ],
    },
    {
      exits: true,
    },
  ],

  direction_mir_interesting: [
    {
      body: [
        {
          background: imikn_mir,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_smile,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Вот и славненько! Главное при поступлении - слушать своё сердце, остальное не важно! А теперь, предлагаю пойти дальше",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default direction_mir;
