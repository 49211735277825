import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import AuthUser from "../../../../Services/AuthUser";
import MenuItem from "@mui/material/MenuItem";

const EditDirectionModal = (props) => {
  const { token, http } = AuthUser();
  const { open, close, direction, setDirections } = props;
  const [directionName, setDirectionName] = useState("");
  const [directionIDinstitute, setDirectionIDinstitute] = useState("");
  const [institutes, setInstitutes] = useState([]);

  useEffect(() => {
    setDirectionName(direction?.dir_name || "");
    setDirectionIDinstitute(direction?.dir_id_inst || "");
  }, [direction]);

  const handleSave = () => {
    const dirData = {
      dir_name: directionName,
      dir_id_inst: directionIDinstitute,
    };
    http
      .put(`/directions/${direction.id}`, dirData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDirections((prevDirections) =>
          prevDirections.map((dir) =>
            dir.id === direction.id
              ? {
                  ...dir,
                  dir_name: res.data.dir_name,
                  dir_id_inst: res.data.dir_id_inst,
                }
              : dir
          )
        );
        close();
      });
  };

  const handleCancel = () => {
    close();
  };

  const handleNameChange = (event) => {
    setDirectionName(event.target.value);
  };

  const handleInstituteChange = (event) => {
    setDirectionIDinstitute(event.target.value);
  };

  useEffect(() => {
    const getInstitutes = async () => {
      const response = await http.get("/institutes", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setInstitutes(response.data);
    };
    getInstitutes();
  }, []);

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Редактировать направление</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Введите новое название направления:
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Название направления"
          type="text"
          fullWidth
          value={directionName}
          onChange={handleNameChange}
        />
        <TextField
          margin="normal"
          id="dir_id_inst"
          label="Институт"
          select 
          fullWidth
          value={directionIDinstitute}
          onChange={handleInstituteChange}
          required
        >
          {institutes.map((inst) => (
            <MenuItem key={inst.id} value={inst.id}>
              {inst.inst_name}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Отмена
        </Button>
        <Button onClick={handleSave} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDirectionModal;
