import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ChoiceFilter from "./ChoiceFilter";
import { useTheme } from "@mui/material/styles";

export default function PanelFiltr(props) {
  const theme = useTheme();
  const { onSearchChange, onCheckedRating } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSearchClick = (value) => {
    onSearchChange(value);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchClick(event.target.value);
    }
  };
  const [filterIconClick, setFilterIconClick] = useState(false);

  const handleFilterIconClick = () => {
    setFilterIconClick(filterIconClick ? false : true);
  };

  const handleRatingChange = (checked) => {
    onCheckedRating(checked);
  };
  return (
    <>
      <Box sx={{ mt: 5, color: theme?.palette?.text.primary }}>
        <Grid container spacing={1}>
          <Grid item xs={8} md={10}>
            <TextField
              id="search"
              type="search"
              label="Поиск"
              placeholder="Введите фразу или слово"
              value={searchTerm}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              fullWidth
              sx={{
                input: { color: theme.palette.box.border },
                label: { color: theme.palette.box.border },
                "& .MuiOutlinedInput-root": {
                  "&:hover fieldset": {
                    borderColor: theme?.palette?.text.primary,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: theme?.palette?.text.primary,
                  },
                },
                "& label.Mui-focused": {
                  color: theme?.palette?.text.primary,
                },
             
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleSearchClick(searchTerm)}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2} md={2} alignItems="center" justifyContent="center">
            <Box>
              <IconButton onClick={handleFilterIconClick}>
                <FilterAltIcon sx={{ fontSize: 35 }} />
              </IconButton>
            </Box>
          </Grid>
          {filterIconClick ? (
            <ChoiceFilter onCheckedRating={handleRatingChange} />
          ) : (
            <></>
          )}
        </Grid>
      </Box>
    </>
  );
}
