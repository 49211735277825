import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Widjet from "./Widjet";
import AuthUser from "../../../Services/AuthUser";
import Grid from "@mui/material/Grid";

export default function ListWidjet() {
  const { token, http } = AuthUser();
  const [users, setUsers] = useState([]);
  const [stories, setStories] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);
  const [achievementsIssued, setAchievementsIssued] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await http.get("/admin/users", {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getStories = async () => {
      try {
        const response = await http.get("/stories", {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setStories(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    const getFeedbacks = async () => {
      const response = await http.get("/feedbacks", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setFeedbacks(response.data);
    };

    const getAchievementsIssued = async () => {
      const response = await http.get(`/users/achievements-issued`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setAchievementsIssued(response.data);
    };

    getUsers();
    getStories();
    getFeedbacks();
    getAchievementsIssued();
  }, []);
  function FormRow() {
    return (
      <React.Fragment>
        <Grid item xs={3} md={3}>
          <Widjet name_wid={"Пользователи"} name={users} />
        </Grid>
        <Grid item xs={3} md={3}>
          <Widjet name_wid={"Истории"} name={stories} />
        </Grid>
        <Grid item xs={3} md={3}>
          <Widjet name_wid={"Обратная связь"} name={feedbacks} />
        </Grid>
        <Grid item xs={3} md={3}>
          <Widjet name_wid={"Полученные достижения"} name={achievementsIssued} />
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid container item spacing={3}>
            <FormRow />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
