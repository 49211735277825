import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import AuthUser from "../../../../Services/AuthUser";

const AddInstituteModal = (props) => {
  const [instituteName, setInstituteName] = useState("");
  const { token, http } = AuthUser();
  const { handleAddInstitute } = props;

  const handleSubmit = () => {
    const instData = {
      inst_name: instituteName,
    };
    http
      .post("/institutes", instData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data.inst_name);
        handleAddInstitute(prevInstitutes => [...prevInstitutes, res.data]); // Обновить список институтов
        props.close();
      });

  };

  return (
    <Dialog open={props.open} onClose={props.close} fullWidth maxWidth="sm">
      <IconButton
        aria-label="close"
        onClick={props.close}
        sx={{
          position: "absolute",
          top: 12,
          right: 12,
          color: (theme) => theme.palette.secondary.main,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>Добавить институт</DialogTitle>

      <DialogContent>
        <DialogContentText>Введите название института</DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            margin="normal"
            id="name_inst"
            label="Название института"
            type="text"
            fullWidth
            variant="outlined"
            value={instituteName}
            onChange={(e) => setInstituteName(e.target.value)}
            required
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} variant="contained">
          Отмена
        </Button>
        <Button type="submit" variant="contained" onClick={handleSubmit}>
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddInstituteModal;
