import {
    getSpriteCharacterOfGender,
    getNamePlayer,
} from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_first_coridor = require("../../../../assets/imikn/backgrounds/imikn_first_coridor.jpg");

// character
const guide_smile = require("../../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let lessons_intro_1 = {
    Start: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну вот, скоро начнутся занятия. Если есть желание, можешь их посетить",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Я бы на твоем месте не отказывался от такой возможности",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Можно просто так прийти и послушать?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Конечно! Только лишь на правах вольнослушателя",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Кого?",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Вольнослушатель - это человек, который имеет право посещать некоторые лекции с разрешения преподавателя и деканата",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Так как я за тебя уже договорился, ты можешь спокойно ходить на занятия, но пока не на все...",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Почему? Ты же договорился",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Договорился-то, договорился... Но некоторые преподаватели сказали мне, что хотят сначала пообщаться с тобой лично...",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Хотят знать в лицо будущего студента, который возможно окажется на их направлении!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Ого, такая честь, получить приглашение лично!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Да-да. Так что если хочешь посетить занятие, беги скорее знакомиться с преподавателями!",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Сперва со всеми пообщайся, а потом возвратись сюда!",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Ладно, спасибо!",
                },
            ],
        },
        {
            exits: true,
        },
    ],
};

export default lessons_intro_1;
