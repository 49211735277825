export const ID = '/:id';
export const INSTNAME ='/:instName';

export const LANDING = '/';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const RECOVER = '/recover';
export const PASSWORD_CHANGE = '/passwordChange';

export const HOME = '/home';
export const NEWS = '/news';
export const FEEDBACK = '/feedback';
export const ABOUTUS = '/aboutus';

export const GAMESECTION = '/gamesection';
export const DIRECTIONSECTION = '/direction';
export const TEACHERSECTION = '/teacher';
export const LESSONSECTION = '/lesson';

export const STUDENTBOOK = '/studentbook';
export const INTRO = '/intro';
export const CHAPTER1 = '/chapter1';
export const CONCLUSION = '/conclusion';

export const TERM = '/term';
export const CHARACTER = '/characters';

export const ADMIN = '/admin';
export const ADMIN_DASHBOARD = '/dashboard'
export const ADMIN_VOCABULARY = '/vocabulary';
export const ADMIN_STORY = '/stories';
export const ADMIN_INSTITUTE = '/institutes';
export const ADMIN_DIRECTION = '/directions';
export const ADMIN_ROLE = '/roles';
export const ADMIN_USER = '/users';
export const ACHIEVEMENT = '/achievements';


export const PROFILE_USER = '/profile';

export const IMIKN = '/imikn';