import Footer from "../Components/footer/Footer";
import Header from "../Components/header/Header";
import FormFeedback from "../Components/feedback/FormFeedback";
import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function FeedbackPage() {
  const theme = useTheme();
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <Grid container spacing={2}>
          <Grid item xs={3}></Grid>
          <Grid
            item
            xs={6}
            sx={{
              // background: theme?.palette?.box.main,
              color: theme?.palette?.text.primary,
            }}
          >
            <FormFeedback alignItemsValue="center"/>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        <Footer />
      </div>
    </>
  );
}
