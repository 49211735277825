import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const department_place_02 = require("../../../assets/imikn/backgrounds/department_place_02.jpg");

// character
const guide_smile = require("../../../assets/imikn/characters/guide_smile.png");
const lud_vas = require("../../../assets/imikn/characters/lud_vas.png");
const lud_vas_sad = require("../../../assets/imikn/characters/lud_vas_sad.png");
const lud_vas_smile = require("../../../assets/imikn/characters/lud_vas_smile.png");
const lud_vas_smile_with_tea = require("../../../assets/imikn/characters/lud_vas_smile_with_tea.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";
const lud_vas_color = "#CBD9EE";

let teacher_lud_vas = {
  Start: [
    {
      body: [
        {
          background: department_place_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: lud_vas_smile,
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Добрый день!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Ой, здравствуйте! Как я рада, что Вы зашли! Очень хочу с Вами поболтать!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Взаимно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Как Ваши дела? Не устали ходить?",
        },
      ],
    },
    {
      choices: [
        "Да, есть такое, это оказалось немного утомительно|lud_vas_yes",
        "Нет, мои приключения только начинаются|lud_vas_no",
      ],
    },
  ],

  lud_vas_yes: [
    {
      body: [
        {
          background: department_place_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: lud_vas_smile,
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Ох, может Вы отдохнете, и мы вместе попьём чаю с печеньками?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Да, Богданчик?",
        },
        {
          centerSprite: guide_smile,
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Конечно, Людмила Васильевна, только чай не буду, сами понимаете...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Конечно, милый, просто посиди с нами. Сейчас, сейчас, налью чаёк, присаживайтесь",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "И это не просьба, а приказ! Поняли, да? Хи-хи-хи *наливает чай себе и гостю*",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lud_vas_continue",
    },
  ],

  lud_vas_no: [
    {
      body: [
        {
          background: department_place_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: lud_vas_smile,
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Ух, замечательно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "А ты, Богданчик, утомился?",
        },
        {
          centerSprite: guide_smile,
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Людмила Васильевна! Я же призрак! Не сплю, не устаю...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Ой, прости, запамятовала... Но вы всё равно присаживайтесь... Сейчас чаю налью... *наливает чай*",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lud_vas_continue",
    },
  ],

  lud_vas_continue: [
    {
      body: [
        {
          background: department_place_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: guide_smile,
          rightSprite: lud_vas_smile_with_tea,
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Как Вам у нас?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Не правда ли уютненько?",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Да, почти как дома",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Я старалась. Понимаете, кафедра не должна быть чем-то страшным, как учительская или кабинет директора в школе",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Обычно туда попадают за плохое поведение, а здесь исключительно за хорошее!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "И поэтому кабинет должен выглядеть соответственно, тем более, что тут располагается моё рабочее место, понимаете, да?",
        },
        {
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Ну, Людмила Васильевна! Не начинайте, это ваше соответственно, сразу вспоминаю лекции по матанализу...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "А что в этом плохого? Зато не забываешь материал...",
        },
        {
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Ещё скажите, что он мне пригодится, ха-ха-ха",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Ну Богданчик, не начинай!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Расскажите, пожалуйста, поподробнее про предметы, которые ведёте",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Конечно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Я преподаю высшую математику",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Говорят, мои лекции немного скучноваты, но посмотрите на меня, я и в жизни-то не особо активная",
        },
        {
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Людмила Васильевна, Вы просто ангел, как Ваши лекции могут быть скучными?",
        },
        {
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Скорее всего студенты засыпают, потому что голос у Вас очень успокаивающий и убаюкивающий...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Ну, а что поделать? Какой есть, хи-хи",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "На практических же занятиях я стараюсь объяснять материал ещё раз...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Но более простым языком, чтобы точно все всё поняли, поняли да?",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ага",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Также не люблю, когда к моим занятиям относятся несерьёзно",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Я же понимаю, что мой предмет не единственный и задаю не так много на дом...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Но находятся люди, которые и занятия прогуливают, а если и приходят, то без домашней работы...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А как проходят экзамены и зачёты?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Ой, их я тоже не люблю, стараюсь давать всем возможность закрыться автоматом",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Извиняюсь, “закрыться автоматом”? Это как?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "А так: в течение всего семестра студент, посещая все лекции и практические занятия, может получать баллы",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "За их определенное количество выставляется зачет или соответствующая оценка за экзамен",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Смотря какая форма финальной проверки знаний предусмотрена предметом...",
        },
        {
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Вы лучше расскажите, как эти баллы заработать...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Ах, да, есть свои заморочки, у меня баллы можно получить только за контрольные работы!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Хотя, Богданчик, по секрету скажу: если часто выходить к доске, то я к контрольной пару баллов от себя добавлю",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Чтобы оценка хорошая получилась, но студенты этого знать не должны, а то расслабятся совсем...",
        },
        {
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Ах вот как! Ну теперь понятно, почему я на зачёт тогда вышел",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Конечно! Сидел в углу весь семестр и не показывал никому, какая светлая голова у тебя!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "А на контрольные то опаздывал, то не приходил вовсе!",
        },
        {
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Я просто на Вас обижался немножко",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Да ты мой хороший, извини, если что не так, но мы заболтались и забыли про гостя...",
        },
        {
          rightSprite: lud_vas_smile,
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Может у тебя есть ко мне вопросы?",
        },
      ],
    },
    {
      choices: [
        "Как долго Вы работаете в университете?|lud_vas_question",
        "Нет, ничего в голову не приходит...|lud_vas_nothing",
      ],
    },
  ],

  lud_vas_question: [
    {
      body: [
        {
          background: department_place_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: lud_vas_smile,
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Почти всю жизнь, как сама образование получила...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Так долго? А почему именно преподавание?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Ну как же, я люблю учить детишек, хоть они уже и совершеннолетние",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Но в душе-то ещё малютки!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Боятся всего",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Один раз я даже ненароком подслушала разговор двух первокурсников, которые обсуждали прогул занятий",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Они думают, что здесь кто-то следит за этим, а какой-нибудь преподаватель может и родителям позвонить. Хи-хи!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А это не так?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Конечно, нет! Здесь Вам не школа, нет директора, за плохое поведение оценку не ставят и родителей не вызывают",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Университет все-таки рассчитывает, что сюда поступают взрослые люди, с осознанием своей ответственности",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Но я-то понимаю, что они еще дети. Хи-хи-хи",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "А потом, спустя уже год, так приятно смотреть, как бывшие мальчишки становятся мужчинами, а девочки – девушками! Поняли да?",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Да, теперь понятно",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А почему Вы выбрали именно математику?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "А почему? А почему? Как много вопрсоов у Вас хи-хи-хи",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Это хорошо!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Потому что математика прекрасна",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Она сохраняет такой порядок в голове, такую чёткость мыслей",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Возможно, кто-то скажет, что это нездоровое увлечение, а я думаю, что это любовь с первого взгляда! Самая настоящая страсть!",
        },
      ],
    },
    {
      choices: [
        "На каких направлениях Вы преподаёте?|lud_vas_question_2",
        "Спасибо, вопросов больше нет!|lud_vas_exit",
      ],
    },
  ],

  lud_vas_question_2: [
    {
      body: [
        {
          background: department_place_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: lud_vas_smile,
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "А что, хочешь попасть ко мне в группу?",
        },
      ],
    },
    {
      choices: [
        "Да, очень бы хотелось!|lud_vas_yes_2",
        "Да я просто интересуюсь...|lud_vas_no_2",
      ],
    },
  ],

  lud_vas_yes_2: [
    {
      body: [
        {
          background: department_place_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: lud_vas_smile,
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Ну я на любом могу преподавать, ведь сейчас матанализ есть у всех направлений на первом курсе",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Там уже, как молодёжь говорит - «чистый рандом» хи-хи-хи!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Эх, очень жаль...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Ну не волнуйся, не важно на какое направление ты поступишь, заходи ко мне просто так, если поймаешь на кафедре, конечно...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lud_vas_exit",
    },
  ],

  lud_vas_no_2: [
    {
      body: [
        {
          background: department_place_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: lud_vas_smile,
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Ну я на любом могу преподавать...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Ведь сейчас матанализ есть у всех направлений на первом курсе, там уже, как молодёжь говорит - «чистый рандом» хи-хи-хи!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lud_vas_exit",
    },
  ],

  lud_vas_nothing: [
    {
      body: [
        {
          background: department_place_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: lud_vas_smile,
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Ну ничего, как что-то вспомнишь, обязательно спрашивай!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lud_vas_exit",
    },
  ],

  lud_vas_exit: [
    {
      body: [
        {
          background: department_place_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: guide_smile,
          rightSprite: lud_vas_smile,
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Ладненько, Людмила Васильевна, мы пойдём...",
        },
        {
          rightSprite: lud_vas_sad,
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Но почему же, так быстро?",
        },
        {
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Понимаете, если мы не уйдём сейчас, то останемся здесь на весь день, а у нас ещё есть, что посмотреть...",
        },
        {
          rightSprite: lud_vas,
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "А, ну да, ну да, не буду задерживать",
        },
        {
          speakSprite: "right",
          nameSpeak: "Людмила Васильевна",
          speakColor: lud_vas_color,
          text: "Приходите, я буду рада Вас видеть в любое время! До скорой встречи!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "До свидания, Людмила Васильевна!",
        },
        {
          speakSprite: "center",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "До свидания!",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default teacher_lud_vas;
