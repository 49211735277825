import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ImageAvatarMale from "../../Assets/avatar_main_male.png";
import ImageAvatarFemale from "../../Assets/avatar_main_female.png";
import CardAvatar from "./CardAvatar";
import CardDataAccount from "./CardDataAccount";
import CardAchievement from "./CardAchievement";
import AuthUser from "../../Services/AuthUser";
import { useTheme } from "@mui/material/styles";

export default function Profile() {
  const theme = useTheme();
  const localUser = JSON.parse(localStorage.getItem("user"));
  const { http } = AuthUser();
  const [gender, setGender] = useState(0);
  const [genderAvatar, setGenderAvatar] = useState(getGenderAvatar());
  const [achievementsIssued, setAchievementsIssued] = useState([]);
  const [achievementsLength, setAchievementsLength] = useState(0);

  function getGenderAvatar() {
    if (localUser.gender === 0) {
      return ImageAvatarMale;
    } else {
      return ImageAvatarFemale;
    }
  }

  useEffect(() => {
    setGenderAvatar(getGenderAvatar());
    getAchievementsIssued();
  }, [localUser.gender]);

  function handleSaveClick() {
    const updatedUser = { ...localUser, gender: 1 - gender };
    localStorage.setItem("user", JSON.stringify(updatedUser));
    setGender(updatedUser.gender);
  }

  const getAchievementsIssued = async () => {
    const AchievChild = await http.get(`achievements/child/1`);
    const response = await http.get(`/achievements-player/${localUser.id}`);
    setAchievementsIssued(response.data);
    setAchievementsLength(
      (response.data.length / AchievChild.data.length) * 100
    );
  };
  return (
    <>
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 8,
            boxShadow: `0px 0px 25px 7px ${theme?.palette?.box.shadow}`,
            borderRadius: 5,
            background: theme?.palette?.box.main,
          }}
        >
          <Grid container spacing={2} columns={16}>
            <Grid
              item
              xs={4}
              sx={{
                ml: 4,
              }}
            >
              {/* Карточка с аватором */}
              <CardAvatar
                image={genderAvatar}
                username={localUser.username}
                nickname={localUser.nickname}
              />
            </Grid>
            <Grid
              item
              xs={11}
              sx={{
                ml: 1,
              }}
            >
              {/* Карточка с данными о пользователе */}
              <CardDataAccount dataUser={localUser} onSave={handleSaveClick} />
            </Grid>
          </Grid>
          {/* Достижения */}
          <CardAchievement
            nameInst="Институт Математики и Компьютерных Наук"
            progress={achievementsLength}
          />
        </Box>
      </Container>
    </>
  );
}
