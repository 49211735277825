import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import AuthUser from "../../../../Services/AuthUser";

const EditModal = (props) => {
  const { token, http } = AuthUser();
  const { open, close, role, setRoles } = props;
  const [roleName, setRoleName] = useState(role?.name);
  const [roleDescription, setRoleDescription] = useState(role?.description);

  const handleSave = () => {
    const data = {
      name: roleName,
      description: roleDescription,
    };
    http
      .put(`/roles/${role.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setRoles((prevRoles) =>
          prevRoles.map((rol) => (rol.id === role.id ? res.data : rol))
        );

        close();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>Редактировать роль</DialogTitle>
      <DialogContent>
        <DialogContentText>Введите новое название роли</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Название роли"
          type="text"
          fullWidth
          defaultValue={role?.name}
          onChange={(e) => setRoleName(e.target.value)}
        />
        <TextField
          autoFocus
          margin="normal"
          id="desc_role"
          label="Описание роли"
          type="text"
          fullWidth
          variant="outlined"
          defaultValue={role?.description}
          onChange={(e) => setRoleDescription(e.target.value)}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Отмена
        </Button>
        <Button onClick={handleSave} color="primary">
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
