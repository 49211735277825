import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AuthUser from "../../Services/AuthUser";
import Footer from "../../Components/footer/Footer";
import Header from "../../Components/header/Header";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { Box, Container, Typography } from "@mui/material";
import DefaultImage from "../../Assets/04.png";
import { useTheme } from "@mui/material/styles";

export default function NewsDetail() {
  const theme = useTheme();
  const { id } = useParams();
  const { http } = AuthUser();
  const [newsList, setNewsList] = useState([]);
  useEffect(() => {
    const getNewsList = async () => {
      const response = await http.get(`/news/${id}`);
      setNewsList(response.data);
      console.log(response.data);
    };
    getNewsList();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <Container maxWidth="lg">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Card
              sx={{
                mt: 7,
                width: "90%",
                mb: 7,
                p: 3,
                boxShadow: `0px 0px 25px 7px ${theme?.palette?.box.shadow}`,
                background: theme?.palette?.box.main,
              }}
            >
              <Typography
                component="h3"
                variant="h4"
                padding={2}
                align="center"
              >
                {newsList.new_title}
              </Typography>
              <Typography variant="body2" padding={1} align="left">
                {new Date(newsList.created_at).toLocaleDateString("ru-RU")}
              </Typography>
              <Typography variant="h6" paddingLeft={3} paddingRight={3}>
                {newsList.new_text}
              </Typography>
              <CardMedia
                component="img"
                image={(() => {
                  try {
                    return require(`../../Assets/News/${newsList.new_name_img}`);
                  } catch (error) {
                    console.error(error);
                    return DefaultImage;
                  }
                })()}
                title={newsList.title}
                sx={{ p: 3 }}
                alt={newsList.new_name_img}
              />
            </Card>
          </Box>
        </Container>
        <Footer />
      </div>
    </>
  );
}
