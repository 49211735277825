import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

function CardAvatar(props) {
  const theme = useTheme();
  return (
    <Card
      sx={{
        background: theme?.palette?.box.main,
        boxShadow: `0px 0px 15px 2px ${theme?.palette?.box.shadow}`,
      }}
    >
      <CardMedia
        component="img"
        alt="avatar"
        image={props.image}
        title="Avatar"
      />
      <CardContent>
        <Typography variant="body" component="h4" align="center">
          {props.username}
        </Typography>
        <Typography variant="body" component="p" align="center">
          {props.nickname}
        </Typography>
      </CardContent>
    </Card>
  );
}
export default CardAvatar;
