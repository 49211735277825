import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
const MiniProfile = (props) => {
  const theme = useTheme();
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 6, mb: 3 }}>
        <Card
          sx={{
            backgroundColor: theme?.palette?.box.main,
            color: theme?.palette?.text.primary,
            boxShadow: `0px 0px 15px 2px ${theme?.palette?.box.shadow}`,
            minWidth: "100%",
            maxWidth: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CardHeader
            avatar={
              <Avatar
                src={props.imageAvatar}
                sx={{
                  width: 100,
                  height: 100,
                  border: "solid white 4px",
                  background: "white",
                  p: 0,
                  borderColor: "#ffffff",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  margin: "auto",
                }}
              ></Avatar>
            }
          />
          <Box sx={{ p: 2 }}>
            <Typography
              variant="h1"
              sx={{
                fontWeight: "bold",
                fontSize: "1.1rem",
                textAlign: "center",
                p: 1,
              }}
            >
              {props.name}
            </Typography>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                borderTop: "solid rgb(206, 206, 206) 1px",
                textAlign: "center",
              }}
            >
              <div>
                <Typography
                  variant="h3"
                  sx={{ fontWeight: "bold", ml: 10, mr: 10 }}
                >
                  {props.achievement}
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    fontWeight: "normal",
                    fontSize: "0.7rem",
                    color: "hsl(0, 0%, 50%)",
                    letterSpacing: 1,
                    paddingBottom: 2,
                  }}
                >
                  Достижения
                </Typography>
              </div>
            </Stack>
          </Box>
        </Card>
      </Box>
    </>
  );
};
export default MiniProfile;
