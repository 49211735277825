import { Container, Typography } from "@mui/material";
import DirectionImage from "../../../Assets/imikn/cover_directions.jpg";
import TeacherImage from "../../../Assets/imikn/cover_teachers_book.jpg";
import LocationImage from "../../../Assets/imikn/cover_additional_locations.jpg";

import CardCarouselHome from "../../../Components/card/CardCarouselHome";
const data = [
  {
    id: 1,
    title: "Преподаватели",
    img: TeacherImage,
  },
  {
    id: 2,

    title: "Направления",
    img: DirectionImage,
  },
  {
    id: 3,
    title: "Учебные занятия",
    img: LocationImage,
  },
];
export default function CardSectionBlock() {
  return (
    <>
      <Container component="main" maxWidth="lg" sx={{padding: 0, margin:"0 auto"}}>
        <Typography variant="h5" gutterBottom align="justify " 
        sx={{
          '@media (max-width: 600px)': {
                fontSize: '4vw',
              },}}>
          Игра состоит из трёх разделов - направления, преподаватели и учебные
          занятия. Внутри них и будет происходить волшебство визуальных новелл!
          Про навигацию внутри этих разделов ты узнаешь уже во время игры.
        </Typography>

      <CardCarouselHome data={data} />

        <Typography variant="h5" gutterBottom align="justify " 
        sx={{'@media (max-width: 600px)': {
                fontSize: '4vw',},}}>
          Не забывай, что от твоих выборов зависит дальнейший сюжет и события, а
          также возможность получить уникальные достижения!
        </Typography>
      </Container>
    </>
  );
}
