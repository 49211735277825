import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState, useEffect } from "react";
import AuthUser from "../../../../Services/AuthUser";
import Heading from "../../../../Components/adminpanel/Heading";
import AddModal from "./Add";
import EditModal from './Edit';

export default function ListIndex() {
  const [terms, setTerms] = useState([]);
  const { token, http } = AuthUser();
  const [openDialog, setOpenDialog] = useState(false);
  const [institutes, setInstitutes] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState(null);

  const buttonInfo = {
    value: "Добавить",
    onClick: () => setOpenDialog(true),
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "term_title", headerName: "Термин", width: 130, flex: 0.1 },
    {
      field: "term_description",
      headerName: "Описание",
      width: 200,
      flex: 0.5,
    },
    {
      field: "term_id_inst",
      headerName: "Институт",
      width: 90,
      flex: 0.2,
      valueGetter: (params) => {
        const institute = institutes.find(
          (inst) => inst.id === params.row.term_id_inst
        );
        return institute ? institute.inst_name : "";
      },
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      flex: 0,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => editTerm(params.id)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => deleteTerm(params.id)}
        />,
      ],
    },
  ];

  useEffect(() => {
    const getTerms = async () => {
      const response = await http.get("/terms", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setTerms(response.data);
    };
    const getInstitutes = async () => {
      const response = await http.get("/institutes", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
      setInstitutes(response.data);
    };
    getTerms();
    getInstitutes();
  }, []);

  const editTerm = (id) => {
    const term = terms.find((ter) => ter.id === id);
    setSelectedTerm(term);
  };

  const deleteTerm = async (id) => {
    try {
      await http.delete(`/terms/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setTerms((prevTerms) => prevTerms.filter((term) => term.id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddTerm = async () => {
    try {
      const response = await http.get("/terms", {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setTerms(response.data);
      setOpenDialog(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Heading title="Азбука для самых маленьких" button={buttonInfo} />
      <Box sx={{ width: "100%", mt: 3 }}>
        <DataGrid rows={terms} columns={columns} />
      </Box>
      <AddModal
        open={openDialog}
        close={() => setOpenDialog(false)}
        handleAddTerm={handleAddTerm}
      />
       <EditModal
        open={Boolean(selectedTerm)}
        close={() => setSelectedTerm(null)}
        term={selectedTerm}
        setTerms={setTerms}
      />
    </>
  );
}
