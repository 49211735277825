import Header from "../../Components/header/Header";
import RegisterComponent from "../../Components/auth/Register";

export default function RegisterPage() {
  return (
    <>
      {/* <Header /> */}
      <RegisterComponent />
    </>
  );
}
