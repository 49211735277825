import Anastasia from "../../../Assets/Members/Anastasia.png";
import Ira from "../../../Assets/Members/Ira.png";
import Irina from "../../../Assets/Members/Irina.png";
import Maria from "../../../Assets/Members/Maria.png";
import Venera from "../../../Assets/Members/Venera.png";

export const AboutUs = [
  {
    id: 1,
    name: "Ирина",
    fullName: "Ирина “Секретутка” Мансурова",
    image: Irina,
    positionY: "15%",
    color: "#11B16A",
    colorGradient: "#8bf4c4, #052e1c",
    label: "Разработчик",
    desc: "Кодер, личный секретарь руководителя, стендапер на созвонах, начитана, может развить любую идею. Превращается в злого таможенника, когда проверяет код игровых сценариев на ошибки. Она настолько любит чай, что скоро превратится в чашку! Знаете шутку про фронтендера и кнопку? Так вот это не шутка…",
  },
  {
    id: 2,
    name: "Анастасия",
    fullName: "Анастасия “Кокон” Жданова",
    image: Anastasia,
    positionY: "10%",
    color: "#FFC90E",
    colorGradient: "#ffeeb3, #665000",
    label: "Художник-иллюстратор",
    desc: "Богиня планшета и пера, автор абсолютно всего визуального контента, представленного в игре и на сайте. Имеет неповторимый стиль, всегда трепетно относится к своим творениям. Если бы пунктуальность была человеком, то это была бы она. Горит своим делом и сгорает, когда случаются казусы с перспективой и носами.",
  },
  {
    id: 3,
    name: "Венера",
    fullName: "Венера Кодо",
    image: Venera,
    positionY: "20%",
    color: "#000000",
    colorGradient: "#d9d9d9, #1a1a1a",
    label:
      "Руководитель/Разработчик/Геймдизайнер/Генератор идей и просто разнорабочий",
    desc: "Начальник и разнорабочий в одном лице, человек-многозадачность, киборг, матерь P.U.P.A., идейный вдохновитель. В одиночку разработала сайты компании и игры. Всё записывает, чтобы не забыть, но всё равно что-то забывает. Достигает поставленных целей несмотря ни на что, даже если для этого нужно не спать неделю и остаться с одной нервной клеткой.",
  },
  {
    id: 4,
    name: "Мария",
    fullName: "Мария Якубова",
    image: Maria,
    positionY: "5%",
    color: "#563AF5",
    colorGradient: "#c1b6fb, #0e0449",
    label: "Сценарист",
    desc: "Автор этого текста, всех текстов на сайте и части текстов в игре, простите за тавтологию. Генератор сюжетов и персонажей, переработчик информации, редактор и кодер игровых сценариев. Её схемам сюжетов в Миро позавидовал бы даже да Винчи. Ненавидит фразу руководителя: “Хорошо написано, но чего-то не хватает…”.",
  },

  {
    id: 5,
    name: "Ирина",
    fullName: "Ирина “Салли” Заплишная",
    image: Ira,
    positionY: "10%",
    color: "#640DB1",
    colorGradient: "#cfa0f8, #280547",
    label: "Помощник сценариста",
    desc: "Наш маленький ребёнок, который может мастерски писать и сочинять, гений шуток, отсылок и подтекста. Днём котик, у которого лапки, а ночью - мафия. Если бы не чёртова цензура, мир бы увидел поистине чёрный юмор из рук данного автора!",
  },
];
