import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
export default function AchievementChild({
  onSave,
  onDelete,
  achiev_child,
  panelId,
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [childName, setChildName] = React.useState(achiev_child?.achiev_name);
  const [childDescription, setChildDescription] = React.useState(
    achiev_child?.achiev_description
  );
  const [childImg, setChildImg] = React.useState(achiev_child?.achiev_child_img);

  const [isEditing, setIsEditing] = useState(false);

  const handleSaveChild = (panelId) => {
    const updatedChild = {
      achiev_name: childName,
      achiev_description: childDescription,
      achiev_child_img: childImg,
      // achiev_id_child: panelId,
    };
    // console.log(updatedChild);

    onSave(updatedChild, panelId);
    setIsEditing(false);
  };
  const handleDeleteChild = (panelId) => {
    onDelete(panelId);
  };
  return (
    <div>
      <Accordion
        expanded={expanded === panelId}
        onChange={handleChange(panelId)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${panelId}-content`}
          id={`${panelId}-header`}
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            {achiev_child.achiev_name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isEditing ? (
            <>
              <TextField
                margin="normal"
                id="child_name"
                label="Название дочернего достижения"
                type="text"
                fullWidth
                variant="outlined"
                value={childName}
                onChange={(e) => setChildName(e.target.value)}
              />

              <TextField
                margin="normal"
                id="child_description"
                label="Описание дочернего достижения"
                type="text"
                fullWidth
                variant="outlined"
                value={childDescription}
                onChange={(e) => setChildDescription(e.target.value)}
              />
              <TextField
                autoFocus
                margin="normal"
                id="name_inst"
                label="Название дочернего изображения"
                type="text"
                fullWidth
                variant="outlined"
                value={childImg}
                onChange={(e) => setChildImg(e.target.value)}
                required
              />
              <IconButton
                size="small"
                aria-label="edit"
                onClick={() => handleSaveChild(panelId)}
                sx={{ ml: 2 }}
              >
                <SaveIcon />
              </IconButton>
              <Button onClick={() => setIsEditing(false)} color="secondary">
                Отмена
              </Button>
            </>
          ) : (
            <>
              <Typography variant="h6">{achiev_child.achiev_name}</Typography>
              <Typography variant="body1">
                {achiev_child.achiev_description}
              </Typography>
              <IconButton
                size="small"
                aria-label="edit"
                onClick={() => setIsEditing(true)}
              >
                <EditIcon />
              </IconButton>

              <IconButton
                size="small"
                aria-label="delete"
                onClick={() => handleDeleteChild(panelId)}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
