import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function AuthUser() {
  const navigate = useNavigate();

  const getToken = () => {
    return Cookies.get("jwt_token");
  };

  const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());
  const getGameData = async (userID, token) => {
    const response = await axios.get(
      `https://api.zhmyak.com/api/data-game/${userID}`,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(response.data);
    if (response.data[0] && response.data[0].imikn) {
      const imiknData = JSON.parse(response.data[0].imikn);
      if (localStorage.getItem("intro")) {
        const res_json_intro_direction =
          imiknData.intro &&
          imiknData.intro.direction &&
          imiknData.intro.direction.count > 1;
        const res_json_intro_teacher =
          imiknData.intro &&
          imiknData.intro.teacher &&
          imiknData.intro.teacher.count > 1;
        const res_json_intro_lesson =
          imiknData.intro &&
          imiknData.intro.lesson &&
          imiknData.intro.lesson.count > 1;
        if (
          res_json_intro_direction ||
          res_json_intro_teacher ||
          res_json_intro_lesson
        ) {
          localStorage.setItem(
            "direction",
            JSON.stringify(imiknData.direction)
          );
          localStorage.setItem("intro", JSON.stringify(imiknData.intro));
          localStorage.setItem("lesson", JSON.stringify(imiknData.lesson));
          localStorage.setItem("teacher", JSON.stringify(imiknData.teacher));
        } else {
          return;
        }
      } else {
        localStorage.setItem("direction", JSON.stringify(imiknData.direction));
        localStorage.setItem("intro", JSON.stringify(imiknData.intro));
        localStorage.setItem("lesson", JSON.stringify(imiknData.lesson));
        localStorage.setItem("teacher", JSON.stringify(imiknData.teacher));
      }
    }
  };
  const saveToken = (user, token) => {
    Cookies.set("jwt_token", token);
    localStorage.setItem("user", JSON.stringify(user));
    delete user.question_id;
    delete user.created_at;
    delete user.updated_at;
    delete user.answer;
    localStorage.setItem("user", JSON.stringify(user));

    getGameData(user.id, token);

    setToken(token);
    setUser(user);
    navigate("/profile");
  };

  const http = axios.create({
    // baseURL: "http://127.0.0.1:8000/api",
    baseURL: "https://api.zhmyak.com/api",
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const logout = () => {
    Cookies.remove("jwt_token");
    localStorage.clear();
    navigate("/");
  };

  return {
    setToken: saveToken,
    token,
    user,
    getToken,
    http,
    logout,
  };
}
