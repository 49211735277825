import { checkVisitedNode } from "../../../Components/dialogueSystem/scripts/CheckVisited";
import DialogueSystem from "../../../Components/dialogueSystem/DialogueSystem";
import BoxGame from "../../../Components/gameSection/BoxGame";
import LessonDoor from "./components/LessonDoor";
import SectionDirection from "../../../Pages/gameSection/SectionDirectionPage";
import { useState, useEffect } from "react";
import AuthUser from "../../../Services/AuthUser";
import lessons_intro_1 from "../../dialogues/data/imikn/lesson/intro/lessons_intro_1";
import lessons_intro_2 from "../../dialogues/data/imikn/lesson/intro/lessons_intro_2";
import lessons_intro_3 from "../../dialogues/data/imikn/lesson/intro/lessons_intro_3";
import lessons_intro_4 from "../../dialogues/data/imikn/lesson/intro/lessons_intro_4";
import lessons_intro_5 from "../../dialogues/data/imikn/lesson/intro/lessons_intro_5";
import lessons_intro_6 from "../../dialogues/data/imikn/lesson/intro/lessons_intro_6";

import lesson_lecture from "../../dialogues/data/imikn/lesson/lesson_lecture";
import lesson_networking from "../../dialogues/data/imikn/lesson/lesson_networking/lesson_networking_begin";
import lesson_programming from "../../dialogues/data/imikn/lesson/lesson_programming/lesson_programming_begin";
import lesson_project_protection from "../../dialogues/data/imikn/lesson/lesson_project_protection";
import ProgrammingGameLvl1 from "./components/ProgrammingGameLvl1";
import ProgrammingGameLvl2 from "./components/ProgrammingGameLvl2";
import CiscoGame from "./components/CiscoGame";

const intro = {
  intro_1: { path: lessons_intro_1 },
  intro_2: { path: lessons_intro_2 },
  intro_3: { path: lessons_intro_3 },
  intro_4: { path: lessons_intro_4 },
  intro_5: { path: lessons_intro_5 },
  intro_6: { path: lessons_intro_6 },
};

const lesson_dialogue = {
  lecture: { path: lesson_lecture },
  networking_lesson: { path: lesson_networking },
  programming_lesson: { path: lesson_programming },
  project_protection: { path: lesson_project_protection },
};

export default function LessonLocationPage() {
  const intro_local = JSON.parse(localStorage.getItem("intro"));
  const direction = JSON.parse(localStorage.getItem("direction"));
  const teacher = JSON.parse(localStorage.getItem("teacher"));
  const [isDialogue, setIsDialogue] = useState(false);
  const [currentClick, setCurrentClick] = useState(null);
  const [currentStories, setCurrentStories] = useState(null);
  const [networking, setNetworking] = useState(false);
  const [programmingLvl1, setProgrammingLvl1] = useState(false);
  const [programmingLvl2, setProgrammingLvl2] = useState(false);
  const { http } = AuthUser();
  const user = JSON.parse(localStorage.getItem("user"));

  const setDataGame = async () => {
    const combined = {
      direction: JSON.parse(localStorage.getItem("direction")),
      intro: JSON.parse(localStorage.getItem("intro")),
      lesson: JSON.parse(localStorage.getItem("lesson")),
      teacher: JSON.parse(localStorage.getItem("teacher")),
    };
    const jsonString = JSON.stringify(combined);
    try {
      const response = await http.put(`/store-data-game/${user.id}`, {
        imikn: jsonString,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const checkIntro = () => {
    if (intro_local.location.isVisited) {
      setCurrentStories(intro[`intro_${intro_local.location.count}`].path);
      setIsDialogue(true);
    } else {
      setIsDialogue(false);
    }
  };

  function setGame(nameGame) {
    if (nameGame === "networking") setNetworking(true);
    else if (nameGame === "programmingLvl1") setProgrammingLvl1(true);
    else if (nameGame === "programmingLvl2") setProgrammingLvl2(true);
  }

  const checkQuestionProjectProtection = () => {
    const lesson = JSON.parse(localStorage.getItem("lesson"));
    if (checkVisitedNode("lesson_project_protection_project_1_questions")) {
      lesson.project_protection.lesson_project_protection_project_1_questions = true;
      localStorage.setItem("lesson", JSON.stringify(lesson));
    }
    if (checkVisitedNode("lesson_project_protection_project_2_questions")) {
      lesson.project_protection.lesson_project_protection_project_2_questions = true;
      localStorage.setItem("lesson", JSON.stringify(lesson));
    }
  };

  const checkAchievProjectProtection = () => {
    const achiev_session = JSON.parse(sessionStorage.getItem("achievements"));
    const lesson = JSON.parse(localStorage.getItem("lesson"));
    if (
      achiev_session[0].achiev_child[0].is_issued === 0 &&
      Object.values(direction).every((item) => item.isVisited === true) &&
      Object.values(teacher).every((item) => item.isVisited === true) &&
      Object.values(lesson).every((item) => item.isVisited === true)
    ) {
      achiev_session[0].achiev_child[0].is_issued = 1;
      sessionStorage.setItem("achievements", JSON.stringify(achiev_session));
      return 1;
    }
    if (
      achiev_session[0].achiev_child[5].is_issued === 0 &&
      Object.values(lesson).every((item) => item.isVisited === true)
    ) {
      achiev_session[0].achiev_child[5].is_issued = 1;
      sessionStorage.setItem("achievements", JSON.stringify(achiev_session));
      return 17;
    }
    if (
      achiev_session[0].achiev_child[4].is_issued === 0 &&
      lesson.programming_lesson.programmingLvl1_completed &&
      lesson.programming_lesson.programmingLvl2_completed
    ) {
      achiev_session[0].achiev_child[4].is_issued = 1;
      sessionStorage.setItem("achievements", JSON.stringify(achiev_session));
      return 16;
    }
    if (checkVisitedNode("lesson_project_protection_project_exit")) {
      if (
        achiev_session[2].achiev_child[7].is_issued === 0 &&
        lesson.project_protection
          .lesson_project_protection_project_1_questions === true &&
        lesson.project_protection
          .lesson_project_protection_project_2_questions === true
      ) {
        const lesson_local = JSON.parse(localStorage.getItem("lesson"));
        lesson_local.project_protection.isVisited = true;
        localStorage.setItem("lesson", JSON.stringify(lesson_local));

        achiev_session[2].achiev_child[7].is_issued = 1;
        sessionStorage.setItem("achievements", JSON.stringify(achiev_session));
        return 32;
      } else if (
        achiev_session[2].achiev_child[6].is_issued === 0 &&
        lesson.project_protection
          .lesson_project_protection_project_1_questions === false &&
        lesson.project_protection
          .lesson_project_protection_project_2_questions === false
      ) {
        const lesson_local = JSON.parse(localStorage.getItem("lesson"));
        lesson_local.project_protection.isVisited = true;
        localStorage.setItem("lesson", JSON.stringify(lesson_local));

        achiev_session[2].achiev_child[6].is_issued = 1;
        sessionStorage.setItem("achievements", JSON.stringify(achiev_session));
        return 31;
      }
    }
  };

  const getAchievements = async () => {
    try {
      const response = await http.get(`/achievement/imikn/${user.id}`);
      sessionStorage.setItem("achievements", JSON.stringify(response.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkIntro();
    setDataGame();
    getAchievements();

    const intervalId1 = setInterval(setDataGame, 60000);
    const intervalId2 = setInterval(checkQuestionProjectProtection, 5000); // Запускаем каждые 10 секунд

    return () => {
      clearInterval(intervalId1);
      clearInterval(intervalId2);
    };
  }, []);

  const handleLessonClick = (click) => {
    if (intro_local.location.isVisited) {
      intro_local.location.isVisited = false;
      localStorage.setItem("intro", JSON.stringify(intro_local));
    }
    setCurrentClick(click);
    storiesClick(click);
  };

  const storiesClick = (nameClick) => {
    const path = lesson_dialogue[nameClick]?.path;
    setCurrentStories(path);
    setIsDialogue(true);
    const lesson_local = JSON.parse(localStorage.getItem("lesson"));
    lesson_local[nameClick].isVisited = true;
    localStorage.setItem("lesson", JSON.stringify(lesson_local));
  };

  const getMain = () => {
    if (networking !== false)
      return (
        <CiscoGame
          setIsDialogue={setIsDialogue}
          setCurrentStories={setCurrentStories}
          setNetworking={setNetworking}
        />
      );
    else if (programmingLvl1 !== false)
      return (
        <ProgrammingGameLvl1
          setIsDialogue={setIsDialogue}
          setCurrentStories={setCurrentStories}
          setProgrammingLvl1={setProgrammingLvl1}
        />
      );
    else if (programmingLvl2 !== false)
      return (
        <ProgrammingGameLvl2
          setIsDialogue={setIsDialogue}
          setCurrentStories={setCurrentStories}
          setProgrammingLvl2={setProgrammingLvl2}
        />
      );
    else return <LessonDoor onLessonClick={handleLessonClick} />;
  };
  return (
    <>
      <SectionDirection
        instID={1}
        main={
          <BoxGame
            main={
              isDialogue ? (
                <DialogueSystem
                  stories={currentStories}
                  setIsDialogue={setIsDialogue}
                  setGame={setGame}
                  checkAchievParams={checkAchievProjectProtection}
                />
              ) : (
                getMain()
              )
            }
          />
        }
      />
    </>
  );
}
