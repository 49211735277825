import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function ChoiceFilter(props) {
  const theme = useTheme();
  const { onCheckedRating } = props;
  const [checkedRating, setCheckedRating] = React.useState(false);

  const handleRatingChange = (event) => {
    setCheckedRating(event.target.checked);
    onCheckedRating(checkedRating);
  };

  return (
    <>
      <Box sx={{ p: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkedRating}
              onChange={handleRatingChange}
              sx={{
                color:theme.palette.box.border,
              }}
            />
          }
          label="Показать все истории выше 4 звезд"
        />
      </Box>
    </>
  );
}
