import {
    getSpriteCharacterOfGender,
    getNamePlayer,
  } from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";
  
  // background
  const imikn_first_coridor = require("../../../../assets/imikn/backgrounds/imikn_first_coridor.jpg");
  
  // character
  const guide_smile = require("../../../../assets/imikn/characters/guide_smile.png");
  
  //color
  const player_color = "#D4D4D4";
  const guide_color = "#9DC7CC";
  
  let teachers_intro_5 = {
    Start: [
      {
        body: [
          {
            background: imikn_first_coridor,
            leftSprite: getSpriteCharacterOfGender("default"),
            centerSprite: "",
            rightSprite: guide_smile,
            speakSprite: "right",
            nameSpeak: "Богдан",
            speakColor: guide_color,
            text: "А ты знаешь, что есть лишь одна цифра, у которой два официальных названия? Это ноль, он же нуль",
          },
          {
            speakSprite: "left",
            nameSpeak: getNamePlayer(),
            speakColor: player_color,
            text: "Это ты вообще к чему?",
          },
          {
            speakSprite: "right",
            nameSpeak: "Богдан",
            speakColor: guide_color,
            text: "Да ни к чему. Просто решил рассказать интересный факт, чтоб твои однообразные похождения не были такими скучными, хе-хе",
          },
          {
            speakSprite: "left",
            nameSpeak: getNamePlayer(),
            speakColor: player_color,
            text: "А, спасибо, ха-ха-ха",
          },
        ],
      },
      {
        exits: true,
      },
    ],
  };
  
  export default teachers_intro_5;
  