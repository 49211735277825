import { getSpriteCharacterOfGender } from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_first_coridor = require("../../../../assets/imikn/backgrounds/imikn_first_coridor.jpg");

// character
const guide_smile = require("../../../../assets/imikn/characters/guide_smile.png");

//color
const guide_color = "#9DC7CC";

let lessons_intro_6 = {
  Start: [
    {
      body: [
        {
          background: imikn_first_coridor,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: guide_smile,
          speakSprite: "right",
          nameSpeak: "Богдан",
          speakColor: guide_color,
          text: "Поторопись, иначе пропустишь что-нибудь важное!",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default lessons_intro_6;
