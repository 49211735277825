import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

export default function Widjet(props) {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: 200,
          backgroundColor: "secondary.dark",
          borderRadius:3,
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          wordBreak: "break-word", 
          "&:hover": {
            backgroundColor: "secondary.main",
            opacity: [0.9, 0.8, 0.7],
          },
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h1">{props.name.length}</Typography>
          <Typography>{props.name_wid} 
          
          </Typography>
        </Stack>
      </Box>
    </>
  );
}
