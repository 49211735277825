import React, { useEffect, useState } from "react";
import AuthUser from "../../../Services/AuthUser";
import Footer from "../../../Components/footer/Footer";
import MiniProfile from "../../../Components/gameSection/MiniProfile";
import Header from "../../../Components/header/Header";
import Voculabury from "../../../Components/vocabulary/Vocabulary";
import { Grid } from "@mui/material";
import ImageAvatarMale from "../../../Assets/avatar_main_male.png";
import ImageAvatarFemale from "../../../Assets/avatar_main_female.png";
import ListSectionCard from "../../../Components/gameSection/ListSection";
import CardCarousel from "../../../Components/gameSection/CarouselGame";
import Card from "../../../Components/gameSection/CardGame";
import ImiknImage from "../../../Games/imikn/assets/cover_imikn.jpg";

export default function GameSection() {
  const user = JSON.parse(localStorage.getItem("user"));
  const { http } = AuthUser();
  const [achievementsIssued, setAchievementsIssued] = useState([]);
  function getName() {
    if (user.nickname) return user.nickname;
    else return user.username;
  }
  function getGenderAvatar() {
    if (user.gender === 0) {
      return ImageAvatarMale;
    } else {
      return ImageAvatarFemale;
    }
  }

  useEffect(() => {
    getAchievementsIssued();
  }, []);

  const getAchievementsIssued = async () => {
    const response = await http.get(`/achievements-player/${user.id}`);
    setAchievementsIssued(response.data);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <Voculabury instituteId={1} />

        <Grid container spacing={2}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <MiniProfile
              imageAvatar={getGenderAvatar()}
              name={getName()}
              achievement={achievementsIssued.length}
            />
            <CardCarousel />
            <Card
              image={ImiknImage}
              title="Институт математики и компютерных наук"
              url="/gamesection/imikn"
            />
          </Grid>
          <Grid item xs={3}></Grid>
          {/* <ListSectionCard /> */}
        </Grid>
        <Footer />
      </div>
    </>
  );
}
