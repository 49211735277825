import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_practice_02 = require("../../../../assets/imikn/backgrounds/practice 2/imikn_practice_2_classroom.jpg");
const imikn_practice_2_explanation = require("../../../../assets/imikn/backgrounds/practice 2/imikn_practice_2_explanation.jpg");

// character
const anas_suleim = require("../../../../assets/imikn/characters/anas_suleim.png");
const anas_suleim_angry = require("../../../../assets/imikn/characters/anas_suleim_angry.png");
const anas_suleim_smirk = require("../../../../assets/imikn/characters/anas_suleim_smirk.png");
const anna_npc = require("../../../../assets/imikn/characters/anna_npc.png");
const popov_npc_mask = require("../../../../assets/imikn/characters/popov_npc_mask.png");
const secretary = require("../../../../assets/imikn/characters/secretary.png");

//color
const player_color = "#D4D4D4";
const secretary_color = "#F5F2BF";
const anas_suleim_color = "#E1D5EF";
const anna_color = "#E0D0B8";
const popov_color = "#AEB0B0";
const student_color = "#CCDFFF";
const another_student_color = "#D8FFF6";
const student_girl_color = "#CFCAFF";

let lesson_programming_begin = {
  Start: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: "",
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Всем добрый день!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Какое мероприятие у нас состоится в ближайшие дни?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студенты",
          speakColor: student_color,
          text: "*тишина*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ага, сайт университета не читаем, получается...",
        },
        {
          rightSprite: anas_suleim_angry,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Очень плохо!",
        },
        {
          rightSprite: anas_suleim_smirk,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Попов! Расскажите нам всем об этом мероприятии",
        },
        {
          leftSprite: popov_npc_mask,
          speakSprite: "left",
          nameSpeak: "Попов",
          speakColor: popov_color,
          text: "Эм-м...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Чего же Вы молчите, не знаете?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Я вот подумала, что Вы всё знаете...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Сидите так загадочно в маске, скрываетесь от нас...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Попов",
          speakColor: popov_color,
          text: "*недоумевающе молчит*",
        },
        {
          leftSprite: "",
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну ладно, сама расскажу тогда, раз никто из вас не в курсе",
        },
        {
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "На днях состоится научная конференция с приглашёнными экспертами из крупных компаний России",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Поэтому НАСТОЯТЕЛЬНО рекомендую всем её посетить",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "А Вы поставите за это дополнительные баллы?",
        },
        {
          rightSprite: anas_suleim_angry,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "За это я НЕ ОТНИМУ у Вас дополнительные баллы, Евгений!",
        },
        {
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Так, а теперь приступим к занятию",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Чтобы вам сегодня не было скучно поступим так: разделитесь на группы по три - четыре человека",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Затем, если у всех членов группы готова лабораторная работа номер четыре",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "То вы поднимаете руку и отвечаете на мои вопросы по теме задания",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Если всё ответите верно, показываете код индивидуально и я ставлю баллы, всё понятно?",
        },
        {
          leftSprite: popov_npc_mask,
          speakSprite: "left",
          nameSpeak: "Попов",
          speakColor: popov_color,
          text: "А если я хочу сдать и четвёртую и пятую лабу?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Тогда сначала сдайте четвёртую, а там, если время останется, посмотрим что с Вами делать...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Попов",
          speakColor: popov_color,
          text: "А если у кого-то из группы не готова четвёртая лаба?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну что ж, тогда Вы всей группой помогаете отстающему...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Попов",
          speakColor: popov_color,
          text: "А если...",
        },
        {
          rightSprite: anas_suleim_angry,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Да что Вы заладили “А если, а если”, сделайте сначала то, что я сказала, потом и обсудим в индивидуальном порядке!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Попов",
          speakColor: popov_color,
          text: "Эх, ладно...",
        },
        {
          leftSprite: "",
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Здравствуйте! Извините за опоздание! Можно войти?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Здраствуйте, нет, нельзя!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Почему?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Потому что Вы зашли в кабинет после преподавателя - это как минимум некрасиво, а как максимум неуважение к моей работе, свободны!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Эх и зачем вообще лабу делал, торопился! Теперь тупо полтора часа ждать... *грустно уходит*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Тишина! Делитесь на группы и проверяйте работы друг друга, как будете готовы, зовите",
        },
        {
          rightSprite: anas_suleim_smirk,
          leftSprite: getSpriteCharacterOfGender("default"),
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "О, смотрю Вы всё-таки пришли, как замечательно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Как Вам институт? Уже сходили на какие-нибудь пары или эта первая?",
        },
      ],
    },
    {
      choices: [
        "Да, на лекцию у Добромира Люциановича|lesson_programming_begin_lecture",
        "Да, на лекцию и практику у Добромира Люциановича|lesson_programming_begin_lecture_practice",
        "Нет, Ваша пара - первая|lesson_programming_begin_first",
      ],
    },
  ],

  lesson_programming_begin_lecture: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim_smirk,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "И как? Не заскучали?",
        },
      ],
    },
    {
      choices: [
        "Было немного скучновато|lesson_programming_begin_yes",
        "Нет, лекция была очень интересной|lesson_programming_begin_no",
      ],
    },
  ],

  lesson_programming_begin_yes: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim_smirk,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну это Вы с непривычки так",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Если бы знали материал с прошлых лекций, то было бы наверняка поинтересней",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_programming_begin_continue",
    },
  ],

  lesson_programming_begin_no: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim_smirk,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Сразу видно - человек тянется к знаниям, не то что эти шалопаи, гонятся только за баллами!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_programming_begin_continue",
    },
  ],

  lesson_programming_begin_lecture_practice: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim_smirk,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вот как!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Похвально! И знания новые получили и попрактиковались",
        },
      ],
    },
    {
      choices: [
        "А у Вас всегда так практические занятия проходят?|lesson_programming_begin_lecture_practice_question",
        "Промолчать|lesson_programming_begin_continue",
      ],
    },
  ],

  lesson_programming_begin_lecture_practice_question: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Как так?",
        },
      ],
    },
    {
      choices: [
        "В строгости|lesson_programming_begin_lecture_practice_question_1",
        "Сдача лабораторных работ в группах|lesson_programming_begin_lecture_practice_question_2",
      ],
    },
  ],

  lesson_programming_begin_lecture_practice_question_1: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim_smirk,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Конечно, иначе совсем распоясаются!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Но Вы не пугайтесь, раз Вы уже были и на практике и на лекции у другого преподавателя по своему собственному желанию...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Значит на Вас эта строгость не распространится, сразу видно - прилежный и ответственный человек!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_programming_begin_continue",
    },
  ],

  lesson_programming_begin_lecture_practice_question_2: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim_smirk,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Нет, просто иногда ввожу новые условия для студентов, чтобы не расслаблялись, хе-хе",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_programming_begin_continue",
    },
  ],

  lesson_programming_begin_first: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim_smirk,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну ничего, ещё успеете заглянуть и на другие...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_programming_begin_continue",
    },
  ],

  lesson_programming_begin_continue: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Предлагаю Вам понаблюдать за процессом сдачи лабораторных работ",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Если появятся какие-нибудь вопросы - спрашивайте, не стесняйтесь!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Хорошо",
        },
        {
          leftSprite: anna_npc,
          speakSprite: "left",
          nameSpeak: "Студентка",
          speakColor: anna_color,
          text: "Анастасия Сулеймановна, подойдите пожалуйста, мы готовы!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Иду, иду",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Так, ну что ж, первый вопрос такой",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Дана группа людей, у каждого из них есть имя, фамилия, отчество, рост, вес и так далее",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Каким способом с точки зрения программирования можно описать эту группу?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студентка",
          speakColor: anna_color,
          text: "Можно создать массив из экземпляров класса “Человек”, а в свойствах этого класса описать всё, что Вы сказали: рост, вес, имя...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Хорошо, Аня, а что Вы понимаете под словом массив?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Аня",
          speakColor: anna_color,
          text: "Это структура данных, в которой хранится упорядоченный набор однотипных элементов",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Замечательно, а почему остальные молчат?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Аня",
          speakColor: anna_color,
          text: "Ну, мы договорились, что я буду отвечать, а если что-то забуду, ребята мне помогут, чтобы не создавать лишний шум",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вот как! Договорились значит... ну ладно...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Повезло вам, что у Ани язык подвешен, а то бы каждый получил по три вопроса...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну ладно, давайте код посмотрю...",
        },
        {
          leftSprite: "",
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Не пойму, Анастасия Сулеймановна сегодня в хорошем настроении или в плохом...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Кто знает, я вообще не понимаю, есть ли у неё хорошее настроение, всегда так страшно с ней говорить",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Да уж...",
        },
        {
          leftSprite: anna_npc,
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Так, ну вроде бы всё хорошо, поставлю Ане 10 баллов, а остальным по 5",
        },
        {
          speakSprite: "center",
          nameSpeak: "",
          speakColor: "",
          text: "*вздохи*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Не вздыхайте так, надо было тоже отвечать на вопросы!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Кто следующий?",
        },
        {
          leftSprite: popov_npc_mask,
          speakSprite: "left",
          nameSpeak: "Попов",
          speakColor: popov_color,
          text: "Мы!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну, давайте Михаил, первый вопрос такой",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Чем переменные отличаются от констант?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "Переменные могут изменяться, а константы нет",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Они как-то сами изменяются?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "Нет, МЫ их меняем...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "То есть была переменная b, а потом мы поменяли её на переменную v?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "Да нет же, мы можем изменять ну там... значения переменных, а имена остаются прежними",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Так сразу и говорите, а то могут изменяться, не могут изменяться - непонятно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Хорошо, давайте дальше",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Что такое метод?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "Ну-у... методы нужны, чтобы не писать один и тот же код...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Подождите!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Как был поставлен вопрос?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "Что такое метод?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А вы на какой вопрос отвечаете?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "*молчание*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вы рассказываете зачем нужны методы",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "Ну да...",
        },
        {
          rightSprite: anas_suleim_smirk,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Так ответьте сначала на первый вопрос",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "Метод - это...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "Это...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Как вспомните, зовите, пока нам не о чем говорить...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "*шёпотом* Ну вот, теперь очередь до нас вообще не дойдёт на этой паре... *грустный вздох*",
        },
        {
          leftSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Кто-то ещё готов сдавать?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студенты",
          speakColor: student_color,
          text: "*тишина*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну готовьтесь...",
        },
        {
          rightSprite: "",
          leftSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*достаёт из рюкзака лимонад*",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "*шёпотом* Ты чего-о!!! Прячь скорее!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* А? Почему? Пить хочу...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "*шёпотом* Если Анастасия Сулеймановна увидит, сразу минус десять баллов поставит! Прячь!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Ну я же пить хочу!!!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "Выйди в коридор и попей!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Эх... Ладно, пойду...",
        },
        {
          rightSprite: anas_suleim,
          leftSprite: getSpriteCharacterOfGender("default"),
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну что, Вы тут ещё не заскучали?",
        },
      ],
    },
    {
      choices: [
        "Нет, столько всего происходит, как тут заскучать!?|lesson_programming_begin_no_2",
        "Немного...|lesson_programming_begin_yes_2",
      ],
    },
  ],

  lesson_programming_begin_no_2: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Это да, практические занятия всегда намного динамичней лекционных",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Но я думаю, что Вам будет ещё интереснее, если примите моё предложение!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Какое?",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_programming_begin_continue_2",
    },
  ],

  lesson_programming_begin_yes_2: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Сейчас исправим!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_programming_begin_continue_2",
    },
  ],

  lesson_programming_begin_continue_2: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Не хотите поупражняться в программировании?",
        },
      ],
    },
    {
      choices: [
        "Хочу!|lesson_programming_begin_want",
        "Нет, я практически ничего в этом не смыслю...|lesson_programming_begin_dont_understand",
        "Не хочу, я лучше ещё понаблюдаю за происходящим|lesson_programming_begin_continuation",
      ],
    },
  ],

  lesson_programming_begin_want: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Замечательно! Без раздумий согласились!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Смотрите, у меня тут есть... *ищет в папке с заданиями*...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Два варианта, Вам полегче или посложнее?",
        },
      ],
    },
    {
      choices: [
        "Точно полегче!|lesson_programming_begin_want_lvl_1",
        "Конечно посложнее!|lesson_programming_begin_want_lvl_2",
      ],
    },
  ],

  lesson_programming_begin_want_lvl_1: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А чего это Вы так в себе не уверены? Или уже утомились за сегодня и не хотите напрягаться?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Впрочем, не отвечайте, я уже что-то придираюсь, Вы же ещё не студент, Вам простительно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Открывайте пока вот эту программу и я зачитаю задание",
          achiev_child_id: 25, // 3.10
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_programming_begin_exercise_lvl_1",
    },
  ],

  lesson_programming_begin_want_lvl_2: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вот это подход к делу!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Похвально!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Тогда открывайте пока вот эту программу и я зачитаю задание",
          achiev_child_id: 26, // 3.11
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_programming_begin_exercise_lvl_2",
    },
  ],

  lesson_programming_begin_dont_understand: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Так это не проблема!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Есть у меня тут на примете задание начального уровня... сейчас поищу... А! Вот оно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Если не будет получаться, я помогу...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Попробуете?",
        },
      ],
    },
    {
      choices: [
        "Хорошо, давайте|lesson_programming_begin_dont_understand_want",
        "Нет, всё-таки откажусь|lesson_programming_begin_continuation",
      ],
    },
  ],

  lesson_programming_begin_dont_understand_want: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вот и славненько!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Открывайте пока вот эту программу и я зачитаю задание",
          achiev_child_id: 25, // 3.10
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_programming_begin_exercise_lvl_1",
    },
  ],

  lesson_programming_begin_exercise_lvl_1: [
    {
      body: [
        {
          background: imikn_practice_2_explanation,
          leftSprite: "",
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Так-с, смотрите, слева расположена виндовс форма",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Это такой инструмент в программировании приложений, отвечающий за графический интерфейс пользователя",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вообще, подобных инструментов существует много, но мы сегодня попрактикуемся конкретно на этом",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "В этой форме могут содержаться различные элементы для управления программой",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Например кнопки, места для ввода текста, выпадающие списки и так далее",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Чтобы расставить элементы по своим местам можно их просто перетащить на форму, но это Вам сегодня не понадобится...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А вот для того, чтобы они работали, нам необходимо окно расположенное справа",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Здесь пишется программный код, который раздаёт указания всем элементам управления на форме",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Например, чтобы при нажатии кнопки происходило какое-то действие",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Именно этим Вы и займётесь!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вам необходимо сделать кнопки, расположенные на формочке слева, рабочими",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "То есть, чтобы при нажатии на кнопку “Красный”, например, фон формы также окрашивался в красный",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "В Вашем случае код уже написан, его необходимо лишь правильно расположить",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вот и всё задание, есть вопросы?",
        },
      ],
    },
    {
      choices: [
        "То есть нужно просто расставить код в правильном порядке?|lesson_programming_begin_exercise_lvl_1_question",
        "Нет|lesson_programming_begin_exercise_lvl1_no_questions",
      ],
    },
  ],

  lesson_programming_begin_exercise_lvl_1_question: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Именно",
        },
      ],
    },
    {
      choices: [
        "А как понять, правильно ли сделано?|lesson_programming_begin_exercise_lvl_1_question_2",
        "Промолчать|lesson_programming_begin_exercise_lvl1_question_continue",
      ],
    },
  ],

  lesson_programming_begin_exercise_lvl_1_question_2: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну смотрите, в коде есть три блока, каждый относится к своей отдельной кнопке",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "К какой конкретно - можно понять по названию",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Например, Button_Yellow - с английского дословно переводится как “кнопка” и “жёлтый”",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Следовательно кусок кода в фигурных скобках относится к жёлтой кнопке",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Свойство BackColor отвечает за цвет фона формочки",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Значит, чтобы всё работало нужно поставить нужный BackColor в соответствующий блок кода",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_programming_begin_exercise_lvl1_question_continue",
    },
  ],

  lesson_programming_begin_exercise_lvl1_question_continue: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: popov_npc_mask,
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "Анастасия Сулеймановна, подойдите пожалуйста!",
        },
        {
          leftSprite: getSpriteCharacterOfGender("default"),
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Всё, меня зовут, давайте, приступайте",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Как закончите - зовите!",
        },
      ],
    },
    {
      // exits: true,
      game: "programmingLvl1",
    },
  ],
  lesson_programming_begin_exercise_lvl2_question_continue: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: popov_npc_mask,
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "Анастасия Сулеймановна, подойдите пожалуйста!",
        },
        {
          leftSprite: getSpriteCharacterOfGender("default"),
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Всё, меня зовут, давайте, приступайте",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Как закончите - зовите!",
        },
      ],
    },
    {
      // exits: true,
      game: "programmingLvl2",
    },
  ],
  lesson_programming_begin_exercise_lvl1_no_questions: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Тогда приступайте, позовите, как закончите",
        },
      ],
    },
    {
      // exits: true,
      game: "programmingLvl1",
    },
  ],
  lesson_programming_begin_exercise_lvl2_no_questions: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Тогда приступайте, позовите, как закончите",
        },
      ],
    },
    {
      // exits: true,
      game: "programmingLvl2",
    },
  ],
  lesson_programming_begin_exercise_lvl_2: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Так-с, смотрите, задание будет с использованием виндовс форм. Вы знаете что это?",
        },
      ],
    },
    {
      choices: [
        "Да|lesson_programming_begin_exercise_lvl_2_yes",
        "Нет|lesson_programming_begin_exercise_lvl_2_no",
      ],
    },
  ],

  lesson_programming_begin_exercise_lvl_2_yes: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Замечательно, тогда объяснять не буду, перейдём сразу к заданию",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_programming_begin_exercise_lvl_2_continue",
    },
  ],

  lesson_programming_begin_exercise_lvl_2_no: [
    {
      body: [
        {
          background: imikn_practice_2_explanation,
          leftSprite: "",
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Так-с, смотрите, слева расположена сама виндовс форма",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Это такой инструмент в программировании приложений, отвечающий за графический интерфейс пользователя",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вообще, подобных инструментов существует много, но мы сегодня попрактикуемся конкретно на этом",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "В этой форме могут содержаться различные элементы для управления программой",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Например кнопки, места для ввода текста, выпадающие списки и так далее",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Чтобы расставить элементы по своим местам можно их просто перетащить на форму, но это Вам сегодня не понадобится...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А вот для того, чтобы они работали, нам необходимо окно расположенное справа",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Здесь пишется программный код, который раздаёт указания всем элементам управления на форме",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Например, чтобы при нажатии кнопки происходило какое-то действие",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "А теперь к заданию...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_programming_begin_exercise_lvl_2_continue",
    },
  ],

  lesson_programming_begin_exercise_lvl_2_continue: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вам необходимо сделать кнопки, расположенные на формочке слева, рабочими",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "То есть, чтобы при нажатии на кнопку “Красный”, например, фон формы также окрашивался в красный",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Для этого, в данном случае, нужно правильно дописать код",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вот и всё задание, есть вопросы?",
        },
      ],
    },
    {
      choices: [
        "Какой именно код нужно дописать?|lesson_programming_begin_exercise_lvl_2_question",
        "Нет|lesson_programming_begin_exercise_lvl2_no_questions",
      ],
    },
  ],

  lesson_programming_begin_exercise_lvl_2_question: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Код, который отвечает за окрашивание формы в нужный цвет при нажатии на кнопку",
        },
      ],
    },
    {
      choices: [
        "А как он примерно должен выглядеть?|lesson_programming_begin_exercise_lvl_2_question_2",
        "Промолчать|lesson_programming_begin_exercise_lvl2_question_continue",
      ],
    },
  ],

  lesson_programming_begin_exercise_lvl_2_question_2: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну смотрите, в задании уже есть часть кода, написанного до Вас",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Там есть три блока, каждый относится к своей отдельной кнопке, к какой конкретно - можно понять по названию",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Например, Button_Yellow - с английского дословно переводится как “кнопка” и “жёлтый”",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Следовательно кусок кода в фигурных скобках относится к жёлтой кнопке",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Чтобы изменить цвет фона формочки нужно BackColor приравнять к нужному цвету",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Вызов цветов осуществляется через Color точка и название желаемого цвета на английском",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "И не забывайте про точку с запятой в конце, это важно!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Хорошо, спасибо!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lesson_programming_begin_exercise_lvl2_question_continue",
    },
  ],

  lesson_programming_begin_continuation: [
    {
      body: [
        {
          background: imikn_practice_02,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну как хотите...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Только вот пара скоро закончится, да и вряд-ли будет что-то новое происходить",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Но дело Ваше...",
          achiev_child_id: 27, // 3.12
        },
        {
          leftSprite: "",
          rightSprite: "",
          speakSprite: "",
          nameSpeak: "",
          speakColor: "",
          text: "*стук в дверь*",
        },
        {
          rightSprite: anas_suleim,
          leftSprite: secretary,
          speakSprite: "left",
          nameSpeak: "Секретарь",
          speakColor: secretary_color,
          text: "Анастасия Сулеймановна! Не забудьте, Вас ждут на защите проектов после этой пары",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Да, да, помню, спасибо!",
        },
        {
          leftSprite: "",
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Итак, у нас заканчивается время, кто-то ещё готов сдавать?",
        },
        {
          leftSprite: popov_npc_mask,
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "Можно мы снова попробуем?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Ну, давайте, Михаил, первый вопрос...",
        },
        {
          leftSprite: "",
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*судорожно собирает вещи в рюкзак*",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Серёга, ты чего?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Да сейчас большая перемена будет, надо со всех ног в столовую бежать...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Иначе я снова покушать не успею...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*шёпотом* Справедливо! Минута промедления и уже километровая очередь соберётся... ",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "*тоже начинает быстро собирать вещи*",
        },
        {
          rightSprite: anas_suleim_angry,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Так! Чего зашуршали? Куда собираемся?! Пара ещё не окончена!",
        },
        {
          leftSprite: popov_npc_mask,
          rightSprite: anas_suleim,
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Хорошо, Михаил, ставлю 7 баллов",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "А почему не 10?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "На вопросы плохо отвечали! Надо лучше готовиться к занятиям!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Михаил",
          speakColor: popov_color,
          text: "Эх, ладно...",
        },
        {
          leftSprite: "",
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Итак, занятие окончено!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Если у кого-то есть вопросы - пишите мне на корпоративную почту",
        },
        {
          speakSprite: "right",
          nameSpeak: "Анастасия Сулеймановна",
          speakColor: anas_suleim_color,
          text: "Решим в индивидуальном порядке, можете быть свободными!",
        },
        {
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Бе-е-е-ж-и-и-и-м в сто-о-л-о-о-ву-у-ю-ю!!!!",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default lesson_programming_begin;
