import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardAchievement from "./CardChild";
import { Grid, Box, Container, Typography } from "@mui/material";
const StyledCardMedia = styled(CardMedia)(({ theme, isachieved }) => ({
  filter: isachieved ? "none" : "brightness(0.05)",
  boxShadow: "0px 0px 37px 10px rgba(0, 0, 0, 0.26)",
}));
export default function CardDetails(props) {
  return (
    <>
      <Container>
        <Box
          sx={{
            mt: 9,
            mb: 5,
            flexGrow: 1,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={2}></Grid>
            <Grid item xs={10}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Card>
                    <StyledCardMedia
                      component="img"
                      image={props.img}
                      alt={props.img}
                      isachieved={props.isAchieved}
                    />
                  </Card>
                </Grid>
                <Grid item xs={7}>
                  {props.childs &&
                    props.childs.map((child, i) => (
                      <CardAchievement
                        key={child.id}
                        id={child.id}
                        title={child.achiev_name}
                        desc={child.achiev_description}
                        img={
                          child.achiev_child_img && child.achiev_child_img !== "default.png"
                            ? require(`../../Assets/imikn/achievement/${child.achiev_child_img}`)
                            : ""
                        }
                        isAchieved={child.is_issued}
                      />
                    ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          {/* <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <Card sx={{ width: 300 }}>
                <StyledCardMedia
                  component="img"
                  image={props.img}
                  alt={props.img}
                  isachieved={props.isAchieved}
                  sx={{ width: 300 }}
                />

                <CardContent sx={{ textAlign: "center" }}>
                  <Typography variant="h4" color="text.secondary">
                    {props.title}
                  </Typography>
                  <Typography variant="h6" color="text.secondary">
                    {props.desc}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} md={8}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Grid item container xs={12} spacing={2}>
                  {props.childs &&
                    props.childs.map((child, i) => (
                      <Grid item key={child.id} xs={12} sm={6} md={4}>
                        <CardAchievement
                          id={child.id}
                          title={child.achiev_name}
                          desc={child.achiev_description}
                          isAchieved={child.is_issued}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Box>
            </Grid>
          </Grid> */}
        </Box>
      </Container>
    </>
  );
}
