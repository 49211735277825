import {
    getSpriteCharacterOfGender,
    getNamePlayer,
} from "../../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_first_coridor = require("../../../../assets/imikn/backgrounds/imikn_first_coridor.jpg");

// character
const guide_default = require("../../../../assets/imikn/characters/guide.png");
const guide_smile = require("../../../../assets/imikn/characters/guide_smile.png");

//color
const player_color = "#D4D4D4";
const guide_color = "#9DC7CC";

let lessons_intro_3 = {
    Start: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_default,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Тебя искали, кстати",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Кто?",
                },

                {
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Питон в пальто! Ха-ха-ха",
                },
            ],
        },
        {
            choices: [
                "...|intro_3_ans_1",
                "Не особо смешно...|intro_3_ans_2",
                "ПХАХАХАХАХАХАХА|intro_3_ans_3",
            ],
        },
    ],

    intro_3_ans_1: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Да ладно тебе, просто глупая шутка, чтоб разрядить обстановку",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Ха, окей",
                },
            ],
        },
        {
            exits: true,
        },
    ],

    intro_3_ans_2: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Да ладно тебе, просто глупая шутка, чтоб разрядить обстановку",
                },
                {
                    speakSprite: "left",
                    nameSpeak: getNamePlayer(),
                    speakColor: player_color,
                    text: "Ха, окей",
                },
            ],
        },
        {
            exits: true,
        },
    ],

    intro_3_ans_3: [
        {
            body: [
                {
                    background: imikn_first_coridor,
                    leftSprite: getSpriteCharacterOfGender("default"),
                    centerSprite: "",
                    rightSprite: guide_smile,
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "А ты в теме! Ха-ха-ха",
                },
                {
                    speakSprite: "right",
                    nameSpeak: "Богдан",
                    speakColor: guide_color,
                    text: "Ну ладно, давай, не буду отвлекать",
                    achiev_child_id: 18, // 3.1
                },
            ],
        },
        {
            exits: true,
        },
    ],
};

export default lessons_intro_3;
