import React from "react";
import AppRouter from "./Router/AppRouter";

export default function App() {
  return (
    <>
      <AppRouter />
    </>
  );
}
