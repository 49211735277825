import * as React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { CardActionArea, Box } from "@mui/material";
import "./styleHome.css";

const Cards = ({ image, label, description, href}) => {
  return (
    <Card
      className="cardsNews"
      // sx={{
      //   maxWidth: "calc(30%)",
      //   margin: 2,
      //   boxShadow: "0px 0px 37px 10px rgba(0, 0, 0, 0.26)",
      //   background: "rgba(18,18,18,0.5)",
      //   "&:hover": {
      //     transform: "scale(1.03)",
      //     transition: "transform 0.3s ease-in-out",
      //   },
      //   position: "relative",
      //   overflow: "hidden",
      // }}
    >
      <CardActionArea href={href}>
        <Box
          sx={{
            position: "relative",
            "&:hover img": {
              opacity: 0.5,
            },
            "& .text-desc": {
              visibility: "hidden",
              transform: "translateY(100%)",
              transition: "visibility 0s, transform 0.3s linear",
              height: 0,
            },
            "&:hover .text-desc": {
              visibility: "visible",
              transform: "translateY(0)",
              height: "auto",
            },
            "& .text-label": {
              transition: "transform 0.3s linear",
            },
            "&:hover .text-label": {
              transform: "translateY(0)",
            },
            
          }}
        >
          <img
            src={image}
            style={{
              width: "100%",
              height: "100%",
              transition: "opacity 0.3s",
            }}
          />

          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              color: "#fff",
              transition: "transform 0.3s",
              p: 1.5,
            }}
          >
            <Typography
              variant="h6"
              className="text-label"
              align="center"
              sx={{
                fontWeight: "bold",
                '@media (max-width: 450px)': {
                  fontSize: '3.1vw',
                },
                '@media (max-width: 800px) and (min-width: 450px)': {
                  fontSize: '2.5vw',
                },
              }}
            >
              {label}
            </Typography>
            <Typography variant="body1" className="text-desc" align="center"
            sx={{
              '@media (max-width: 450px)': {
                fontSize: '3.1vw',
              },
              '@media (max-width: 800px) and (min-width: 450px)': {
                fontSize: '2.5vw',
              },
            }}>
              {description}
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
};
export default Cards;
