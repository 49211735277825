import React, { useEffect, useState } from "react";
import { Container, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import BookImage from "../../../Assets/HomePage/Book.png";
import StudBook from "../../../Assets/HomePage/StudBook.png";
import AnnaCircle from "../../../Assets/HomePage/anna_circle.png";
import CloudBlock from "../../../Assets/HomePage/cloud_block.png";

export default function StudentBookBlock() {
  // const [isHovered, setIsHovered] = useState(false);

  // const handleHover = () => {
  //   setIsHovered(!isHovered);
  // };
  return (
    <Container maxWidth="lg" sx={{margin:"5% auto", padding: 0,}}>
      <Box sx={{position: "relative"}}>
        <Box sx={{ position: "relative"}}>
          <img
            src={BookImage}
            className="studBook"
            alt="Book"
            // style={{
            //   margin: "32% 5% 5%",
            //   width: "30%",
            //   transform: isHovered ? "none" : "rotate(-25deg)",
            //   transition: "transform 0.3s ease-in-out",
            // }}
            // onMouseEnter={handleHover}
            // onMouseLeave={handleHover}
          />
          
        </Box>
        <Box sx={{ position: "absolute", width:"70%", top: "0", right:0, height: "100%", 
          '@media (max-width: 800px)': {
            width: "85%"
                }}}>
          {/* <img
            src={StudBook}
            style={{
              width: "100%",
              // transform: "scale(-1, 1)"
            }}
          /> */}
          <img
              src={AnnaCircle}
              style={{
                width: "50%",
                position:"absolute",
                top:0,
                marginTop:"40%",
                right:0
              }}
            />
            <img
                src={CloudBlock}
                style={{
                  width: "85%",
                  position:"absolute",
                  top:0,
                  right:"30%",
                  '@media (max-width: 600px)': {
                    width: "90%",
                  }         
                }}
            />     
          <Typography
            sx={{
              position: "absolute",
              top: "10%",
              right: "26%",
              color: "black",
              fontSize:"2rem",
              width: "60%",
              '@media (max-width: 1400px) and (min-width: 800px)': {
                fontSize: '2vw',
                top: "6%",
              },
              '@media (max-width: 800px) and (min-width: 600px)': {
                top: "5%",
                fontSize: '2.7vw',
              },
              '@media (max-width: 600px)': {
                top: "8%",
                fontSize: '2.8vw',
              }
            }}
          >
            Книга студентов
          </Typography>
          <Typography
            sx={{
              textAlign:"center",
              position: "absolute",
              top: "16%",
              right: "40%",
              fontSize:"1.3rem",
              lineHeight: 1.4,
              color: "black",
              width: "63%",
              '@media (max-width: 1400px) and (min-width: 800px)': {
                top: "12%",
                fontSize: '1.5vw',
              },
              '@media (max-width: 800px) and (min-width: 600px)': {
                top: "14%",
                fontSize: '2.0vw',
                lineHeight:"1.2",
              },
              '@media (max-width: 600px)': {
                fontSize: '2.0vw',
                lineHeight:"1.2",
                top: "17%",
              }
            }}
          >
            — это сборник реальных историй, рассказанных студентами и
            выпускниками ТюмГУ. Здесь ты можешь узнать о занятиях, учебных дисциплинах, преподавателях и мероприятиях, а также об интересных и
            забавных ситуациях, которые случались со студентами во время учёбы!
            Переходи скорее!
          </Typography>
        </Box>
      </Box>
      {/* <div style={{ flexBasis: "70%" }}>
        <img
          src={BookImage}
          alt="Book"
          style={{
            width: 400,
            transform: isHovered ? "none" : "rotate(-25deg)",
            transition: "transform 0.3s ease-in-out",
          }}
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
        />
      </div>
      <div
        style={{
          flexBasis: "75%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <img
          src={AnnaCircle}
          style={{ width: "30%", transform: "scale(-1, 1)" }}
        />
        <img
          src={CloudBlock}
          style={{ width: "30%", transform: "scale(-1, 1)" }}
        />
        <div>
          <Typography variant="h3" gutterBottom>
            Книга студентов
          </Typography>
          <Typography variant="h5" gutterBottom>
            — это сборник реальных историй, рассказанных студентами и
            выпускниками ТюмГУ. Здесь ты можешь узнать о занятиях, учебных
            дисциплинах, преподавателях и мероприятиях, а также об интересных и
            забавных ситуациях, которые случались со студентами во время учёбы!
            Переходи скорее!
          </Typography>
        </div>
      </div> */}
    </Container>
  );
}
