import {
  getSpriteCharacterOfGender,
  getNamePlayer,
} from "../../../../../Components/dialogueSystem/scripts/MaleOfFemale";

// background
const imikn_lecture_full_empty = require("../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_full_empty.jpg");
const imikn_lecture_full__dob_luc = require("../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_full__dob_luc.jpg");
const imikn_lecture_projector_empty = require("../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_projector_empty.jpg");
const imikn_lecture_projector_01 = require("../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_projector_01.jpg");
const imikn_lecture_projector_02 = require("../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_projector_02.jpg");
const imikn_lecture_projector_03 = require("../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_projector_03.jpg");
const imikn_lecture_projector_04 = require("../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_projector_04.jpg");
const imikn_lecture_projector_05 = require("../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_projector_05.jpg");
const imikn_lecture_projector_06 = require("../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_projector_06.jpg");
const imikn_lecture_projector_07 = require("../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_projector_07.jpg");
const imikn_lecture_projector_08 = require("../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_projector_08.jpg");
const imikn_lecture_blackboard_empty = require("../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_blackboard_empty.jpg");
const imikn_lecture_blackboard_01 = require("../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_blackboard_01.jpg");
const imikn_lecture_blackboard_02 = require("../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_blackboard_02.jpg");
const imikn_lecture_blackboard_03 = require("../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_blackboard_03.jpg");
const imikn_lecture_blackboard_04 = require("../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_blackboard_04.jpg");
const imikn_lecture_blackboard_05 = require("../../../assets/imikn/backgrounds/lecture 01/imikn_lecture_blackboard_05.jpg");

// character
const dobr_luc = require("../../../assets/imikn/characters/dobr_luc.png");
const dobr_luc_smile = require("../../../assets/imikn/characters/dobr_luc_smile.png");
const dobr_luc_speaking = require("../../../assets/imikn/characters/dobr_luc_speaking.png");
const dobr_luc_angry = require("../../../assets/imikn/characters/dobr_luc_angry.png");
const dobr_luc_angry_clap = require("../../../assets/imikn/characters/dobr_luc_angry_clap.png");
const leader_gleb = require("../../../assets/imikn/characters/leader_gleb.png");
const leader_gleb_right = require("../../../assets/imikn/characters/leader_gleb_right.png");
const secretary = require("../../../assets/imikn/characters/secretary.png");
const sleepyhead_sleeping = require("../../../assets/imikn/characters/sleepyhead_sleeping.png");
const sleepyhead_woken = require("../../../assets/imikn/characters/sleepyhead_woken.png");
const sleepyhead = require("../../../assets/imikn/characters/sleepyhead.png");

//color
const player_color = "#D4D4D4";
const dobr_luc_color = "#D7DAED";
const leader_gleb_color = "#D2CDB5";
const secretary_color = "#F5F2BF";
const sleepyhead_color = "#D2B4B4";
const student_color = "#CCDFFF";
const another_student_color = "#D8FFF6";
const student_girl_color = "#CFCAFF";

let lecture = {
  Start: [
    {
      body: [
        {
          background: imikn_lecture_projector_empty,
          leftSprite: "",
          centerSprite: "",
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Добрый день, студенты! Рад вас всех видеть на своём занятии по компьютерным сетям",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Сегодня мы с вами поговорим о видах и строении компьютерных сетей, а также разберём лабораторную работу номер два",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Для начала напомните мне, что такое компьютерная сеть в общем понимании и зачем она нужна...",
        },
        {
          speakSprite: "center",
          nameSpeak: "Студенты",
          speakColor: student_color,
          text: "*молчание*",
        },
        {
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну давайте же, кто был на прошлой лекции?",
        },
        {
          speakSprite: "center",
          nameSpeak: "Студенты",
          speakColor: student_color,
          text: "*тишина*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Хм, ну раз такая тишина, давайте я спрошу случайного человека из списка... Хм-м-м, Иванов!",
        },
        {
          speakSprite: "center",
          nameSpeak: "Иванов",
          speakColor: student_color,
          text: "*тяжёлый вздох*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну чего же Вы вздыхаете, давайте, давайте, вспоминаем, компьютерная сеть - это-о-о...",
        },
        {
          speakSprite: "center",
          nameSpeak: "Иванов",
          speakColor: student_color,
          text: "Компьютерная сеть - это-о-о...",
        },
        {
          speakSprite: "center",
          nameSpeak: "Иванов",
          speakColor: student_color,
          text: "*тяжёлый вздох*",
        },
        {
          speakSprite: "center",
          nameSpeak: "Иванов",
          speakColor: student_color,
          text: "Ну это, куча компьютеров, соединённых проводами и... и... и у них там есть общая папка...",
        },
        {
          speakSprite: "center",
          nameSpeak: "Иванов",
          speakColor: student_color,
          text: "Если в папке что-то есть, ну файлики там...то их видно со всех компьютеров в сети...",
        },
        {
          speakSprite: "center",
          nameSpeak: "Иванов",
          speakColor: student_color,
          text: "Ну, а... а нужна она, чтобы обмениваться этими файликами...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "*тяжёлый вздох*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну, допустим. Совсем, конечно, не по определению, но хоть что-то в голове осталось...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Компьютерная сеть – это совокупность компьютеров и различных устройств, соединенных с помощью каналов связи в единую систему",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Которая обеспечивает разделение ресурсов и обмен информацией между компьютерами",
        },
        {
          speakSprite: "center",
          nameSpeak: "Иванов",
          speakColor: student_color,
          text: "Я так и сказал!",
        },
        {
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ха-ха, хорошо, поставлю вам плюсик за находчивость...",
        },
        {
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Итак, начнём с видов компьютерных сетей",
        },
        {
          background: imikn_lecture_projector_01,
          rightSprite: dobr_luc_speaking,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Современные сети можно классифицировать по различным признакам: по охватываемой ими территории",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Другими словами, по удаленности компьютеров друг от друга, топологии, назначению, методам коммуникации и т.д.",
        },
        {
          background: imikn_lecture_full__dob_luc,
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "*шёпотом* Ты что-то понял?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Не-а",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "*шёпотом* Только начало пары, а мы с тобой уже ничего не понимаем...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*громким шёпотом* Да не важно, эта теория - фи-гня! Главное понять, как лабу делать",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "*шёпотом* Ты говори потише, а то фигня будет у тебя в зачётке потом...",
        },
        {
          background: imikn_lecture_projector_01,
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "... это были виды сетей по методам коммуникации",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Но больше всего нас с вами интересует классификация сетей по охватываемой территории",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* Меня вот не интересует это...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Их всего три: локальные, региональные и глобальные",
        },
        {
          background: imikn_lecture_projector_02,
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Как раз таки локальную сеть или LAN вы попытаетесь построить сами на сегодняшней практической паре...",
        },
        {
          rightSprite: dobr_luc_speaking,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Термин «LAN» может описывать и маленькую офисную сеть, и сеть уровня большого завода, занимающего несколько сотен гектаров",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Локальные сети являются сетями закрытого типа, доступ к ним разрешён только ограниченному кругу пользователей",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Для которых работа в такой сети непосредственно связана с их профессиональной деятельностью",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Региональные сети или MAN, как можно было понять из названия, используются для передачи данных на средние расстояния",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "То есть масштаба района, города, региона...",
        },
        {
          background: imikn_lecture_full__dob_luc,
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Черт, опять *зевок* не выспался... Вчера катка до утра шла, тиммейты - нубы конкретные, просто не понимаю, как они вообще компьютер включают?!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другой студент",
          speakColor: another_student_color,
          text: "Может за пять минут до конца пары свалим? В магаз за энергетиком, м?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Отличная *зевок* идея, ни черта не понимаю...",
        },
        {
          background: imikn_lecture_projector_03,
          rightSprite: dobr_luc_speaking,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Таким образом, если объединить глобальные, региональные и локальные вычислительные сети, то получится многоуровневая иерархия",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Самым большим и известным примером такой иерархии в настоящее время является «сеть сетей» - Интернет",
        },
        {
          background: imikn_lecture_full__dob_luc,
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "Эй, ты вчера видел новую серию?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Да! Она такая классная, но вот новый персонаж, которого они добавили...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "Н-да, вообще ни о чем. Зачем он нужен для сюжета?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "Не знаю...",
        },
        {
          background: imikn_lecture_projector_03,
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Вот такие виды компьютерных сетей существуют, всё понятно?",
        },
        {
          speakSprite: "center",
          nameSpeak: "Студенты",
          speakColor: student_color,
          text: "*молчание*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Может быть есть вопросы?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну, ребята, поговорите со мной, мне же надо видеть, понимаете вы или нет",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Если я буду получать в ответ тишину, мы не продвинемся с мертвой точки. Так что активизируйтесь!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Или вам, как в школе, нужно провести физминутку?",
        },
        {
          speakSprite: "center",
          nameSpeak: "Студенты",
          speakColor: student_color,
          text: "Нееет, мы же не дети!",
        },
        {
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Эх, дети, еще какие... Ладно, я отвлекся. Продолжим...",
        },
        {
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Теперь поговорим подробнее про локальные сети",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Если простыми словами, то это два или большее число компьютеров, серверов, рабочих станций, терминалов и прочих приборов",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Которые подключены к единому каналу передачи данных, и могут связываться друг с другом для обмена информацией",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент с первой парты",
          speakColor: student_color,
          text: "То есть они связаны проводами?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "*вздыхает* Ну, можно и так сказать...",
        },
        {
          rightSprite: dobr_luc_speaking,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Обычно такая сеть объединяет компьютеры, компактно расположенные в одном или нескольких зданиях",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент с первой парты",
          speakColor: student_color,
          text: "А если зданий много, почти целая улица, это всё ещё локальная или уже региональная сеть?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Скорее локальная, её размер не превышает нескольких километров, региональные же начинаются от нескольких десятков и сотен километров...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент с первой парты",
          speakColor: student_color,
          text: "А-а-а",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Так вот...",
        },
        {
          background: imikn_lecture_full__dob_luc,
          rightSprite: leader_gleb,
          leftSprite: getSpriteCharacterOfGender("default"),
          speakSprite: "right",
          nameSpeak: "Сосед по парте",
          speakColor: leader_gleb_color,
          text: "Хей, привет!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Привет",
        },
        {
          speakSprite: "right",
          nameSpeak: "Сосед по парте",
          speakColor: leader_gleb_color,
          text: "Я тебя не видел здесь раньше. Тебя перевели?",
        },
      ],
    },
    {
      choices: [
        "*Промолчать и слушать лекцию дальше*|lecture_listen",
        "Нет, я абитуриент|lecture_talk_neighbor",
      ],
    },
  ],

  lecture_listen: [
    {
      body: [
        {
          background: imikn_lecture_full__dob_luc,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Сосед по парте",
          speakColor: leader_gleb_color,
          text: "Хм, какие мы неразговорчивые! Ладно, не буду отвлекать...",
        },
        {
          background: imikn_lecture_projector_04,
          rightSprite: dobr_luc_speaking,
          leftSprite: "",
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Теперь поговорим о том из чего состоят компьютерные сети",
          achiev_child_id: 19, // 3.2
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Во-первых, это конечно рабочая станция, то есть компьютер рядового пользователя сети, иначе говоря - ПК",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Во-вторых, сеть может содержать сервер - это специально выделенный высокопроизводительный компьютер",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Который способен управлять работой сети и/или предоставлять свои ресурсы для рабочих станций",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Подробнее поговорим об этом на следующей лекции",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Также, в сеть входит различное коммуникационное оборудование",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "То есть технические средства, которые помогают передавать сигнал, соединять ПК и так далее",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Сейчас из всего разнообразия такого оборудования нас интересует только коммутатор, об остальном также будут лекции в будущем",
        },
        {
          background: imikn_lecture_projector_05,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Коммутатор, или как его еще называют свитч – это устройство обеспечивающее соединение узлов компьютерной сети",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Нужен он для организации единой системы доступа пользователей ко всем ресурсам: программам, данным и так далее",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "С его помощью можно соединить несколько сетевых узлов в одну сеть, например, если есть два кабинета с десятью ПК в каждом...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "То чтобы объединить их в компьютерную сеть, можно, например, соединить все провода в общий коммутатор",
        },
        {
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Кстати, примерно такое задание и будет в лабораторной работе...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студенты",
          speakColor: student_color,
          text: "*вздыхают*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Нечего грустить, там нет ничего сложного!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Вы будете работать в специальной программе и у КАЖДОГО будет свой индивидуальный вариант!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент",
          speakColor: student_color,
          text: "*шёпотом* За что-о-о-о-о...",
        },
        {
          rightSprite: dobr_luc_speaking,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Суть задания - сделать маленькую компьютерную сеть",
        },
        {
          background: imikn_lecture_projector_06,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Сначала необходимо соединить всё оборудование, о котором сказано в тексте задания вашего варианта, проводами",
        },
        {
          background: imikn_lecture_projector_07,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Затем, необходимо настроить все ПК в сети",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "При двойном щелчке на иконку ПК откроется панель настройки",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Здесь необходимо прописать IP-адрес для каждого ПК! Чуть позже покажу как",
        },
        {
          background: imikn_lecture_projector_08,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну и чтобы проверить работоспособность вашей сети, на вкладке “Console” необходимо ввести команду “Ping <IP другого компьютера в сети>”",
        },
        {
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Если результат будет хорошим, то вы получите ответ “success”",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "В случае, если команда неверна или неправильно настроены ПК вернётся - “error, wrong command”",
        },
        {
          background: imikn_lecture_blackboard_empty,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну, а теперь пример!",
        },
        {
          background: imikn_lecture_blackboard_01,
          rightSprite: dobr_luc_speaking,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Вот мы соединили два ПК и один коммутатор в сеть с помощью проводов",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Теперь нужно настроить IP",
        },
        {
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Кто мне скажет, что это такое?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент с первой парты",
          speakColor: student_color,
          text: "Это уникальный адрес устройства!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Верно! И как он выглядит?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент с первой парты",
          speakColor: student_color,
          text: "Ну три цифры, точка, три цифры, точка, три цифры, точка, и три цифры!",
        },
        {
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну, в целом да, можно было, конечно, не перечислять так...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "IP-адрес имеет длину 32 бита или 4 байта",
        },
        {
          rightSprite: dobr_luc_speaking,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Его можно представить несколькими способами, но более привычна точечно-десятичная форма записи",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "При которой IP-адрес для удобства разделяется на четыре блока по 8 бит или 1 байт",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Каждый из которых записывается в десятичном виде, то есть числом от 0 до 255 и отделяется от других точкой",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Более подробно, что означает каждая цифра в IP мы разберём на следующих занятиях...",
        },
        {
          background: imikn_lecture_blackboard_02,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Смотрите, в данном случае, чтобы объединить эти два ПК в сеть, необходимо, чтобы у них полностью совпадали первые три цифры",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Например так, ну например сто, точка, десять, точка, хм-м, ну пусть будет пять",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "А вот последняя - это номер конкретного ПК, они должны быть различны",
        },
        {
          background: imikn_lecture_blackboard_03,
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Так как это компьютер номер один, значит тут один, а тут два",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студент с первой парты",
          speakColor: student_color,
          text: "А почему не ноль?! Мы же программисты, у нас всё начинается с нуля!",
        },
        {
          rightSprite: dobr_luc_smile,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Я лишь привожу пример, Вы же можете делать как хотите в своём варианте, тут я вас не ограничиваю!",
        },
        {
          background: imikn_lecture_blackboard_04,
          rightSprite: dobr_luc_speaking,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Теперь, нужно проверить работоспособность, для этого введём в консоль первого компьютера такую команду",
        },
        {
          background: imikn_lecture_blackboard_05,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Видите, тут последняя цифра - это номер второго ПК, поэтому всё пройдёт успешно...",
        },
        {
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Глеб, помой доску, пожалуйста...",
        },
        {
          leftSprite: leader_gleb_right,
          speakSprite: "left",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Хорошо!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lecture_common_part",
    },
  ],

  lecture_talk_neighbor: [
    {
      body: [
        {
          background: imikn_lecture_full__dob_luc,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: leader_gleb,
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Меня преподаватель пригласил послушать...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Сосед по парте",
          speakColor: leader_gleb_color,
          text: "А, вот как. Ты вольнослушатель. Меня, кстати, Глеб зовут. Я - староста этой группы",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: `А я ${getNamePlayer()}`,
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Приятно познакомится. Как тебе здесь? Я не только про лекцию, про весь институт, в целом, здание там, кабинеты... Нравится?",
        },
      ],
    },
    {
      choices: [
        "Да, мне нравится|lecture_talk_neighbor_yes",
        "Не знаю, моё приключение по институту только началось|lecture_talk_neighbor_no",
      ],
    },
  ],

  lecture_talk_neighbor_yes: [
    {
      body: [
        {
          background: imikn_lecture_full__dob_luc,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Это хорошо. Хотя само здание - это бывшая школа, но изменения здесь идут полным ходом!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Интересно, как тут всё будет выглядеть через десять лет...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lecture_talk_neighbor_continue",
    },
  ],

  lecture_talk_neighbor_no: [
    {
      body: [
        {
          background: imikn_lecture_full__dob_luc,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Ого! Тогда не буду спойлерить, но ты обязательно потом пройдись. Тебе точно понравится!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lecture_talk_neighbor_continue",
    },
  ],

  lecture_talk_neighbor_continue: [
    {
      body: [
        {
          background: imikn_lecture_full__dob_luc,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Кстати, тебе очень повезло!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Почему?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Потому что Добромир Люцианович довольно добрый преподаватель, думаю первое впечатление от лекции у тебя останется хорошим...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Взять хоть ситуацию со Стёпой...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "С кем?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Ну Иванов, его в начале пары ещё спросили...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А-а-а-а...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Будь тут не Добромир Люцианович, а к примеру, Анастасия Сулеймановна, от Стёпы и пылинки не осталось бы... Очень уж она...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Строгая!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "А так, вон, сидит, лыбится, ещё и плюсик заработал, дово-о-ольный как слон! Его на прошлой паре даже не было тут...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Это как? Почему его тогда спросили?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Потому что его друг отметил, так что чисто технически, он как будто тут и был...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Я вот считаю, что это плохо...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Если не хочет учиться, так пусть отчисляется, чего тут место учебное занимать...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Я, например, категорически против того, чтоб отмечать отсутствующих, когда меня просят",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Они потом на шею сядут и ножки свесят, совсем перестанут ходить",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "А тем более, если где-то всплывет, что я занимаюсь подобным... Мне ж потом преподаватели доверять не будут",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Ты вообще представляешь себе старосту группы, которому преподаватели не доверяют?",
        },
      ],
    },
    {
      choices: [
        "Нет|lecture_talk_neighbor_no_2",
        "Не знаю... А что вообще входит в обязанности старосты?|lecture_talk_neighbor_dontknow",
      ],
    },
  ],

  lecture_talk_neighbor_no_2: [
    {
      body: [
        {
          background: imikn_lecture_full__dob_luc,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Вот именно, потому что это не совместимые вещи!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lecture_talk_neighbor_continue_2",
    },
  ],

  lecture_talk_neighbor_dontknow: [
    {
      body: [
        {
          background: imikn_lecture_full__dob_luc,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Хм, в основном, это передача важной информации от преподавателя - остальной группе",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Также некоторые преподаватели не хотят тратить своё время на то, чтобы отмечать присутствие студентов на паре и просят меня...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А-а-а, тогда понятно, почему доверие в данном случае важно...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Ага, ещё бы!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lecture_talk_neighbor_continue_2",
    },
  ],

  lecture_talk_neighbor_continue_2: [
    {
      body: [
        {
          background: imikn_lecture_projector_empty,
          leftSprite: secretary,
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "left",
          nameSpeak: "Секретарь",
          speakColor: secretary_color,
          text: "Добромир Люцианович, Вас вызывают в серверную!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ох, ладно. Передайте, что я после пары зайду",
        },
        {
          speakSprite: "left",
          nameSpeak: "Секретарь",
          speakColor: secretary_color,
          text: "Нет, там срочно, институтский сервер “упал”, как мне сказали...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Что-ж такое! Хорошо, сейчас подойду...",
        },
        {
          leftSprite: "",
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Так, мне нужно ненадолго отлучиться. Вы пока посидите здесь тихонько. Я скоро вернусь",
        },
        {
          background: imikn_lecture_full_empty,
          rightSprite: leader_gleb,
          leftSprite: getSpriteCharacterOfGender("default"),
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Эх, ушел... слушай, можешь пожалуйста отметиться за моего друга? Когда назовут его имя, я тебя подтолкну, а ты руку подними",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ха-ха-ха! Ты же не отмечаешь отсутствующих. С чего вдруг такая щедрость? Пять минут тут распинался, как это плохо...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Да это мой кореш, я знаю, что его по уважительной причине сегодня нет, поэтому... Подними руку, пожалуйста...",
        },
      ],
    },
    {
      choices: [
        "Ладно|lecture_talk_neighbor_yes_check_in",
        "Нет. Если отсутствует по уважительной причине, значит, последствий не будет|lecture_talk_neighbor_no_check_in",
      ],
    },
  ],

  lecture_talk_neighbor_yes_check_in: [
    {
      body: [
        {
          background: imikn_lecture_full_empty,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Спасибо большое! На перемене встретимся в кафетерии, я куплю тебе чего-нибудь!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Замётано!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lecture_talk_neighbor_continue_3",
    },
  ],

  lecture_talk_neighbor_no_check_in: [
    {
      body: [
        {
          background: imikn_lecture_full_empty,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Ладно, попытаться стоило...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lecture_talk_neighbor_continue_3",
    },
  ],

  lecture_talk_neighbor_continue_3: [
    {
      body: [
        {
          background: imikn_lecture_full_empty,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Куда будешь поступать?",
        },
      ],
    },
    {
      choices: [
        "Думаю, что начну свое обучение в ИМиКНе|lecture_talk_neighbor_imikn",
        "Так много всего... Нужно хорошо подумать|lecture_talk_neighbor_need_to_think",
      ],
    },
  ],

  lecture_talk_neighbor_imikn: [
    {
      body: [
        {
          background: imikn_lecture_full_empty,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Это здорово! Здесь готовят первоклассных специалистов, и выбор профессий после выпуска - очень большой!",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lecture_talk_neighbor_continue_4",
    },
  ],

  lecture_talk_neighbor_need_to_think: [
    {
      body: [
        {
          background: imikn_lecture_full_empty,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Правильно, торопиться пока некуда",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lecture_talk_neighbor_continue_4",
    },
  ],

  lecture_talk_neighbor_continue_4: [
    {
      body: [
        {
          background: imikn_lecture_blackboard_empty,
          leftSprite: "",
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ох, задержали меня... Итак, на чем я тут остановился...",
        },
        {
          background: imikn_lecture_full__dob_luc,
          leftSprite: getSpriteCharacterOfGender("default"),
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "О, Добромир Люцианович пришел, нужно все-таки дослушать материал. Так что, не отвлекайся...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ла-а-адно",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: "lecture_common_part",
    },
  ],

  lecture_common_part: [
    {
      body: [
        {
          background: imikn_lecture_blackboard_empty,
          leftSprite: "",
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну что ж, виды и строение компьютерных сетей мы с вами рассмотрели, лабораторную работу разобрали",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Вроде бы на этом всё...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Вопросы есть?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Студенты",
          speakColor: student_color,
          text: "*тишина*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ох... Твоя песня хороша, начинай сначала... Всё как обычно!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ладно, давайте отмечаться...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Агафонов!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Агафонов",
          speakColor: student_color,
          text: "Здесь!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Аистов!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Аистов",
          speakColor: student_color,
          text: "Присутствует!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Артёмова!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Артёмова",
          speakColor: student_girl_color,
          text: "Тут!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Баранов!",
        },
        {
          leftSprite: sleepyhead_sleeping,
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "*храп*",
        },
        {
          rightSprite: dobr_luc_angry,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Баранов!!!",
        },
        {
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "*храп усиливается*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Вижу, что кого-то утомила моя лекция...",
        },
        {
          rightSprite: dobr_luc_angry_clap,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "*хлопок в ладошки* Рота, подъем!",
        },
        {
          leftSprite: sleepyhead_woken,
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "А?! Что?!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Просыпаемся-просыпаемся, голубчик, я еще не закончил...",
        },
        {
          rightSprite: dobr_luc_angry,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Баранов, Вы?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Баранов",
          speakColor: sleepyhead_color,
          text: "Да, да, я...",
        },
        {
          leftSprite: sleepyhead,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ясненько...",
        },
        {
          leftSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Волков!",
        },
        {
          background: imikn_lecture_full__dob_luc,
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "Ой, девачки, я вчера все-таки сходила с тем парнем на свидание...",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другая студентка",
          speakColor: another_student_color,
          text: "Да?! И как?",
        },
        {
          speakSprite: "left",
          nameSpeak: "Другая студентка",
          speakColor: another_student_color,
          text: "Давай рассказывай!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "Ну коро-о-оче-е, он повёл меня в кафе...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: ["lecture_talk_neighbor", "lecture_communicate_neighbor", "lecture_didnt_communicate_neighbor"],
    },
  ],

  lecture_didnt_communicate_neighbor: [
    {
      body: [
        {
          background: imikn_lecture_blackboard_empty,
          leftSprite: "",
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ладно, всем спасибо, лекция окончена!"
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Жду всех на практическом занятии!",
          visibleLesson: "networking_lesson", // Открывается практика по сетям
        },
      ],
    },
    {
      exits: true,
    },
  ],

  lecture_communicate_neighbor: [
    {
      body: [
        {
          background: imikn_lecture_full__dob_luc,
          leftSprite: "",
          centerSprite: "",
          rightSprite: "",
          speakSprite: "right",
          nameSpeak: "Студентка",
          speakColor: student_girl_color,
          text: "Очень классное...",
        },
      ],
    },
    {
      choices: [],
    },
    {
      jump: ["lecture_talk_neighbor_yes_check_in", "lecture_take_quest", "lecture_didnt_take_quest"],
    },
  ],

  lecture_didnt_take_quest: [
    {
      body: [
        {
          background: imikn_lecture_blackboard_empty,
          leftSprite: "",
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ладно, всем спасибо, лекция окончена, жду всех на практическом занятии!",
        },
        {
          background: imikn_lecture_full__dob_luc,
          leftSprite: getSpriteCharacterOfGender("default"),
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Приятно было познакомиться и пообщаться, надеюсь ещё увидимся!",
          visibleLesson: "networking_lesson", // Открывается практика по сетям
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Взаимно, пока!",
        },
      ],
    },
    {
      exits: true,
    },
  ],

  lecture_take_quest: [
    {
      body: [
        {
          background: imikn_lecture_full__dob_luc,
          leftSprite: getSpriteCharacterOfGender("default"),
          centerSprite: "",
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "*толкает*",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ай! Что такое?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Когда скажет Яковлев, поднимай руку...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "А! Точно...",
        },
        {
          background: imikn_lecture_blackboard_empty,
          leftSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Яковлев!",
        },
      ],
    },
    {
      choices: [
        "Поднять руку|lecture_raise_hand",
        "Крикнуть “Здесь”|lecture_shout",
        "Ничего не делать|lecture_todo_nothing",
      ],
    },
  ],

  lecture_raise_hand: [
    {
      body: [
        {
          background: imikn_lecture_blackboard_empty,
          leftSprite: "",
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Яковлев!!!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "*осматривает кабинет*",
        },
        {
          rightSprite: dobr_luc_angry,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Чего-о-о?!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Вы посмотрите! Всего одна пара, а уже абитуриента плохому научили!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "У меня память на лица очень хорошая! Не нужно тут спектакль устраивать...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ставлю Яковлеву “Н” и Глебу заодно...",
        },
        {
          leftSprite: leader_gleb,
          speakSprite: "left",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "А я тут причём?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ну, а кто же мог надоумить юное дарование обманом заниматься?",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Тем более вы там дружите, насколько я помню...",
        },
        {
          leftSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ладно, всем спасибо, лекция окончена, жду всех на практическом занятии!",
          visibleLesson: "networking_lesson", // Открывается практика по сетям
        },
        {
          leftSprite: getSpriteCharacterOfGender("default"),
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Эх-х, не получилось...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Не нужно было пробовать, последствий бы не было... А теперь из-за меня тако-о-е...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Да ничего страшного, это я виноват, как старший, возьму всю ответственность на себя!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Но спасибо за попытку, надеюсь ещё увидимся!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "И я... Пока!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Пока!",
          achiev_child_id: 35, // 3.3
        },
      ],
    },
    {
      exits: true,
    },
  ],

  lecture_shout: [
    {
      body: [
        {
          background: imikn_lecture_blackboard_empty,
          leftSprite: "",
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Чего-то у Яковлева голос изменился...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Простудился что-ли?",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Да, ангина...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ой-й, лучше бы дома сидели бы тогда...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ладно, всем спасибо, лекция окончена, жду всех на практическое занятие!",
          visibleLesson: "networking_lesson", // Открывается практика по сетям
        },
        {
          background: imikn_lecture_full__dob_luc,
          leftSprite: getSpriteCharacterOfGender("default"),
          rightSprite: leader_gleb,
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Уф-ф, пронесло! Я и не подумал, что если руку поднимать, то он посмотрит и поймёт, что ты не Яковлев, ха-ха-ха!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Смекалка-то хорошо у тебя работает! В универе пригодится!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ха-ха-ха",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Ладно, спасибо ещё раз, надеюсь ещё увидимся!",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Ага, пока",
          achiev_child_id: 36, // 3.4
        },
      ],
    },
    {
      exits: true,
    },
  ],

  lecture_todo_nothing: [
    {
      body: [
        {
          background: imikn_lecture_blackboard_empty,
          leftSprite: "",
          centerSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Яковлев!!!",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "*осматривает кабинет*",
        },
        {
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ага, отсутствует...",
        },
        {
          background: imikn_lecture_full__dob_luc,
          rightSprite: leader_gleb,
          leftSprite: getSpriteCharacterOfGender("default"),
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "*шёпотом* Ну мы же договорились...",
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "*шёпотом* Да я что-то...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "*шёпотом* Понятно! У страха глаза велики... Ну и ладно...",
          achiev_child_id: 20, // 3.5
        },
        {
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "*шёпотом* Извини... А если бы Добромир Люцианович посмотрел...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "*шёпотом* Да он не особо смотрит кто говорит или руку поднимает, у него же студентов целая ку-у-уча, в глаза всех не видел...",
        },
        {
          background: imikn_lecture_blackboard_empty,
          leftSprite: "",
          rightSprite: dobr_luc,
          speakSprite: "right",
          nameSpeak: "Добромир Люцианович",
          speakColor: dobr_luc_color,
          text: "Ладно, всем спасибо, лекция окончена, жду всех на практическом занятии!",
          visibleLesson: "networking_lesson", // Открывается практика по сетям
        },
        {
          background: imikn_lecture_full__dob_luc,
          rightSprite: leader_gleb,
          leftSprite: getSpriteCharacterOfGender("default"),
          speakSprite: "left",
          nameSpeak: getNamePlayer(),
          speakColor: player_color,
          text: "Эх, я пойду...",
        },
        {
          speakSprite: "right",
          nameSpeak: "Глеб",
          speakColor: leader_gleb_color,
          text: "Бывай...",
        },
      ],
    },
    {
      exits: true,
    },
  ],
};

export default lecture;
