import { Container, Box } from "@mui/material";
import Card from "./CardGame";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export default function ListSection(props) {
  const theme = useTheme();
  const { instName, data } = props;
  const intro_local = JSON.parse(localStorage.getItem("intro"));
  console.log(intro_local);
  return (
    <>
      <Container component="main" maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            background: theme?.palette?.box.main,
            boxShadow: `0px 0px 15px 2px ${theme?.palette?.box.shadow}`,
            color: theme?.palette?.text.primary,
            height: 100,
            borderRadius: 2,
            alignItems: "center",
            width: "fit-content",
            margin: "0 auto",
            padding: 4,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
            }}
          >
            {data.name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            mb: 10,
          }}
        >
          <Card
            id={1}
            image={data.imageDirection}
            title="Раздел с направлениями"
            url={`/gamesection/${instName}/direction`}
          />
          <Card
            id={2}
            image={data.imageTeacher}
            title="Раздел с преподавателями"
            url={`/gamesection/${instName}/teacher`}
          />
          <Card
            id={3}
            image={data.imageAddLocation}
            title="Раздел с учебными занятиями"
            url={`/gamesection/${instName}/lesson`}
          />
        </Box>
      </Container>
    </>
  );
}
