import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonIcon from "@mui/icons-material/Person";
import AbcIcon from "@mui/icons-material/Abc";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FeedbackIcon from "@mui/icons-material/Feedback";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import SchoolIcon from "@mui/icons-material/School";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import Person4Icon from "@mui/icons-material/Person4";

import List from "@mui/material/List";
import MenuItem from "./MenuItem";
import Divider from "@mui/material/Divider";

const MENU = [
  {
    label: "Вернуться на сайт",
    icon: HomeIcon,
    href: "/",
  },
  {
    label: "Dashboard",
    icon: DashboardIcon,
    href: "/admin/dashboard",
  },

  {
    label: "Истории",
    icon: AutoStoriesIcon,
    href: "/admin/stories",
  },
  {
    label: "Азбука",
    icon: AbcIcon,
    href: "/admin/vocabulary",
  },
  {
    label: "Достижения",
    icon: EmojiEventsIcon,
    href: "/admin/achievements",
  },
  {
    label: "Обратная связь",
    icon: FeedbackIcon,
    href: "/admin/feedback",
  },
];

const MENU2 = [
  {
    label: "Пользователи",
    icon: PersonIcon,
    href: "/admin/users",
  },
  {
    label: "Роли",
    icon: Person4Icon,
    href: "/admin/roles",
  },
  {
    label: "Институты",
    icon: SchoolIcon,
    href: "/admin/institutes",
  },
  {
    label: "Направления",
    icon: DirectionsWalkIcon,
    href: "/admin/directions",
  },
];

const ListDrawer = () => {
    return (
      <>
        <List>
          {MENU.map((menu, i) => (
            <MenuItem
              key={i}
              href={menu.href}
              label={menu.label}
              icon={menu.icon}
            />
          ))}
        </List>
        <Divider />
        <List>
          {MENU2.map((menu2, i) => (
            <MenuItem
              key={i}
              href={menu2.href}
              label={menu2.label}
              icon={menu2.icon}
            />
          ))}
        </List>
      </>
    );
  };
  export default ListDrawer;
  