import React from "react";
import { Link } from "react-router-dom";
import Image from "../../Assets/logo-white.png";
import ErrorImage from "../../Assets/Errors/error_something_wrong.png";
import "./error.css";
import { Typography } from "@mui/material";
export default function Error404() {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          background: "rgb(18,18,18)",
        }}
      >
        <img src={ErrorImage} style={{ width: "100%" }} />
        <div
          style={{
            position: "absolute",
            top: "20%",
            right: "20%",
          }}
        >
          <Typography variant="h1">404</Typography>
          <Typography variant="h5">Страница не найдена</Typography>
          <div className="image-container">
            <img
              className="logo-error"
              src={Image}
              alt="Your Image"
              style={{ width: "5rem", height: "5rem" }}
            />
          </div>
          <div className="mt-3">
            <Link style={{ color: "white" }} to="/">
              Вернуться на главную
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
